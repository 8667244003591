/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-03-16 21:44:10
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { connect } from 'react-redux';

import { Canvas, RectDrawingMethod } from 'cvat-canvas-wrapper';
import {
    mergeObjects,
    groupObjects,
    splitTrack,
    redrawShapeAsync,
    rotateCurrentFrame,
    repeatDrawShapeAsync,
    pasteShapeAsync,
    resetAnnotationsGroup,

    rememberObject,
    resetCanvas,
    createAnnotationsAsync,
} from 'actions/annotation-actions';
import ControlsSideBarComponent from 'components/annotation-page/standard-workspace/controls-side-bar/controls-side-bar';
import {
    ActiveControl, CombinedState, Rotation,
    ShapeType,
    ObjectType,
} from 'reducers/interfaces';
import { KeyMap } from 'utils/mousetrap-react';

interface StateToProps {
    canvasInstance: Canvas;
    rotateAll: boolean;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    selectedStatesID: number[];
    states: any[];
    activeLabelID: number;
    activeObjectType: ObjectType;
    activeShapeType: ShapeType;
    activeNumOfPoints: number;
    activeRectDrawingMethod: RectDrawingMethod | undefined;
    jobInstance: any;
    frame: number;
}

interface DispatchToProps {
    mergeObjects(enabled: boolean): void;
    groupObjects(enabled: boolean): void;
    splitTrack(enabled: boolean): void;
    rotateFrame(angle: Rotation): void;
    resetGroup(): void;
    repeatDrawShape(): void;
    pasteShape(): void;
    redrawShape(): void;
    onDrawStart(
        shapeType: ShapeType,
        labelID: number,
        objectType: ObjectType,
        points?: number,
        rectDrawingMethod?: RectDrawingMethod,
    ): void;
    onAnnotationCreate(sessionInstance: any, frame: number, states: any[]): void;
    onRememberObject(labelID: number): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            canvas: { instance: canvasInstance, activeControl },
            job: { instance: jobInstance, labels },
            // annotations: { isDrawShape },
            drawing: {
                activeLabelID,
                activeObjectType,
                activeShapeType,
                activeNumOfPoints,
                activeRectDrawingMethod,
            },
            player: {
                frame: { number: frame },
            },
            annotations: {
                selectedStatesID,
                states,
            },
        },
        settings: {
            player: { rotateAll },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    return {
        rotateAll,
        canvasInstance: canvasInstance as Canvas,
        activeControl,
        labels,
        normalizedKeyMap,
        keyMap,

        // isDrawShape,
        activeLabelID,
        activeObjectType,
        activeShapeType,
        activeNumOfPoints: activeNumOfPoints as number,
        activeRectDrawingMethod,
        jobInstance,
        frame,
        selectedStatesID,
        states,
    };
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        mergeObjects(enabled: boolean): void {
            dispatch(mergeObjects(enabled));
        },
        groupObjects(enabled: boolean): void {
            dispatch(groupObjects(enabled));
        },
        splitTrack(enabled: boolean): void {
            dispatch(splitTrack(enabled));
        },
        rotateFrame(rotation: Rotation): void {
            dispatch(rotateCurrentFrame(rotation));
        },
        repeatDrawShape(): void {
            dispatch(repeatDrawShapeAsync());
        },
        pasteShape(): void {
            dispatch(pasteShapeAsync());
        },
        resetGroup(): void {
            dispatch(resetAnnotationsGroup());
        },
        redrawShape(): void {
            dispatch(redrawShapeAsync());
        },
        // changeDrawType(isDrawShape: boolean): void {
        //     dispatch(rememberObject({ activeObjectType: isDrawShape ? ObjectType.SHAPE : ObjectType.TRACK }));
        //     dispatch(changeDrawTypeAsync(isDrawShape));
        // },
        onDrawStart(
            shapeType: ShapeType,
            labelID: number,
            objectType: ObjectType,
            points?: number,
            rectDrawingMethod?: RectDrawingMethod,
        ): void {
            dispatch(resetCanvas());
            dispatch(
                rememberObject({
                    activeObjectType: objectType,
                    activeShapeType: shapeType,
                    activeLabelID: labelID,
                    activeNumOfPoints: points,
                    activeRectDrawingMethod: rectDrawingMethod,
                }),
            );
        },
        onAnnotationCreate(sessionInstance: any, frame: number, states: any[]): void {
            dispatch(createAnnotationsAsync(sessionInstance, frame, states));
        },
        onRememberObject(labelID: number): void {
            dispatch(rememberObject({ activeObjectType: ObjectType.TAG, activeLabelID: labelID }));
        },
        // onIncludeStart(statesToUpdate: any[]): void {
        //     dispatch(updateAnnotationsAsync(statesToUpdate));
        // },
    };
}

export default connect(mapStateToProps, dispatchToProps)(ControlsSideBarComponent);
