/*
 * @Author: swxy
 * @Date: 2022-03-18 10:04:09
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-core/src/config.js
 * Copyright (C) Amygo
 */
// Copyright (C) 2019-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

module.exports = {
    // backendAPI: '/api',
    // backendAPI: '/aatp/api',
    backendAPI: '',
    proxy: false,
    organizationID: null,
    origin: '',
    uploadChunkSize: 100,

    isOriginal: false,
};
