/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-27 21:20:48
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { GroupIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { ActiveControl, DimensionType } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas | Canvas3d;
    activeControl: ActiveControl;
    switchGroupShortcut: string;
    resetGroupShortcut: string;
    disabled?: boolean;
    jobInstance?: any;
    groupObjects(enabled: boolean): void;
}

function GroupControl(props: Props): JSX.Element {
    const {
        switchGroupShortcut,
        resetGroupShortcut,
        activeControl,
        canvasInstance,
        groupObjects,
        disabled,
        jobInstance,
    } = props;
    const { t } = useTranslation();

    const dynamicIconProps =
        activeControl === ActiveControl.GROUP
            ? {
                  className: 'cvat-group-control cvat-active-canvas-control',
                  onClick: (): void => {
                      canvasInstance.group({ enabled: false });
                      groupObjects(false);
                  },
              }
            : {
                  className: 'cvat-group-control',
                  onClick: (): void => {
                      canvasInstance.cancel();
                      canvasInstance.group({ enabled: true });
                      groupObjects(true);
                  },
              };

    // const title = [
    //     // `Group shapes${
    //     //     jobInstance && jobInstance.dimension === DimensionType.DIM_3D ? '' : '/tracks'
    //     // } ${switchGroupShortcut}. `,
    //     // `Select and press ${resetGroupShortcut} to reset a group.`,
    //     `将普通帧${
    //         jobInstance && jobInstance.dimension === DimensionType.DIM_3D ? '' : '/连续帧'
    //     }分组 ${switchGroupShortcut}. `,
    //     `选择组后按 ${resetGroupShortcut} 取消该组.`,
    // ].join(' ');

    return disabled ? (
        <Icon className='cvat-group-control cvat-disabled-canvas-control' component={GroupIcon} />
    ) : (
        <CVATTooltip
            title={t('workspace.side.controls.group_tip', {
                key: resetGroupShortcut,
            })}
            placement='right'
        >
            <Icon {...dynamicIconProps} component={GroupIcon} />
        </CVATTooltip>
    );
}

export default React.memo(GroupControl);
