// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { BoundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActions, AuthActionTypes } from 'actions/auth-actions';
import { ShortcutsActions, ShortcutsActionsTypes } from 'actions/shortcuts-actions';
import { KeyMap, KeyMapItem } from 'utils/mousetrap-react';
import { DimensionType, ShortcutsState } from './interfaces';

function formatShortcuts(shortcuts: KeyMapItem): string {
    const list: string[] = shortcuts.displayedSequences || (shortcuts.sequences as string[]);
    return `[${list
        .map((shortcut: string): string => {
            let keys = shortcut.split('+');
            keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
            keys = keys.join('+').split(/\s/g);
            keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
            return keys.join(' ');
        })
        .join(', ')}]`;
}

const defaultKeyMap = ({
    QUICKLABEL: {
        name: '快速标注',
        description: '立刻进入/取消矩形的标注模式',
        sequences: ['a'],
        action: 'keydown',
    },
    SWITCH_SHORTCUTS: {
        // name: 'Show shortcuts',
        // description: 'Open/hide the list of available shortcuts',
        name: '快捷键查看界面',
        description: '打开或者隐藏当前快捷键列表',
        sequences: ['f1'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_SETTINGS: {
        // name: 'Show settings',
        // description: 'Open/hide settings dialog',
        name: '设置',
        description: '弹出或者关闭设置的窗口',
        sequences: ['f2'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },

    SWITCH_ALL_LOCK: {
        // name: 'Lock/unlock all objects',
        // description: 'Change locked state for all objects in the side bar',
        name: '锁定/取消锁定所有对象',
        description: '更改在面板里的所有对象的锁定状态',
        sequences: ['t l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_LOCK: {
        // name: 'Lock/unlock an object',
        // description: 'Change locked state for an active object',
        name: '锁定/取消锁定对象',
        description: '更改当前对象的锁定状态',
        sequences: ['l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_ALL_HIDDEN: {
        // name: 'Hide/show all objects',
        // description: 'Change hidden state for objects in the side bar',
        name: '隐藏/显示所有对象',
        description: '更改在面板里的所有对象的隐藏状态',
        sequences: ['t h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_HIDDEN: {
        // name: 'Hide/show an object',
        // description: 'Change hidden state for an active object',
        name: '隐藏/显示对象',
        description: '更改当前对象的隐藏状态',
        sequences: ['h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_OCCLUDED: {
        // name: 'Switch occluded',
        // description: 'Change occluded property for an active object',
        name: '是否被遮挡',
        description: '更改当前对象的被遮挡状态',
        sequences: ['q', '/'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_KEYFRAME: {
        // name: 'Switch keyframe',
        // description: 'Change keyframe property for an active track',
        name: '是否是关键帧',
        description: '更改当前连续帧在本帧的关键帧状态',
        sequences: ['k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_OUTSIDE: {
        // name: 'Switch outside',
        // description: 'Change outside property for an active track',
        name: '是否不在画面中',
        description: '更改当前连续帧在本帧的是否不在画面内的状态',
        sequences: ['o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    DELETE_OBJECT: {
        // name: 'Delete object',
        // description: 'Delete an active object. Use shift to force delete of locked objects',
        name: '删除对象',
        description: '删除当前对象，使用shift可强制删除锁定的对象',
        sequences: ['del', 'shift+del'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TO_BACKGROUND: {
        // name: 'To background',
        // description: 'Put an active object "farther" from the user (decrease z axis value)',
        name: '后置',
        description: '将当前对象放在离用户“更远”的地方（减少 z 轴值）',
        sequences: ['-', '_'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    TO_FOREGROUND: {
        // name: 'To foreground',
        // description: 'Put an active object "closer" to the user (increase z axis value)',
        name: '前置',
        description: '将当前对象放在离用户“更近”的地方（增加 z 轴值）',
        sequences: ['+', '='],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    COPY_SHAPE: {
        // name: 'Copy shape',
        // description: 'Copy shape to CVAT internal clipboard',
        name: '复制',
        description: '将形状复制到内部剪切板',
        sequences: ['ctrl+c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PROPAGATE_OBJECT: {
        // name: 'Propagate object',
        // description: 'Make a copy of the object on the following frames',
        name: '传播对象',
        description: '在以下帧中，复制对象',
        sequences: ['ctrl+b'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_KEY_FRAME: {
        // name: 'Next keyframe',
        // description: 'Go to the next keyframe of an active track',
        name: '下一个关键帧',
        description: '前往当前连续帧的下一个关键帧',
        sequences: ['r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    PREV_KEY_FRAME: {
        // name: 'Previous keyframe',
        // description: 'Go to the previous keyframe of an active track',
        name: '上一个关键帧',
        description: '前往当前连续帧的上一个关键帧',
        sequences: ['e'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },

    NEXT_ATTRIBUTE: {
        // name: 'Next attribute',
        // description: 'Go to the next attribute',
        name: '下一个属性',
        description: '前往下一个属性',
        sequences: ['down'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_ATTRIBUTE: {
        // name: 'Previous attribute',
        // description: 'Go to the previous attribute',
        name: '上一个属性',
        description: '前往上一个属性',
        sequences: ['up'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_OBJECT: {
        // name: 'Next object',
        // description: 'Go to the next object',
        name: '下一个对象',
        description: '前往下一个对象',
        sequences: ['tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_OBJECT: {
        // name: 'Previous object',
        // description: 'Go to the previous object',
        name: '上一个属性',
        description: '前往上一个对象',
        sequences: ['shift+tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },

    PASTE_SHAPE: {
        // name: 'Paste shape',
        // description: 'Paste a shape from internal CVAT clipboard',
        name: '粘贴',
        description: '粘贴在内部剪切板里的对象',
        sequences: ['ctrl+v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_DRAW_MODE: {
        // name: 'Draw mode',
        // description:
        //     'Repeat the latest procedure of drawing with the same parameters (shift to redraw an existing shape)',
        name: '再次绘画',
        description: '重复最近一次的绘画，将使用相同的参数。（shift重绘当前选中的一个）',
        sequences: ['shift+n', 'n'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    OPEN_REVIEW_ISSUE: {
        // name: 'Open an issue',
        // description: 'Create a new issues in the review workspace',
        name: '创建批注',
        description: '在审阅模式下创建批注',
        sequences: ['n'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_MERGE_MODE: {
        // name: 'Merge mode',
        // description: 'Activate or deactivate mode to merging shapes',
        name: '合并',
        description: '进入合并模式，再次点击将选中的多个对象合并为一个连续帧',
        sequences: ['m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_SPLIT_MODE: {
        // name: 'Split mode',
        // description: 'Activate or deactivate mode to splitting shapes',
        name: '分割',
        description: '将选择的连续帧分割为不同的两个连续帧',
        sequences: ['alt+m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_GROUP_MODE: {
        // name: 'Group mode',
        // description: 'Activate or deactivate mode to grouping shapes',
        name: '组',
        description: '进入组模式，再次点击将选中的多个对象组成一组',
        sequences: ['g'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    RESET_GROUP: {
        // name: 'Reset group',
        // description: 'Reset group for selected shapes (in group mode)',
        name: '重置组',
        description: '组模式下，重置选中的对象的组',
        sequences: ['shift+g'],
        action: 'keyup',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    CANCEL: {
        // name: 'Cancel',
        // description: 'Cancel any active canvas mode',
        name: '取消',
        description: '取消一切模式和活动',
        sequences: ['esc'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    CLOCKWISE_ROTATION: {
        // name: 'Rotate clockwise',
        // description: 'Change image angle (add 90 degrees)',
        name: '顺时针旋转',
        description: '更改图像角度（增加90度）',
        sequences: ['ctrl+r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    ANTICLOCKWISE_ROTATION: {
        // name: 'Rotate anticlockwise',
        // description: 'Change image angle (subtract 90 degrees)',
        name: '逆时针旋转',
        description: '更改图像角度（减少90度）',
        sequences: ['ctrl+shift+r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },

    SAVE_JOB: {
        // name: 'Save the job',
        // description: 'Send all changes of annotations to the server',
        name: '保存当前工作',
        description: '将所有更改保存到服务器',
        sequences: ['ctrl+s'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    UNDO: {
        // name: 'Undo action',
        // description: 'Cancel the latest action related with objects',
        name: '撤销',
        description: '取消与对象相关的最新操作',
        sequences: ['ctrl+z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    REDO: {
        // name: 'Redo action',
        // description: 'Cancel undo action',
        name: '恢复',
        description: '恢复撤销的操作',
        sequences: ['ctrl+shift+z', 'ctrl+y'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_FRAME: {
        // name: 'Next frame',
        // description: 'Go to the next frame',
        name: '下一帧',
        description: '前往下一帧',
        sequences: ['f'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREV_FRAME: {
        // name: 'Previous frame',
        // description: 'Go to the previous frame',
        name: '上一帧',
        description: '前往上一帧',
        sequences: ['d'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FORWARD_FRAME: {
        // name: 'Forward frame',
        // description: 'Go forward with a step',
        name: '前一节',
        description: '前往上一节',
        sequences: ['v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    BACKWARD_FRAME: {
        // name: 'Backward frame',
        // description: 'Go backward with a step',
        name: '后一节',
        description: '前往下一节',
        sequences: ['c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_FORWARD: {
        // name: 'Search forward',
        // description: 'Search the next frame that satisfies to the filters',
        name: '向前搜索',
        description: '搜索满足过滤器的下一帧',
        sequences: ['right'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_BACKWARD: {
        // name: 'Search backward',
        // description: 'Search the previous frame that satisfies to the filters',
        name: '向后搜索',
        description: '搜索满足过滤器的上一帧',
        sequences: ['left'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PLAY_PAUSE: {
        // name: 'Play/pause',
        // description: 'Start/stop automatic changing frames',
        name: '播放/暂停',
        description: '开始/暂停 自动变换帧数',
        sequences: ['space'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FOCUS_INPUT_FRAME: {
        // name: 'Focus input frame',
        // description: 'Focus on the element to change the current frame',
        name: '聚焦到变帧输入框',
        description: '将焦点聚集到帧数输入框里',
        sequences: ['`'],
        displayedSequences: ['~'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_AUTOMATIC_BORDERING: {
        // name: 'Switch automatic bordering',
        // description: 'Switch automatic bordering for polygons and polylines during drawing/editing',
        name: '切换自动边框',
        description: '在绘图/编辑期间切换多边形和折线的自动边框',
        sequences: ['ctrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_TOOLS_BLOCKER_STATE: {
        // name: 'Switch algorithm blocker',
        // description: 'Postpone running the algorithm for interaction tools',
        name: '切换算法拦截器',
        description: '推迟运行交互工具的算法',
        sequences: ['ctrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CHANGE_OBJECT_COLOR: {
        // name: 'Change color',
        // description: 'Set the next color for an activated shape',
        name: '更改颜色',
        description: '为活动的对象设置其他颜色',
        sequences: ['enter'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TOGGLE_LAYOUT_GRID: {
        // name: 'Toggle layout grid',
        // description: 'The grid is used to UI development',
        name: '切换布局网格',
        description: '网格用于UI开发',
        sequences: ['ctrl+alt+enter'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_LABEL: {
        // name: 'Switch label',
        name: '变换标签',
        // description: 'Changes a label for an activated object or for the
        // next drawn object if no objects are activated',
        description: '有选中的一个对象，变更选中对象的标签类型。没有选中对象，更改默认标签类型',
        sequences: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((val: string): string => `ctrl+${val}`),
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TILT_UP: {
        name: 'Camera Roll Angle Up',
        description: 'Increases camera roll angle',
        sequences: ['shift+arrowup'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    TILT_DOWN: {
        name: 'Camera Roll Angle Down',
        description: 'Decreases camera roll angle',
        sequences: ['shift+arrowdown'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_LEFT: {
        name: 'Camera Pitch Angle Left',
        description: 'Decreases camera pitch angle',
        sequences: ['shift+arrowleft'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_RIGHT: {
        name: 'Camera Pitch Angle Right',
        description: 'Increases camera pitch angle',
        sequences: ['shift+arrowright'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_UP: {
        name: 'Camera Move Up',
        description: 'Move the camera up',
        sequences: ['alt+u'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_DOWN: {
        name: 'Camera Move Down',
        description: 'Move the camera down',
        sequences: ['alt+o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_LEFT: {
        name: 'Camera Move Left',
        description: 'Move the camera left',
        sequences: ['alt+j'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_RIGHT: {
        name: 'Camera Move Right',
        description: 'Move the camera right',
        sequences: ['alt+l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_IN: {
        name: 'Camera Zoom In',
        description: 'Performs zoom in',
        sequences: ['alt+i'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_OUT: {
        name: 'Camera Zoom Out',
        description: 'Performs zoom out',
        sequences: ['alt+k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ENTER_MOVE: {
        // name: 'Select frame states',
        // description: 'Select all states in the frame',
        name: '3D进入移动视角的模式',
        description: '移动当前3D的视角',
        sequences: ['alt+x'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ADD_THREE_SHAPE: {
        // name: 'Select frame states',
        // description: 'Select all states in the frame',
        name: '增加3D框',
        description: '增加3D框',
        sequences: ['alt+c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    SELECT_FRAME_STATES: {
        // name: 'Select frame states',
        // description: 'Select all states in the frame',
        name: '当前帧所有对象',
        description: '选中当前帧的所有对象',
        sequences: ['ctrl+a'],
        applicable: [DimensionType.DIM_2D],
        action: 'keydown',
    },
    PANEL_SHOW_MODEL: {
        // name: 'Panel show model',
        // description: 'Panel show list or single model',
        name: '面板列表模式',
        description: '切换列表是单个展示还是多个展示',
        sequences: ['x'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CREATE_SUB_SPLIT_LINE_V: {
        name: '增加分割线',
        description: '在选中的矩形对象中，增加一条垂直分割线。',
        sequences: ['z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CREATE_SUB_SPLIT_LINE_H: {
        name: '增加分割线',
        description: '在选中的矩形对象中，增加一条水平分割线。',
        sequences: ['shift+z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    DELETE_ONE_POINT: {
        name: '删除当前点/线',
        description: '点/线对象被选中时，删除当前对象鼠标正在点击的这一个点，对象不变',
        sequences: ['alt+leftmouse'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CANCLE_RELATION_ON_ACTIVITY: {
        name: '取消关系',
        description: '取消选中的对象的关联关系，选中的是父对象时，会取消和所有子对象的关联关系。',
        sequences: ['b'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_ACTIVITY_OBJECT: {
        name: '选中后一个对象',
        description: '选中当前帧，当前活动对象的下一个对象',
        sequences: ['s'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREV_ACTIVITY_OBJECT: {
        name: '选中前一个对象',
        description: '选中当前帧，当前活动对象的上一个对象',
        sequences: ['w'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
} as any) as KeyMap;

const defaultState: ShortcutsState = {
    visibleShortcutsHelp: false,
    keyMap: defaultKeyMap,
    normalizedKeyMap: Object.keys(defaultKeyMap).reduce((acc: Record<string, string>, key: string) => {
        const normalized = formatShortcuts(defaultKeyMap[key]);
        acc[key] = normalized;
        return acc;
    }, {}),
};

export default (state = defaultState, action: ShortcutsActions | BoundariesActions | AuthActions): ShortcutsState => {
    switch (action.type) {
        case ShortcutsActionsTypes.SWITCH_SHORTCUT_DIALOG: {
            return {
                ...state,
                visibleShortcutsHelp: !state.visibleShortcutsHelp,
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        default: {
            return state;
        }
    }
};
