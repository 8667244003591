/*
 * @Author: swxy
 * @Date: 2022-08-17 18:35:05
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'workspace.track': '连续帧',
    'workspace.shape': '普通帧',

    'workspace.top.back': '前往上一个页面',
    'workspace.top.save': '保存当前变更',

    'workspace.top.undo': '取消前一个操作',
    'workspace.top.redo': '前往后一个操作',

    'workspace.top.go_back': '上一个',
    'workspace.top.go_back_filter': '上一个条件帧',
    'workspace.top.go_back_empty': '上一个空白帧',
    'workspace.top.go_next': '下一个',
    'workspace.top.go_next_filter': '下一个条件帧',
    'workspace.top.go_next_empty': '下一个空白帧',

    'workspace.top.frist_frame': '第一帧',
    'workspace.top.prve_step': '上一个节',
    'workspace.top.play': '播放',
    'workspace.top.pause': '暂停',

    'workspace.top.next_step': '下一节',
    'workspace.top.last_frame': '最后一帧',

    'workspace.top.change_frame_input_tip': '按下{{hot}}聚焦',
    'workspace.top.panle_m': '展示/隐藏面板',
    'workspace.top.attribute_m': '在标注画面中展示/隐藏属性',
    'workspace.top.active_move': '鼠标移动激活对象',
    'workspace.top.active_click': '鼠标点击激活对象',
    'workspace.top.fullscreen_exit': '退出全屏',
    'workspace.top.fullscreen': '全屏',
    'workspace.top.annotation_info': '标注信息',
    'workspace.top.annotation_object_filter': '筛选标注对象',

    'workspace.top.drop.unsavedata_tip': '当前工作还有未保存的操作',
    'workspace.top.drop.save_tip': '是否先保存后再继续?',
    'workspace.top.drop.save': '保存',
    'workspace.top.drop.unsave': '不保存',

    'workspace.top.drop.change_workspace_tip': '改变当前工作状态',
    'workspace.top.drop.change_workspace_content_tip': '当前工作状态将更改为"已完成"，是否继续?',
    'workspace.top.drop.change_workspace_continue': '继续',
    'workspace.top.drop.change_workspace_cancle': '取消',

    'workspace.top.drop.panel_model': '面板展示模式',
    'workspace.top.drop.change_panel_model': '切换面板列表展示模式',
    'workspace.top.drop.panel_left': '左侧面板',
    'workspace.top.drop.panel_show_on_left': '面板列表展示在左侧',
    'workspace.top.drop.change_serialid': '修改标识',
    'workspace.top.drop.panel_sort': '面板列表排序',
    'workspace.top.drop.change_workspace': '更改工作模式',
    'workspace.top.drop.submit': '提交',
    'workspace.top.drop.submit_title': '提交',

    'workspace.top.drop.workspace_standard3d': '标准(3D)',
    'workspace.top.drop.workspace_standard': '标准',
    'workspace.top.drop.workspace_attribute_annotation': '属性',
    'workspace.top.drop.workspace_tag_annotation': '签条<Tag>',
    'workspace.top.drop.workspace_review': '审核',

    'workspace.top.modal.sort': '排序',
    'workspace.top.modal.sort_ID_descent': '对象标识 - 降序',
    'workspace.top.modal.sort_ID_ascent': '对象标识 - 升序',
    'workspace.top.modal.sort_update_time': '更新时间',

    'workspace.top.modal.submit_permission': '权限',
    'workspace.top.modal.submit_permission_sub': '您可能没有权限进行此操作，如果有任何疑问，可以询问项目管理员！',
    'workspace.top.modal.submit_permission_close': '关闭',

    'workspace.top.modal.submit_review_success': '审核成功！',
    'workspace.top.modal.submit_review_back_reason_w': '请输入退回原因',
    'workspace.top.modal.submit_status_submit': '提交',
    'workspace.top.modal.submit_status_frist_review': '一审通过',
    'workspace.top.modal.submit_status_second_review': '二审通过',
    'workspace.top.modal.submit_status_acceptance': '验收通过',
    'workspace.top.modal.submit_status_review': '审核',

    'workspace.top.modal.submit_loading_tip': '数据生成中，请勿关闭页面...',
    'workspace.top.modal.submit_content': ' 这个题包？',

    'workspace.top.modal.submit_sorce_very_bad': '非常差',
    'workspace.top.modal.submit_sorce_bad': '比较差',
    'workspace.top.modal.submit_sorce_authorized': '合格',
    'workspace.top.modal.submit_sorce_good': '很好',
    'workspace.top.modal.submit_sorce_very_good': '非常好',

    'workspace.top.modal.submit_submit': '提交',
    'workspace.top.modal.submit_pass': '通过',
    'workspace.top.modal.submit_back_reason': '请输入退回原因！',
    'workspace.top.modal.submit_back': '退回',
    'workspace.top.modal.submit_close': '关闭',

    'workspace.content.view.top': '上方视角',
    'workspace.content.view.side': '侧方视角',
    'workspace.content.view.front': '前方视角',

    'workspace.content.view.object_lenght': '长：',
    'workspace.content.view.object_width': '宽：',
    'workspace.content.view.object_height': '高：',

    'workspace.content.view.object_cloud_point': '点云数：',

    'workspace.content.image.only_show_projection_tip': '只显示选中对象的投影',
    'workspace.content.image.only_show_select': '选中',

    'workspace.side.controls.cursor': '光标',
    'workspace.side.controls.parent_sub_delete': '选中后，有父子关联的对象，当删除父对象时，子元素也会一起删除。',

    'workspace.side.controls.max_points': '最多点数',
    'workspace.side.controls.draw_cuboid_model_tip': '长方体生成模式',
    'workspace.side.controls.draw_cuboid_model_from_rectangle': '矩形延伸',
    'workspace.side.controls.draw_cuboid_model_4_points': '4个点',

    'workspace.side.controls.draw_continue_tip': '完成一个标注后，停留在当前模式下，继续标注',
    'workspace.side.controls.draw_continue': '连续标注',

    'workspace.side.controls.cuboid_tip': '按下可进入画长方体模式。重复操作取消',
    'workspace.side.controls.cuboid_3d_tip': '画3D框',

    'workspace.side.controls.ellipse_tip': '按下可进入画圆模式',
    'workspace.side.controls.points_tip': '按下可进入画点模式。未设置数量时，再次点击确定。',
    'workspace.side.controls.polygon_tip': '按下可进入画多边形模式。未设置数量时，再次点击确定。',
    'workspace.side.controls.polyline_tip': '按下可进入画线模式。未设置数量时，再次点击确定。',
    'workspace.side.controls.rectangle_tip': '按下可进入画矩形模式，可按‘a’快捷键直接进入画矩形模式。重复操作取消',
    'workspace.side.controls.fit_tip': '以窗口大小适应展示图片 [双击鼠标]',
    'workspace.side.controls.group_tip': '选择组后按{{key}}取消该组',
    'workspace.side.controls.merge_tip': '将普通帧/连续帧合并为一个连续帧',
    'workspace.side.controls.split_tip': '将选中的连续帧及后续分割为一个新的连续帧',
    'workspace.side.controls.add_v_line': '用当前默认的标签，增加一个二级对象-垂直分割线',
    'workspace.side.controls.add_h_line': '用当前默认的标签，增加一个二级对象-水平分割线',
    'workspace.side.controls.move_tip': '移动图片',
    'workspace.side.controls.resize_interest_tip': '选择一个范围缩放',
    'workspace.side.controls.rotate_anticlockwise_tip': '逆时针旋转图片',
    'workspace.side.controls.rotate_clockwise_tip': '顺时针旋转图片',
    'workspace.side.controls.fine_tuning': '微调',

    'workspace.side.controls.create_issue': '创建批注',

    // 'workspace.side.controls.track_model_tip': '在创建<普通标注对象>与<连续帧标注对象>的模式之间切换',
    'workspace.side.controls.track_model_tip': '标注类型切换',
    'workspace.side.controls.track_model': '连续帧',

    'workspace.side.issue.defualt_label_msg': '默认标签变更为：',
    'workspace.side.issue.delete_issue_pop': '是否是删除该批注',
    'workspace.side.issue.delete_delete': '删除',
    'workspace.side.issue.delete_cancel': '取消',
    'workspace.side.issue.find_previous_frame_issues': '查找有批注的前一帧',
    'workspace.side.issue.find_next_frame_issues': '查找有批注的后一帧',
    'workspace.side.issue.show/hide_all_issue': '展示/隐藏所有批注',
    'workspace.side.issue.hide_all_resolved_issue': '隐藏所有已解决的批注',
    'workspace.side.issue.resolved': '解决',
    'workspace.side.issue.open': '打开',

    'workspace.side.object.show_detail_att': '展示当前对象的属性',
    'workspace.side.object.track_simple': '连',
    'workspace.side.object.lock': '锁定当前对象',
    'workspace.side.object.occluded': '当前对象是否被遮挡',
    'workspace.side.object.pinned': '固定当前对象的位置',
    'workspace.side.object.hidden': '隐藏当前对象',
    'workspace.side.object.outside': '当前对象在画面外',
    'workspace.side.object.keyframe': '设置/取消当前对象的关键帧',

    'workspace.side.object.label_type': '标签类型',
    'workspace.side.object.parent_id': '父对象ID',
    'workspace.side.object.cancel_relation': '取消关联',
    'workspace.side.object.cancel_relation_tip': '取消当前的关联关系',

    'workspace.side.object.context.create_url': '创建当前对象链接',
    'workspace.side.object.context.right_context_panel': '展示右键属性面板',
    'workspace.side.object.context.copy': '复制',
    'workspace.side.object.context.propagate': '多帧复制',
    'workspace.side.object.context.switch': '切换方向',
    'workspace.side.object.context.reset': '重置透视',
    'workspace.side.object.context.background': '背景',
    'workspace.side.object.context.foreground': '前景',
    'workspace.side.object.context.changeColor': '更改 {{name}} 的颜色',
    'workspace.side.object.context.object_lock_tip': '对象已经被锁定',
    'workspace.side.object.context.delete_object_lock_tip': '是否继续删除?',
    'workspace.side.object.context.delete': '删除',

    'workspace.side.top.lock_all': '锁定当前所有标注对象',
    'workspace.side.top.hide_all_unlock': '隐藏当前所有未锁定的标注对象',
    'workspace.side.top.expand_collapse_all': '展开/收起列表中所有标注对象的属性',
    'workspace.side.top.force_active': '只展示当前',

    'workspace.side.appearance.color': '颜色',

    'workspace.side.appearance.instance': '对象',
    'workspace.side.appearance.group': '组',
    'workspace.side.appearance.label': '标签',

    'workspace.side.appearance.opacity': '不透明度',
    'workspace.side.appearance.selected_opacity': '选择的不透明度',
    'workspace.side.appearance.outlined_borders': '轮廓边框',
    'workspace.side.appearance.show_bitmap': '显示位图',
    'workspace.side.appearance.show_projections': '显示预测',

    'workspace.side.tab.object': '对象',
    'workspace.side.tab.labels': '标签',
    'workspace.side.tab.issues': '审批',
    'workspace.side.tab.appear': '外观',
};
