/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-07-08 19:37:10
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'components/annotation-page/canvas/canvas-wrapper2DImage';
import ControlsSideBar from './controls-side-bar/controls-side-bar';
import ControlsFooterBar from './controls-side-bar/controls-footer-bar';
// import ControlsSideBarContainer from
// 'containers/annotation-page/standard-workspace/controls-side-bar/controls-side-bar';
// import PropagateConfirmContainer from 'containers/annotation-page/standard-workspace/propagate-confirm';
// import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
// import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
// import ObjectSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
// import CanvasPointContextMenuComponent from 'components/annotation-page/canvas/canvas-point-context-menu';
// import IssueAggregatorComponent from 'components/annotation-page/review/issues-aggregator';

export default function StandardWorkspaceComponent(props: any): JSX.Element {
    const { labelsLength } = props;
    return (
        <Layout className='cvat-standard-workspace'>
            {/* {sidebarPositionLeft && <ObjectSideBarComponent objectsList={<ObjectsListContainer />} />} */}
            <Layout>
                <CanvasWrapperContainer />
                {labelsLength > 6 && <ControlsSideBar />}
                {/* <ObjectSideBarComponent objectsList={<ObjectsListContainer />} /> */}
                {/* {!sidebarPositionLeft && <ObjectSideBarComponent objectsList={<ObjectsListContainer />} />} */}
                {/* <PropagateConfirmContainer />
            <CanvasContextMenuContainer />
            <CanvasPointContextMenuComponent />
            <IssueAggregatorComponent /> */}
            </Layout>
            <ControlsFooterBar />
        </Layout>
    );
}
