/*
 * @Author: swxy
 * @Date: 2022-08-15 09:37:58
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import { Select, SelectProps } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { setLanguage, getLanguage } from 'utils/storage';
import { changeLanguage } from 'actions/settings-actions';
import { Language as LanguageType } from 'reducers/interfaces';
import { useDispatch } from 'react-redux';
import moment from 'moment';

type Props<T = any> = SelectProps<T>;

const Language: FC<Props> = ({ ...resetProps }): JSX.Element => {
    const { i18n: language } = useTranslation();
    const dispatch = useDispatch();
    const onChange = (value: string): void => {
        language.changeLanguage(value);
        moment.locale(value);

        dispatch(changeLanguage(value as LanguageType));
        setLanguage(value);
    };

    const options = [
        { label: '中文', value: 'zh_CN' },
        { label: 'English', value: 'en_US' },
    ];
    return (
        <Select
            style={{ minWidth: '300px', marginTop: '10px', marginRight: '10px' }}
            defaultValue={language.language || getLanguage() || 'zh_CN'}
            options={options}
            onChange={onChange}
            {...resetProps}
        />
    );
};

export default Language;
