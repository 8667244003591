// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useRef, useState } from 'react';
import Layout from 'antd/lib/layout';
import { ActiveControl, ObjectType, ShapeType, CombinedState } from 'reducers/interfaces';
import { Canvas3d as Canvas, Canvas3d } from 'cvat-canvas3d-wrapper';
import MoveControl, {
    Props as MoveControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/move-control';
import CursorControl, {
    Props as CursorControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/cursor-control';
import DrawCuboidControl, {
    Props as DrawCuboidControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/draw-cuboid-control';
import GroupControl, {
    Props as GroupControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/group-control';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import ControlVisibilityObserver from 'components/annotation-page/standard-workspace/controls-side-bar/control-visibility-observer';
import LowControl from 'components/annotation-page/standard3D-workspace/controls-side-bar/low-control';
import { Tooltip, Checkbox } from 'antd';
import { rememberObject } from 'actions/annotation-actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const enum BottomCheckType {
    ContinueLabel = 'continueLabel',
    ConsecutiveFrames = 'ConsecutiveFrames',
}

interface Props {
    keyMap: KeyMap;
    canvasInstance: Canvas | Canvas3d;
    activeControl: ActiveControl;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    jobInstance: any;
    readonly?: boolean;
    repeatDrawShape(): void;
    redrawShape(): void;
    pasteShape(): void;
    groupObjects(enabled: boolean): void;
    resetGroup(): void;
}

const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedMoveControl = ControlVisibilityObserver<MoveControlProps>(MoveControl);
const ObservedDrawCuboidControl = ControlVisibilityObserver<DrawCuboidControlProps>(DrawCuboidControl);
const ObservedGroupControl = ControlVisibilityObserver<GroupControlProps>(GroupControl);
// const ObservedLowControl = ControlVisibilityObserver(LowControl);

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const {
        canvasInstance,
        pasteShape,
        activeControl,
        normalizedKeyMap,
        keyMap,
        labels,
        redrawShape,
        repeatDrawShape,
        groupObjects,
        resetGroup,
        jobInstance,
        readonly,
    } = props;

    const { t } = useTranslation();

    const { activeLabelID, activeNumOfPoints, activeRectDrawingMethod, activeCuboidDrawingMethod } = useSelector(
        (state: CombinedState) => state.annotation.drawing,
    );

    const changeCameraViewEvent = useRef<any>();
    const addShapeEvent = useRef<any>();

    const [objectType, setObjectType] = useState(ObjectType.SHAPE);
    const dispatch = useDispatch();

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    let subKeyMap: any = {
        CANCEL: keyMap.CANCEL,
    };

    let handlers: any = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        },
    };

    if (labels.length && !readonly) {
        handlers = {
            ...handlers,
            PASTE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                canvasInstance.cancel();
                pasteShape();
            },
            SWITCH_DRAW_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [ActiveControl.DRAW_CUBOID].includes(activeControl);

                if (!drawing) {
                    canvasInstance.cancel();
                    if (event && event.shiftKey) {
                        redrawShape();
                    } else {
                        repeatDrawShape();
                    }
                } else {
                    canvasInstance.draw({ enabled: false });
                }
            },
            SWITCH_GROUP_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    canvasInstance.cancel();
                }
                canvasInstance.group({ enabled: !grouping });
                groupObjects(!grouping);
            },
            RESET_GROUP: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    return;
                }
                resetGroup();
                canvasInstance.group({ enabled: false });
                groupObjects(false);
            },
            ENTER_MOVE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                changeCameraViewEvent.current?.();
            },
            ADD_THREE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                addShapeEvent.current?.();
            },
        };
        subKeyMap = {
            ...subKeyMap,
            PASTE_SHAPE: keyMap.PASTE_SHAPE,
            SWITCH_DRAW_MODE: keyMap.SWITCH_DRAW_MODE,
            SWITCH_GROUP_MODE: keyMap.SWITCH_GROUP_MODE,
            RESET_GROUP: keyMap.RESET_GROUP,
            ENTER_MOVE: keyMap.ENTER_MOVE,
            ADD_THREE_SHAPE: keyMap.ADD_THREE_SHAPE,
        };
    }

    const onDraw = (): void => {
        canvasInstance.cancel();
        canvasInstance.draw({
            enabled: true,
            shapeType: ShapeType.CUBOID,
        });

        dispatch(
            rememberObject({
                activeObjectType: objectType,
                activeShapeType: ShapeType.CUBOID,
                activeLabelID,
                activeNumOfPoints,
                activeRectDrawingMethod,
                activeCuboidDrawingMethod,
            }),
        );
    };

    const switchChange = (e: CheckboxChangeEvent, checkType: BottomCheckType): void => {
        if (checkType === BottomCheckType.ConsecutiveFrames) {
            setObjectType(e.target.checked ? ObjectType.TRACK : ObjectType.SHAPE);
            if (activeControl === ActiveControl.DRAW_CUBOID) {
                // console.log('当前类型：', objectType);
                onDraw();
            }
        }
    };

    const disabled = !labels.length || readonly;

    return (
        <Layout.Sider
            // className='cvat-canvas-controls-sidebar'
            className={`${
                objectType === ObjectType.TRACK ? 'aatp_canvas_controls_sidebar_track ' : ''
            }cvat-canvas-controls-sidebar`}
            theme='light'
            width={90}
        >
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <ObservedCursorControl
                cursorShortkey={normalizedKeyMap.CANCEL}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />
            <ObservedMoveControl
                cursorShortkey={normalizedKeyMap.ENTER_MOVE}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                onClick={(callback: () => void) => {
                    changeCameraViewEvent.current = callback;
                }}
            />
            <ObservedDrawCuboidControl
                cursorShortkey={normalizedKeyMap.ADD_THREE_SHAPE}
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_CUBOID}
                disabled={disabled}
                onClickEvent={(callback: (objectType: ObjectType) => void) => {
                    addShapeEvent.current = callback;
                }}
                onClick={onDraw}
            />
            <ObservedGroupControl
                switchGroupShortcut={normalizedKeyMap.SWITCH_GROUP_MODE}
                resetGroupShortcut={normalizedKeyMap.RESET_GROUP}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                groupObjects={groupObjects}
                disabled={disabled}
                jobInstance={jobInstance}
            />

            <div className='aatp_controls_side_bar_bottom'>
                <LowControl />
                {/* <Tooltip title='完成一个标注后，停留在当前模式下，继续标注' placement='right'>
                    <div>
                        <Checkbox
                            defaultChecked={false}
                            onChange={(e: CheckboxChangeEvent) => {
                                switchChange(e, BottomCheckType.ContinueLabel);
                            }}
                        >
                            连续标注
                        </Checkbox>
                    </div>
                </Tooltip> */}
                {/* <Tooltip title='在创建<普通标注对象>与<连续帧标注对象>的模式之间切换' placement='right'> */}
                <Tooltip title={t('workspace.side.controls.track_model_tip')} placement='right'>
                    {/* <Switch
                        className='aatp_controls_side_bar_model'
                        checkedChildren='普通帧'
                        unCheckedChildren='连续帧'
                        onChange={switchChange}
                        defaultChecked={paramsRef.current.objectType === ObjectType.SHAPE}
                    /> */}
                    <div>
                        <Checkbox
                            defaultChecked={objectType === ObjectType.TRACK}
                            onChange={(e) => {
                                switchChange(e, BottomCheckType.ConsecutiveFrames);
                            }}
                        >
                            {t('workspace.side.controls.track_model')}
                        </Checkbox>
                    </div>
                </Tooltip>
            </div>
        </Layout.Sider>
    );
}
