/*
 * @Author: swxy
 * @Date: 2022-08-11 10:37:03
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { ProjectionInfo } from 'cvat-canvas3d-wrapper';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';

interface CanvasProps<T = any> extends React.CanvasHTMLAttributes<T> {}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    imageRef?: any;
    url: string;
    canvasProps?: CanvasProps;
    index?: number;
    type?: 'preview' | 'image';
    projectInfo?: ProjectionInfo;
    onlyShowSelect?: boolean;
}

const ImageCanvas: React.FC<Props> = ({
    imageRef,
    className,
    url,
    index = 0,
    canvasProps = {},
    type = 'image',
    projectInfo,
    onlyShowSelect = false,
    ...props
}) => {
    const canvasImageRef = useRef<HTMLCanvasElement>(null);
    const canvasObjectsRef = useRef<HTMLCanvasElement>(null);
    const canvasActiveRef = useRef<HTMLCanvasElement>(null);
    const actID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const jobInstance = useSelector((state: CombinedState) => state.annotation.job.instance);

    const [size] = useState({ width: jobInstance.imgWidth, height: jobInstance.imgHeight });

    // const drawnCanvas = () => {
    //     if (canvasImageRef.current) {
    //         const ctx = canvasImageRef.current.getContext('2d');
    //         if (image) {
    //             ctx?.drawImage(image, 0, 0);
    //         } else {
    //             ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //         }
    //     }
    // };

    const defaultColor = '#ff4136';
    const defaultActiveColor = '#61c200';

    const drawnRect = (
        ctx: CanvasRenderingContext2D,
        point1: { x: number; y: number },
        point2: { x: number; y: number },
        point3: { x: number; y: number },
        point4: { x: number; y: number },
        // color: string = defaultColor,
    ) => {
        ctx.beginPath();
        ctx.moveTo(point1.x, point1.y);
        ctx.lineTo(point2.x, point2.y);
        ctx.lineTo(point3.x, point3.y);
        ctx.lineTo(point4.x, point4.y);
        ctx.lineTo(point1.x, point1.y);
        // ctx.fillStyle = color;
        ctx.fill();
    };

    const drawnLine = (
        ctx: CanvasRenderingContext2D,
        point1: { x: number; y: number },
        point2?: { x: number; y: number },
        color: string = defaultColor,
    ) => {
        if (point2) {
            const { x, y } = point2;
            ctx.moveTo(x, y);
        }
        ctx.strokeStyle = color;
        ctx.lineTo(point1.x, point1.y);
    };

    const drawnObjects = () => {
        if (canvasObjectsRef.current) {
            const ctx = canvasObjectsRef.current.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                if (!projectInfo) {
                    return;
                }

                for (const [clientID, projections] of Object.entries(projectInfo.projects)) {
                    const projection = projections[index];
                    if (projectInfo.projectType === 'points' && projection.points.length === 8) {
                        // 8点
                        ctx.lineWidth = 5;
                        ctx.beginPath();
                        const array = [
                            [0, 6],
                            [1, 7],
                            [2, 4],
                            [3, 5],

                            [6, 4],
                            [6, 7],
                            [5, 4],
                            [5, 7],
                        ];
                        for (const arr of array) {
                            drawnLine(ctx, projection.points[arr[0]], projection.points[arr[1]]);
                        }
                        // drawnLine(ctx, projection.points[0], projection.points[6]);
                        // drawnLine(ctx, projection.points[1], projection.points[7]);
                        // drawnLine(ctx, projection.points[2], projection.points[4]);
                        // drawnLine(ctx, projection.points[3], projection.points[5]);

                        // drawnLine(ctx, projection.points[6], projection.points[4]);
                        // drawnLine(ctx, projection.points[6], projection.points[7]);
                        // drawnLine(ctx, projection.points[5], projection.points[4]);
                        // drawnLine(ctx, projection.points[5], projection.points[7]);

                        ctx.closePath();
                        ctx.stroke();

                        ctx.beginPath();
                        // 最后画出车头面
                        const acts = [
                            [0, 2],
                            [0, 1],
                            [3, 1],
                            [3, 2],
                        ];
                        for (const arr of acts) {
                            drawnLine(ctx, projection.points[arr[0]], projection.points[arr[1]], defaultActiveColor);
                        }
                        // drawnLine(ctx, projection.points[0], projection.points[2], defaultActiveColor);
                        // drawnLine(ctx, projection.points[0], projection.points[1], defaultActiveColor);
                        // drawnLine(ctx, projection.points[3], projection.points[1], defaultActiveColor);
                        // drawnLine(ctx, projection.points[3], projection.points[2], defaultActiveColor);
                        ctx.closePath();
                        ctx.stroke();
                    }
                }
            }
        }
    };

    const drawnActObject = () => {
        if (canvasActiveRef.current) {
            const ctx = canvasActiveRef.current.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                if (!projectInfo || !actID || !projectInfo.projects[actID]) {
                    return;
                }
                const projection = projectInfo.projects[actID][index];
                if (projectInfo.projectType === 'points' && projection.points.length === 8) {
                    // 8点
                    ctx.fillStyle = '#639bf545';
                    ctx.lineWidth = 2;

                    const paths = [
                        [0, 2, 4, 6], // 上
                        [1, 3, 5, 7], // 下
                        [0, 6, 7, 1], // 左
                        [2, 4, 5, 3], // 右
                        [6, 4, 5, 7], // 后
                        [0, 2, 3, 1], // 车门
                    ];

                    for (const path of paths) {
                        if (path === paths[paths.length - 1]) {
                            ctx.fillStyle = '#de4def45';
                        }
                        drawnRect(
                            ctx,
                            projection.points[path[0]],
                            projection.points[path[1]],
                            projection.points[path[2]],
                            projection.points[path[3]],
                        );
                    }
                }
            }
        }
    };

    // useEffect(() => {
    //     // drawnCanvas();
    //     if (imgRef.current) {
    //         imgRef.current.src = url;

    //         imgRef.current.onload = () => {
    //             setSize({ width: imgRef.current?.width || 0, height: imgRef.current?.height || 0 });
    //         };
    //     }
    // }, [url, imgRef.current]);

    useEffect(() => {
        if (projectInfo) {
            drawnObjects();
        }
    }, [projectInfo, onlyShowSelect, size]);

    useEffect(() => {
        if (projectInfo) {
            drawnActObject();
        }
    }, [projectInfo, actID, size]);

    return (
        <div ref={imageRef} className={`aatp_image_mask_preview_image ${className || ''}`} {...props}>
            <img src={url} {...canvasProps} />
            {!onlyShowSelect && (
                <canvas ref={canvasObjectsRef} width={size?.width} height={size?.height} {...canvasProps} />
            )}
            <canvas ref={canvasActiveRef} width={size?.width} height={size?.height} {...canvasProps} />
        </div>
    );
};

export default memo(ImageCanvas);
