/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'login.login': 'login',
    'login.username': 'username',
    'login.password': 'password',
    'login.empty_username': 'Please enter your username',
    'login.empty_password': 'Please enter your password',
};
