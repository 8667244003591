/*
 * @Author: swxy
 * @Date: 2022-03-18 10:04:08
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/annotation-page/standard3D-workspace/controls-side-bar/low-control.tsx
 * Copyright (C) Amygo
 */
import React, { useState } from 'react';
import { CombinedState } from 'reducers/interfaces';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { useTranslation } from 'react-i18next';

export default (): JSX.Element => {
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance) as Canvas3d;
    const [select, setSelect] = useState(false);
    const { t } = useTranslation();
    if (!activatedStateID) {
        return <></>;
    }

    const change = (): void => {
        setSelect(!select);
        canvasInstance.actionModel(!select);
    };

    return (
        <div className='aatp_canvas_controls_sidebar_bottom' style={{ marginBottom: '20px' }}>
            <Button size='small' shape='round' type={select ? 'primary' : 'default'} onClick={change}>
                {t('workspace.side.controls.fine_tuning')}
            </Button>
        </div>
    );
};
