/*
 * @Author: swxy
 * @Date: 2022-03-21 16:31:07
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/create-task-page/create-task-json.tsx
 * Copyright (C) Amygo
 */
/*
 * @Author: swxy
 * @Date: 2022-03-21 16:31:07
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/create-task-page/create-task-json.tsx
 * Copyright (C) Amygo
 */

import React, { useEffect, useState } from 'react';
import {
    Button, Form, Space, Upload, UploadProps, notification,
} from 'antd';
import type { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
// import { useLocation } from 'react-router';

export interface CreateTaskData {
    file: File,
}

interface Props {
    onCreate: (data: CreateTaskData) => void;
    projectId: number | null,
    status: string,
}

// 通过json创建任务
const createTask = ({ onCreate, projectId, status }: Props):JSX.Element => {
    const [file, setFile] = useState<UploadFile | null>(null);
    const taskId = useSelector((state: CombinedState) => state.tasks.activities.creates.taskId);

    const loading = !!status && status !== 'CREATED' && status !== 'FAILED';
    const history = useHistory();
    // console.log('projectID:', projectId);

    // const goBack = (): void => {

    // };

    useEffect(() => {
        if (status === 'CREATED') {
            const btn = <Button onClick={() => history.push(`/tasks/${taskId}`)}>Open task</Button>;

            notification.info({
                message: 'The task has been created',
                btn,
                className: 'cvat-notification-create-task-success',
            });
        }
    }, [status]);

    const onFinish = async (): Promise<void> => {
        if (file) {
            const values = {
                file: file as any,
                projectId,
            };
            // console.log('参数：', values);

            await onCreate(values);
            setFile(null);
            setFile(null);
        }
    };

    const uploadProps: UploadProps = {
        accept: '.json',
        beforeUpload: (selectFile: RcFile) => {
            setFile(selectFile);
            return false;
        },
        data: {
            projectId,
        },
        // showUploadList: false,
        fileList: file ? [file] : [],
    };

    return (
        <Form onFinish={onFinish}>
            <Form.Item
                name='files'
                rules={[{
                    required: true,
                    message: '请选择创建任务依据的json文件',
                }]}
            >
                <Upload {...uploadProps}>
                    <Button>请选择文件</Button>
                </Upload>
            </Form.Item>
            <Space>
                {/* <Button onClick={goBack}>返回</Button> */}
                <Button type='primary' htmlType='submit' loading={loading}>提交</Button>
            </Space>
        </Form>
    );
};
export default createTask;
