// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { ReactNode, useState } from 'react';
import { Row, Col } from 'antd/lib/grid';
import { MoreOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';
import { Avatar, Space, Tooltip } from 'antd';

import { ObjectType, ShapeType, ColorBy } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
// import LabelSelector from 'components/label-selector/label-selector';
import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
import ItemMenu from './object-item-menu';

import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    jobInstance: any;
    readonly: boolean;
    clientID: number;
    serverID: number | undefined;
    // labelID: number;
    // labels: any[];
    shapeType: ShapeType;
    objectType: ObjectType;
    color: string;
    colorBy: ColorBy;
    type: {
        name: string;
        fullName: string;
    };
    locked: boolean;
    changeColorShortcut: string;
    copyShortcut: string;
    pasteShortcut: string;
    propagateShortcut: string;
    toBackgroundShortcut: string;
    toForegroundShortcut: string;
    removeShortcut: string;
    collapsed: boolean;
    changeColor(color: string): void;
    // changeLabel(label: any): void;
    copy(): void;
    remove(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    resetCuboidPerspective(): void;
    collapse(): void;
    shouContextMenuItem(): void;
}

function ItemTopComponent(props: Props): JSX.Element {
    const {
        readonly,
        clientID,
        serverID,
        // labelID,
        // labels,
        shapeType,
        objectType,
        color,
        colorBy,
        type,
        locked,
        changeColorShortcut,
        copyShortcut,
        pasteShortcut,
        propagateShortcut,
        toBackgroundShortcut,
        toForegroundShortcut,
        removeShortcut,
        changeColor,
        // changeLabel,
        copy,
        remove,
        propagate,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        resetCuboidPerspective,
        jobInstance,
        collapse,
        shouContextMenuItem,
    } = props;

    const { t } = useTranslation();

    const [menuVisible, setMenuVisible] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

    const changeMenuVisible = (visible: boolean): void => {
        if (!visible && colorPickerVisible) return;
        setMenuVisible(visible);
    };

    const changeColorPickerVisible = (visible: boolean): void => {
        if (!visible) {
            setMenuVisible(false);
        }
        setColorPickerVisible(visible);
    };

    function SwitchOutlined(superprops: Props): JSX.Element {
        const { collapsed } = superprops;

        return (
            // <CVATTooltip title='Show detail attributes'>
            <CVATTooltip title={t('workspace.side.object.show_detail_att')}>
                {collapsed ? <RightOutlined /> : <DownOutlined />}
            </CVATTooltip>
        );
    }

    return (
        // <Row align='middle'>
        //     <Col span={10}>
        //         <Text style={{ fontSize: 12 }}>{clientID}</Text>
        //         <br />
        //         <Text
        //             type='secondary'
        //             style={{ fontSize: 10 }}
        //             className='cvat-objects-sidebar-state-item-object-type-text'
        //         >
        //             {type}
        //         </Text>
        //     </Col>
        //     <Col span={12}>
        //         <CVATTooltip title='Change current label'>
        //             <LabelSelector
        //                 disabled={readonly}
        //                 size='small'
        //                 labels={labels}
        //                 value={labelID}
        //                 onChange={changeLabel}
        //                 className='cvat-objects-sidebar-state-item-label-selector'
        //             />
        //         </CVATTooltip>
        //     </Col>
        <Row align='middle' className='cvat-objects-sidebar-state-item-object-row' onClick={collapse}>
            <Col span={1}>
                <SwitchOutlined {...props} />
            </Col>
            <Col span={3}>
                <Text className='cvat-objects-sidebar-state-item-object-clientID-text' style={{ fontSize: 12 }}>
                    {clientID}
                </Text>
            </Col>
            <Col span={3}>
                <Space>
                    <Tooltip title={type.fullName}>
                        <Text
                            type='secondary'
                            style={{ fontSize: 10 }}
                            className='cvat-objects-sidebar-state-item-object-type-text'
                        >
                            {type.name}
                        </Text>
                    </Tooltip>
                    {objectType === ObjectType.TRACK && (
                        // <Text
                        //     type='danger'
                        //     style={{ fontSize: 8 }}
                        //     // className='cvat-objects-sidebar-state-item-object-type-text'
                        // >
                        //     连
                        // </Text>
                        <Tooltip title={t('workspace.track')}>
                            <Avatar
                                style={{
                                    backgroundColor: 'green',
                                    verticalAlign: 'middle',
                                    fontSize: 8,
                                    width: '18px',
                                    height: '18px',
                                    lineHeight: '18px',
                                }}
                                size='small'
                            >
                                {t('workspace.side.object.track_simple')}
                            </Avatar>
                        </Tooltip>
                    )}
                </Space>
            </Col>
            <Col span={14}>
                {/* <CVATTooltip title='Change current label'>
                    <LabelSelector
                        disabled={readonly}
                        size='small'
                        labels={labels}
                        value={labelID}
                        onChange={changeLabel}
                        className='cvat-objects-sidebar-state-item-label-selector'
                    />
                </CVATTooltip> */}
                <ObjectButtonsContainer readonly={readonly} clientID={clientID} />
            </Col>
            <Col
                span={2}
                onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                }}
                className='cvat-object-item-button-hover'
            >
                <Dropdown
                    visible={menuVisible}
                    trigger={['click']}
                    onVisibleChange={changeMenuVisible}
                    placement='bottomLeft'
                    overlay={ItemMenu({
                        jobInstance,
                        readonly,
                        serverID,
                        locked,
                        shapeType,
                        objectType,
                        color,
                        colorBy,
                        colorPickerVisible,
                        changeColorShortcut,
                        copyShortcut,
                        pasteShortcut,
                        propagateShortcut,
                        toBackgroundShortcut,
                        toForegroundShortcut,
                        removeShortcut,
                        changeColor,
                        copy,
                        remove,
                        propagate,
                        createURL,
                        switchOrientation,
                        toBackground,
                        toForeground,
                        resetCuboidPerspective,
                        changeColorPickerVisible,
                        changeMenuVisible,
                        shouContextMenuItem,
                    })}
                >
                    <MoreOutlined />
                </Dropdown>
            </Col>
        </Row>
    );
}

export default React.memo(ItemTopComponent);
