/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-04-11 10:05:22
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'containers/annotation-page/canvas/canvas-wrapper';
import AttributeAnnotationSidebar from './attribute-annotation-sidebar/attribute-annotation-sidebar';

export default function AttributeAnnotationWorkspace(props: any): JSX.Element {
    const { sidebarPositionLeft } = props;
    return (
        <Layout hasSider className='attribute-annotation-workspace'>
            {sidebarPositionLeft && <AttributeAnnotationSidebar />}
            <CanvasWrapperContainer />
            {!sidebarPositionLeft && <AttributeAnnotationSidebar />}

        </Layout>
    );
}
