/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-18 18:26:21
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';
import Popover from 'antd/lib/popover';

import { RotateIcon } from 'icons';
import { Rotation } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import withVisibilityHandling from './handle-popover-visibility';
import { useTranslation } from 'react-i18next';

export interface Props {
    clockwiseShortcut: string;
    anticlockwiseShortcut: string;
    rotateFrame(rotation: Rotation): void;
}

const CustomPopover = withVisibilityHandling(Popover, 'rotate-canvas');
function RotateControl(props: Props): JSX.Element {
    const { anticlockwiseShortcut, clockwiseShortcut, rotateFrame } = props;
    const { t } = useTranslation();

    return (
        <CustomPopover
            placement='right'
            content={
                <>
                    {/* <CVATTooltip title={`Rotate the image anticlockwise ${anticlockwiseShortcut}`}
                    placement='topRight'> */}
                    <CVATTooltip
                        title={`${t('workspace.side.controls.rotate_anticlockwise_tip')} ${anticlockwiseShortcut}`}
                        placement='topRight'
                    >
                        <Icon
                            className='cvat-rotate-canvas-controls-left'
                            onClick={(): void => rotateFrame(Rotation.ANTICLOCKWISE90)}
                            component={RotateIcon}
                        />
                    </CVATTooltip>
                    {/* <CVATTooltip title={`Rotate the image clockwise ${clockwiseShortcut}`}
                    placement='topRight'> */}
                    <CVATTooltip
                        title={`${t('workspace.side.controls.rotate_clockwise_tip')} ${clockwiseShortcut}`}
                        placement='topRight'
                    >
                        <Icon
                            className='cvat-rotate-canvas-controls-right'
                            onClick={(): void => rotateFrame(Rotation.CLOCKWISE90)}
                            component={RotateIcon}
                        />
                    </CVATTooltip>
                </>
            }
            trigger='hover'
        >
            <Icon className='cvat-rotate-canvas-control' component={RotateIcon} />
        </CustomPopover>
    );
}

export default React.memo(RotateControl);
