// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
// import Icon from '@ant-design/icons';
// import Popover from 'antd/lib/popover';

import CVATTooltip from 'components/common/cvat-tooltip';

import Icon, {
    FastBackwardOutlined,
    FastForwardOutlined,
    ForwardOutlined,
    BackwardOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
} from '@ant-design/icons';
import {
    // FirstIcon,
    // BackJumpIcon,
    // PreviousIcon,
    // PlayIcon,
    // PauseIcon,
    // NextIcon,
    PreviousFilteredIcon,
    PreviousEmptyIcon,
    NextFilteredIcon,
    NextEmptyIcon,
    // ForwardJumpIcon,
    // LastIcon,
} from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
    playing: boolean;
    playPauseShortcut: string;
    nextFrameShortcut: string;
    previousFrameShortcut: string;
    forwardShortcut: string;
    backwardShortcut: string;
    prevButtonType: string;
    nextButtonType: string;
    onSwitchPlay(): void;
    onPrevFrame(): void;
    onNextFrame(): void;
    onForward(): void;
    onBackward(): void;
    onFirstFrame(): void;
    onLastFrame(): void;
    setPrevButton(type: 'regular' | 'filtered' | 'empty'): void;
    setNextButton(type: 'regular' | 'filtered' | 'empty'): void;
}

function PlayerButtons(props: Props): JSX.Element {
    const {
        playing,
        playPauseShortcut,
        nextFrameShortcut,
        previousFrameShortcut,
        forwardShortcut,
        backwardShortcut,
        prevButtonType,
        nextButtonType,
        onSwitchPlay,
        onPrevFrame,
        onNextFrame,
        onForward,
        onBackward,
        onFirstFrame,
        onLastFrame,
        // setPrevButton,
        // setNextButton,
    } = props;

    const { t } = useTranslation();

    // const prevRegularText = 'Go back';
    // const prevFilteredText = 'Go back with a filter';
    // const prevEmptyText = 'Go back to an empty frame';
    // const nextRegularText = 'Go next';
    // const nextFilteredText = 'Go next with a filter';
    // const nextEmptyText = 'Go next to an empty frame';
    // const prevRegularText = '上一个';
    // const prevFilteredText = '上一个条件帧';
    // const prevEmptyText = '上一个空白帧';
    // const nextRegularText = '下一个';
    // const nextFilteredText = '下一个条件帧';
    // const nextEmptyText = '下一个空白帧';
    const prevRegularText = t('workspace.top.go_back');
    const prevFilteredText = t('workspace.top.go_back_filter');
    const prevEmptyText = t('workspace.top.go_back_empty');
    const nextRegularText = t('workspace.top.go_next');
    const nextFilteredText = t('workspace.top.go_next_filter');
    const nextEmptyText = t('workspace.top.go_next_empty');

    // let prevButton = <Icon className='cvat-player-previous-button' component={PreviousIcon} onClick={onPrevFrame} />;
    let prevButton = <CaretLeftOutlined className='cvat-player-previous-button' onClick={onPrevFrame} />;
    let prevButtonTooltipMessage = prevRegularText;
    if (prevButtonType === 'filtered') {
        prevButton = (
            <Icon
                className='cvat-player-previous-button-filtered'
                component={PreviousFilteredIcon}
                onClick={onPrevFrame}
            />
        );
        prevButtonTooltipMessage = prevFilteredText;
    } else if (prevButtonType === 'empty') {
        prevButton = (
            <Icon className='cvat-player-previous-button-empty' component={PreviousEmptyIcon} onClick={onPrevFrame} />
        );
        prevButtonTooltipMessage = prevEmptyText;
    }

    // let nextButton = <Icon className='cvat-player-next-button' component={NextIcon} onClick={onNextFrame} />;
    let nextButton = <CaretRightOutlined className='cvat-player-next-button' onClick={onNextFrame} />;
    let nextButtonTooltipMessage = nextRegularText;
    if (nextButtonType === 'filtered') {
        nextButton = (
            <Icon className='cvat-player-next-button-filtered' component={NextFilteredIcon} onClick={onNextFrame} />
        );
        nextButtonTooltipMessage = nextFilteredText;
    } else if (nextButtonType === 'empty') {
        nextButton = <Icon className='cvat-player-next-button-empty' component={NextEmptyIcon} onClick={onNextFrame} />;
        nextButtonTooltipMessage = nextEmptyText;
    }

    return (
        <Col className='cvat-player-buttons'>
            {/* <CVATTooltip title='Go to the first frame'> */}
            {/* <CVATTooltip title='第一帧'> */}
            <CVATTooltip title={t('workspace.top.frist_frame')}>
                {/* <Icon className='cvat-player-first-button' component={FirstIcon} onClick={onFirstFrame} /> */}
                <FastBackwardOutlined className='cvat-player-first-button' onClick={onFirstFrame} />
            </CVATTooltip>
            {/* <CVATTooltip title={`Go back with a step ${backwardShortcut}`}> */}
            {/* <CVATTooltip title={`上一个节 ${backwardShortcut}`}> */}
            <CVATTooltip title={`${t('workspace.top.prve_step')} ${backwardShortcut}`}>
                {/* <Icon className='cvat-player-backward-button' component={BackJumpIcon} onClick={onBackward} /> */}
                <BackwardOutlined className='cvat-player-backward-button' onClick={onBackward} />
            </CVATTooltip>
            {/* <Popover
                trigger='contextMenu'
                placement='bottom'
                content={(
                    <>
                        <CVATTooltip title={`${prevRegularText}`}>
                            <Icon
                                className='cvat-player-previous-inlined-button'
                                component={PreviousIcon}
                                onClick={() => {
                                    setPrevButton('regular');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${prevFilteredText}`}>
                            <Icon
                                className='cvat-player-previous-filtered-inlined-button'
                                component={PreviousFilteredIcon}
                                onClick={() => {
                                    setPrevButton('filtered');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${prevEmptyText}`}>
                            <Icon
                                className='cvat-player-previous-empty-inlined-button'
                                component={PreviousEmptyIcon}
                                onClick={() => {
                                    setPrevButton('empty');
                                }}
                            />
                        </CVATTooltip>
                    </>
                )}
            > */}
            <CVATTooltip placement='top' title={`${prevButtonTooltipMessage} ${previousFrameShortcut}`}>
                {prevButton}
            </CVATTooltip>
            {/* </Popover> */}

            {!playing ? (
                // <CVATTooltip title={`Play ${playPauseShortcut}`}>
                // <CVATTooltip title={`播放 ${playPauseShortcut}`}>
                <CVATTooltip title={`${t('workspace.top.play')} ${playPauseShortcut}`}>
                    {/* <Icon className='cvat-player-play-button' component={PlayIcon} onClick={onSwitchPlay} /> */}
                    <PlayCircleOutlined className='cvat-player-play-button' onClick={onSwitchPlay} />
                </CVATTooltip>
            ) : (
                // <CVATTooltip title={`Pause ${playPauseShortcut}`}>
                // <CVATTooltip title={`暂停 ${playPauseShortcut}`}>
                <CVATTooltip title={`${t('workspace.top.pause')} ${playPauseShortcut}`}>
                    {/* <Icon className='cvat-player-pause-button' component={PauseIcon} onClick={onSwitchPlay} /> */}
                    <PauseCircleOutlined className='cvat-player-pause-button' onClick={onSwitchPlay} />
                </CVATTooltip>
            )}

            {/* <Popover
                trigger='contextMenu'
                placement='bottom'
                content={(
                    <>
                        <CVATTooltip title={`${nextRegularText}`}>
                            <Icon
                                className='cvat-player-next-inlined-button'
                                component={NextIcon}
                                onClick={() => {
                                    setNextButton('regular');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${nextFilteredText}`}>
                            <Icon
                                className='cvat-player-next-filtered-inlined-button'
                                component={NextFilteredIcon}
                                onClick={() => {
                                    setNextButton('filtered');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${nextEmptyText}`}>
                            <Icon
                                className='cvat-player-next-empty-inlined-button'
                                component={NextEmptyIcon}
                                onClick={() => {
                                    setNextButton('empty');
                                }}
                            />
                        </CVATTooltip>
                    </>
                )}
            > */}
            <CVATTooltip placement='top' title={`${nextButtonTooltipMessage} ${nextFrameShortcut}`}>
                {nextButton}
            </CVATTooltip>
            {/* </Popover> */}
            {/* <CVATTooltip title={`Go next with a step ${forwardShortcut}`}> */}
            {/* <CVATTooltip title={`下一节 ${forwardShortcut}`}> */}
            <CVATTooltip title={`${t('workspace.top.next_step')} ${forwardShortcut}`}>
                {/* <Icon className='cvat-player-forward-button' component={ForwardJumpIcon} onClick={onForward} /> */}
                <ForwardOutlined className='cvat-player-forward-button' onClick={onForward} />
            </CVATTooltip>
            {/* <CVATTooltip title='Go to the last frame'> */}
            {/* <CVATTooltip title='最后一帧'> */}
            <CVATTooltip title={t('workspace.top.last_frame')}>
                {/* <Icon className='cvat-player-last-button' component={LastIcon} onClick={onLastFrame} /> */}
                <FastForwardOutlined className='cvat-player-last-button' onClick={onLastFrame} />
            </CVATTooltip>
        </Col>
    );
}

export default React.memo(PlayerButtons);
