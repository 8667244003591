/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-27 21:04:06
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { Canvas } from 'cvat-canvas-wrapper';
import { RectangleIcon } from 'icons';
import { useTranslation } from 'react-i18next';
// import { ShapeType } from 'reducers/interfaces';

// import DrawShapePopoverContainer from
// 'containers/annotation-page/standard-workspace/controls-side-bar/draw-shape-popover';
// import withVisibilityHandling from './handle-popover-visibility';

export interface Props {
    canvasInstance: Canvas;
    isDrawing: boolean;
    disabled?: boolean;
    onClick(): void;
}

// const CustomPopover = withVisibilityHandling(Popover, 'draw-rectangle');
function DrawRectangleControl(props: Props): JSX.Element {
    const {
        // canvasInstance,
        isDrawing,
        disabled,
        onClick,
    } = props;
    const { t } = useTranslation();
    // const dynamicPopoverProps = isDrawing ? {
    //     overlayStyle: {
    //         display: 'none',
    //     },
    // } : {};

    const dynamicIconProps = isDrawing
        ? {
              className: 'cvat-draw-rectangle-control cvat-active-canvas-control',
              // onClick: (): void => {
              //     canvasInstance.draw({ enabled: false });
              // },
          }
        : {
              className: 'cvat-draw-rectangle-control',
          };

    return disabled ? (
        <Icon className='cvat-draw-rectangle-control cvat-disabled-canvas-control' component={RectangleIcon} />
    ) : (
        // <CustomPopover
        //     {...dynamicPopoverProps}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.RECTANGLE} />}
        // >
        // </CustomPopover>
        <Tooltip placement='rightTop' title={t('workspace.side.controls.rectangle_tip')}>
            <Icon onClick={onClick} {...dynamicIconProps} component={RectangleIcon} />
        </Tooltip>
    );
}

export default React.memo(DrawRectangleControl);
