// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT
// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
// import notification from 'antd/lib/notification';
import { useDispatch, useSelector } from 'react-redux';
// import { QuestionCircleOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import ImagePreview from 'components/common/aatp-image-preview';

import { CombinedState } from 'reducers/interfaces';
// import { hideShowContextImage, getContextImageAsync } from 'actions/annotation-actions';
// import CVATTooltip from 'components/common/cvat-tooltip';
// import ContextImageSvg from './context-image-svg';
import Image, { ImageGroup } from 'components/common/canvas-images';

export function adjustContextImagePosition(sidebarCollapsed: boolean): void {
    const element = window.document.getElementsByClassName('aatp-context-image-wrapper')[0] as
        | HTMLDivElement
        | undefined;
    if (element) {
        if (sidebarCollapsed) {
            element.style.right = '40px';
        } else {
            element.style.right = '';
        }
    }
}

interface Props {
    // cuboidsByImages: any;
}

function ContextImage({}: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const {
        frame: { number: frame, hasRelatedContext, data: frameData },
        playing,
    } = useSelector((state: CombinedState) => state.annotation.player);
    const {
        imagesData,
        hidden: contextImageHidden,
        fetching: contextImageFetching,
    } = useSelector((state: CombinedState) => state.annotation.player.contextImage);
    // const [requested, setRequested] = useState(false);
    const [urls, setUrls] = useState<string[]>([]);
    const [contextImagesData, setContextImagesData] = useState<any[]>([]);
    const maxWidth = 'calc(100% - 30px)';

    useEffect(() => {
        setUrls(contextImagesData.map((imageData: any) => URL.createObjectURL(imageData.data)));
        return () => {
            urls.forEach((url) => {
                URL.revokeObjectURL(url);
            });
        };
    }, [contextImagesData]);

    // useEffect(() => {
    //     if (requested && !playing) {
    //         setRequested(false);
    //     }
    // }, [frame, contextImagesData, playing]);

    // useEffect(() => {
    //     if (hasRelatedContext && !contextImageHidden && !requested) {
    //         dispatch(getContextImageAsync());
    //         setRequested(true);
    //     }
    // }, [contextImageHidden, requested, hasRelatedContext, frame]);

    useEffect(() => {
        if (hasRelatedContext && !contextImageHidden && frameData) {
            try {
                frameData
                    .data((): void => {
                        setContextImagesData([]);
                    })
                    .then((data: any): void => {
                        if (data.imageData && data.imageData.images) {
                            setContextImagesData(data.imageData.images || []);
                        }
                    });
            } catch (err) {}
        }
    }, [hasRelatedContext, contextImageHidden, frameData]);

    if (!hasRelatedContext) {
        return null;
    }

    return (
        <Row className='aatp_canvas_image' justify='end'>
            <ImageGroup>
                <Space className='aatp_image_thumbnail'>
                    {contextImagesData &&
                        contextImagesData.map((imageData: any, index: number) => {
                            return (
                                <Image
                                    key={imageData?.name}
                                    name={imageData?.name}
                                    src={urls[index]}
                                    loading={contextImageFetching}
                                />
                            );
                        })}
                </Space>
            </ImageGroup>
        </Row>
    );
}

export default React.memo(ContextImage);
