/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-18 18:20:48
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { MergeIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { ActiveControl } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    switchMergeShortcut: string;
    disabled?: boolean;
    mergeObjects(enabled: boolean): void;
}

function MergeControl(props: Props): JSX.Element {
    const { switchMergeShortcut, activeControl, canvasInstance, mergeObjects, disabled } = props;

    const { t } = useTranslation();

    const dynamicIconProps =
        activeControl === ActiveControl.MERGE
            ? {
                  className: 'cvat-merge-control cvat-active-canvas-control',
                  onClick: (): void => {
                      canvasInstance.merge({ enabled: false });
                      mergeObjects(false);
                  },
              }
            : {
                  className: 'cvat-merge-control',
                  onClick: (): void => {
                      canvasInstance.cancel();
                      canvasInstance.merge({ enabled: true });
                      mergeObjects(true);
                  },
              };

    return disabled ? (
        <Icon className='cvat-merge-control cvat-disabled-canvas-control' component={MergeIcon} />
    ) : (
        // <CVATTooltip title={`Merge shapes/tracks ${switchMergeShortcut}`} placement='right'>
        <CVATTooltip title={`${t('workspace.side.controls.merge_tip')} ${switchMergeShortcut}`} placement='right'>
            <Icon {...dynamicIconProps} component={MergeIcon} />
        </CVATTooltip>
    );
}

export default React.memo(MergeControl);
