/*
 * @Author: swxy
 * @Date: 2022-08-02 14:18:37
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

// 开发
// export const zjValueByLabelId: Record<number, any> = {
//     19: {
//         kind: 99,
//         objCertainty: 94,
//         motion: 97,
//         shapeObjType: 1,
//     },
//     20: {
//         kind: 92,
//         objCertainty: 88,
//         motion: 90,
//         shapeObjType: 4,
//     },
//     21: {
//         objCertainty: 93,
//         crowd: 91,
//         motion: 89,
//         shapeObjType: 2,
//     },
//     18: {
//         crowd: 98,
//         objCertainty: 95,
//         motion: 96,
//         shapeObjType: 3,
//     },
//     22: {
//         objCertainty: 82,
//         motion: 86,
//         shapeObjType: 5,
//     },
//     23: {
//         objCertainty: 87,
//         motion: 85,
//         shapeObjType: 6,
//     },
//     24: {
//         objCertainty: 84,
//         motion: 83,
//         shapeObjType: 7,
//     },
// };

// 生产
export const zjValueByLabelId: Record<number, any> = {
    27: {
        kind: 190,
        objCertainty: 191,
        motion: 188,
        shapeObjType: 1,
    },
    28: {
        kind: 189,
        objCertainty: 187,
        motion: 185,
        shapeObjType: 4,
    },
    29: {
        objCertainty: 184,
        crowd: 186,
        motion: 182,
        shapeObjType: 2,
    },
    30: {
        crowd: 183,
        objCertainty: 181,
        motion: 180,
        shapeObjType: 3,
    },
    31: {
        objCertainty: 178,
        motion: 179,
        shapeObjType: 5,
    },
    32: {
        objCertainty: 177,
        motion: 176,
        shapeObjType: 6,
    },
    33: {
        objCertainty: 174,
        motion: 175,
        shapeObjType: 7,
    },
};

export interface ZJSummarizeData {
    shapeObjType?: number; //  框目标物类型
    objCertainty?: number; //     确定性   false integer(int32)
    crowd?: number; //     是否密集   false integer(int32)
    motion?: number; //     运动状态   false integer(int32)
    kind ?: string; //    类型   false string
}

export enum ZJExtendKey {
    shapeObjType = 'shapeObjType',
    objCertainty = 'objCertainty',
    crowd = 'crowd',
    motion = 'motion',
    kind = 'kind',
}
