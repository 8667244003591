/*
 * @Author: swxy
 * @Date: 2022-03-18 10:04:08
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/projects-page/project-list.tsx
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import Pagination from 'antd/lib/pagination';

// import { getProjectsAsync } from 'actions/projects-actions';
import { CombinedState, Project } from 'reducers/interfaces';
import ProjectItem from './project-item';

const pageSize = 12;

export default function ProjectListComponent(): JSX.Element {
    // const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const projectsCount = useSelector((state: CombinedState) => state.projects.count);
    // const { page } = useSelector((state: CombinedState) => state.projects.gettingQuery);
    const projects = useSelector((state: CombinedState) => state.projects.current);
    // const gettingQuery = useSelector((state: CombinedState) => state.projects.gettingQuery);

    // const changePage = useCallback(() => {
    //     // dispatch(
    //     //     getProjectsAsync({
    //     //         ...gettingQuery,
    //     //     }),
    //     // );
    // }, [dispatch, getProjectsAsync]);

    // useEffect(() => {
    //     console.log('执行了几次');

    //     // changePage();
    // }, []);

    const dimensions = {
        md: 22,
        lg: 18,
        xl: 16,
        xxl: 16,
    };

    return (
        <>
            <Row justify='center' align='middle' className='cvat-project-list-content'>
                <Col className='cvat-projects-list' {...dimensions}>
                    {projects
                        .filter((p, index) => index + 1 < page * pageSize && index + 1 > (page - 1) * pageSize)
                        .map(
                            (project: Project): JSX.Element => (
                                <ProjectItem key={project.instance.id} projectInstance={project} />
                            ),
                        )}
                </Col>
            </Row>
            <Row justify='center' align='middle'>
                <Col {...dimensions}>
                    <Pagination
                        className='cvat-projects-pagination'
                        onChange={setPage}
                        showSizeChanger={false}
                        total={projectsCount}
                        pageSize={pageSize}
                        current={page}
                        showQuickJumper
                    />
                </Col>
            </Row>
        </>
    );
}
