/*
 * @Author: swxy
 * @Date: 2021-12-21 10:17:35
 * @LastEditTime: 2022-01-05 17:42:20
 * @LastEditors: swxy
 * @FilePath: \aatp-2d-ui\cvat-ui\src\components\annotation-page\top-bar\IDInforManage.tsx
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import {
    Row, Col, InputNumber, Modal, Checkbox, Tooltip,
} from 'antd';
import React, { useRef, useState, useEffect } from 'react';

import '../styles.scss';

interface Props {
    visible: boolean;
    maxID: number;
    minID: number;

    changeSerialid(): void;
    onChange(minID: string | undefined, includesLocked: boolean): void;
}

function IDInforManage(props: Props): JSX.Element {
    const {
        minID = 0,
        maxID = 0,
        visible,
        changeSerialid,
        onChange,
    } = props;

    const [maxShow, setMaxShow] = useState(maxID.toString());

    const inputRef = useRef<HTMLInputElement>(null);
    const checkboxRef = useRef<any>(null);
    useEffect(() => {
        setMaxShow(maxID.toString());
    }, [maxID]);
    const changeValue = (num: number): void => {
        if (num !== minID) {
            setMaxShow('???');
        } else {
            setMaxShow(maxID.toString());
        }
    };

    return (
        <Modal
            className=''
            visible={visible}
            destroyOnClose
            centered
            onCancel={changeSerialid}
            onOk={() => {
                onChange(inputRef.current?.value, !!checkboxRef.current?.state?.checked);
            }}
            title={`统一设置ID的起始值  ${minID} ～ ${maxID}`}
            okText='确定'
            cancelText='取消'
        >
            <Row align='middle' justify='center' className='aatp-objects-sidebar-states-header-top-id-show'>
                <Col>
                    <Tooltip title='标注对象的属性中，类型为serialid的字段'>
                        ID:
                    </Tooltip>
                </Col>
                <Col><InputNumber ref={inputRef} defaultValue={minID} onChange={changeValue} /></Col>
                <Col>至</Col>
                <Col>{maxShow}</Col>
            </Row>
            <Row align='middle' justify='end' className='aatp-objects-sidebar-states-header-top-id-checkbox'>
                <Tooltip title='包含已锁定的标注对象'>
                    <Checkbox ref={checkboxRef}>包含锁定</Checkbox>
                </Tooltip>
            </Row>
            <Row align='middle' justify='center' className='aatp-objects-sidebar-states-header-top-id-show'>
                <Col style={{ color: 'red' }}>
                    该功能会修改当前帧所有(默认不包含已锁定的对象)的标注对象，请谨慎使用
                </Col>
            </Row>
        </Modal>
    );
}

export default React.memo(IDInforManage);
