/*
 * @Author: swxy
 * @Date: 2022-08-11 10:37:24
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import { Modal } from 'antd';
import React, { FC, memo, useState } from 'react';
import Preview from './preview';

interface Props {
    preview?: boolean; // 是否可以展开
    // src?: string | Blob | null;
}

interface PreviewContext {
    context: string;
    canPreview: boolean;
}

export interface GroupConsumerValue extends Props {
    isImageGroup?: boolean;
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;

    previewContexts: Map<number, PreviewContext>;
    setPreviewContexts: React.Dispatch<React.SetStateAction<Map<number, PreviewContext>>>;

    registerImage: (id: number, context: string, canPreview?: boolean) => () => void;

    // setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
    // setMousePosition: React.Dispatch<React.SetStateAction<null | { x: number; y: number }>>;
}

export const context = React.createContext<GroupConsumerValue>({
    isImageGroup: false,
    current: 0,
    setCurrent: () => null,

    previewContexts: new Map(),
    setPreviewContexts: () => null,

    registerImage: () => () => null,
});

const { Provider } = context;
const ImageGroup: FC<Props> = ({ children, preview = true }) => {
    const [current, setCurrent] = useState(0);
    const [previewContexts, setPreviewContexts] = useState<Map<number, PreviewContext>>(new Map());

    // 保存图像信息
    const registerImage = (id: number, context: string, canPreview: boolean = true) => {
        const unregister = () => {
            setPreviewContexts((oldPreviewContexts) => {
                const newPreviewContexts = new Map(oldPreviewContexts);
                const deleteResult = newPreviewContexts.delete(id);
                return deleteResult ? newPreviewContexts : oldPreviewContexts;
            });
        };
        setPreviewContexts((oldPreviewContexts) => {
            return new Map(oldPreviewContexts).set(id, { context, canPreview });
        });

        return unregister;
    };

    return (
        <Provider
            value={{
                preview,

                current,
                setCurrent,

                previewContexts,
                setPreviewContexts,

                isImageGroup: true,
                registerImage,
            }}
        >
            {children}
            <Preview />
            {/* <Modal visible={current !== 0} destroyOnClose footer={null} modalRender={() => }></Modal> */}
        </Provider>
    );
};

export default ImageGroup;
