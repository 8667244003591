// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionCreator, AnyAction, Dispatch, Store } from 'redux';
import { ThunkAction } from 'utils/redux';
import isAbleToChangeFrame from 'utils/is-able-to-change-frame';
import { RectDrawingMethod, CuboidDrawingMethod, Canvas } from 'cvat-canvas-wrapper';
import getCore from 'cvat-core-wrapper';
import logger, { LogType } from 'cvat-logger';
import { getCVATStore } from 'cvat-store';

import {
    ActiveControl,
    CombinedState,
    ContextMenuType,
    DimensionType,
    FrameSpeed,
    Model,
    ObjectType,
    OpenCVTool,
    Rotation,
    ShapeType,
    Task,
    Workspace,
    StatesOrdering,
    TaskStatus,
    ActivationModel,
    ImageDataType,
} from 'reducers/interfaces';
// import aatp from 'aatp-extentd-wrapper';
import { AttributeType } from 'components/labels-editor/label-form';
import { checkAnnotationStatus } from 'utils/constant';
import { updateJobAsync } from './tasks-actions';
import { switchToolsBlockerState } from './settings-actions';

// const aatp = aatpCore();

interface AnnotationsParameters {
    filters: string[];
    frame: number;
    showAllInterpolationTracks: boolean;
    jobInstance: any;
}

const cvat = getCore();
let store: null | Store<CombinedState> = null;

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}

function receiveAnnotationsParameters(): AnnotationsParameters {
    if (store === null) {
        store = getCVATStore();
    }

    const state: CombinedState = getStore().getState();
    const {
        annotation: {
            annotations: { filters },
            player: {
                frame: { number: frame },
            },
            job: { instance: jobInstance },
        },
        settings: {
            workspace: { showAllInterpolationTracks },
        },
    } = state;

    return {
        filters,
        frame,
        jobInstance,
        showAllInterpolationTracks,
    };
}

export function computeZRange(states: any[]): number[] {
    const filteredStates = states.filter((state: any): any => state.objectType !== ObjectType.TAG);
    let minZ = filteredStates.length ? filteredStates[0].zOrder : 0;
    let maxZ = filteredStates.length ? filteredStates[0].zOrder : 0;
    filteredStates.forEach((state: any): void => {
        minZ = Math.min(minZ, state.zOrder);
        maxZ = Math.max(maxZ, state.zOrder);
    });

    return [minZ, maxZ];
}

async function jobInfoGenerator(job: any): Promise<Record<string, number>> {
    const { total } = await job.annotations.statistics();
    return {
        'frame count': job.stopFrame - job.startFrame + 1,
        'track count':
            total.rectangle.shape +
            total.rectangle.track +
            total.polygon.shape +
            total.polygon.track +
            total.polyline.shape +
            total.polyline.track +
            total.points.shape +
            total.points.track +
            total.cuboid.shape +
            total.cuboid.track,
        'object count': total.total,
        'box count': total.rectangle.shape + total.rectangle.track,
        'polygon count': total.polygon.shape + total.polygon.track,
        'polyline count': total.polyline.shape + total.polyline.track,
        'points count': total.points.shape + total.points.track,
        'cuboids count': total.cuboid.shape + total.cuboid.track,
        'image count': total.image.shape + total.image.track,
        'tag count': total.tags,
    };
}

export enum AnnotationActionTypes {
    GET_JOB = 'GET_JOB',
    GET_JOB_SUCCESS = 'GET_JOB_SUCCESS',
    GET_JOB_FAILED = 'GET_JOB_FAILED',
    CLOSE_JOB = 'CLOSE_JOB',
    CHANGE_FRAME = 'CHANGE_FRAME',
    CHANGE_FRAME_SUCCESS = 'CHANGE_FRAME_SUCCESS',
    CHANGE_FRAME_FAILED = 'CHANGE_FRAME_FAILED',
    SAVE_ANNOTATIONS = 'SAVE_ANNOTATIONS',
    SAVE_ANNOTATIONS_SUCCESS = 'SAVE_ANNOTATIONS_SUCCESS',
    SAVE_ANNOTATIONS_FAILED = 'SAVE_ANNOTATIONS_FAILED',
    SAVE_UPDATE_ANNOTATIONS_STATUS = 'SAVE_UPDATE_ANNOTATIONS_STATUS',
    SWITCH_PLAY = 'SWITCH_PLAY',
    CONFIRM_CANVAS_READY = 'CONFIRM_CANVAS_READY',
    DRAG_CANVAS = 'DRAG_CANVAS',
    // REVIEW_CANVAS = 'REVIEW_CANVAS',
    ZOOM_CANVAS = 'ZOOM_CANVAS',
    SELECT_ISSUE_POSITION = 'SELECT_ISSUE_POSITION',
    MERGE_OBJECTS = 'MERGE_OBJECTS',
    GROUP_OBJECTS = 'GROUP_OBJECTS',
    SPLIT_TRACK = 'SPLIT_TRACK',
    COPY_SHAPE = 'COPY_SHAPE',
    PASTE_SHAPE = 'PASTE_SHAPE',
    EDIT_SHAPE = 'EDIT_SHAPE',
    REPEAT_DRAW_SHAPE = 'REPEAT_DRAW_SHAPE',
    SHAPE_DRAWN = 'SHAPE_DRAWN',
    RESET_CANVAS = 'RESET_CANVAS',
    REMEMBER_CREATED_OBJECT = 'REMEMBER_CREATED_OBJECT',
    UPDATE_ANNOTATIONS_SUCCESS = 'UPDATE_ANNOTATIONS_SUCCESS',
    UPDATE_ANNOTATIONS_FAILED = 'UPDATE_ANNOTATIONS_FAILED',
    CREATE_ANNOTATIONS_SUCCESS = 'CREATE_ANNOTATIONS_SUCCESS',
    CREATE_ANNOTATIONS_FAILED = 'CREATE_ANNOTATIONS_FAILED',
    MERGE_ANNOTATIONS_SUCCESS = 'MERGE_ANNOTATIONS_SUCCESS',
    MERGE_ANNOTATIONS_FAILED = 'MERGE_ANNOTATIONS_FAILED',
    RESET_ANNOTATIONS_GROUP = 'RESET_ANNOTATIONS_GROUP',
    GROUP_ANNOTATIONS = 'GROUP_ANNOTATIONS',
    GROUP_ANNOTATIONS_SUCCESS = 'GROUP_ANNOTATIONS_SUCCESS',
    GROUP_ANNOTATIONS_FAILED = 'GROUP_ANNOTATIONS_FAILED',
    SPLIT_ANNOTATIONS_SUCCESS = 'SPLIT_ANNOTATIONS_SUCCESS',
    SPLIT_ANNOTATIONS_FAILED = 'SPLIT_ANNOTATIONS_FAILED',
    COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR',
    COLLAPSE_APPEARANCE = 'COLLAPSE_APPEARANCE',
    COLLAPSE_OBJECT_ITEMS = 'COLLAPSE_OBJECT_ITEMS',
    ACTIVATE_OBJECT = 'ACTIVATE_OBJECT',
    REMOVE_OBJECT_SUCCESS = 'REMOVE_OBJECT_SUCCESS',
    REMOVE_OBJECT_FAILED = 'REMOVE_OBJECT_FAILED',
    PROPAGATE_OBJECT = 'PROPAGATE_OBJECT',
    PROPAGATE_OBJECT_SUCCESS = 'PROPAGATE_OBJECT_SUCCESS',
    PROPAGATE_OBJECT_FAILED = 'PROPAGATE_OBJECT_FAILED',
    CHANGE_PROPAGATE_FRAMES = 'CHANGE_PROPAGATE_FRAMES',
    SWITCH_SHOWING_STATISTICS = 'SWITCH_SHOWING_STATISTICS',
    SWITCH_SHOWING_FILTERS = 'SWITCH_SHOWING_FILTERS',
    COLLECT_STATISTICS = 'COLLECT_STATISTICS',
    COLLECT_STATISTICS_SUCCESS = 'COLLECT_STATISTICS_SUCCESS',
    COLLECT_STATISTICS_FAILED = 'COLLECT_STATISTICS_FAILED',
    UPLOAD_JOB_ANNOTATIONS = 'UPLOAD_JOB_ANNOTATIONS',
    UPLOAD_JOB_ANNOTATIONS_SUCCESS = 'UPLOAD_JOB_ANNOTATIONS_SUCCESS',
    UPLOAD_JOB_ANNOTATIONS_FAILED = 'UPLOAD_JOB_ANNOTATIONS_FAILED',
    REMOVE_JOB_ANNOTATIONS_SUCCESS = 'REMOVE_JOB_ANNOTATIONS_SUCCESS',
    REMOVE_JOB_ANNOTATIONS_FAILED = 'REMOVE_JOB_ANNOTATIONS_FAILED',
    UPDATE_CANVAS_CONTEXT_MENU = 'UPDATE_CANVAS_CONTEXT_MENU',
    UNDO_ACTION_SUCCESS = 'UNDO_ACTION_SUCCESS',
    UNDO_ACTION_FAILED = 'UNDO_ACTION_FAILED',
    REDO_ACTION_SUCCESS = 'REDO_ACTION_SUCCESS',
    REDO_ACTION_FAILED = 'REDO_ACTION_FAILED',
    CHANGE_ANNOTATIONS_FILTERS = 'CHANGE_ANNOTATIONS_FILTERS',
    FETCH_ANNOTATIONS_SUCCESS = 'FETCH_ANNOTATIONS_SUCCESS',
    FETCH_ANNOTATIONS_FAILED = 'FETCH_ANNOTATIONS_FAILED',
    ROTATE_FRAME = 'ROTATE_FRAME',
    SWITCH_Z_LAYER = 'SWITCH_Z_LAYER',
    ADD_Z_LAYER = 'ADD_Z_LAYER',
    SEARCH_ANNOTATIONS_FAILED = 'SEARCH_ANNOTATIONS_FAILED',
    SEARCH_EMPTY_FRAME_FAILED = 'SEARCH_EMPTY_FRAME_FAILED',
    CHANGE_WORKSPACE = 'CHANGE_WORKSPACE',
    SAVE_LOGS_SUCCESS = 'SAVE_LOGS_SUCCESS',
    SAVE_LOGS_FAILED = 'SAVE_LOGS_FAILED',
    INTERACT_WITH_CANVAS = 'INTERACT_WITH_CANVAS',
    GET_DATA_FAILED = 'GET_DATA_FAILED',
    SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG = 'SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG',
    UPDATE_PREDICTOR_STATE = 'UPDATE_PREDICTOR_STATE',
    GET_PREDICTIONS = 'GET_PREDICTIONS',
    GET_PREDICTIONS_FAILED = 'GET_PREDICTIONS_FAILED',
    GET_PREDICTIONS_SUCCESS = 'GET_PREDICTIONS_SUCCESS',
    HIDE_SHOW_CONTEXT_IMAGE = 'HIDE_SHOW_CONTEXT_IMAGE',
    GET_CONTEXT_IMAGE = 'GET_CONTEXT_IMAGE',
    GET_CONTEXT_IMAGE_SUCCESS = 'GET_CONTEXT_IMAGE_SUCCESS',
    GET_CONTEXT_IMAGE_FAILED = 'GET_CONTEXT_IMAGE_FAILED',
    SWITCH_NAVIGATION_BLOCKED = 'SWITCH_NAVIGATION_BLOCKED',
    CHANGE_ANNOTATIONS_SETTING_STATUS = 'CHANGE_ANNOTATIONS_SETTING_STATUS',

    SAVE_ANNOTATIONS_SILENT = 'SAVE_ANNOTATIONS_SILENT',
    SAVE_ANNOTATIONS_SILENT_SUCCESS = 'SAVE_ANNOTATIONS_SILENT_SUCCESS',
    SAVE_ANNOTATIONS_SILENT_FAILED = 'SAVE_ANNOTATIONS_SILENT_FAILED',
    MOUSEMULTSELECT = 'MOUSEMULTSELECT',
    CHANGE_SIDEBAR_POSITION = 'CHANGE_SIDEBAR_POSITION',
    ONLY_SHOW_SELECT_STATES = 'ONLY_SHOW_SELECT_STATES',
    CHANGE_PANEL_MODEL = 'CHANGE_PANEL_MODEL',
    UPDATE_SERIALID = 'UPDATE_SERIALID',
    CHANGE_PANEL_SORT = 'CHANGE_PANEL_SORT',
    // CHANGE_DRAW_TYPE = 'CHANGE_DRAW_TYPE',
    CHANGE_ACTIVE_MODEL = 'CHANGE_ACTIVE_MODEL',
    UPDATE_OBJECT_RELATION_SUCCESS = 'UPDATE_OBJECT_RELATION_SUCCESS',
    UPDATE_OBJECT_RELATION_FAILED = 'UPDATE_OBJECT_RELATION_FAILED',
    GET_OBJECT_RELATION_SUCCESS = 'GET_OBJECT_RELATION_SUCCESS',
    GET_OBJECT_RELATION_FAILED = 'GET_OBJECT_RELATION_FAILED',
    CHANGE_RELATION_OPERATE = 'CHANGE_RELATION_OPERATE',

    SELECT_OBJECTS = 'SELECT_OBJECTS',
}

export function saveLogsAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>) => {
        try {
            await logger.save();
            dispatch({
                type: AnnotationActionTypes.SAVE_LOGS_SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SAVE_LOGS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function changeWorkspace(workspace: Workspace): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_WORKSPACE,
        payload: {
            workspace,
        },
    };
}

export function getDataFailed(error: any): AnyAction {
    return {
        type: AnnotationActionTypes.GET_DATA_FAILED,
        payload: {
            error,
        },
    };
}

export function addZLayer(): AnyAction {
    return {
        type: AnnotationActionTypes.ADD_Z_LAYER,
        payload: {},
    };
}

export function switchZLayer(cur: number): AnyAction {
    return {
        type: AnnotationActionTypes.SWITCH_Z_LAYER,
        payload: {
            cur,
        },
    };
}

export function fetchAnnotationsAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const { filters, frame, showAllInterpolationTracks, jobInstance } = receiveAnnotationsParameters();
            const states = await jobInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await jobInstance.actions.get();
            const [minZ, maxZ] = computeZRange(states);

            dispatch({
                type: AnnotationActionTypes.FETCH_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                    minZ,
                    maxZ,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.FETCH_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function changeAnnotationsFilters(filters: any[]): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_ANNOTATIONS_FILTERS,
        payload: { filters },
    };
}

export function updateCanvasContextMenu(
    visible: boolean,
    left: number,
    top: number,
    pointID: number | null = null,
    type?: ContextMenuType,
): AnyAction {
    return {
        type: AnnotationActionTypes.UPDATE_CANVAS_CONTEXT_MENU,
        payload: {
            visible,
            left,
            top,
            type,
            pointID,
        },
    };
}

export function removeAnnotationsAsync(
    startFrame: number,
    endFrame: number,
    delTrackKeyframesOnly: boolean,
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const { filters, frame, showAllInterpolationTracks, jobInstance } = receiveAnnotationsParameters();
            await jobInstance.annotations.clear(false, startFrame, endFrame, delTrackKeyframesOnly);
            await jobInstance.actions.clear();
            const history = await jobInstance.actions.get();
            const states = await jobInstance.annotations.get(frame, showAllInterpolationTracks, filters);

            dispatch({
                type: AnnotationActionTypes.REMOVE_JOB_ANNOTATIONS_SUCCESS,
                payload: {
                    history,
                    states,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.REMOVE_JOB_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function uploadJobAnnotationsAsync(job: any, loader: any, file: File): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const state: CombinedState = getStore().getState();
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

            if (state.tasks.activities.loads[job.taskId]) {
                throw Error('Annotations is being uploaded for the task');
            }
            if (state.annotation.activities.loads[job.id]) {
                throw Error('Only one uploading of annotations for a job allowed at the same time');
            }

            dispatch({
                type: AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS,
                payload: {
                    job,
                    loader,
                },
            });

            const frame = state.annotation.player.frame.number;
            await job.annotations.upload(file, loader);

            await job.logger.log(LogType.uploadAnnotations, {
                ...(await jobInfoGenerator(job)),
            });

            await job.annotations.clear(true);
            await job.actions.clear();
            const history = await job.actions.get();

            // One more update to escape some problems
            // in canvas when shape with the same
            // clientID has different type (polygon, rectangle) for example
            dispatch({
                type: AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS_SUCCESS,
                payload: {
                    job,
                    states: [],
                    history,
                },
            });

            const states = await job.annotations.get(frame, showAllInterpolationTracks, filters);

            setTimeout(() => {
                dispatch({
                    type: AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS_SUCCESS,
                    payload: {
                        history,
                        job,
                        states,
                    },
                });
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS_FAILED,
                payload: {
                    job,
                    error,
                },
            });
        }
    };
}

export function collectStatisticsAsync(sessionInstance: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch({
                type: AnnotationActionTypes.COLLECT_STATISTICS,
                payload: {},
            });

            const data = await sessionInstance.annotations.statistics();

            dispatch({
                type: AnnotationActionTypes.COLLECT_STATISTICS_SUCCESS,
                payload: {
                    data,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.COLLECT_STATISTICS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function showStatistics(visible: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SWITCH_SHOWING_STATISTICS,
        payload: {
            visible,
        },
    };
}
export function showFilters(visible: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SWITCH_SHOWING_FILTERS,
        payload: {
            visible,
        },
    };
}

export function propagateObjectAsync(sessionInstance: any, objectState: any, from: number, to: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const copy = {
                attributes: objectState.attributes,
                points: objectState.points,
                occluded: objectState.occluded,
                objectType: objectState.objectType !== ObjectType.TRACK ? objectState.objectType : ObjectType.SHAPE,
                shapeType: objectState.shapeType,
                label: objectState.label,
                zOrder: objectState.zOrder,
                frame: from,
                source: objectState.source,
            };

            await sessionInstance.logger.log(LogType.propagateObject, { count: to - from + 1 });
            const states = [];
            for (let frame = from; frame <= to; frame++) {
                copy.frame = frame;
                const newState = new cvat.classes.ObjectState(copy);
                states.push(newState);
            }

            await sessionInstance.annotations.put(states);
            const history = await sessionInstance.actions.get();

            dispatch({
                type: AnnotationActionTypes.PROPAGATE_OBJECT_SUCCESS,
                payload: {
                    objectState,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.PROPAGATE_OBJECT_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function propagateObject(objectState: any | null): AnyAction {
    return {
        type: AnnotationActionTypes.PROPAGATE_OBJECT,
        payload: {
            objectState,
        },
    };
}

export function changePropagateFrames(frames: number): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_PROPAGATE_FRAMES,
        payload: {
            frames,
        },
    };
}

// export function removeObjectAsync(sessionInstance: any, objectState: any, force: boolean): ThunkAction {
//     return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
//         try {
//             await sessionInstance.logger.log(LogType.deleteObject, { count: 1 });
//             const { frame } = receiveAnnotationsParameters();

//             const removed = await objectState.delete(frame, force);
//             const history = await sessionInstance.actions.get();

//             if (removed) {
//                 dispatch({
//                     type: AnnotationActionTypes.REMOVE_OBJECT_SUCCESS,
//                     payload: {
//                         objectState,
//                         history,
//                     },
//                 });
//             } else {
//                 throw new Error('Could not remove the locked object');
//             }
//         } catch (error) {
//             dispatch({
//                 type: AnnotationActionTypes.REMOVE_OBJECT_FAILED,
//                 payload: {
//                     error,
//                 },
//             });
//         }
//     };
// }

export function editShape(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.EDIT_SHAPE,
        payload: {
            enabled,
        },
    };
}

export function copyShape(objectState: any): AnyAction {
    const job = getStore().getState().annotation.job.instance;
    job.logger.log(LogType.copyObject, { count: 1 });

    return {
        type: AnnotationActionTypes.COPY_SHAPE,
        payload: {
            objectState,
        },
    };
}

export function activateObject(activatedStateID: number | null, activatedAttributeID: number | null): AnyAction {
    return {
        type: AnnotationActionTypes.ACTIVATE_OBJECT,
        payload: {
            activatedStateID,
            activatedAttributeID,
        },
    };
}

export function collapseSidebar(): AnyAction {
    return {
        type: AnnotationActionTypes.COLLAPSE_SIDEBAR,
        payload: {},
    };
}

export function collapseAppearance(): AnyAction {
    return {
        type: AnnotationActionTypes.COLLAPSE_APPEARANCE,
        payload: {},
    };
}

export function collapseObjectItems(states: any[], collapsed: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.COLLAPSE_OBJECT_ITEMS,
        payload: {
            states,
            collapsed,
        },
    };
}

export function switchPlay(playing: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SWITCH_PLAY,
        payload: {
            playing,
        },
    };
}

export function getPredictionsAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            annotations: {
                states: currentStates,
                zLayer: { cur: curZOrder },
            },
            predictor: { enabled, annotatedFrames },
        } = getStore().getState().annotation;

        const { filters, frame, showAllInterpolationTracks, jobInstance: job } = receiveAnnotationsParameters();
        if (!enabled || currentStates.length || annotatedFrames.includes(frame)) return;

        dispatch({
            type: AnnotationActionTypes.GET_PREDICTIONS,
            payload: {},
        });

        let annotations = [];
        try {
            annotations = await job.predictor.predict(frame);
            // current frame could be changed during a request above, need to fetch it from store again
            const { number: currentFrame } = getStore().getState().annotation.player.frame;
            if (frame !== currentFrame || annotations === null) {
                // another request has already been sent or user went to another frame
                // we do not need dispatch predictions success action
                return;
            }
            annotations = annotations.map(
                (data: any): any =>
                    new cvat.classes.ObjectState({
                        shapeType: data.type,
                        label: job.labels.filter((label: any): boolean => label.id === data.label)[0],
                        points: data.points,
                        objectType: ObjectType.SHAPE,
                        frame,
                        occluded: false,
                        source: 'auto',
                        attributes: {},
                        zOrder: curZOrder,
                    }),
            );

            dispatch({
                type: AnnotationActionTypes.GET_PREDICTIONS_SUCCESS,
                payload: { frame },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.GET_PREDICTIONS_FAILED,
                payload: {
                    error,
                },
            });
        }

        try {
            await job.annotations.put(annotations);
            const states = await job.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await job.actions.get();

            dispatch({
                type: AnnotationActionTypes.CREATE_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.CREATE_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function updateMinMaxID(minID: number, maxID: number): AnyAction {
    return {
        type: AnnotationActionTypes.UPDATE_SERIALID,
        payload: {
            minID,
            maxID,
        },
    };
}

// 计算当前states里的最大值和最小值
function changeMinMaxID(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>) => {
        // 获取当前的states列表，获取当前frame，不需要筛选
        const state: CombinedState = getStore().getState();
        const { frame, jobInstance } = receiveAnnotationsParameters();
        const {
            job: { serialidItemBylabelId },
        } = state.annotation;
        const states = await jobInstance.annotations.get(frame, true, []);

        let minID: number = Number.MAX_SAFE_INTEGER; // 没有值的话，默认是0，所以从1开始。
        let maxID = 0; // 没有值的话，默认是0，所以从1开始。

        const { length } = states;
        for (let index = 0; index < length; index++) {
            const element = states[index];
            const id = element.attributes[serialidItemBylabelId[element.label.id]];
            minID = Math.min(minID, parseInt(id || Number.MAX_SAFE_INTEGER, 10));
            maxID = Math.max(minID, parseInt(id || '0', 10));
        }

        minID = minID === Number.MAX_SAFE_INTEGER ? 0 : minID;

        dispatch(updateMinMaxID(minID, maxID));
        // if (!isChangeLock) {
        //     // 连同锁定对象一起更改，则将锁定对象的id记录下来。 以便于重置id时跳过已有的.
        //     states.forEach((item: any) => {
        //         if (item.lock) {
        //             lockIDs.push(item.attributes[idItemBylabelId[item.label.id]]);
        //         }
        //     });
        // } else {
        //     // 如果包含，则记录锁定的对象。 以便于改回锁定状态
        //     states.forEach((item: any) => {
        //         if (item.lock) {
        //             lockStates.push(item);
        //         }
        //     });
        // }

        //     const getID = (): string => {
        //         if (lockIDs.includes(maxID)) {
        //             maxID = `${(parseInt(maxID, 10)) + 1}`;
        //             return getID();
        //         }
        //         return maxID;
        //     };

        //     const upStates = [];

        //     for (let index = 0; index < states.length; index++) {
        //         const item = states[index];
        //         if (includesLocked || !item.lock) {
        //             item.lock = false; // 将所有锁定对象置为非锁定。
        //             // 当包含锁定对象，或者对象未锁定时。 可以更改id
        //             const att: Record<number, string> = {};

        //             att[idItemBylabelId[item.label.id]] = getID();
        //             maxID = `${(parseInt(maxID, 10)) + 1}`;
        //             item.attributes = att;
        //             upStates.push(item);
        //         }
        //     }

        //     await dispatch(updateAnnotationsAsync(upStates));

        //     await dispatch(updateAnnotationsAsync(lockStates.map((item: any) => {
        //         // eslint-disable-next-line no-param-reassign
        //         item.lock = true;
        //         return item;
        //     })));
    };
}

export function changeFrameAsync(
    toFrame: number,
    fillBuffer?: boolean,
    frameStep?: number,
    forceUpdate?: boolean,
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>, getState: () => CombinedState): Promise<void> => {
        const state: CombinedState = getState();
        const { instance: job } = state.annotation.job;
        const { filters, frame, showAllInterpolationTracks } = receiveAnnotationsParameters();

        try {
            if (toFrame < job.startFrame || toFrame > job.stopFrame) {
                throw Error(`Required frame ${toFrame} is out of the current job`);
            }

            const abortAction = (): AnyAction => {
                const currentState = getState();
                return {
                    type: AnnotationActionTypes.CHANGE_FRAME_SUCCESS,
                    payload: {
                        number: currentState.annotation.player.frame.number,
                        data: currentState.annotation.player.frame.data,
                        filename: currentState.annotation.player.frame.filename,
                        hasRelatedContext: currentState.annotation.player.frame.hasRelatedContext,
                        delay: currentState.annotation.player.frame.delay,
                        changeTime: currentState.annotation.player.frame.changeTime,
                        states: currentState.annotation.annotations.states,
                        minZ: currentState.annotation.annotations.zLayer.min,
                        maxZ: currentState.annotation.annotations.zLayer.max,
                        curZ: currentState.annotation.annotations.zLayer.cur,
                    },
                };
            };

            dispatch({
                type: AnnotationActionTypes.CHANGE_FRAME,
                payload: {},
            });

            if (toFrame === frame && !forceUpdate) {
                dispatch(abortAction());
                return;
            }

            const data = await job.frames.get(toFrame, fillBuffer, frameStep);
            const states = await job.annotations.get(toFrame, showAllInterpolationTracks, filters);

            if (!isAbleToChangeFrame()) {
                // while doing async actions above, canvas can become used by a user in another way
                // so, we need an additional check and if it is used, we do not update state
                dispatch(abortAction());
                return;
            }

            // 保存最新访问的帧，但如果访问的最初和最后一帧，则删除记录
            if (toFrame === job.stopFrame || toFrame === job.startFrame) {
                localStorage.removeItem(`Job_${job.id}_frame`);
            } else {
                localStorage.setItem(`Job_${job.id}_frame`, `${toFrame}`);
            }
            await job.logger.log(LogType.changeFrame, {
                from: frame,
                to: toFrame,
            });

            const [minZ, maxZ] = computeZRange(states);
            const currentTime = new Date().getTime();
            let frameSpeed;
            switch (state.settings.player.frameSpeed) {
                case FrameSpeed.Fast: {
                    frameSpeed = (FrameSpeed.Fast as number) / 2;
                    break;
                }
                case FrameSpeed.Fastest: {
                    frameSpeed = (FrameSpeed.Fastest as number) / 3;
                    break;
                }
                default: {
                    frameSpeed = state.settings.player.frameSpeed as number;
                }
            }
            const delay = Math.max(
                0,
                Math.round(1000 / frameSpeed) - currentTime + (state.annotation.player.frame.changeTime as number),
            );

            dispatch({
                type: AnnotationActionTypes.CHANGE_FRAME_SUCCESS,
                payload: {
                    number: toFrame,
                    data,
                    filename: data.filename,
                    hasRelatedContext: data.hasRelatedContext,
                    states,
                    minZ,
                    maxZ,
                    curZ: maxZ,
                    changeTime: currentTime + delay,
                    delay,
                },
            });
            dispatch(getPredictionsAsync());
            // dispatch(changeMinMaxID());
        } catch (error) {
            if (error !== 'not needed') {
                dispatch({
                    type: AnnotationActionTypes.CHANGE_FRAME_FAILED,
                    payload: {
                        number: toFrame,
                        error,
                    },
                });
            }
        }
    };
}

export function undoActionAsync(sessionInstance: any, frame: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const state = getStore().getState();
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

            // TODO: use affected IDs as an optimization
            const [undo] = state.annotation.annotations.history.undo.slice(-1);
            const undoLog = await sessionInstance.logger.log(
                LogType.undoAction,
                {
                    name: undo[0],
                    frame: undo[1],
                    count: 1,
                },
                true,
            );

            await sessionInstance.actions.undo();
            const history = await sessionInstance.actions.get();
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const [minZ, maxZ] = computeZRange(states);
            await undoLog.close();

            dispatch({
                type: AnnotationActionTypes.UNDO_ACTION_SUCCESS,
                payload: {
                    history,
                    states,
                    minZ,
                    maxZ,
                },
            });

            const undoOnFrame = undo[1];
            if (frame !== undoOnFrame) {
                dispatch(changeFrameAsync(undoOnFrame));
            }
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.UNDO_ACTION_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function redoActionAsync(sessionInstance: any, frame: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const state = getStore().getState();
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

            // TODO: use affected IDs as an optimization
            const [redo] = state.annotation.annotations.history.redo.slice(-1);
            const redoLog = await sessionInstance.logger.log(
                LogType.redoAction,
                {
                    name: redo[0],
                    frame: redo[1],
                    count: 1,
                },
                true,
            );

            await sessionInstance.actions.redo();
            const history = await sessionInstance.actions.get();
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const [minZ, maxZ] = computeZRange(states);
            await redoLog.close();

            dispatch({
                type: AnnotationActionTypes.REDO_ACTION_SUCCESS,
                payload: {
                    history,
                    states,
                    minZ,
                    maxZ,
                },
            });

            const redoOnFrame = redo[1];
            if (frame !== redoOnFrame) {
                dispatch(changeFrameAsync(redoOnFrame));
            }
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.REDO_ACTION_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function rotateCurrentFrame(rotation: Rotation): AnyAction {
    const state: CombinedState = getStore().getState();
    const {
        annotation: {
            player: {
                frame: { number: frameNumber },
                frameAngles,
            },
            job: {
                instance: job,
                instance: { startFrame },
            },
        },
        settings: {
            player: { rotateAll },
        },
    } = state;

    const frameAngle = (frameAngles[frameNumber - startFrame] + (rotation === Rotation.CLOCKWISE90 ? 90 : 270)) % 360;

    job.logger.log(LogType.rotateImage, { angle: frameAngle });

    return {
        type: AnnotationActionTypes.ROTATE_FRAME,
        payload: {
            offset: frameNumber - state.annotation.job.instance.startFrame,
            angle: frameAngle,
            rotateAll,
        },
    };
}

export function dragCanvas(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.DRAG_CANVAS,
        payload: {
            enabled,
        },
    };
}

// export function reviewCanvas(enabled: boolean): AnyAction {
//     return {
//         type: AnnotationActionTypes.REVIEW_CANVAS,
//         payload: {
//             enabled,
//         },
//     };
// }

export function zoomCanvas(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.ZOOM_CANVAS,
        payload: {
            enabled,
        },
    };
}

export function resetCanvas(): AnyAction {
    return {
        type: AnnotationActionTypes.RESET_CANVAS,
        payload: {},
    };
}

export function confirmCanvasReady(): AnyAction {
    return {
        type: AnnotationActionTypes.CONFIRM_CANVAS_READY,
        payload: {},
    };
}

export function closeJob(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { jobInstance } = receiveAnnotationsParameters();
        if (jobInstance) {
            await jobInstance.close();
        }

        dispatch({
            type: AnnotationActionTypes.CLOSE_JOB,
        });
    };
}

export function getRelations(job: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        // const { filters, showAllInterpolationTracks, jobInstance } = receiveAnnotationsParameters();

        try {
            // console.log('执行了');

            // const getRelations = (): void => {
            //     console.log('获取到当前帧的关系列表', frame);
            // };

            const state: CombinedState = getStore().getState();
            const {
                annotation: {
                    annotations: { states },
                },
            } = state;
            // 获取关系列表
            // const data = await aatp.getJobObjectRelation(job.id);
            const data = await cvat.jobs.getRelation(job.id);
            // console.log('data：', data);
            // 父id对应的子id们
            const relationByServerIdAndObjectType: Record<string, any> = {};

            // 与 serverID 相对应的 clientID
            const objectByServerID: Record<number, number> = {};
            states.forEach((current) => {
                objectByServerID[current.serverID] = current;
            });
            // 目前data中子对象的id,应该是唯一的.
            // 也就是本逻辑是基于:一个子对象,只能有一个父对象...来写的.    如果子对象有多个父对象,需要重写该逻辑
            // 遍历子对象关系列表
            data.forEach((item: any) => {
                // // 创建父关系对象key
                const key = `${item.parentShapeId}-${item.parentFrameType === 0 ? ObjectType.SHAPE : ObjectType.TRACK}`;
                // 获取可能已有的父关系对象
                const Prelation = relationByServerIdAndObjectType[key] || {};
                // 更新父关系对象的子clientID列表
                const { children = [] } = Prelation;
                const object = objectByServerID[item.childShapeId];
                if (object) {
                    children.push(object);
                }

                Prelation.children = children;
                relationByServerIdAndObjectType[key] = Prelation;

                // 创建子关系对象id
                const subKey = `${item.childShapeId}-${
                    item.childFrameType === 0 ? ObjectType.SHAPE : ObjectType.TRACK
                }`;
                const Srelation = relationByServerIdAndObjectType[subKey] || {};
                Srelation.id = item.id;
                Srelation.relationType = item.relationType;
                const parent = objectByServerID[item.parentShapeId];
                if (parent) {
                    Srelation.parent = parent;
                }
                relationByServerIdAndObjectType[subKey] = Srelation;
            });

            // 需要更新的states
            const updateStates = states.reduce((previous, current) => {
                const key = `${current.serverID}-${current.objectType}`;
                const relation = relationByServerIdAndObjectType[key];
                if (relation) {
                    // eslint-disable-next-line no-param-reassign
                    current.relation = relation;
                    if (relation.relationType) {
                        // eslint-disable-next-line no-param-reassign
                        current.pinned = false;
                    }
                    previous.push(current);
                }
                return previous;
            }, []);
            // console.log('upadateState：', updateStates);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            dispatch(updateAnnotationsAsync(updateStates));
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.GET_OBJECT_RELATION_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function getJobAsync(tid: number, jid: number, initialFrame = 0, initialFilters: object[]): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>, getState): Promise<void> => {
        try {
            const state = getState();
            const filters = initialFilters;
            const {
                settings: {
                    workspace: { showAllInterpolationTracks },
                },
            } = state;

            dispatch({
                type: AnnotationActionTypes.GET_JOB,
                payload: {
                    requestedId: jid,
                },
            });

            const loadJobEvent = await logger.log(
                LogType.loadJob,
                {
                    task_id: tid,
                    job_id: jid,
                },
                true,
            );

            // Check if the task was already downloaded to the state
            let job: any | null = null;
            const [task] = state.tasks.current
                .filter((_task: Task) => _task.instance.id === tid)
                .map((_task: Task) => _task.instance);
            if (task && task.jobs && task.jobs.length) {
                [job] = task.jobs.filter((_job: any) => _job.id === jid);
                if (!job) {
                    throw new Error(`Task ${tid} doesn't contain the job ${jid}`);
                }
            } else {
                [job] = await cvat.jobs.get({ jobId: jid });
            }

            // console.log('testdd:', jobStatus);

            const frameNumber = Math.max(Math.min(job.stopFrame, initialFrame), 0);
            const frameData = await job.frames.get(frameNumber);
            // call first getting of frame data before rendering interface
            // to load and decode first chunk
            try {
                await frameData.data();
                // console.log('获取data结束了？');
            } catch (error) {
                dispatch({
                    type: AnnotationActionTypes.GET_DATA_FAILED,
                    payload: {
                        error,
                    },
                });
            }
            const states = await job.annotations.get(frameNumber, showAllInterpolationTracks, filters);
            // 可以在一定状态之后再去调用。
            // 获取预设的评论列表
            const beforehandIssues = await job.beforehandIssues();
            // 获取评论
            const issues = await job.issues();
            // const issues: any = [];
            const [minZ, maxZ] = computeZRange(states);
            const colors = [...cvat.enums.colors];

            loadJobEvent.close(await jobInfoGenerator(job));

            const openTime = Date.now();
            dispatch({
                type: AnnotationActionTypes.GET_JOB_SUCCESS,
                payload: {
                    openTime,
                    job,
                    issues,
                    beforehandIssues,
                    states,
                    frameNumber,
                    frameFilename: frameData.filename,
                    frameHasRelatedContext: frameData.hasRelatedContext,
                    frameData,
                    colors,
                    filters,
                    minZ,
                    maxZ,
                },
            });

            // if (job.dimension === DimensionType.DIM_3D) {
            //     const workspace = Workspace.STANDARD3D;
            //     dispatch(changeWorkspace(workspace));
            // }

            job.stage = checkAnnotationStatus(job.jobStatus);
            // job.status = job.stage;
            const updatePredictorStatus = async (): Promise<void> => {
                // get current job
                const currentState: CombinedState = getState();
                const { openTime: currentOpenTime, instance: currentJob } = currentState.annotation.job;
                if (currentJob === null || currentJob.id !== job.id || currentOpenTime !== openTime) {
                    // the job was closed, changed or reopened
                    return;
                }

                try {
                    const status = await job.predictor.status();
                    dispatch({
                        type: AnnotationActionTypes.UPDATE_PREDICTOR_STATE,
                        payload: status,
                    });
                    setTimeout(updatePredictorStatus, 60 * 1000);
                } catch (error) {
                    dispatch({
                        type: AnnotationActionTypes.UPDATE_PREDICTOR_STATE,
                        payload: { error },
                    });
                    setTimeout(updatePredictorStatus, 20 * 1000);
                }
            };

            if (state.plugins.list.PREDICT && job.projectId !== null) {
                updatePredictorStatus();
            }

            dispatch(getRelations(job));
            dispatch(changeFrameAsync(frameNumber, false));
            // dispatch(changeMinMaxID());
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.GET_JOB_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function saveAnnotationsAsync(sessionInstance: any, afterSave?: () => void): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

        try {
            // if (sessionInstance.status === TaskStatus.REVIEW) {
            //     throw new Error('当前工作已提交验证，还请耐心等待审核！');
            // }
            if (sessionInstance.status === TaskStatus.COMPLETED) {
                throw new Error('当前工作已完成，如有需要，请重新开启任务！');
            }

            dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS,
                payload: {},
            });

            const saveJobEvent = await sessionInstance.logger.log(LogType.saveJob, {}, true);

            await sessionInstance.annotations.save((status: string) => {
                dispatch({
                    type: AnnotationActionTypes.SAVE_UPDATE_ANNOTATIONS_STATUS,
                    payload: {
                        status,
                    },
                });
            });
            await saveJobEvent.close();
            await sessionInstance.logger.log(LogType.sendTaskInfo, await jobInfoGenerator(sessionInstance));
            dispatch(saveLogsAsync());

            const { frame } = receiveAnnotationsParameters();
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            if (typeof afterSave === 'function') {
                afterSave();
            }

            if (sessionInstance instanceof cvat.classes.Job && sessionInstance.state === cvat.enums.JobState.NEW) {
                sessionInstance.state = cvat.enums.JobState.IN_PROGRESS;
                dispatch(updateJobAsync(sessionInstance));
            }

            dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

// used to reproduce the latest drawing (in case of tags just creating) by using N
export function rememberObject(createParams: {
    activeObjectType?: ObjectType;
    activeLabelID?: number;
    activeShapeType?: ShapeType;
    activeNumOfPoints?: number;
    activeRectDrawingMethod?: RectDrawingMethod;
    activeCuboidDrawingMethod?: CuboidDrawingMethod;
}): AnyAction {
    return {
        type: AnnotationActionTypes.REMEMBER_CREATED_OBJECT,
        payload: createParams,
    };
}

export function shapeDrawn(): AnyAction {
    return {
        type: AnnotationActionTypes.SHAPE_DRAWN,
        payload: {},
    };
}

export function selectIssuePosition(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SELECT_ISSUE_POSITION,
        payload: {
            enabled,
        },
    };
}

export function mergeObjects(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.MERGE_OBJECTS,
        payload: {
            enabled,
        },
    };
}

export function groupObjects(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.GROUP_OBJECTS,
        payload: {
            enabled,
        },
    };
}

export function splitTrack(enabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SPLIT_TRACK,
        payload: {
            enabled,
        },
    };
}

async function reAddObjectAttriID(sessionInstance: any, frame: number, statesToCreate: any[]): Promise<any[]> {
    // 计算最大值
    let max = 0;
    // 获取所有states
    // const states = await sessionInstance.annotations.get(frame, true);

    const state: CombinedState = getStore().getState();
    const {
        annotation: {
            annotations: { states },
        },
    } = state;
    // 记录下所有id的值
    const ids: number[] = [];

    try {
        states.forEach((item: any) => {
            const { attributes } = item.label;
            let attribute: any;
            if (attributes && attributes.length) {
                attribute = attributes.find((_att: any) => _att.inputType.toUpperCase() === AttributeType.SERIALID);
            }
            if (item.attributes && attribute) {
                ids.push(parseInt(item.attributes[attribute.id] || 0, 10));
                // max = Math.max(parseInt(item.attributes[attribute.id], 10) || 0, max);
            }
        });
    } catch (err) {
        // max = 0;
    }

    max = Math.max(max, ...ids);

    // 检查新增的states里的ID值，在当前的ids里，是否存在。不存在，则直接用复制过来时的id
    const checkIncludes = (value?: string | null): boolean => {
        if (value && !ids.includes(parseInt(value, 10))) {
            return false;
        }
        return true;
    };

    // 最大值计算完成
    await statesToCreate.map((item: any): any => {
        const labelAtt = item.label ? item.label.attributes : [];
        const attribute = labelAtt.find((_att: any): any => _att.inputType.toUpperCase() === AttributeType.SERIALID);
        const serialIDKey = attribute ? attribute.id : null;
        if (serialIDKey) {
            const idObject = {
                [serialIDKey]: checkIncludes(item.attributes[serialIDKey]) ? `${++max}` : item.attributes[serialIDKey],
            };
            // eslint-disable-next-line no-param-reassign
            item.attributes = idObject;
        }
        return item;
    });

    return statesToCreate;
}

// 静默保存，不会出现提示。 出错的时候提示
export function saveAnnotationsSilentAsync(sessionInstance: any, afterSave?: () => void): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

        try {
            if (sessionInstance.status === TaskStatus.COMPLETED) {
                return;
            }
            dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT,
                payload: {},
            });

            const saveJobEvent = await sessionInstance.logger.log(LogType.saveJob, {}, true);

            await sessionInstance.annotations.save((status: string) => {
                dispatch({
                    type: AnnotationActionTypes.SAVE_UPDATE_ANNOTATIONS_STATUS,
                    payload: {
                        status,
                    },
                });
            });
            await saveJobEvent.close();
            await sessionInstance.logger.log(LogType.sendTaskInfo, await jobInfoGenerator(sessionInstance));
            dispatch(saveLogsAsync());

            const { frame } = receiveAnnotationsParameters();
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);

            await dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT_SUCCESS,
                payload: {
                    states,
                },
            });

            if (typeof afterSave === 'function') {
                afterSave();
            }
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

saveAnnotationsSilentAsync.prototype.waitTimer = null;
saveAnnotationsSilentAsync.prototype.throttleTime = null;

function autoSave(jobInstance: any, afterSave?: () => void): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>) => {
        const state: CombinedState = getStore().getState();
        // const isAutoSave = type === 'object' ?
        //     state.settings.workspace.autoSaveByObject :
        //     state.settings.workspace.autoSaveByFrame;
        if (state.settings.workspace.autoSaveByObject) {
            // 判断是否已有记下的时间。有， 则是距离上次修改还没到3秒
            if (!saveAnnotationsSilentAsync.prototype.throttleTime) {
                // 节流更新  开始更新，记下当前时间
                saveAnnotationsSilentAsync.prototype.throttleTime = Date.now();
            }

            // 现在距离上次修改是否已经超过3秒，超过，则立刻执行一次保存。
            if (Date.now() - saveAnnotationsSilentAsync.prototype.throttleTime > 3000) {
                dispatch(saveAnnotationsSilentAsync(jobInstance, afterSave));
                saveAnnotationsSilentAsync.prototype.throttleTime = null;
            }

            // if (!saveAnnotationsSilentAsync.prototype.throttleTimer) {
            //     saveAnnotationsSilentAsync.prototype.throttleTimer = setTimeout(() => {
            //         dispatch(saveAnnotationsSilentAsync(jobInstance));
            //         clearTimeout(saveAnnotationsSilentAsync.prototype.throttleTimer);
            //         saveAnnotationsSilentAsync.prototype.throttleTimer = null;
            //     }, 3000);
            // }

            // 最后一次修改的保存。  由于上面的节流无法保存触发最后一次修改。因此要手动执行最后一次修改。
            // 如果事先有定时器则清除
            if (saveAnnotationsSilentAsync.prototype.waitTimer) {
                clearTimeout(saveAnnotationsSilentAsync.prototype.waitTimer);
                saveAnnotationsSilentAsync.prototype.waitTimer = null;
            }
            // 增加一个定时器，确保多次点击时
            // 该保存是整体保存
            saveAnnotationsSilentAsync.prototype.waitTimer = setTimeout(() => {
                dispatch(saveAnnotationsSilentAsync(jobInstance, afterSave));
                saveAnnotationsSilentAsync.prototype.throttleTime = null;
            }, 3000);
        }
    };
    // return async (dispatch: ActionCreator<Dispatch>) => {
    //     const state: CombinedState = getStore().getState();
    //     const isAutoSave = type === 'object' ?
    //         state.settings.workspace.autoSaveByObject :
    //         state.settings.workspace.autoSaveByFrame;
    //     if (isAutoSave) {

    //         // 最后一次修改的保存。

    //         // 设置了切换frame的时候自动保存
    //         if (state.settings.workspace.autoSaveByObject) {
    //             // 如果事先有定时器则清除
    //             if (saveAnnotationsSilentAsync.prototype.waitTimer) {
    //                 clearTimeout(saveAnnotationsSilentAsync.prototype.waitTimer);
    //                 saveAnnotationsSilentAsync.prototype.waitTimer = null;
    //             }
    //             // 增加一个定时器，确保多次点击时
    //             // 该保存是整体保存
    //             saveAnnotationsSilentAsync.prototype.waitTimer = setTimeout(() => {
    //                 dispatch(saveAnnotationsSilentAsync(jobInstance));
    //             }, 3000);
    //         }
    //     }
    // };
}

export function updateAnnotationsAsync(
    statesToUpdate: any[],
    // , isChangeLabelType?: boolean
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { jobInstance, filters, frame, showAllInterpolationTracks } = receiveAnnotationsParameters();

        // console.log('测试更新11111111111:', statesToUpdate);

        try {
            if (statesToUpdate.some((state: any): boolean => state.updateFlags.zOrder)) {
                // deactivate object to visualize changes immediately (UX)
                dispatch(activateObject(null, null));
            }

            const promises = statesToUpdate.map((objectState: any): Promise<any> => objectState.save());
            const states = await Promise.all(promises);
            // if (isChangeLabelType) {
            //     // 切换标签类型后，id会丢失。 重新标注属性的ID值。
            //     states = await reAddObjectAttriID(jobInstance, frame, states);
            //     const idPromises = states.map((objectState: any): Promise<any> => objectState.save());
            //     states = await Promise.all(idPromises);
            //     // id重新保存
            // }
            // const states = await Promise.all(promises);
            const history = await jobInstance.actions.get();
            const [minZ, maxZ] = computeZRange(states);

            dispatch(autoSave(jobInstance));
            dispatch({
                type: AnnotationActionTypes.UPDATE_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                    minZ,
                    maxZ,
                },
            });
        } catch (error) {
            const states = await jobInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            dispatch({
                type: AnnotationActionTypes.UPDATE_ANNOTATIONS_FAILED,
                payload: {
                    error,
                    states,
                },
            });
        }
    };
}

export function removeObjectAsync(sessionInstance: any, states: any[], force: boolean): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const objectStates = Array.isArray(states) ? states : [states];
            await sessionInstance.logger.log(LogType.deleteObject, { count: objectStates.length });
            const { frame } = receiveAnnotationsParameters();

            const promises: Promise<boolean>[] = [];
            objectStates.forEach((objectState: any) => {
                promises.push(objectState.delete(frame, force));
            });

            const removeds = await Promise.all(promises);

            const history = await sessionInstance.actions.get();

            if (removeds.every((removed: boolean) => removed)) {
                dispatch({
                    type: AnnotationActionTypes.REMOVE_OBJECT_SUCCESS,
                    payload: {
                        objectStates,
                        history,
                    },
                });
            } else if (removeds.some((removed: boolean) => removed)) {
                dispatch({
                    type: AnnotationActionTypes.REMOVE_OBJECT_SUCCESS,
                    payload: {
                        objectStates: objectStates.filter((_, index: number) => removeds[index]),
                        history,
                    },
                });
                throw new Error('Could not remove some locked objects');
            } else {
                throw new Error('Could not remove the locked object');
            }

            dispatch(autoSave(sessionInstance));
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.REMOVE_OBJECT_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function createAnnotationsAsync(
    sessionInstance: any,
    frame: number,
    statesToCreate: any[],
    afterSave?: () => void,
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            // 重新标注属性的ID值
            // const createObject = await reAddObjectAttriID(sessionInstance, frame, statesToCreate);
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
            await sessionInstance.annotations.put(statesToCreate);
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await sessionInstance.actions.get();

            dispatch(autoSave(sessionInstance, afterSave));
            dispatch({
                type: AnnotationActionTypes.CREATE_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.CREATE_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function mergeAnnotationsAsync(sessionInstance: any, frame: number, statesToMerge: any[]): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
            await sessionInstance.annotations.merge(statesToMerge);
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await sessionInstance.actions.get();

            dispatch({
                type: AnnotationActionTypes.MERGE_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.MERGE_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function resetAnnotationsGroup(): AnyAction {
    return {
        type: AnnotationActionTypes.RESET_ANNOTATIONS_GROUP,
        payload: {},
    };
}

export function groupAnnotationsAsync(sessionInstance: any, frame: number, statesToGroup: any[]): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
            const reset = getStore().getState().annotation.annotations.resetGroupFlag;

            // The action below set resetFlag to false
            dispatch({
                type: AnnotationActionTypes.GROUP_ANNOTATIONS,
                payload: {},
            });

            await sessionInstance.annotations.group(statesToGroup, reset);
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await sessionInstance.actions.get();

            dispatch({
                type: AnnotationActionTypes.GROUP_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.GROUP_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function splitAnnotationsAsync(sessionInstance: any, frame: number, stateToSplit: any): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
        try {
            await sessionInstance.annotations.split(stateToSplit, frame);
            const states = await sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            const history = await sessionInstance.actions.get();

            dispatch({
                type: AnnotationActionTypes.SPLIT_ANNOTATIONS_SUCCESS,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SPLIT_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function changeGroupColorAsync(group: number, color: string): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const groupStates = state.annotation.annotations.states.filter(
            (_state: any): boolean => _state.group.id === group,
        );
        if (groupStates.length) {
            groupStates[0].group.color = color;
            dispatch(updateAnnotationsAsync(groupStates));
        } else {
            dispatch(updateAnnotationsAsync([]));
        }
    };
}

export function searchAnnotationsAsync(sessionInstance: any, frameFrom: number, frameTo: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const { filters } = receiveAnnotationsParameters();
            const frame = await sessionInstance.annotations.search(filters, frameFrom, frameTo);
            if (frame !== null) {
                dispatch(changeFrameAsync(frame));
            }
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SEARCH_ANNOTATIONS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function searchEmptyFrameAsync(sessionInstance: any, frameFrom: number, frameTo: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const frame = await sessionInstance.annotations.searchEmpty(frameFrom, frameTo);
            if (frame !== null) {
                dispatch(changeFrameAsync(frame));
            }
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SEARCH_EMPTY_FRAME_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export function pasteShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
            player: {
                frame: { number: frameNumber },
            },
            drawing: { activeInitialState: initialState },
        } = getStore().getState().annotation;

        if (initialState) {
            let activeControl = ActiveControl.CURSOR;
            if (initialState.shapeType === ShapeType.RECTANGLE) {
                activeControl = ActiveControl.DRAW_RECTANGLE;
            } else if (initialState.shapeType === ShapeType.POINTS) {
                activeControl = ActiveControl.DRAW_POINTS;
            } else if (initialState.shapeType === ShapeType.POLYGON) {
                activeControl = ActiveControl.DRAW_POLYGON;
            } else if (initialState.shapeType === ShapeType.POLYLINE) {
                activeControl = ActiveControl.DRAW_POLYLINE;
            } else if (initialState.shapeType === ShapeType.CUBOID) {
                activeControl = ActiveControl.DRAW_CUBOID;
            } else if (Array.isArray(initialState)) {
                activeControl = ActiveControl.MULT_DRAW;
            }

            dispatch({
                type: AnnotationActionTypes.PASTE_SHAPE,
                payload: {
                    activeControl,
                },
            });
            if (canvasInstance instanceof Canvas) {
                canvasInstance.cancel();
            }
            if (initialState.objectType === ObjectType.TAG) {
                const objectState = new cvat.classes.ObjectState({
                    objectType: ObjectType.TAG,
                    label: initialState.label,
                    attributes: initialState.attributes,
                    frame: frameNumber,
                });
                dispatch(createAnnotationsAsync(jobInstance, frameNumber, [objectState]));
            } else {
                canvasInstance?.draw({
                    enabled: true,
                    initialState,
                });
            }
        }
    };
}

export function interactWithCanvas(activeInteractor: Model | OpenCVTool, activeLabelID: number): AnyAction {
    return {
        type: AnnotationActionTypes.INTERACT_WITH_CANVAS,
        payload: {
            activeInteractor,
            activeLabelID,
        },
    };
}

export function repeatDrawShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            canvas: { instance: canvasInstance },
            job: { labels, instance: jobInstance },
            player: {
                frame: { number: frameNumber },
            },
            drawing: {
                activeInteractor,
                activeObjectType,
                activeLabelID,
                activeShapeType,
                activeNumOfPoints,
                activeRectDrawingMethod,
                activeCuboidDrawingMethod,
            },
        } = getStore().getState().annotation;

        let activeControl = ActiveControl.CURSOR;
        if (activeInteractor && canvasInstance instanceof Canvas) {
            if (activeInteractor.type.includes('tracker')) {
                canvasInstance.interact({
                    enabled: true,
                    shapeType: 'rectangle',
                });
                dispatch(interactWithCanvas(activeInteractor, activeLabelID));
                dispatch(switchToolsBlockerState({ buttonVisible: false }));
            } else {
                canvasInstance.interact({
                    enabled: true,
                    shapeType: 'points',
                    ...activeInteractor.params.canvas,
                });
                dispatch(interactWithCanvas(activeInteractor, activeLabelID));
            }

            return;
        }
        if (activeShapeType === ShapeType.RECTANGLE) {
            activeControl = ActiveControl.DRAW_RECTANGLE;
        } else if (activeShapeType === ShapeType.POINTS) {
            activeControl = ActiveControl.DRAW_POINTS;
        } else if (activeShapeType === ShapeType.POLYGON) {
            activeControl = ActiveControl.DRAW_POLYGON;
        } else if (activeShapeType === ShapeType.POLYLINE) {
            activeControl = ActiveControl.DRAW_POLYLINE;
        } else if (activeShapeType === ShapeType.CUBOID) {
            activeControl = ActiveControl.DRAW_CUBOID;
        } else if (activeShapeType === ShapeType.ELLIPSE) {
            activeControl = ActiveControl.DRAW_ELLIPSE;
        }

        dispatch({
            type: AnnotationActionTypes.REPEAT_DRAW_SHAPE,
            payload: {
                activeControl,
            },
        });

        if (canvasInstance instanceof Canvas) {
            canvasInstance.cancel();
        }
        if (activeObjectType === ObjectType.TAG) {
            const objectState = new cvat.classes.ObjectState({
                objectType: ObjectType.TAG,
                label: labels.filter((label: any) => label.id === activeLabelID)[0],
                frame: frameNumber,
            });
            dispatch(createAnnotationsAsync(jobInstance, frameNumber, [objectState]));
        } else if (canvasInstance) {
            canvasInstance.draw({
                enabled: true,
                rectDrawingMethod: activeRectDrawingMethod,
                cuboidDrawingMethod: activeCuboidDrawingMethod,
                numberOfPoints: activeNumOfPoints,
                shapeType: activeShapeType,
                crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(activeShapeType),
            });
        }
    };
}

export function redrawShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            annotations: { activatedStateID, states },
            canvas: { instance: canvasInstance },
        } = getStore().getState().annotation;

        if (activatedStateID !== null) {
            const [state] = states.filter((_state: any): boolean => _state.clientID === activatedStateID);
            if (state && state.objectType !== ObjectType.TAG) {
                let activeControl = ActiveControl.CURSOR;
                if (state.shapeType === ShapeType.RECTANGLE) {
                    activeControl = ActiveControl.DRAW_RECTANGLE;
                } else if (state.shapeType === ShapeType.POINTS) {
                    activeControl = ActiveControl.DRAW_POINTS;
                } else if (state.shapeType === ShapeType.POLYGON) {
                    activeControl = ActiveControl.DRAW_POLYGON;
                } else if (state.shapeType === ShapeType.POLYLINE) {
                    activeControl = ActiveControl.DRAW_POLYLINE;
                } else if (state.shapeType === ShapeType.CUBOID) {
                    activeControl = ActiveControl.DRAW_CUBOID;
                }

                dispatch({
                    type: AnnotationActionTypes.REPEAT_DRAW_SHAPE,
                    payload: {
                        activeControl,
                    },
                });
                if (canvasInstance instanceof Canvas) {
                    canvasInstance.cancel();
                }
                canvasInstance?.draw({
                    enabled: true,
                    redraw: activatedStateID,
                    shapeType: state.shapeType,
                    crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(state.shapeType),
                });
            }
        }
    };
}

export function setForceExitAnnotationFlag(forceExit: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG,
        payload: {
            forceExit,
        },
    };
}

export function switchPredictor(predictorEnabled: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.UPDATE_PREDICTOR_STATE,
        payload: {
            enabled: predictorEnabled,
        },
    };
}
export function hideShowContextImage(hidden: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.HIDE_SHOW_CONTEXT_IMAGE,
        payload: {
            hidden,
        },
    };
}

export function getContextImageAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const { instance: job } = state.annotation.job;
        const { number: frameNumber } = state.annotation.player.frame;
        if (job.dimension === DimensionType.DIM_2D) {
            return;
        }

        try {
            dispatch({
                type: AnnotationActionTypes.GET_CONTEXT_IMAGE,
                payload: {},
            });

            const frameData = await job.frames.get(frameNumber);
            const imageData: ImageDataType = await frameData.data((): void => {});
            const contextImageDataJson = imageData.imageData.images;

            // const contextImageData = await job.frames.contextImage(frameNumber);
            // const contextImageDataJson = JSON.parse(contextImageData);
            // const contextImageDataJson = JSON.parse(contextImageData);
            dispatch({
                type: AnnotationActionTypes.GET_CONTEXT_IMAGE_SUCCESS,
                payload: {
                    // contextImageData,
                    contextImageDataJson,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.GET_CONTEXT_IMAGE_FAILED,
                payload: { error },
            });
        }
    };
}

export function switchNavigationBlocked(navigationBlocked: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.SWITCH_NAVIGATION_BLOCKED,
        payload: {
            navigationBlocked,
        },
    };
}

export function changeAnnotationsSettingStatus(visible?: boolean): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_ANNOTATIONS_SETTING_STATUS,
        payload: {
            visible,
        },
    };
}

export function selectObjects(selectedStatesID: number[]): AnyAction {
    return {
        type: AnnotationActionTypes.SELECT_OBJECTS,
        payload: {
            selectsID: selectedStatesID,
        },
    };
}

// mouse选中
export function mouseMultSelect(e: MouseEvent): AnyAction {
    const {
        canvas: { instance: canvasInstance },
        // job: { labels, instance: jobInstance },
        // player: {
        //     frame: { number: frameNumber },
        // },
        // drawing: {
        //     activeInteractor,
        //     activeObjectType,
        //     activeLabelID,
        //     activeShapeType,
        //     activeNumOfPoints,
        //     activeRectDrawingMethod,
        // },
    } = getStore().getState().annotation;
    (canvasInstance as Canvas)?.multSelect(e);
    return {
        type: AnnotationActionTypes.MOUSEMULTSELECT,
        payload: {
            e,
        },
    };
}

export function changeSibebarPosition(): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_SIDEBAR_POSITION,
        payload: {},
    };
}

export function onlyShowSelectStates(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch({
            type: AnnotationActionTypes.ONLY_SHOW_SELECT_STATES,
            payload: {},
        });
    };
}

export function changePanelModel(): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_PANEL_MODEL,
        payload: {},
    };
}

// 重置所有SerialId，
export function updateSerialId(minID: string | undefined, includesLocked: boolean): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        let maxID: string = minID || '1'; // 没有值的话，默认是0，所以从1开始。
        const chagneMinID = parseInt(minID || '0', 10);
        const state: CombinedState = getStore().getState();
        const {
            annotations: { states },
            job: { labels, attributes },
        } = state.annotation;

        const idItemBylabelId: any = {};
        const lockIDs: string[] = []; // 记录锁定对象的id
        const lockStates: any[] = []; // 记录锁定的对象

        // 获取id
        for (const item of labels) {
            const itemAttributes = attributes[item.id];
            const changeItem = itemAttributes.find((ele) => ele.inputType === 'serialid');
            idItemBylabelId[item.id] = changeItem.id;
        }

        if (!includesLocked) {
            // 如果不包含锁定对象，则将锁定对象的id记录下来。 以便于重置id时跳过已有的.
            states.forEach((item: any) => {
                if (item.lock) {
                    lockIDs.push(item.attributes[idItemBylabelId[item.label.id]]);
                }
            });
        } else {
            // 如果包含，则记录锁定的对象。 以便于改回锁定状态
            states.forEach((item: any) => {
                if (item.lock) {
                    lockStates.push(item);
                }
            });
        }

        const getID = (): string => {
            if (lockIDs.includes(maxID)) {
                maxID = `${parseInt(maxID, 10) + 1}`;
                return getID();
            }
            return maxID;
        };

        const upStates = [];

        for (let index = 0; index < states.length; index++) {
            const item = states[index];
            if (includesLocked || !item.lock) {
                item.lock = false; // 将所有锁定对象置为非锁定。
                // 当包含锁定对象，或者对象未锁定时。 可以更改id
                const att: Record<number, string> = {};

                att[idItemBylabelId[item.label.id]] = getID();
                maxID = `${parseInt(maxID, 10) + 1}`;
                item.attributes = att;
                upStates.push(item);
            }
        }

        await dispatch(updateAnnotationsAsync(upStates));

        await dispatch(
            updateAnnotationsAsync(
                lockStates.map((item: any) => {
                    // eslint-disable-next-line no-param-reassign
                    item.lock = true;
                    return item;
                }),
            ),
        );

        dispatch(updateMinMaxID(chagneMinID, parseInt(maxID, 10)));
    };
}

export function changeStatesOrdering(statesOrdering: StatesOrdering): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_PANEL_SORT,
        payload: {
            statesOrdering,
        },
    };
}

// export function changeDrawType(isDrawShape: boolean): AnyAction {
//     return {
//         type: AnnotationActionTypes.CHANGE_DRAW_TYPE,
//         payload: {
//             isDrawShape,
//         },
//     };
// }

export function changeActiveModel(activeModel: ActivationModel): AnyAction {
    return {
        type: AnnotationActionTypes.CHANGE_ACTIVE_MODEL,
        payload: {
            activeModel,
        },
    };
}

/*
@name 新增关系
*/
export function updateObjectRelation(
    parentObject?: any, // 要关联的父对象
    subObject?: any, // 要关联的子对象
    deleteRelation?: any, // 要删除的对象的信息，两种情况。1、根据单id（对象id、父对象id、labelid等）来删，2、根据批量id来删
    updateStates?: any[], // 删除时，需要更新的states们。如果不传，不会更新视图，注意。
    relationType?: number, // 0def缺省;1divider分割线
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const {
            annotation: {
                annotations: { states },
            },
        } = state;
        try {
            const { jobInstance } = receiveAnnotationsParameters();
            // 3d没有关系
            if (jobInstance.dimension === 'three' || jobInstance.dimension === '3d') {
                return;
            }

            if (deleteRelation) {
                // 删除
                // const data = await aatp.saveJobObjectRelation({
                //     ...deleteRelation,
                // }, 'delete');

                const data = await cvat.jobs.saveRelation(
                    {
                        ...deleteRelation,
                    },
                    'delete',
                );
                if (data || data === 0) {
                    const updateObjects: any[] = [];
                    if (updateStates && updateStates.length) {
                        updateStates.forEach((item) => {
                            const { relation = {} } = item;
                            if (relation.parent) {
                                const { relation: Prelation = {} } = relation.parent;
                                let { children = [] } = Prelation;
                                children = children.filter((_object: any) => _object.clientID !== item.clientID);
                                Prelation.children = children;
                                relation.parent.relation = Prelation;
                                updateObjects.push(relation.parent);
                            }
                            if (relation.id) {
                                // eslint-disable-next-line no-param-reassign
                                item.relation = {};
                            }
                            updateObjects.push(item);
                        });
                        await dispatch(updateAnnotationsAsync(updateObjects));
                    }
                }
                return;
            }

            if (!deleteRelation) {
                // 添加和更新关系
                const { relation = {} } = subObject || {};

                // const result = await aatp.saveJobObjectRelation({
                const result = await cvat.jobs.saveRelation(
                    {
                        projectId: jobInstance.projectId,
                        taskId: jobInstance.taskId,
                        jobId: jobInstance.id,
                        id: relation.id,
                        childShapeId: subObject.serverID,
                        childSpecId: subObject.label.id,
                        childFrameType: subObject.objectType === ObjectType.TRACK ? 1 : 0,
                        parentShapeId: parentObject.serverID,
                        parentSpecId: parentObject.label.id,
                        parentFrameType: parentObject.objectType === ObjectType.TRACK ? 1 : 0,
                        relationType: relationType || relation.relationType ? relationType || relation.relationType : 0,
                    },
                    relation.id ? 'update' : 'create',
                );

                if (result) {
                    const updateObjects = [];
                    if (parentObject) {
                        const { relation: Prelation = {} } = parentObject;
                        const { children = [] } = Prelation;
                        children.push(subObject);
                        Prelation.children = children;
                        // eslint-disable-next-line no-param-reassign
                        parentObject.relation = Prelation;
                        updateObjects.push(parentObject);
                    }
                    if (subObject) {
                        const { Srelation = {} } = subObject;
                        if (Srelation.parent) {
                            // 已有父对象，在父对象中，删除该子元素
                            const oldParentObject = Srelation.parent;
                            const { relation: oldPrelation = {} } = oldParentObject;
                            let { children = [] } = oldParentObject;
                            children = children.filter((object: any) => object.clientID !== subObject.clientID);
                            oldPrelation.children = children;
                            oldParentObject.relation = oldPrelation;
                            updateObjects.push(oldParentObject);
                        }
                        Srelation.parent = parentObject;
                        Srelation.id = result.id;
                        Srelation.relationType = result.relationType;
                        // eslint-disable-next-line no-param-reassign
                        subObject.relation = Srelation;
                        if (result.relationType) {
                            // eslint-disable-next-line no-param-reassign
                            subObject.pinned = false;
                        }
                        updateObjects.push(subObject);
                    }
                    await dispatch(updateAnnotationsAsync(updateObjects));
                }
            }

            // dispatch({
            //     type: AnnotationActionTypes.CREATE_OBJECT_RELATION_SUCCESS,
            //     payload: {
            //     },
            // });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.UPDATE_OBJECT_RELATION_FAILED,
                payload: {
                    states,
                    error,
                },
            });
        }
    };
}

export function changeRelationOperate(relationOperate: boolean): AnyAction {
    const state: CombinedState = getStore().getState();
    const {
        annotation: {
            canvas: { instance },
        },
    } = state;
    const canvasInstance = instance as Canvas;
    if (canvasInstance.changeRelationOperate) {
        canvasInstance.changeRelationOperate(relationOperate);
    }
    return {
        type: AnnotationActionTypes.CHANGE_RELATION_OPERATE,
        payload: {
            relationOperate,
        },
    };
}

export function createAndSaveAttachAnnotations(parentObjects: any[], relationType?: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const {
            annotation: {
                // canvas: { instance },
                job: { instance: jobInstance },
                player: {
                    frame: { number },
                },
                drawing: { activeLabelID },
            },
        } = state;
        const type = relationType || 1;
        if (parentObjects && parentObjects.length) {
            const linesObjects = parentObjects.map((object: any) => {
                jobInstance.logger.log(LogType.drawObject, { count: 1, duration: 0 });
                const objectState = new cvat.classes.ObjectState({
                    objectType: object.objectType,
                    label: object.label || jobInstance.labels.filter((label: any) => label.id === activeLabelID)[0],
                    frame: number,
                    occluded: false,
                    shapeType: ShapeType.POLYLINE,
                    points:
                        type === 1
                            ? [
                                  object.points[0] + ((object.points[2] - object.points[0]) / 3) * 2,
                                  object.points[1],
                                  object.points[0] + ((object.points[2] - object.points[0]) / 3) * 2,
                                  object.points[3],
                              ]
                            : [
                                  object.points[0],
                                  object.points[1] + ((object.points[3] - object.points[1]) / 3) * 2,
                                  object.points[2],
                                  object.points[1] + ((object.points[3] - object.points[1]) / 3) * 2,
                              ],
                    zOrder: object.zOrder || 0,
                    relation: {
                        parent: object,
                    },
                });
                return objectState;
            });

            dispatch(
                createAnnotationsAsync(jobInstance, number, linesObjects, () => {
                    const updatedState: CombinedState = getStore().getState();
                    const {
                        annotation: {
                            annotations: { states },
                        },
                    } = updatedState;

                    // 创建成功的clientID
                    const updatesStates = states.filter((item: any) => item.relation.parent && !item.relation.id);

                    // console.log('updatesStates:', updatesStates);
                    updatesStates.forEach((subObject: any) => {
                        const { relation = {} } = subObject;
                        dispatch(updateObjectRelation(relation.parent, subObject, null, undefined, type));
                    });
                }),
            );
        }
    };
}

// export function submitJobAsync(data: any): ThunkAction {
//     return async (dispatch: ActionCreator<Dispatch>): Promise<boolean> => {

//     };
// }
