/*
 * @Author: swxy
 * @Date: 2022-01-06 14:45:42
 * @LastEditTime: 2022-08-18 17:51:46
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import React from 'react';
import { connect } from 'react-redux';
import { OneToOneOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { CombinedState, Workspace } from 'reducers/interfaces';
import { changeRelationOperate as changeRelationOperateAction } from 'actions/annotation-actions';
import '../styles.scss';
import { Translation } from 'react-i18next';

export interface Props {
    disabled: boolean;
}

interface StateToProps {
    workspace: Workspace;
    relationOperate: boolean; // 相关联的对象，父对象移动和删除时，子对象一起操作。
}

interface DispatchToProps {
    changeRelationOperate(relationOperate: boolean): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: { relationOperate, workspace },
    } = state;

    return {
        workspace,
        relationOperate,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        changeRelationOperate(relationOperate: boolean): void {
            dispatch(changeRelationOperateAction(relationOperate));
        },
    };
}

type AllProps = StateToProps & DispatchToProps & Props;
class MoreDropdown extends React.PureComponent<AllProps> {
    public constructor(props: AllProps) {
        super(props);

        this.state = {};
    }

    private selectClick = (): void => {
        const { relationOperate, changeRelationOperate } = this.props;
        if (changeRelationOperate) {
            changeRelationOperate(!relationOperate);
        }
    };

    public render(): JSX.Element {
        const { workspace, relationOperate, disabled } = this.props;
        if (workspace !== Workspace.STANDARD) {
            return <></>;
        }

        const dynamicIconProps = relationOperate
            ? {
                  className: 'aatp-content-right-tool cvat-active-canvas-control',
              }
            : {
                  className: 'aatp-content-right-tool',
              };

        return disabled ? (
            <OneToOneOutlined className='aatp-content-right-tool cvat-disabled-canvas-control' />
        ) : (
            // <Tooltip title='选中后，有父子关联的对象，当删除父对象时，子元素也会一起删除。'>
            <Tooltip title={<Translation>{(t) => t('workspace.side.controls.parent_sub_delete')}</Translation>}>
                <OneToOneOutlined {...dynamicIconProps} onClick={this.selectClick} />
            </Tooltip>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDropdown);
