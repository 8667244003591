/*
 * @Author: swxy
 * @Date: 2022-01-10 09:33:17
 * @LastEditTime: 2022-08-27 21:14:53
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import React from 'react';
import Icon from '@ant-design/icons';
import { SplitLineInRectangleIcon, SplitLineInRectangleIconH } from 'icons';

import { Canvas } from 'cvat-canvas-wrapper';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas;
    shortcut1: string;
    shortcut2: string;
    disabled?: boolean;
    onClick(type: number): void;
}

function SplitRectangleControl(props: Props): JSX.Element {
    const { shortcut1, shortcut2, disabled, onClick } = props;

    const { t } = useTranslation();

    const dynamicIconProps = {
        className: 'aatp-split-rectangle-control',
    };

    return (
        <>
            {disabled ? (
                <Icon
                    className='aatp-split-rectangle-control cvat-disabled-canvas-control'
                    component={SplitLineInRectangleIcon}
                />
            ) : (
                // <CVATTooltip title={`Split a track ${switchSplitShortcut}`} placement='right'>
                <CVATTooltip title={`${t('workspace.side.controls.add_v_line')} ${shortcut1}`} placement='right'>
                    <Icon
                        {...dynamicIconProps}
                        onClick={() => {
                            onClick(1);
                        }}
                        component={SplitLineInRectangleIcon}
                    />
                </CVATTooltip>
            )}
            {disabled ? (
                <Icon
                    className='aatp-split-rectangle-control cvat-disabled-canvas-control'
                    component={SplitLineInRectangleIconH}
                />
            ) : (
                // <CVATTooltip title={`Split a track ${switchSplitShortcut}`} placement='right'>
                <CVATTooltip title={`${t('workspace.side.controls.add_h_line')} ${shortcut2}`} placement='right'>
                    <Icon
                        {...dynamicIconProps}
                        onClick={() => {
                            onClick(2);
                        }}
                        component={SplitLineInRectangleIconH}
                    />
                </CVATTooltip>
            )}
        </>
    );
}

export default React.memo(SplitRectangleControl);
