// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import {
    // Icon,
    StopOutlined,
    CheckCircleOutlined,
    SaveFilled,
    RollbackOutlined,
} from '@ant-design/icons';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Timeline from 'antd/lib/timeline';
// import Dropdown from 'antd/lib/dropdown';
import {
    Tooltip,
    // Dropdown,
} from 'antd';
import { useHistory } from 'react-router';

// import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import {
    // MainMenuIcon, SaveIcon, UndoIcon, RedoIcon,
    AmygoLogoWhite,
} from 'icons';
import {
    ActiveControl,
    ToolsBlockerState,
    Workspace,
    // TaskStatus,
} from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import { saveDisable } from 'utils/constant';
import { useTranslation } from 'react-i18next';

interface Props {
    saving: boolean;
    savingStatuses: string[];
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    drawShortcut: string;
    switchToolsBlockerShortcut: string;
    toolsBlockerState: ToolsBlockerState;
    activeControl: ActiveControl;
    jobInstance: any;
    workspace: Workspace;
    user: any;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onFinishDraw(): void;
    onSwitchToolsBlockerState(): void;
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        savingStatuses,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        drawShortcut,
        switchToolsBlockerShortcut,
        activeControl,
        toolsBlockerState,
        jobInstance,
        workspace,
        user,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onFinishDraw,
        onSwitchToolsBlockerState,
    } = props;

    const history = useHistory();
    const { t } = useTranslation();
    const goBack = (): void => {
        const { location } = history;
        history.push(`/tasks/${jobInstance.taskId}`, location.state);
    };

    const getSave = (): JSX.Element => {
        if (workspace === Workspace.REVIEW_WORKSPACE) {
            return <></>;
        }
        if (!saveDisable(jobInstance.jobStatus, user?.roleType)) {
            return <></>;
        }
        // if (jobInstance.status !== TaskStatus.ANNOTATION) {
        //     return <></>;
        // }

        return (
            // <CVATTooltip overlay={`Save current changes ${saveShortcut}`}>
            <CVATTooltip overlay={`${t('workspace.top.save')} ${saveShortcut}`}>
                <Button
                    onClick={saving ? undefined : onSaveAnnotation}
                    type='link'
                    className={saving ? 'cvat-annotation-disabled-header-button' : 'cvat-annotation-header-button'}
                >
                    {/* <Icon component={SaveIcon} /> */}
                    <SaveFilled />
                    {/* {saving ? 'Saving...' : 'Save'} */}
                </Button>
            </CVATTooltip>
        );
    };

    const includesDoneButton = [
        ActiveControl.DRAW_POLYGON,
        ActiveControl.DRAW_POLYLINE,
        ActiveControl.DRAW_POINTS,
        ActiveControl.AI_TOOLS,
        ActiveControl.OPENCV_TOOLS,
    ].includes(activeControl);

    const includesToolsBlockerButton =
        [ActiveControl.OPENCV_TOOLS, ActiveControl.AI_TOOLS].includes(activeControl) && toolsBlockerState.buttonVisible;

    const shouldEnableToolsBlockerOnClick = [ActiveControl.OPENCV_TOOLS].includes(activeControl);

    return (
        <>
            <Modal title='Saving changes on the server' visible={saving} footer={[]} closable={false}>
                <Timeline pending={savingStatuses[savingStatuses.length - 1] || 'Pending..'}>
                    {savingStatuses.slice(0, -1).map((status: string, id: number) => (
                        <Timeline.Item key={id}>{status}</Timeline.Item>
                    ))}
                </Timeline>
            </Modal>
            <Col className='cvat-annotation-header-left-group'>
                {/* <Dropdown overlay={<AnnotationMenuContainer />}>
                    <Button type='link' className='cvat-annotation-header-button'>
                        <Icon component={MainMenuIcon} />
                        Menu
                    </Button>
                </Dropdown> */}
                {/* <Tooltip title='Go to the previous page'> */}
                <Tooltip title={t('workspace.top.back')}>
                    <Button type='link' className='cvat-annotation-header-button' onClick={goBack}>
                        <AmygoLogoWhite className='aatp_annotation_header_left_logo' />

                        {/* <span className='amygo_logo'>
                        amy
                        <span style={{ fontSize: '17px', fontWeight: 'bold' }}>G</span>
                        o.ai
                    </span> */}
                    </Button>
                </Tooltip>
                {getSave()}
                {/* <CVATTooltip overlay={`Undo: ${undoAction} ${undoShortcut}`}> */}
                <CVATTooltip overlay={`${t('workspace.top.undo')}: ${undoAction} ${undoShortcut}`}>
                    <Button
                        style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-button'
                        onClick={onUndoClick}
                    >
                        {/* <Icon component={UndoIcon} />
                        <span>Undo</span> */}
                        <RollbackOutlined />
                    </Button>
                </CVATTooltip>
                {/* <CVATTooltip overlay={`Redo: ${redoAction} ${redoShortcut}`}> */}
                <CVATTooltip overlay={`${t('workspace.top.redo')}: ${redoAction} ${redoShortcut}`}>
                    <Button
                        style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-button'
                        onClick={onRedoClick}
                    >
                        {/* <Icon component={RedoIcon} />
                        Redo */}
                        <RollbackOutlined style={{ transform: 'rotateY(180deg) scale(0.8)' }} />
                    </Button>
                </CVATTooltip>

                {includesDoneButton ? (
                    // <CVATTooltip overlay={`Press "${drawShortcut}" to finish`}>
                    <CVATTooltip overlay={`按下 "${drawShortcut}" 结束`}>
                        <Button type='link' className='cvat-annotation-header-button' onClick={onFinishDraw}>
                            <CheckCircleOutlined />
                            {/* Done */}
                            {/* 结束 */}
                        </Button>
                    </CVATTooltip>
                ) : null}
                {includesToolsBlockerButton ? (
                    // <CVATTooltip overlay={`Press "${switchToolsBlockerShortcut}" t
                    // o postpone running the algorithm `}>
                    <CVATTooltip overlay={`按下 "${switchToolsBlockerShortcut}" 推迟运行算法 `}>
                        <Button
                            type='link'
                            className={`cvat-annotation-header-button ${
                                toolsBlockerState.algorithmsLocked ? 'cvat-button-active' : ''
                            }`}
                            onClick={shouldEnableToolsBlockerOnClick ? onSwitchToolsBlockerState : undefined}
                        >
                            <StopOutlined />
                            {/* Block */}
                        </Button>
                    </CVATTooltip>
                ) : null}
            </Col>
        </>
    );
}

export default React.memo(LeftGroup);
