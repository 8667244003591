// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';

import { LogType } from 'cvat-logger';
import {
    collapseObjectItems,
    updateAnnotationsAsync,
    changeFrameAsync,
    removeObjectAsync,
    changeGroupColorAsync,
    pasteShapeAsync,
    copyShape as copyShapeAction,
    activateObject as activateObjectAction,
    propagateObject as propagateObjectAction,
    updateCanvasContextMenu,
    updateObjectRelation as updateObjectRelationAction,
} from 'actions/annotation-actions';
import { ActiveControl, CombinedState, ColorBy, ShapeType, ContextMenuType, ObjectType } from 'reducers/interfaces';
import ObjectStateItemComponent from 'components/annotation-page/standard-workspace/objects-side-bar/object-item';
// import ObjectItemContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-item';
import { shift } from 'utils/math';
import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { message } from 'antd';

interface OwnProps {
    readonly: boolean;
    clientID: number;
    objectStates: any[];
    initialCollapsed: boolean;
    subStates?: any[];
    contextMenued?: boolean;
}

interface StateToProps {
    objectState: any;
    collapsed: boolean;
    labels: any[];
    attributes: any[];
    jobInstance: any;
    frameNumber: number;
    activated: boolean;
    colorBy: ColorBy;
    ready: boolean;
    activeControl: ActiveControl;
    minZLayer: number;
    maxZLayer: number;
    normalizedKeyMap: Record<string, string>;
    canvasInstance: Canvas | Canvas3d;
    selectStates: any[];
    selectedStatesID: number[];
    activatedStateID: number | null;
    states: any[];
    relationOperate: boolean;
}

interface DispatchToProps {
    changeFrame(frame: number): void;
    updateState(objectState: any | any[]): void;
    collapseOrExpand(objectStates: any[], collapsed: boolean): void;
    activateObject: (activatedStateID: number | null) => void;
    removeObject: (sessionInstance: any, objectState: any) => void;
    copyShape: (objectState: any) => void;
    propagateObject: (objectState: any) => void;
    changeGroupColor(group: number, color: string): void;
    onUpdateContextMenu(visible: boolean, left: number, top: number, type: ContextMenuType, pointID?: number): void;
    updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        annotation: {
            annotations: {
                collapsed: statesCollapsed,
                activatedStateID,
                zLayer: { min: minZLayer, max: maxZLayer },
                selectedStatesID,
                states: allStates,
                activeModel,
            },
            job: { attributes: jobAttributes, instance: jobInstance, labels },
            player: {
                frame: { number: frameNumber },
            },
            canvas: { instance: canvasInstance, ready, activeControl },
            relationOperate,
        },
        settings: {
            shapes: { colorBy },
        },
        shortcuts: { normalizedKeyMap },
    } = state;

    const { objectStates, initialCollapsed, clientID, subStates } = own;
    let states = objectStates;
    if (subStates && subStates.length) {
        // 子标签列表，数据源从全states中，筛选出subStates里拥有的
        states = allStates.filter((item: any) => subStates.includes(item.clientID));
    }
    const stateIDs = states.map((_state: any): number => _state.clientID);
    const index = stateIDs.indexOf(clientID);

    const collapsedState =
        typeof statesCollapsed[clientID] === 'undefined' ? initialCollapsed : statesCollapsed[clientID];

    return {
        objectState: states[index],
        collapsed: collapsedState,
        attributes: jobAttributes[states[index].label.id],
        labels,
        ready,
        activeControl,
        colorBy,
        jobInstance,
        frameNumber,
        activated: activatedStateID === clientID,
        minZLayer,
        maxZLayer,
        normalizedKeyMap,
        canvasInstance,
        selectStates: allStates.filter((item: any) => selectedStatesID && selectedStatesID.includes(item.clientID)),
        selectedStatesID,
        activatedStateID,
        states: allStates,
        relationOperate,
        activeModel,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        changeFrame(frame: number): void {
            dispatch(changeFrameAsync(frame));
        },
        updateState(state: any | any[]): void {
            dispatch(updateAnnotationsAsync(state && state.length ? state : [state]));
        },
        collapseOrExpand(objectStates: any[], collapsed: boolean): void {
            dispatch(collapseObjectItems(objectStates, collapsed));
        },
        activateObject(activatedStateID: number | null): void {
            dispatch(activateObjectAction(activatedStateID, null));
        },
        removeObject(sessionInstance: any, objectState: any): void {
            dispatch(removeObjectAsync(sessionInstance, objectState, true));
        },
        copyShape(objectState: any): void {
            dispatch(copyShapeAction(objectState));
            dispatch(pasteShapeAsync());
        },
        propagateObject(objectState: any): void {
            dispatch(propagateObjectAction(objectState));
        },
        changeGroupColor(group: number, color: string): void {
            dispatch(changeGroupColorAsync(group, color));
        },
        onUpdateContextMenu(
            visible: boolean,
            left: number,
            top: number,
            type: ContextMenuType,
            pointID?: number,
        ): void {
            dispatch(updateCanvasContextMenu(visible, left, top, pointID, type));
        },
        updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void {
            dispatch(updateObjectRelationAction(parentObject, subObject, deleteRelation, updateStates));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
class ObjectItemContainer extends React.PureComponent<Props> {
    private copy = (): void => {
        const { objectState, readonly, copyShape } = this.props;
        if (!readonly) {
            copyShape(objectState);
        }
    };

    private propagate = (): void => {
        const { objectState, readonly, propagateObject } = this.props;
        if (!readonly) {
            propagateObject(objectState);
        }
    };

    private remove = (): void => {
        const { objectState, jobInstance, readonly, relationOperate, removeObject } = this.props;

        if (!readonly) {
            const deleteStates = [objectState];
            const { relation = {} } = objectState;
            if (relation && relation.children && relation.children.length) {
                deleteStates.push(
                    ...relation.children.filter((item: any) => item.relation.relationType || relationOperate),
                );
            }
            removeObject(jobInstance, deleteStates);
        }
    };

    private createURL = (): void => {
        const { objectState, frameNumber } = this.props;
        const { origin, pathname } = window.location;

        const search = `frame=${frameNumber}&type=${objectState.objectType}&serverID=${objectState.serverID}`;
        const url = `${origin}${pathname}?${search}`;
        copy(url);
    };

    private switchOrientation = (): void => {
        const { objectState, readonly, updateState } = this.props;
        if (readonly) {
            return;
        }

        if (objectState.shapeType === ShapeType.CUBOID) {
            this.switchCuboidOrientation();
            return;
        }

        const reducedPoints = objectState.points.reduce(
            (acc: number[][], _: number, index: number, array: number[]): number[][] => {
                if (index % 2) {
                    acc.push([array[index - 1], array[index]]);
                }

                return acc;
            },
            [],
        );

        if (objectState.shapeType === ShapeType.POLYGON) {
            objectState.points = reducedPoints.slice(0, 1).concat(reducedPoints.reverse().slice(0, -1)).flat();
            updateState(objectState);
        } else if (objectState.shapeType === ShapeType.POLYLINE) {
            objectState.points = reducedPoints.reverse().flat();
            updateState(objectState);
        }
    };

    private toBackground = (): void => {
        const { objectState, readonly, minZLayer } = this.props;

        if (!readonly) {
            objectState.zOrder = minZLayer - 1;
            this.commit();
        }
    };

    private toForeground = (): void => {
        const { objectState, readonly, maxZLayer } = this.props;

        if (!readonly) {
            objectState.zOrder = maxZLayer + 1;
            this.commit();
        }
    };

    private activate = (e: React.MouseEvent): void => {
        const {
            objectState,
            ready,
            activeControl,
            activateObject,
            canvasInstance,
            selectedStatesID,
            activatedStateID,
        } = this.props;

        if (
            ready &&
            activeControl === ActiveControl.CURSOR &&
            !(activatedStateID === objectState.clientID || selectedStatesID.includes(objectState.clientID)) &&
            !(e.altKey || e.metaKey || e.ctrlKey)
        ) {
            activateObject(objectState.clientID);
            if (canvasInstance instanceof Canvas3d) {
                canvasInstance.activate(objectState.clientID);
            }
        }
    };

    private collapse = (isCollapse?: boolean): void => {
        const { collapseOrExpand, objectState, collapsed } = this.props;

        // collapseOrExpand([objectState], !collapsed);
        collapseOrExpand([objectState], isCollapse === true ? false : !collapsed);
    };

    private changeColor = (color: string): void => {
        const { objectState, colorBy, changeGroupColor } = this.props;

        if (colorBy === ColorBy.INSTANCE) {
            objectState.color = color;
            this.commit();
        } else if (colorBy === ColorBy.GROUP) {
            changeGroupColor(objectState.group.id, color);
        }
    };

    private changeLabel = (label: any): void => {
        const { objectState, readonly } = this.props;
        if (!readonly) {
            objectState.label = label;
            this.commit();
        }
    };

    private changeAttribute = (id: number, value: string): void => {
        const { objectState, readonly, jobInstance, selectStates } = this.props;
        if (!readonly) {
            jobInstance.logger.log(LogType.changeAttribute, {
                id,
                value,
                object_id: objectState.clientID,
            });
            const attr: Record<number, string> = {};
            attr[id] = value;

            if (selectStates && selectStates.length) {
                // 多个同时修改
                // 1、获取当前修改的属性条目
                // 2、对比对象中，是否有与这一条目，相同id的条目。
                // 3、根据对象中获取的条目的id ，修改对应的属性。
                let count = 0;
                const changelabelAttribute = objectState.label.attributes.find((_att: any) => _att.id === id);
                if (changelabelAttribute) {
                    selectStates.forEach((state: any) => {
                        const changeStateLabelAttribute = state.label.attributes.find(
                            (_att: any) => _att.id === changelabelAttribute.id && _att.inputType !== 'serialid',
                        );
                        if (changeStateLabelAttribute) {
                            // eslint-disable-next-line no-param-reassign
                            state.attributes = attr;
                            count++;
                        }
                    });
                }
                if (changelabelAttribute.inputType === 'serialid') {
                    // 修改的是ID时，只修改自己
                    count++;
                    objectState.attributes = attr;
                    this.commit(objectState);
                } else {
                    this.commit(selectStates);
                }
                // 待优化
                // message.success(`${count} objects change success!`);
                message.success(`${count} 个对象修改成功!`);
            } else {
                objectState.attributes = attr;
                this.commit(objectState);
            }
        }
    };

    private switchCuboidOrientation = (): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }

        const { objectState, readonly } = this.props;

        if (!readonly) {
            this.resetCuboidPerspective(false);
            objectState.points = shift(objectState.points, cuboidOrientationIsLeft(objectState.points) ? 4 : -4);

            this.commit();
        }
    };

    private resetCuboidPerspective = (commit = true): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }
        const { objectState, readonly } = this.props;

        if (!readonly) {
            const { points } = objectState;
            const minD = {
                x: (points[6] - points[2]) * 0.001,
                y: (points[3] - points[1]) * 0.001,
            };

            if (cuboidOrientationIsLeft(points)) {
                points[14] = points[10] + points[2] - points[6] + minD.x;
                points[15] = points[11] + points[3] - points[7];
                points[8] = points[10] + points[4] - points[6];
                points[9] = points[11] + points[5] - points[7] + minD.y;
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
            } else {
                points[10] = points[14] + points[6] - points[2] - minD.x;
                points[11] = points[15] + points[7] - points[3];
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
                points[8] = points[12] + points[4] - points[0] - minD.x;
                points[9] = points[13] + points[5] - points[1];
            }

            objectState.points = points;
            if (commit) this.commit();
        }
    };

    private shouContextMenuItem = (): void => {
        const { objectState, onUpdateContextMenu } = this.props;

        if (objectState && objectState.clientID && objectState.objectType !== ObjectType.TAG) {
            // const { points } = objectState;
            // 目前先展示在屏幕中间
            onUpdateContextMenu(
                objectState.clientID !== null,
                document.body.clientWidth / 2,
                document.body.clientHeight / 2,
                ContextMenuType.CANVAS_SHAPE,
            );
        }
        // 显示后，再次展开一次当前选中的对象
        setTimeout(() => {
            this.collapse(true);
        });
    };

    private changeRelegation = (selectParentID?: number | undefined): void => {
        const { objectState, states, updateObjectRelation, jobInstance } = this.props;
        const parentID = selectParentID || objectState.parentID;
        const parentObject = states.find((item) => item.clientID === parentID);

        const { relation = {} } = objectState;

        // 新增
        if (relation.id && !selectParentID) {
            // 删除
            updateObjectRelation(null, null, { jobId: jobInstance.id, id: relation.id }, [objectState]);
        } else if (selectParentID) {
            updateObjectRelation(parentObject, objectState);
        }

        // 归属完成后，再次展开一次当前选中的对象
        setTimeout(() => {
            this.collapse(true);
        });
    };

    private commit(states?: any[]): void {
        const { objectState, readonly, updateState } = this.props;
        if (!readonly) {
            // updateState(objectState);
            updateState(states && states.length ? states : objectState);
        }
    }

    public render(): JSX.Element {
        const {
            objectState,
            collapsed,
            labels,
            attributes,
            activated,
            colorBy,
            normalizedKeyMap,
            readonly,
            jobInstance,
            contextMenued,
            states,
            activeModel,
        } = this.props;

        let stateColor = '';
        if (colorBy === ColorBy.INSTANCE) {
            stateColor = objectState.color;
        } else if (colorBy === ColorBy.GROUP) {
            stateColor = objectState.group.color;
        } else if (colorBy === ColorBy.LABEL) {
            stateColor = objectState.label.color;
        }

        const subClientIDs: number[] = []; // 当前子对象列表id
        // 获取当前对象可选择的父id列表
        const parentIDs: number[] = [];
        states.forEach((current) => {
            const { relation = {} } = current;
            const { parent } = relation;
            if (parent && objectState.clientID === parent.clientID) {
                subClientIDs.push(current.clientID);
            }
            if (
                !parent &&
                objectState.clientID !== current.clientID &&
                current.objectType === objectState.objectType &&
                current.serverID
            ) {
                // 已经是二级标签的id无法选择
                parentIDs.push(current.clientID);
            }
        }, []);

        const { relation = {} } = objectState;
        // 子对象列表,及父id
        const { parent } = relation;

        return (
            <ObjectStateItemComponent
                jobInstance={jobInstance}
                readonly={readonly}
                subStates={subClientIDs}
                activated={activated}
                objectType={objectState.objectType}
                shapeType={objectState.shapeType}
                clientID={objectState.clientID}
                serverID={objectState.serverID}
                locked={objectState.lock}
                attrValues={{ ...objectState.attributes }}
                labelID={objectState.label.id}
                color={stateColor}
                attributes={attributes}
                normalizedKeyMap={normalizedKeyMap}
                labels={labels}
                colorBy={colorBy}
                collapsed={collapsed}
                contextMenued={contextMenued}
                parentIDs={parentIDs}
                parentID={parent ? parent.clientID : undefined}
                activeModel={activeModel}
                changeRelegation={this.changeRelegation}
                activate={this.activate}
                remove={this.remove}
                copy={this.copy}
                propagate={this.propagate}
                createURL={this.createURL}
                switchOrientation={this.switchOrientation}
                toBackground={this.toBackground}
                toForeground={this.toForeground}
                changeColor={this.changeColor}
                changeLabel={this.changeLabel}
                changeAttribute={this.changeAttribute}
                collapse={this.collapse}
                resetCuboidPerspective={() => this.resetCuboidPerspective()}
                shouContextMenuItem={() => this.shouContextMenuItem()}
            />
        );
    }
}

export default connect<StateToProps, DispatchToProps, OwnProps, CombinedState>(
    mapStateToProps,
    mapDispatchToProps,
)(ObjectItemContainer);
