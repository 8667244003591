/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-17 17:57:30
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import getCore from 'cvat-core-wrapper';
import { updateProjectAsync } from 'actions/projects-actions';
// import { updateObjectRelation } from 'actions/annotation-actions';
import LabelsEditor from 'components/labels-editor/labels-editor';
import { useTranslation } from 'react-i18next';

const core = getCore();

interface DetailsComponentProps {
    project: any;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { project } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const [projectName, setProjectName] = useState(project.name);

    return (
        <div cvat-project-id={project.id} className='cvat-project-details'>
            <Row>
                <Col>
                    <Title
                        level={4}
                        className='cvat-text-color cvat-project-name'
                        // editable={{
                        //     onChange: (value: string): void => {
                        //         setProjectName(value);
                        //         project.name = value;
                        //         dispatch(updateProjectAsync(project));
                        //     },
                        // }}
                    >
                        {project.name}
                    </Title>
                </Col>
            </Row>
            <Row justify='space-between' className='cvat-project-description'>
                <Col>
                    {/* <Text type='secondary'>
                        {`Project #${project.id} created`}
                        {project.owner ? ` by ${project.owner.username}` : null}
                        {` on ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                    </Text> */}
                    <Text type='secondary'>
                        {t('project.projcet')}
                        {` #${project.id} `}
                        {/* {project.owner ? ` 被 ${project.owner.username}` : null} */}
                        {t('project.create_by')}
                        {`  ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                    </Text>
                    {/* <BugTrackerEditor
                        instance={project}
                        onChange={(bugTracker): void => {
                            project.bugTracker = bugTracker;
                            dispatch(updateProjectAsync(project));
                        }}
                    /> */}
                </Col>
                <Col>
                    {/* <Text type='secondary'>Assigned to</Text> */}
                    {/* <Text type='secondary'>分配给</Text>
                    <UserSelector
                        value={project.assignee}
                        onSelect={(user) => {
                            project.assignee = user;
                            dispatch(updateProjectAsync(project));
                        }}
                    /> */}
                </Col>
            </Row>
            {/* <LabelsEditor
                labels={project.labels.map((label: any): string => label.toJSON())}
                onSubmit={(labels: any[]): void => {
                    // 先删除关系，再更新标签列表
                    // const oldLabels: any[] = project.labels || [];
                    // const deleteLabel = oldLabels.filter((label): boolean => {
                    //     if (labels.find((item: any) => item.id === label.id)) {
                    //         return false;
                    //     }
                    //     return true;
                    // });
                    project.labels = labels
                        .map((labelData): any => new core.classes.Label({ projectId: project.id, ...labelData }));
                    dispatch(updateProjectAsync(project));
                    // if (deleteLabel && deleteLabel.length > 0) {
                    //     deleteLabel.forEach((label) => {
                    //         dispatch(updateObjectRelation(null, null, {
                    //             parentSpecId: label.id,
                    //             childSpecId: label.id,
                    //         }));
                    //     });
                    // }
                }}
            /> */}
        </div>
    );
}
