/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'login.login': '登录',
    'login.username': '用户名',
    'login.password': '密码',
    'login.empty_username': '请输入用户名',
    'login.empty_password': '请输入密码',
};
