/*
 * @Author: swxy
 * @Date: 2022-05-14 11:06:43
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/utils/utils.ts
 * Copyright (C) Amygo
 */

// 生成随机的十六进制颜色
export const getRandomColor = (): string => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const dataBase64ToBlob = (base64Data: string) => {
    let byteArr = Buffer.from(base64Data, 'base64');
    if (base64Data.split(',')[0].indexOf('base64') >= 0) byteArr = Buffer.from(base64Data, 'base64'); //base64 解码
    else {
        // @ts-ignore
        byteArr = Buffer.from(base64Data, 'base64url');
    }
    const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]; //mime类型 -- image/png
    const uintArr = new Uint8Array(byteArr.buffer); // 创建视图

    return new Blob([uintArr], {
        type: mimeString,
    });
};
