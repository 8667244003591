/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-07-16 18:39:57
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row } from 'antd/lib/grid';
import Collapse from 'antd/lib/collapse';

import ItemAttribute from './object-item-attribute';

interface Props {
    readonly: boolean;
    collapsed: boolean;
    attributes: any[];
    // values: Record<number, string>;
    values: Record<number, { specId: number; value: string }>;
    labelID: number;
    labels: any[];
    changeAttribute(attrID: number, value: string): void;
    collapse(): void;
    changeLabel(label: any): void;
}

export function attrValuesAreEqual(
    next: Record<number, { specId: number; value: string }>,
    prev: Record<number, { specId: number; value: string }>,
): boolean {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);

    return (
        nextKeys.length === prevKeys.length &&
        nextKeys
            .map(
                (key: string): boolean =>
                    prev[+key]?.specId === next[+key].specId && prev[+key]?.value === next[+key].value,
            )
            .every((value: boolean) => value)
    );
}

function attrAreTheSame(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.readonly === prevProps.readonly &&
        nextProps.collapsed === prevProps.collapsed &&
        nextProps.attributes === prevProps.attributes &&
        attrValuesAreEqual(nextProps.values, prevProps.values)
    );
}

function ItemAttributesComponent(props: Props): JSX.Element {
    const { collapsed, attributes, values, readonly, changeAttribute, collapse } = props;

    // const sorted = [...attributes].sort((a: any, b: any): number => a.inputType.localeCompare(b.inputType));
    // 调整顺序，由类型的字符顺序，更改为id顺序
    const sorted = [...attributes].sort((a: any, b: any): number => a.id - b.id);

    return (
        <Row>
            <Collapse
                className='cvat-objects-sidebar-state-item-collapse'
                activeKey={collapsed ? [] : ['details']}
                onChange={collapse}
            >
                <Collapse.Panel
                    // header={<span style={{ fontSize: '11px' }}>Details</span>}
                    header={null}
                    showArrow={false}
                    key='details'
                >
                    {sorted.map(
                        (attribute: any): JSX.Element => (
                            <Row
                                key={attribute.id}
                                align='middle'
                                justify='start'
                                className='cvat-object-item-attribute-wrapper'
                            >
                                <ItemAttribute
                                    readonly={readonly}
                                    attrValue={values[attribute.id]?.value}
                                    attrInputType={attribute.inputType}
                                    attrName={attribute.name}
                                    attrID={attribute.id}
                                    attrValues={attribute.values}
                                    changeAttribute={changeAttribute}
                                />
                            </Row>
                        ),
                    )}
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
}

export default React.memo(ItemAttributesComponent, attrAreTheSame);
