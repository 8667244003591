/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-03-21 19:53:35
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2019-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import DetailsComponent from 'components/task-page/details';
import { updateTaskAsync } from 'actions/tasks-actions';
import { cancelInferenceAsync } from 'actions/models-actions';
import { Task, CombinedState } from 'reducers/interfaces';
import { updateObjectRelation as updateObjectRelationAction } from 'actions/annotation-actions';

interface OwnProps {
    task: Task;
}

interface StateToProps {
    // activeInference: ActiveInference | null;
    // installedGit: boolean;
    projectSubsets: string[];
    // dumpers: any[];
    user: any;
}

interface DispatchToProps {
    cancelAutoAnnotation(): void;
    onTaskUpdate: (taskInstance: any) => void;
    updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    // const { list } = state.plugins;
    const [taskProject] = state.projects.current.filter((project) => project.id === own.task.instance.projectId);

    return {
        // dumpers: state.formats.annotationFormats.dumpers,
        user: state.auth.user,
        // installedGit: list.GIT_INTEGRATION,
        // activeInference: state.models.inferences[own.task.instance.id] || null,
        projectSubsets: taskProject ?
            ([
                ...new Set(taskProject.tasks.map((task: any) => task.subset).filter((subset: string) => subset)),
            ] as string[]) :
            [],
    };
}

function mapDispatchToProps(dispatch: any, own: OwnProps): DispatchToProps {
    return {
        onTaskUpdate(taskInstance: any): void {
            dispatch(updateTaskAsync(taskInstance));
        },
        cancelAutoAnnotation(): void {
            dispatch(cancelInferenceAsync(own.task.instance.id));
        },
        updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void {
            dispatch(updateObjectRelationAction(parentObject, subObject, deleteRelation, updateStates));
        },
    };
}

function TaskPageContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        task,
        // installedGit,
        // activeInference,
        projectSubsets,
        // dumpers,
        user,
        cancelAutoAnnotation,
        onTaskUpdate,
        updateObjectRelation,
    } = props;

    return (
        <DetailsComponent
            // dumpers={dumpers}
            user={user}
            previewImage={task.preview}
            taskInstance={task.instance}
            // installedGit={installedGit}
            // activeInference={activeInference}
            projectSubsets={projectSubsets}
            onTaskUpdate={onTaskUpdate}
            cancelAutoAnnotation={cancelAutoAnnotation}
            updateObjectRelation={updateObjectRelation}
        />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskPageContainer);
