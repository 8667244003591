/*
 * @Author: swxy
 * @Date: 2022-08-02 14:18:59
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import { ZJSummarizeData, ZJExtendKey, zjValueByLabelId } from './zjExportType';

export interface SummarizeData extends ZJSummarizeData {
    // id: number; // 编号   true integer(int64)
    jobId: number; //    题包编号   true integer(int32)
    frame: number; //    帧号   true integer(int32)
    frameType: number; //    帧类型   true integer(int32)
    trackShapeId: number; //    连续框编号   false integer(int64)
    objId: number; //    目标编号   false integer(int32)

    normalShapeId: number; //    普通框编号   false integer(int64)

    pointsNum: number; //    点云数   false integer(int32)

    objLength: number; //    目标物长   false number(double)
    objWidth: number; //    目标物宽   false number(double)
    objHeight: number; // 目标物高   false number(double)

    objWx: number; //    中心点x   false number(double)
    objWy: number; //    中心点y   false number(double)
    objWz: number; //    中心点z   false number(double)

    objPitch: number; //    旋转角度pitch   false number(double)
    objRoll: number; //    旋转角度roll   false number(double)
    objYaw: number; //    旋转角度yaw   false number(double)

    // objCertainty    确定性   false integer(int32)
    // crowd    是否密集   false integer(int32)
    // kind    类型   false string
    // motion    运动状态   false integer(int32)

    // sceneArea    场景区域   false string
    // sceneName    场景名称   false string
    // sceneTime    场景时间   false string

    // updateTime    更新时间   false string(date-time)
    // addTime    输入时间   false string(date-time)
}

export type ExtendKey = ZJExtendKey;

export function getExtendValues(labelId: number, attributes: any[]): ZJSummarizeData {
    const obj = zjValueByLabelId[labelId];
    if (!obj) {
        return {
            shapeObjType: 0,
        };
    }

    const attIds = Object.keys(attributes).map((attId) => +attId);
    const values = Object.keys(obj).reduce((previous: Record<string, any>, current): Record<string, any> => {
        if (attIds.includes(obj[current])) {
            let { value } = attributes[obj[current]];
            if (current === 'kind') {
                // 当前对象，在属性中存在。
                if (value === '大车') {
                    value = 'large';
                } else if (value === '小车') {
                    value = 'small';
                } else if (value === '是') {
                    value = 'Covered';
                } else if (value === '否') {
                    value = 'Uncovered';
                }
            } else if (value === '是') {
                value = 1;
            } else if (value === '否') {
                value = 0;
            } else if (value === '无法判断' || value === '无法判定') {
                value = 2;
            } else if (value === '1') {
                value = 1;
            } else if (value === '0') {
                value = 0;
            }
            previous[current] = value;
        } else if (current === 'shapeObjType') {
            previous[current] = obj[current];
        }
        return previous;
    }, {});

    return values;
}
