// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import * as THREE from 'three';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import CameraControls from 'camera-controls';
// import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Canvas3dController } from './canvas3dController';
import { Listener, Master } from './master';
import CONST, { setControls, setNoneControls } from './consts';
import {
    Canvas3dModel,
    DrawData,
    Mode,
    Planes,
    UpdateReasons,
    ViewType,
    CameraType,
    CameraDistortionParameter,
    // ActivationModel,
    Issue,
    ActivationModel,
} from './canvas3dModel';
import { createRotationHelper, CuboidModel, setEdges, setTranslationHelper, getSurfaces } from './cuboid';
import { SphereModel, setEdges as SphereSetEdges } from './sphere';
import { getExtendValues } from './exportType';

export interface Canvas3dView {
    html(): ViewsDOM;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
}

export enum CameraAction {
    ZOOM_IN = 'KeyI',
    MOVE_UP = 'KeyU',
    MOVE_DOWN = 'KeyO',
    MOVE_LEFT = 'KeyJ',
    ZOOM_OUT = 'KeyK',
    MOVE_RIGHT = 'KeyL',
    TILT_UP = 'ArrowUp',
    TILT_DOWN = 'ArrowDown',
    ROTATE_RIGHT = 'ArrowRight',
    ROTATE_LEFT = 'ArrowLeft',
}

export interface RayCast {
    renderer: THREE.Raycaster;
    mouseVector: THREE.Vector2;
}

export interface Views {
    perspective: RenderView;
    top: RenderView;
    side: RenderView;
    front: RenderView;
}

export interface CubeObject {
    perspective: THREE.Mesh;
    top: THREE.Mesh;
    side: THREE.Mesh;
    front: THREE.Mesh;
}

export interface RenderView {
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera?: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    controls?: CameraControls;
    rayCaster?: RayCast;
}

export interface ViewsDOM {
    perspective: HTMLCanvasElement;
    top: HTMLCanvasElement;
    side: HTMLCanvasElement;
    front: HTMLCanvasElement;
}

type Points = [
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
];

export interface PointsByType {
    normal: Points;
    point4Line: [THREE.Vector2, THREE.Vector2];
}

export interface Projection {
    points: [
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
        THREE.Vector2,
    ];
    rects: [
        [THREE.Vector2, THREE.Vector2],
        [THREE.Vector2, THREE.Vector2],
        [THREE.Vector2, THREE.Vector2],
        [THREE.Vector2, THREE.Vector2],
        [THREE.Vector2, THREE.Vector2],
        [THREE.Vector2, THREE.Vector2],
    ]; // 6个数组，长方体的6面// 不能用2个点来画
    points4: [THREE.Vector2, THREE.Vector2, THREE.Vector2, THREE.Vector2];
    cloudPoints: number;
    length: number;
    width: number;
    height: number;
    option: any; // 预制参数
}

export interface ProjectionInfo {
    projects: Record<number, Projection[]>;
    projectType: 'points' | 'points4';
    activeId?: number;
}

export class Canvas3dViewImpl implements Canvas3dView, Listener {
    private controller: Canvas3dController;
    private views: Views;
    private clock: THREE.Clock;
    private speed: number;
    private cube: CuboidModel;
    private sphere: SphereModel;
    private highlighted: boolean;
    private selected: CubeObject | SphereModel;
    private model: Canvas3dModel & Master;
    private action: any;
    private globalHelpers: any;
    private moving: boolean;
    private projectionInfo: ProjectionInfo;

    private set mode(value: Mode) {
        this.controller.mode = value;
    }

    private get mode(): Mode {
        return this.controller.mode;
    }

    public constructor(model: Canvas3dModel & Master, controller: Canvas3dController) {
        this.controller = controller;
        this.clock = new THREE.Clock();
        this.speed = CONST.MOVEMENT_FACTOR;
        this.cube = new CuboidModel('line', '#ffffff');
        this.sphere = new SphereModel('yellow');
        this.highlighted = false;
        // this.selected = this.cube;
        this.model = model;
        this.projectionInfo = {
            projectType: 'points',
            projects: {},
        };

        this.globalHelpers = {
            top: {
                resize: [],
                rotate: [],
            },
            side: {
                resize: [],
                rotate: [],
            },
            front: {
                resize: [],
                rotate: [],
            },
        };
        this.action = {
            loading: false,
            activeMove: false, // 在选中某个对象后，点击空闲点云空间时，在鼠标点中期间有没有移动过。 有就认为是在移动视角，不需要取消对象。没有则取消当前选中的对象。
            controllActive: false, // perspective 的CameraControls是否被激活，如果激活的话。不跟随移动
            perspMoveAble: false, // 是否可以移动
            oldState: '',
            scan: null,
            selectable: true,
            frameCoordinates: {
                x: 0,
                y: 0,
                z: 0,
            },
            detected: false,
            initialMouseVector: new THREE.Vector2(),
            detachCam: false,
            detachCamRef: 'null',
            detachIssueCam: false,
            detachIssueCamRef: 'null',
            translation: {
                status: false,
                helper: null,
                coordinates: null,
                offset: new THREE.Vector3(),
                inverseMatrix: new THREE.Matrix4(),
            },
            rotation: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
                screenInit: {
                    x: 0,
                    y: 0,
                },
                screenMove: {
                    x: 0,
                    y: 0,
                },
            },
            resize: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
                initScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                memScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                resizeVector: new THREE.Vector3(0, 0, 0),
                frontBool: false,
            },
        };

        this.views = {
            perspective: {
                renderer: new THREE.WebGLRenderer({ antialias: true }),
                scene: new THREE.Scene(),
                rayCaster: {
                    renderer: new THREE.Raycaster(),
                    mouseVector: new THREE.Vector2(),
                },
            },
            top: {
                renderer: new THREE.WebGLRenderer({ antialias: true }),
                scene: new THREE.Scene(),
                rayCaster: {
                    renderer: new THREE.Raycaster(),
                    mouseVector: new THREE.Vector2(),
                },
            },
            side: {
                renderer: new THREE.WebGLRenderer({ antialias: true }),
                scene: new THREE.Scene(),
                rayCaster: {
                    renderer: new THREE.Raycaster(),
                    mouseVector: new THREE.Vector2(),
                },
            },
            front: {
                renderer: new THREE.WebGLRenderer({ antialias: true }),
                scene: new THREE.Scene(),
                rayCaster: {
                    renderer: new THREE.Raycaster(),
                    mouseVector: new THREE.Vector2(),
                },
            },
        };
        CameraControls.install({ THREE });

        const canvasPerspectiveView = this.views.perspective.renderer.domElement;
        const canvasTopView = this.views.top.renderer.domElement;
        const canvasSideView = this.views.side.renderer.domElement;
        const canvasFrontView = this.views.front.renderer.domElement;

        canvasPerspectiveView.addEventListener('contextmenu', (e: MouseEvent): void => {
            if (this.controller.focused.clientID !== null) {
                this.dispatchEvent(
                    new CustomEvent('canvas.contextmenu', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            clientID: Number(this.controller.focused.clientID),
                            clientX: e.clientX,
                            clientY: e.clientY,
                        },
                    }),
                );
            }
            if (this.model.mode === Mode.DRAW && e.ctrlKey && this.model.data.drawData.initialState) {
                const { x, y, z } = this.cube.perspective.position;
                const { x: width, y: height, z: depth } = this.cube.perspective.scale;
                const { x: rotationX, y: rotationY, z: rotationZ } = this.cube.perspective.rotation;
                const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];
                const initState = this.model.data.drawData.initialState;

                let label;
                if (initState) {
                    ({ label } = initState);
                }
                this.dispatchEvent(
                    new CustomEvent('canvas.drawn', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state: {
                                ...initState,
                                shapeType: 'cuboid',
                                frame: this.model.data.imageID,
                                points,
                                label,
                            },
                            continue: true,
                            duration: 0,
                        },
                    }),
                );
                this.action.oldState = Mode.DRAW;
            }
        });

        canvasTopView.addEventListener('mousedown', this.startAction.bind(this, 'top'));
        canvasSideView.addEventListener('mousedown', this.startAction.bind(this, 'side'));
        canvasFrontView.addEventListener('mousedown', this.startAction.bind(this, 'front'));

        canvasTopView.addEventListener('mousemove', this.moveAction.bind(this, 'top'));
        canvasSideView.addEventListener('mousemove', this.moveAction.bind(this, 'side'));
        canvasFrontView.addEventListener('mousemove', this.moveAction.bind(this, 'front'));

        canvasTopView.addEventListener('mouseup', this.completeActions.bind(this));
        canvasTopView.addEventListener('mouseleave', this.completeActions.bind(this));
        canvasSideView.addEventListener('mouseup', this.completeActions.bind(this));
        canvasSideView.addEventListener('mouseleave', this.completeActions.bind(this));
        canvasFrontView.addEventListener('mouseup', this.completeActions.bind(this));
        canvasFrontView.addEventListener('mouseleave', this.completeActions.bind(this));

        const changeEvnet = (event: MouseEvent): void => {
            const canvas = this.views.perspective.renderer.domElement;
            const rect = canvas.getBoundingClientRect();
            const { mouseVector } = this.views.perspective.rayCaster as { mouseVector: THREE.Vector2 };
            mouseVector.x = ((event.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
            mouseVector.y = -((event.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;
        };

        // canvasPerspectiveView.addEventListener('mousedown', (event: MouseEvent): void => {
        //     event.preventDefault();
        //     // const canvas = this.views.perspective.renderer.domElement;
        //     // const rect = canvas.getBoundingClientRect();
        //     // const { mouseVector } = this.views.perspective.rayCaster;
        //     // mouseVector.x = ((event.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
        //     // mouseVector.y = -((event.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;

        //     // console.log('已有选中:', this.model.data.selected);
        //     // 鼠标左键按下，重置移动事件
        //     if (event.button === 0) {
        //         this.action.activeMove = false;
        //     }

        //     // console.log('可移动1:', this.action.perspMoveAble);
        // });

        canvasPerspectiveView.addEventListener('mousemove', (event: MouseEvent): void => {
            // event.preventDefault();
            // 有选中对象时，记录是否在点击中移动
            // if (
            //     (this.controller.activeElement.clientID || this.controller.activeElement.issueID) &&
            //     event.button <= 2 &&
            //     event.buttons !== 0
            // ) {
            //     this.action.activeMove = true;
            //     // console.log('move11111111111:', event);
            // }
            if (
                this.mode === Mode.DRAG_CANVAS ||
                (this.mode === Mode.IDLE && this.controller.configuration.activeModel === ActivationModel.clickActive)
            ) {
                return;
            }
            // console.log('可移动2222:', this.action.perspMoveAble);

            // if (this.mode === Mode.DRAG_CANVAS ||
            // (this.mode === Mode.IDLE && this.controller.configuration.activeModel === ActivationModel.clickActive)
            // ) {
            //     return;
            // }
            const canvas = this.views.perspective.renderer.domElement;
            const rect = canvas.getBoundingClientRect();
            const { mouseVector } = this.views.perspective.rayCaster as { mouseVector: THREE.Vector2 };
            mouseVector.x = ((event.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
            mouseVector.y = -((event.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;
        });

        // canvasPerspectiveView.addEventListener('mouseup', (event: MouseEvent): void => {
        //     event.preventDefault();
        //     //
        //     if (
        //         (!this.action.activeMove && event.button === 0) ||
        //         ((event.ctrlKey || event.altKey) && event.button === 0)
        //     ) {
        //         this.activity(event);
        //     }

        //     // 鼠标左键抬起，重置移动事件。并且不可移动
        //     if (event.button === 0) {
        //         this.action.activeMove = false;
        //         this.action.perspMoveAble = false;
        //     }
        // });

        canvasPerspectiveView.addEventListener('click', (e: MouseEvent): void => {
            e.preventDefault();
            if (e.detail !== 1) return;

            // if ([Mode.IDLE, Mode.EDIT].includes(this.mode) && !intersects.length) {

            // }
            if ([Mode.IDLE, Mode.EDIT].includes(this.mode)) {
                changeEvnet(e);
            }
            // if ([Mode.IDLE, Mode.EDIT].includes(this.mode)) {
            //     changeEvnet(e);
            //     return;
            // }
            const intersects = this.views.perspective.rayCaster.renderer.intersectObjects(
                this.views.perspective.scene.children[0].children,
                false,
            );
            if (![Mode.GROUP, Mode.IDLE].includes(this.mode) || !this.views.perspective.rayCaster) return;

            if (
                intersects.length !== 0 &&
                intersects[0].object instanceof CuboidModel &&
                this.mode === Mode.GROUP &&
                this.model.data.groupData.grouped
            ) {
                const item = this.model.data.groupData.grouped.filter(
                    (_state: any): boolean => _state.clientID === Number(intersects[0].object.name),
                );
                if (item.length !== 0) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.model.data.groupData.grouped = this.model.data.groupData.grouped.filter(
                        (_state: any): boolean => _state.clientID !== Number(intersects[0].object.name),
                    );
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    intersects[0].object.material.color.set(intersects[0].object.originalColor);
                } else {
                    const [state] = this.model.data.objects.filter(
                        (_state: any): boolean => _state.clientID === Number(intersects[0].object.name),
                    );
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.model.data.groupData.grouped.push(state);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    intersects[0].object.material.color.set('#ffffff');
                }
            } else if (this.mode === Mode.IDLE) {
                if (intersects.length === 0) {
                    this.setHelperVisibility(false);
                }
                let clientID = null;
                let issueID = null;
                if (intersects.length !== 0) {
                    if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry) {
                        // 点击的是方体
                        clientID = Number(intersects[0].object.name);
                    }
                    if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                        // 点击的是方体
                        issueID = Number(intersects[0].object.name);
                    }
                }

                this.dispatchEvent(
                    new CustomEvent('canvas.selected', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            clientID,
                            issueID,
                        },
                    }),
                );
            }
        });

        canvasPerspectiveView.addEventListener('dblclick', (e: MouseEvent): void => {
            e.preventDefault();

            if (this.mode === Mode.REVIEW_CANVAS) {
                // 审核模式, , 画一个圆
                // const { clientX, clientY } = e;
                // console.log('e:', e);
                const { x, y, z } = this.sphere.perspective.position;

                this.dispatchEvent(
                    new CustomEvent('canvas.drawnissue', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            points: [x, y, z],
                            // position: [clientX, clientY],
                            isContinue: this.model.data.reviewData.redraw, // 默认是连续，继续画
                            duration: 0,
                        },
                    }),
                );
                return;
            }

            if (this.mode !== Mode.DRAW) {
                const { perspective: viewType } = this.views;
                viewType.rayCaster.renderer.setFromCamera(viewType.rayCaster.mouseVector, viewType.camera);
                const intersects = viewType.rayCaster.renderer.intersectObjects(
                    this.views.perspective.scene.children[0].children,
                    false,
                );
                if (intersects.length !== 0 || this.controller.focused.clientID !== null) {
                    this.setDefaultZoom();
                } else {
                    const { x, y, z } = this.action.frameCoordinates;
                    this.positionAllViews(x, y, z, true);
                }
                return;
            }
            this.controller.drawData.enabled = false;
            this.mode = Mode.IDLE;
            const {
                x,
                y,
                // z,
            } = this.cube.perspective.position;

            // const { x: width, y: height, z: depth } = this.cube.perspective.scale;
            const { x: width, y: height, z: depth } = { x: 4, y: 1.5, z: 1.5 };
            const { x: rotationX, y: rotationY, z: rotationZ } = this.cube.perspective.rotation;
            const points = [x, y, 0.5, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];
            const initState = this.model.data.drawData.initialState;

            let label;

            if (initState) {
                ({ label } = initState);
            }

            if (typeof this.model.data.drawData.redraw === 'number') {
                const [state] = this.model.data.objects.filter(
                    (_state: any): boolean => _state.clientID === Number(this.model.data.selected.perspective.name),
                );
                this.dispatchEvent(
                    new CustomEvent('canvas.edited', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state,
                            points,
                        },
                    }),
                );
            } else {
                this.dispatchEvent(
                    new CustomEvent('canvas.drawn', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state: {
                                ...initState,
                                shapeType: 'cuboid',
                                frame: this.model.data.imageID,
                                points,
                                label,
                            },
                            continue: undefined,
                            duration: 0,
                        },
                    }),
                );
            }
            this.dispatchEvent(new CustomEvent('canvas.canceled'));
        });

        this.mode = Mode.IDLE;

        Object.keys(this.views).forEach((view: string): void => {
            this.views[view as keyof Views].scene.background = new THREE.Color(0x000000);
        });

        const viewSize = CONST.ZOOM_FACTOR;
        const height = window.innerHeight;
        const width = window.innerWidth;
        const aspectRatio = window.innerWidth / window.innerHeight;

        // setting up the camera and adding it in the scene
        this.views.perspective.camera = new THREE.PerspectiveCamera(50, aspectRatio, 1, 500);
        // this.views.perspective.camera.position.set(-15, 0, 4);
        this.views.perspective.camera.position.set(0, 0, 40);
        this.views.perspective.camera.up.set(0, 0, 1);
        this.views.perspective.camera.lookAt(10, 0, 0);
        this.views.perspective.camera.name = 'cameraPerspective';

        this.views.top.camera = new THREE.OrthographicCamera(
            (-aspectRatio * viewSize) / 2 - 2,
            (aspectRatio * viewSize) / 2 + 2,
            viewSize / 2 + 2,
            -viewSize / 2 - 2,
            -50,
            50,
        );

        this.views.top.camera.position.set(0, 0, 5);
        this.views.top.camera.lookAt(0, 0, 0);
        this.views.top.camera.up.set(0, 0, 1);
        this.views.top.camera.name = 'cameraTop';

        this.views.side.camera = new THREE.OrthographicCamera(
            (-aspectRatio * viewSize) / 2,
            (aspectRatio * viewSize) / 2,
            viewSize / 2,
            -viewSize / 2,
            -50,
            50,
        );
        this.views.side.camera.position.set(0, 5, 0);
        this.views.side.camera.lookAt(0, 0, 0);
        this.views.side.camera.up.set(0, 0, 1);
        this.views.side.camera.name = 'cameraSide';

        this.views.front.camera = new THREE.OrthographicCamera(
            (-aspectRatio * viewSize) / 2,
            (aspectRatio * viewSize) / 2,
            viewSize / 2,
            -viewSize / 2,
            -50,
            50,
        );
        this.views.front.camera.position.set(3, 0, 0);
        this.views.front.camera.up.set(0, 0, 1);
        this.views.front.camera.lookAt(0, 0, 0);
        this.views.front.camera.name = 'cameraFront';

        Object.keys(this.views).forEach((view: string): void => {
            const viewType = this.views[view as keyof Views];
            if (viewType.camera) {
                viewType.renderer.setSize(width, height);
                if (view !== ViewType.PERSPECTIVE) {
                    viewType.controls = new CameraControls(viewType.camera, viewType.renderer.domElement);
                    viewType.controls.mouseButtons.left = CameraControls.ACTION.NONE;
                    viewType.controls.mouseButtons.right = CameraControls.ACTION.NONE;
                } else {
                    viewType.controls = new CameraControls(viewType.camera, viewType.renderer.domElement);
                    // viewType.controls.mouseButtons.left = CameraControls.ACTION.NONE;
                    // viewType.controls.mouseButtons.right = CameraControls.ACTION.NONE;
                    // viewType.controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
                    // viewType.controls.touches.one = CameraControls.ACTION.NONE;
                    // viewType.controls.touches.two = CameraControls.ACTION.NONE;
                    // viewType.controls.touches.three = CameraControls.ACTION.NONE;
                    setControls(viewType.controls);
                }
                viewType.controls.minDistance = CONST.MIN_DISTANCE;
                viewType.controls.maxDistance = CONST.MAX_DISTANCE;
            }
        });
        this.views.top.controls.enabled = false;
        this.views.side.controls.enabled = false;
        this.views.front.controls.enabled = false;

        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            this.views[view].renderer.domElement.addEventListener(
                'wheel',
                (event: WheelEvent): void => {
                    event.preventDefault();
                    const { camera } = this.views[view];
                    if (event.deltaY < CONST.FOV_MIN && camera.zoom < CONST.FOV_MAX) {
                        camera.zoom += CONST.FOV_INC;
                    } else if (event.deltaY > CONST.FOV_MIN && camera.zoom > CONST.FOV_MIN + 0.1) {
                        camera.zoom -= CONST.FOV_INC;
                    }
                    this.setHelperSize(view);
                },
                { passive: false },
            );
        });

        model.subscribe(this);
    }

    private editable(object: any): boolean {
        if (this.model.data.activeElement.clientID && !this.controller.configuration.forceDisableEditing) {
            if (
                this.model.data.selected &&
                !this.model.data.selected.perspective.userData.lock &&
                object.name === this.model.data.activeElement.clientID
            ) {
                return true;
            }
        }
        return false;
    }

    private idle(): boolean {
        // return [Mode.IDLE, Mode.DRAG_CANVAS].includes(this.mode);
        return [Mode.IDLE].includes(this.mode);
    }
    //
    private activity(e: MouseEvent): void {
        // if ([Mode.IDLE, Mode.EDIT].includes(this.mode)) {
        //     changeEvnet(e);
        //     return;
        // }

        // const canvas = this.views.perspective.renderer.domElement;
        // const rect = canvas.getBoundingClientRect();
        // const { mouseVector } = this.views.perspective.rayCaster as { mouseVector: THREE.Vector2 };
        // mouseVector.x = ((event.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
        // mouseVector.y = -((event.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;

        const canvas = this.views.perspective.renderer.domElement;
        const { renderer, mouseVector } = this.views.perspective.rayCaster;
        const rect = canvas.getBoundingClientRect();
        mouseVector.x = ((e.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
        mouseVector.y = -((e.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;

        if (![Mode.GROUP, Mode.EDIT, Mode.IDLE].includes(this.mode) || !this.views.perspective.rayCaster) return;

        // 通过摄像机和鼠标位置更新射线
        const { children } = this.views.perspective.scene.children[0];
        renderer.setFromCamera(mouseVector, this.views.perspective.camera);
        const intersects = renderer.intersectObjects(children, false);
        // const intersects = this.views.perspective.rayCaster.renderer.intersectObjects(
        //     this.views.perspective.scene.children[0],
        //     false,
        // );
        if (
            intersects.length !== 0 &&
            intersects[0].object instanceof CuboidModel &&
            this.mode === Mode.GROUP &&
            this.model.data.groupData.grouped
        ) {
            const item = this.model.data.groupData.grouped.filter(
                (_state: any): boolean => _state.clientID === Number(intersects[0].object.name),
            );
            if (item.length !== 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.model.data.groupData.grouped = this.model.data.groupData.grouped.filter(
                    (_state: any): boolean => _state.clientID !== Number(intersects[0].object.name),
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                intersects[0].object.material.color.set(intersects[0].object.originalColor);
            } else {
                const [state] = this.model.data.objects.filter(
                    (_state: any): boolean => _state.clientID === Number(intersects[0].object.name),
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.model.data.groupData.grouped.push(state);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                intersects[0].object.material.color.set('#ffffff');
            }
        } else if (this.idle()) {
            if (intersects.length === 0) {
                this.setHelperVisibility(false);
            }
            let clientID = null;
            let issueID = null;
            if (intersects.length !== 0) {
                if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry) {
                    // 点击的是方体
                    clientID = Number(intersects[0].object.name);
                }
                if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                    // 点击的是方体
                    issueID = Number(intersects[0].object.name);
                }
            }

            this.dispatchEvent(
                new CustomEvent('canvas.selected', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        clientID,
                        issueID,
                    },
                }),
            );
        }
    }

    // private transformTop(): void {
    // this.views.perspective.camera.lookAt(0, 0, 20);
    // const { azimuthAngle, y, z } = this.views.perspective.controls.rotate;
    // console.log('旋转：', azimuthAngle);
    // const { x, y, z } = this.views.perspective.controls.getTarget(new THREE.Vector3());
    // const { x, y, z } = this.views.perspective.camera.rotation;

    // this.views.perspective.controls.rotate(5.5, 2.55, true);

    // this.views.perspective.controls.setLookAt(x, y, z + 40, x, y, z, true);
    // console.log('up:', this.views.perspective.controls);
    // this.views.perspective.controls.setLookAt(x, y, z + 20, x + offsetX, y + offsetY, z + offsetZ - 40, true);
    // console.log(`旋转：x=${x}, y=${y}, z=${z}`);
    // console.log('相机：', this.views.perspective.camera);
    // }

    private setDefaultZoom(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.model.data.activeElement === 'null') {
            Object.keys(this.views).forEach((view: string): void => {
                const viewType = this.views[view as keyof Views];
                if (view !== ViewType.PERSPECTIVE) {
                    viewType.camera.zoom = CONST.FOV_DEFAULT;
                    viewType.camera.updateProjectionMatrix();
                }
            });
        } else {
            // const canvasTop = this.views.top.renderer.domElement;
            // const bboxtop = new THREE.Box3().setFromObject(this.model.data.selected.top);
            // const width = bboxtop.max.x - bboxtop.min.x;
            // const height = bboxtop.max.y - bboxtop.min.y;
            // const x1 = Math.min(
            //     canvasTop.offsetWidth / (bboxtop.max.x - bboxtop.min.x),
            //     canvasTop.offsetHeight / (bboxtop.max.y - bboxtop.min.y),
            // ) * 0.4;
            // const x1 = Math.min(
            //     (canvasTop.offsetWidth) / (bboxtop.max.x - bboxtop.min.x),
            //     (canvasTop.offsetHeight) / (bboxtop.max.y - bboxtop.min.y),
            // );
            // this.views.top.camera.zoom = x1 / 100;
            this.views.top.camera.zoom = 1;
            this.views.top.camera.updateProjectionMatrix();
            this.views.top.camera.updateMatrix();
            this.setHelperSize(ViewType.TOP);

            // const canvasFront = this.views.top.renderer.domElement;
            // const bboxfront = new THREE.Box3().setFromObject(this.model.data.selected.front);
            // const x2 = Math.min(
            //     canvasFront.offsetWidth / (bboxfront.max.y - bboxfront.min.y),
            //     canvasFront.offsetHeight / (bboxfront.max.z - bboxfront.min.z),
            // ) * 0.4;
            this.views.front.camera.zoom = 1;
            // this.views.front.camera.zoom = x2 / 100;
            this.views.front.camera.updateProjectionMatrix();
            this.views.front.camera.updateMatrix();
            this.setHelperSize(ViewType.FRONT);

            // const canvasSide = this.views.side.renderer.domElement;
            // const bboxside = new THREE.Box3().setFromObject(this.model.data.selected.side);
            // const x3 = Math.min(
            //     canvasSide.offsetWidth / (bboxside.max.x - bboxside.min.x),
            //     canvasSide.offsetHeight / (bboxside.max.z - bboxside.min.z),
            // ) * 0.4;
            this.views.side.camera.zoom = 1;
            // this.views.side.camera.zoom = x3 / 100;
            this.views.side.camera.updateProjectionMatrix();
            this.views.side.camera.updateMatrix();
            this.setHelperSize(ViewType.SIDE);
        }
    }

    private startAction(view: any, event: MouseEvent): void {
        if (event.detail !== 1) return;
        if (this.model.mode === Mode.DRAG_CANVAS) return;
        const { clientID } = this.model.data.activeElement;
        if (!clientID) return;
        // if (clientID === 'null') return;
        const canvas = this.views[view as keyof Views].renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const { mouseVector } = this.views[view as keyof Views].rayCaster as { mouseVector: THREE.Vector2 };
        const diffX = event.clientX - rect.left;
        const diffY = event.clientY - rect.top;
        mouseVector.x = (diffX / canvas.clientWidth) * 2 - 1;
        mouseVector.y = -(diffY / canvas.clientHeight) * 2 + 1;
        this.action.rotation.screenInit = { x: diffX, y: diffY };
        this.action.rotation.screenMove = { x: diffX, y: diffY };
        if (
            this.model.data.selected &&
            !this.model.data.selected.perspective.userData.hidden &&
            ((this.model.data.selected instanceof SphereModel &&
                !this.model.data.selected.perspective.userData.resolve) ||
                (!this.model.data.selected.perspective.userData.lock &&
                    !this.controller.configuration.forceDisableEditing))
        ) {
            // 选中的对象：1、有值，2、没有被隐藏。3、分为两种是审批对象，或者没有被锁定、不是审核模式。
            this.action.scan = view;
            this.model.mode = Mode.EDIT;
            this.action.selectable = false;
        }
    }

    private moveAction(view: any, event: MouseEvent): void {
        event.preventDefault();
        if (this.model.mode === Mode.DRAG_CANVAS) return;
        const { clientID } = this.model.data.activeElement;
        // if (clientID === 'null') return;
        if (!clientID) return;

        // console.log('查看运行时机');
        const canvas = this.views[view as keyof Views].renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const { mouseVector } = this.views[view as keyof Views].rayCaster as { mouseVector: THREE.Vector2 };
        const diffX = event.clientX - rect.left;
        const diffY = event.clientY - rect.top;
        // if (this.model.data.isFineTuning) {
        //     // console.log(`微调中X:${diffX}, Y:${diffY}`);
        //     const fineTuning = 5; // 微调时，减缓的倍率
        // mouseVector.x = (diffX / canvas.clientWidth) * 2 - 1;
        // mouseVector.y = -(diffY / canvas.clientHeight) * 2 + 1;
        // this.action.rotation.screenMove = { x: diffX, y: diffY };
        //     // console.log(`mouseVector:${JSON.stringify(mouseVector)},
        //     // screenMove:${JSON.stringify(this.action.rotation.screenMove)}`);
        // } else {
        mouseVector.x = (diffX / canvas.clientWidth) * 2 - 1;
        mouseVector.y = -(diffY / canvas.clientHeight) * 2 + 1;
        this.action.rotation.screenMove = { x: diffX, y: diffY };
        // }
    }

    private translateReferencePlane(coordinates: any): void {
        const topPlane = this.views.top.scene.getObjectByName(Planes.TOP);
        if (topPlane) {
            topPlane.position.x = coordinates.x;
            topPlane.position.y = coordinates.y;
            topPlane.position.z = coordinates.z;
        }
        const sidePlane = this.views.side.scene.getObjectByName(Planes.SIDE);
        if (sidePlane) {
            sidePlane.position.x = coordinates.x;
            sidePlane.position.y = coordinates.y;
            sidePlane.position.z = coordinates.z;
        }
        const frontPlane = this.views.front.scene.getObjectByName(Planes.FRONT);
        if (frontPlane) {
            frontPlane.position.x = coordinates.x;
            frontPlane.position.y = coordinates.y;
            frontPlane.position.z = coordinates.z;
        }
    }

    private resetActions(): void {
        this.action = {
            ...this.action,
            scan: null,
            detected: false,
            translation: {
                status: false,
                helper: null,
            },
            rotation: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
            },
            resize: {
                ...this.action.resize,
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
            },
        };
        this.model.mode = Mode.IDLE;
        this.action.selectable = true;
    }

    private completeActions(): void {
        const { scan, detected } = this.action;
        if (this.model.mode === Mode.DRAG_CANVAS) return;
        if (!detected) {
            this.resetActions();
            return;
        }

        const { x, y, z } = this.model.data.selected[scan].position;
        if (this.model.data.activeElement.clientID) {
            const { x: width, y: height, z: depth } = this.model.data.selected[scan].scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = this.model.data.selected[scan].rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];

            const [state] = this.model.data.objects.filter(
                (_state: any): boolean => _state.clientID === Number(this.model.data.selected[scan].name),
            );
            this.dispatchEvent(
                new CustomEvent('canvas.edited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        state,
                        points,
                    },
                }),
            );
        }
        if (this.model.data.activeElement.issueID) {
            const [issue] = this.model.data.issueRegions.filter(
                (_issue: any): boolean => _issue.id === Number(this.model.data.selected[scan].name),
            );
            // const { x, y, z } = this.model.data.selected[scan].position;
            const points = [x, y, z];

            this.dispatchEvent(
                new CustomEvent('canvas.issueedited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        issue,
                        points,
                    },
                }),
            );
        }

        if (this.action.rotation.status) {
            this.detachCamera(scan);
        }

        this.adjustPerspectiveCameras();
        this.translateReferencePlane(new THREE.Vector3(x, y, z));
        this.resetActions();
    }

    private onGroupDone(objects?: any[]): void {
        if (objects && objects.length !== 0) {
            this.dispatchEvent(
                new CustomEvent('canvas.groupped', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        states: objects,
                    },
                }),
            );
        } else {
            this.dispatchEvent(
                new CustomEvent('canvas.canceled', {
                    bubbles: false,
                    cancelable: true,
                }),
            );
        }

        this.controller.group({
            enabled: false,
            grouped: [],
        });

        this.mode = Mode.IDLE;
    }

    private getDistortionCoordinate(
        point3D: THREE.Vector3,
        cameraCalib: THREE.Matrix3,
        cameraToBumper: THREE.Matrix4,
        cameraDistortionParameter: CameraDistortionParameter,
    ): THREE.Vector3 {
        // let result: THREE.Vector3[] = [];
        const { k1, k2, k3, k4, cameraType } = cameraDistortionParameter;

        // 转换成世界坐标系
        let result = point3D.clone();
        result.applyMatrix4(cameraToBumper);

        if (result.z < 0) {
            return null;
        }
        // 投影到图片上的像素点位置（未偏移前）
        const a = result.x / result.z;
        const b = result.y / result.z;
        // const a = point.x;
        // const b = point.y;
        const r = Math.sqrt(a ** 2 + b ** 2);
        // 鱼眼相机的畸变计算
        if (cameraType === CameraType.fisheye) {
            // const angle = r;
            // const angle = a * Math.tan(r * (Math.PI / 180));
            // 投影时的入射角度
            const angle = Math.atan(r);
            // const angle = Math.asin(r);

            // const angle = cameraPoint.angleTo(new THREE.Vector3(0, 0, cameraPoint.z));
            // const angle = cameraPoint.angleTo(new THREE.Vector3(0, 0, cameraPoint.z)) * (Math.PI / 180);
            // 偏转后的实际出射角度，值在1左右时。弧度基本上和边长相近，差距在误差范围内。
            // 因此认为其是边长R。R/r=x/a=y/b;
            const angle2 = angle * (1 + k1 * angle ** 2 + k2 * angle ** 4 + k3 * angle ** 6 + k4 * angle ** 8);

            // 反转回rd
            const rd = Math.tan(angle2);
            // const rd = angle2;
            // const rd = Math.sin(angle2);
            result = new THREE.Vector3((rd / r) * a, (rd / r) * b, 1);
        } else if (cameraType === CameraType.buttonhole) {
            // const angle = Math.atan(r);
            // const angle2 = angle + [];
            const { p1, p2, k5, k6, s1, s2, s3, s4 } = cameraDistortionParameter;
            const k123 = 1 + k1 * r ** 2 + k2 * r ** 4 + k3 * r ** 6;
            const k456 = 1 + k4 * r ** 2 + k5 * r ** 4 + k6 * r ** 6;

            const k = k123 / k456;

            const x = a * k + 2 * p1 * a * b + p2 * (r ** 2 + 2 * a ** 2) + s1 * r ** 2 + s2 * r ** 4;
            const y = b * k + p1 * (r ** 2 + 2 * b ** 2) + 2 * p2 * a * b + s3 * r ** 2 + s4 * r ** 4;
            result = new THREE.Vector3(x, y, 1);
            // let x = a;
            // let y = b;
            // const mapList = (a1: number, b1: number): void => {
            //     const r2 = a1 ** 2 + b1 ** 2;
            //     const k123 = (1 + k1 * r2 + k2 * r2 ** 2 + k3 * r2 ** 3);
            //     const k456 = (1 + k4 * r2 + k5 * r2 ** 2 + k6 * r2 ** 3);

            //     const k = k123 / k456;

            //     const x1 = a1 * k + 2 * p1 * a1 * b1 + p2 * (r2 + 2 * a1 ** 2) + s1 * r2 + s2 * r2 ** 2;
            //     const y1 = b1 * k + p1 * (r2 + 2 * b1 ** 2) + 2 * p2 * a1 * b1 + s3 * r2 + s4 * r2 ** 2;

            //     x = x1;
            //     y = y1;
            // };

            // // 经历多次畸变测试效果
            // for (let index = 0; index < 5; index++) {
            //     mapList(x, y);
            // }
            result = new THREE.Vector3(x, y, 1);
        }
        // if (points && points.length) {
        //     result = points.map((point: THREE.Vector3) => {
        //         const angle = point.angleTo(new THREE.Vector3(0, 0, 1));
        //         console.log('正常角：', angle);
        //         const angle2 = angle * (1 + k1 * angle ** 2 + k2 * angle ** 4 + k3 * angle ** 6 + k4 * angle ** 8);
        //         console.log('偏转角：', angle2);

        //         const pointP = point.project(this.views.perspective.camera);
        //         console.log('pointP:', pointP);

        //         return point;
        //     });
        // }

        // console.log('result:', result);
        // 与相机内参相乘，计算出图像坐标。
        result.applyMatrix3(cameraCalib);

        // const {
        //     p1, p2, k5, k6, s1, s2, s3, s4,
        // } = cameraDistortionParameter;
        // const x = result.x / result.z;
        // const y = result.y / result.z;
        // console.log(`---------------------x:${x}},y:${y}`);
        // const u = x * (1 + k1 * r ** 2 + k2 * r ** 4 + k3 * r ** 6);
        // const v = y * (1 + k1 * r ** 2 + k2 * r ** 4 + k3 * r ** 6);
        // // const u = x + 2 * p1 * a * b + p2 * (r ** 2 + 2 * a ** 2);

        // // const v = y + p1 * (r ** 2 + 2 * y ** 2) + 2 * p2 * x * y;
        // // p1 * (r ** 2 + 2 * b ** 2) + 2 * p2 * a * b
        // console.log(`---------------------u:${u}},v:${v}`);
        // result.set(u, v, 1);
        return result;
    }

    private setProjectInfo = (
        clientID: number,
        projectInfo?: { [key: string]: any } | { [key: string]: any }[],
        index?: number,
    ) => {
        if (clientID && !projectInfo) {
            this.projectionInfo.activeId = clientID;
            return;
        }
        const projections: Projection[] =
            this.projectionInfo.projects[clientID] ||
            new Array(this.model.data.cameraDistortionParameter.length).fill({
                points: [],
                points4: [],
                rects: [],
                cloudPoints: 0,
                length: 0,
                width: 0,
                height: 0,
            });
        if (typeof index === 'number' && !Array.isArray(projectInfo)) {
            // index有值，只更改index这个视角
            for (const key in projectInfo) {
                // @ts-ignore
                projections[index][key] = projectInfo[key];
            }
            this.projectionInfo.projects[clientID] = projections;
            return;
        } else if (!Array.isArray(projectInfo)) {
            // index没有值，且不是数组，更改所有视角
            for (let i = 0; i < projections.length; i++) {
                for (const key in projectInfo) {
                    // @ts-ignore
                    projections[i][key] = projectInfo[key];
                }
            }
        } else if (Array.isArray(projectInfo) && projectInfo.length === projections.length) {
            for (let i = 0; i < projectInfo.length; i++) {
                const info = projectInfo[i];
                for (const key in info) {
                    // @ts-ignore
                    projections[index][key] = info[key];
                }
            }
        }
        this.projectionInfo.projects[clientID] = projections;
    };

    private getPoints(
        cuboid: CuboidModel,
    ): [
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
        THREE.Vector3,
    ] {
        cuboid.perspective.updateMatrixWorld();
        // console.log('投影', cuboid);
        // console.log('矩阵：', cuboid.perspective.matrix.toArray());
        // const a = new THREE.Vector3(0, 0, 0).applyMatrix4(cuboid.perspective.matrixWorld);
        // console.log('(0,0,0)a:', a);
        const result = cuboid.relativelyPoints.map((vector: THREE.Vector3): THREE.Vector3 => {
            const newVec = vector.clone();

            newVec.applyMatrix4(cuboid.perspective.matrixWorld);
            // console.log(`前:${JSON.stringify(vector)}, 后：${JSON.stringify(newVec)}`);
            return newVec;
        }) as [
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
            THREE.Vector3,
        ];

        return result;
    }

    private setThreeViewsPoints(id: string | null, type: 'cuboid' | 'sphere'): void {
        // 是否有被选中
        if (
            id &&
            (type === 'cuboid'
                ? this.controller.activeElement.clientID === id
                : this.controller.activeElement.issueID === id)
        ) {
            // 主视图 点云层
            const perspectivePointsView = this.views.perspective.scene.children[0] as THREE.Points<
                THREE.BufferGeometry,
                THREE.PointsMaterial
            >;
            const { geometry } = perspectivePointsView;
            const positions = geometry.getAttribute('position');
            const { array, itemSize } = positions;

            const duration = 10;
            const moveDuration = 0.5;

            const object = perspectivePointsView.getObjectByName(id) as THREE.Mesh;

            // 比实际扩大一些范围
            const topCuboid = object.clone() as THREE.Mesh;
            const sideCuboid = object.clone() as THREE.Mesh;
            const frontCuboid = object.clone() as THREE.Mesh;

            if (type === 'cuboid') {
                topCuboid.scale.set(
                    object.scale.x + duration,
                    object.scale.y + duration,
                    object.scale.z + moveDuration,
                );
                sideCuboid.scale.set(
                    object.scale.x + duration,
                    object.scale.y + moveDuration,
                    object.scale.z + duration,
                );
                frontCuboid.scale.set(
                    object.scale.x + moveDuration,
                    object.scale.y + duration,
                    object.scale.z + duration,
                );
            }

            if (type === 'sphere') {
                topCuboid.geometry = new THREE.SphereGeometry(3, 16, 8);
                sideCuboid.geometry = new THREE.SphereGeometry(3, 16, 8);
                frontCuboid.geometry = new THREE.SphereGeometry(3, 16, 8);
            }

            object.updateMatrixWorld();
            topCuboid.updateMatrixWorld();
            sideCuboid.updateMatrixWorld();
            frontCuboid.updateMatrixWorld();

            // 三个的基本box是相同的，所以只需要用原始box
            if (type === 'cuboid') {
                object.geometry.computeBoundingBox();
            }

            if (type === 'sphere') {
                object.geometry.computeBoundingSphere();
                topCuboid.geometry.computeBoundingSphere();
                sideCuboid.geometry.computeBoundingSphere();
                frontCuboid.geometry.computeBoundingSphere();
            }
            const bounding = type === 'cuboid' ? object.geometry.boundingBox : topCuboid.geometry.boundingSphere;

            const colors: Record<ViewType, number[]> = {
                [ViewType.TOP]: [],
                [ViewType.SIDE]: [],
                [ViewType.FRONT]: [],
                [ViewType.PERSPECTIVE]: [],
            };

            const points: Record<ViewType, number[]> = {
                [ViewType.TOP]: [],
                [ViewType.SIDE]: [],
                [ViewType.FRONT]: [],
                [ViewType.PERSPECTIVE]: [],
            };

            let pointsNum = 0;

            for (let index = 0; index < array.length; index += itemSize) {
                const x = array[index];
                const y = array[index + 1];
                const z = array[index + 2];

                const point = new THREE.Vector3(x, y, z);

                const isInBox = bounding.containsPoint(object.worldToLocal(point.clone()));

                const isInTopBox = bounding.containsPoint(topCuboid.worldToLocal(point.clone()));
                const isInSideBox = bounding.containsPoint(sideCuboid.worldToLocal(point.clone()));
                const isInFrontBox = bounding.containsPoint(frontCuboid.worldToLocal(point.clone()));

                if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z) && isInBox) {
                    pointsNum++;
                }

                const rgb = new THREE.Color('#ffffff');
                const { r, g, b } = rgb;
                if (isInTopBox) {
                    points.top.push(x, y, z);
                    colors.top.push(r, b, g);
                }
                if (isInSideBox) {
                    points.side.push(x, y, z);
                    colors.side.push(r, b, g);
                }
                if (isInFrontBox) {
                    points.front.push(x, y, z);
                    colors.front.push(r, b, g);
                }
            }

            if (type === 'cuboid') {
                this.setProjectInfo(+id, { cloudPoints: pointsNum });
            }

            // object.userData.cloudPoints = pointsNum;

            // const positions = geometry.getAttribute('position');
            [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewName) => {
                const view: RenderView = this.views[viewName];
                const pointsView = view.scene.children[0] as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
                pointsView.geometry.setAttribute('position', new THREE.Float32BufferAttribute(points[viewName], 3));
                pointsView.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors[viewName], 3));
            });
        } else {
            [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewName) => {
                const view: RenderView = this.views[viewName];
                const pointsView = view.scene.children[0] as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
                pointsView.geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3));
                pointsView.geometry.setAttribute('color', new THREE.Float32BufferAttribute([], 3));
            });
        }
    }

    private setupObject(object: any, addToScene: boolean): CuboidModel {
        const { opacity, outlined, outlineColor, selectedOpacity, colorBy } = this.model.data.shapeProperties;
        const clientID = String(object.clientID);
        const cuboid = new CuboidModel(object.occluded ? 'dashed' : 'line', outlined ? outlineColor : '#ffffff');

        cuboid.setName(clientID);
        cuboid.perspective.userData = object;
        let color = '';
        if (colorBy === 'Label') {
            ({ color } = object.label);
        } else if (colorBy === 'Instance') {
            ({ color } = object);
        } else {
            ({ color } = object.group);
        }
        cuboid.setOriginalColor(color);
        cuboid.setColor(color);
        cuboid.setOpacity(opacity);

        if (this.model.data.activeElement.clientID === clientID && ![Mode.GROUP].includes(this.mode)) {
            cuboid.setOpacity(selectedOpacity);
            if (!object.lock && !this.controller.configuration.forceDisableEditing) {
                createRotationHelper(cuboid.top, ViewType.TOP);
                createRotationHelper(cuboid.side, ViewType.SIDE);
                createRotationHelper(cuboid.front, ViewType.FRONT);
                setTranslationHelper(cuboid.top);
                setTranslationHelper(cuboid.side);
                setTranslationHelper(cuboid.front);
            }
            setEdges(cuboid.top);
            setEdges(cuboid.side);
            setEdges(cuboid.front);
            this.translateReferencePlane(new THREE.Vector3(object.points[0], object.points[1], object.points[2]));
            this.model.data.selected = cuboid;
            if (object.hidden) {
                this.setHelperVisibility(false);
                return cuboid;
            }
        } else {
            cuboid.top.visible = false;
            cuboid.side.visible = false;
            cuboid.front.visible = false;
        }
        if (object.hidden) {
            return cuboid;
        }
        cuboid.setPosition(object.points[0], object.points[1], object.points[2]);
        cuboid.setScale(object.points[6], object.points[7], object.points[8]);
        cuboid.setRotation(object.points[3], object.points[4], object.points[5]);
        if (addToScene) {
            this.addSceneChildren(cuboid);
        }
        if (this.model.data.activeElement.clientID === clientID) {
            cuboid.attachCameraReference();
            this.rotatePlane(null, null);
            this.action.detachCam = true;
            this.action.detachCamRef = this.model.data.activeElement.clientID;
            if (!object.lock && !this.controller.configuration.forceDisableEditing) {
                this.setSelectedChildScale(1 / cuboid.top.scale.x, 1 / cuboid.top.scale.y, 1 / cuboid.top.scale.z);
                this.setHelperVisibility(true);
                this.updateRotationHelperPos();
                this.updateResizeHelperPos();
            } else {
                this.setHelperVisibility(false);
            }
            //  为下方三视图添加points
            this.setThreeViewsPoints(clientID, 'cuboid');
        }

        return cuboid;
    }

    private setupIssue(issue: Issue): void {
        const [x, y, z] = issue.points;
        const issueID = String(issue.id);

        const sphere = new SphereModel(issue.resolve ? 'green' : '#ffe001');
        sphere.setName(issueID);
        sphere.setPosition(x, y, z);
        sphere.perspective.userData = issue;

        // if (this.views.perspective.scene.children[0]) {
        //     this.clearSceneObjects();
        //     this.setHelperVisibility(false);
        //     const cuboids = [];
        //     for (let i = 0; i < this.model.data.objects.length; i++) {
        //         const object = this.model.data.objects[i];
        //         const cuboid = this.setupObject(object, true);
        //         cuboids.push(cuboid);
        //     }
        //     // pointsByPointtypeByClientByImages.push(this.getXY(cuboid));
        //     this.getXY(cuboids);
        //     this.action.loading = false;
        // }
        if (!issue.hidden) {
            // 没有隐藏的话，就添加。
            // this.views.perspective.scene.children[0].add(sphere.perspective);

            this.views.perspective.scene.children[0].add(sphere.perspective);
            if (this.controller.activeElement.issueID === issueID) {
                this.model.data.selected = sphere;
                // 选中了当前的批注。
                // console.log('执行次数！');
                //  为下方三视图添加points
                SphereSetEdges(sphere.top);
                SphereSetEdges(sphere.side);
                SphereSetEdges(sphere.front);

                // setTranslationHelper(sphere.top);
                // setTranslationHelper(sphere.side);
                // setTranslationHelper(sphere.front);

                sphere.attachCameraReference();
                this.rotatePlane(null, null);

                this.action.detachIssueCam = true;
                this.action.detachIssueCamRef = this.model.data.activeElement.issueID;
                // this.translateReferencePlane(/\
                // new THREE.Vector3(object.points[0], object.points[1], object.points[2]));

                // sphere.attachCameraReference();
                // sphereSetTranslationHelper(sphere.top);
                // sphereSetTranslationHelper(sphere.side);
                // sphereSetTranslationHelper(sphere.front);

                this.views.top.scene.children[0].add(sphere.top);
                this.views.side.scene.children[0].add(sphere.side);
                this.views.front.scene.children[0].add(sphere.front);
                this.setThreeViewsPoints(issueID, 'sphere');
            }
        }
    }

    private setupObjects(): void {
        if (this.views.perspective.scene.children[0]) {
            this.projectionInfo = {
                projectType: 'points',
                projects: {},
            };
            this.clearSceneObjects();
            this.setHelperVisibility(false);
            const cuboids = [];

            for (const issue of this.model.data.issueRegions) {
                this.setupIssue(issue);
            }

            for (let i = 0; i < this.model.data.objects.length; i++) {
                const object = this.model.data.objects[i];
                const cuboid = this.setupObject(object, true);
                cuboids.push(cuboid);
            }
            // for (const iterator of Object.entries(this.model.data.issueRegions)) {

            // }

            // for (let i = 0; i < Object.entries(this.model.data.issueRegions); i++) {
            //     const issue = this.model.data.issueRegions[i];
            //     this.setupIssue(issue);
            // }

            // pointsByPointtypeByClientByImages.push(this.getXY(cuboid));
            this.getXY(cuboids);
            this.action.loading = false;
        }
    }

    // 获取投影的点信息
    private getXY(cuboids: CuboidModel[]): void {
        // const pointsByPointtypeByClientByImages: Record<number, PointsByType>[] = [];
        const calibsLen = this.model.data.cameraCalibs.length;
        const bumpersLen = this.model.data.cameraToBumpers.length;

        // 循环内参、外参列表，如果内参、外参有一个缺少。就没必要计算。
        // 每个内参外参，对应着相应方向的图片。
        for (let index = 0; index < calibsLen && index < bumpersLen; index++) {
            const cameraCalib: THREE.Matrix3 = this.model.data.cameraCalibs[index];
            const cameraToBumper: THREE.Matrix4 = this.model.data.cameraToBumpers[index];
            const cameraDistortionParameter: CameraDistortionParameter = this.model.data.cameraDistortionParameter?.[
                index
            ] || { cameraType: CameraType.normal };
            this.getPointsByPointtypeByClient(cuboids, cameraCalib, cameraToBumper, cameraDistortionParameter, index);
            // pointsByPointtypeByClientByImages.push(pointsByPointtypeByClient);
        }

        // const pointBy3D: Record<number, number[]> = {};

        // let cloudPoints: number | undefined;
        // cuboids.forEach((item: CuboidModel) => {
        //     const arr = [];
        //     arr.push(item.perspective.scale.x, item.perspective.scale.y, item.perspective.scale.z);
        //     pointBy3D[item.perspective.userData.clientID] = arr;
        //     if (`${this.controller.activeElement.clientID}` === `${item.perspective.userData.clientID}`) {
        //         cloudPoints = item.perspective.userData.cloudPoints;
        //     }
        // });
        // console.log('cuboids:', pointsByPointtypeByClientByImages);

        // const cloud = this.views[ViewType.PERSPECTIVE].scene[0]

        if (this.model.data.activeElement.clientID) {
            this.setProjectInfo(+this.model.data.activeElement.clientID);
        }
        this.dispatchEvent(
            new CustomEvent('canvas.projection', {
                bubbles: false,
                cancelable: true,
                detail: {
                    projectInfo: this.projectionInfo,
                    // actID: this.model.data.activeElement.clientID,
                    // cuboidsByImages: pointsByPointtypeByClientByImages,
                    // pointBy3D,
                    // cloudPoints,
                },
            }),
        );
    }

    // 获取每个对象的4点或8点坐标。
    private getPointsByPointtypeByClient(
        cuboids: CuboidModel[],
        cameraCalib: THREE.Matrix3,
        cameraToBumper: THREE.Matrix4,
        cameraDistortionParameter: CameraDistortionParameter,
        index: number,
        // ): Record<number, PointsByType> {
    ): void {
        // const result: Record<number, PointsByType> = {};

        // 循环对象列表
        cuboids.forEach((cuboid: CuboidModel) => {
            const fixedPoint3Ds = this.getPoints(cuboid);
            const min = new THREE.Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
            const max = new THREE.Vector2(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

            const normal = fixedPoint3Ds.reduce((previous: THREE.Vector2[], current: THREE.Vector3) => {
                const point = this.getDistortionCoordinate(
                    current,
                    cameraCalib,
                    cameraToBumper,
                    cameraDistortionParameter,
                );
                if (point) {
                    const value = new THREE.Vector2(point.x / point.z, point.y / point.z);
                    min.setX(min.x || min.x === 0 ? Math.min(min.x, value.x) : value.x);
                    min.setY(min.y || min.y === 0 ? Math.min(min.y, value.y) : value.y);
                    max.setX(max.x || max.x === 0 ? Math.max(max.x, value.x) : value.x);
                    max.setY(max.y || max.y === 0 ? Math.max(max.y, value.y) : value.y);
                    // 3d坐标
                    previous.push(value);
                } else {
                    // 会有部分点在前视图中，部分点在后视图中。因此空对象不能
                    // previous.push(null);
                }
                return previous;
            }, []) as Points;

            let point4Line = [min, max] as [THREE.Vector2, THREE.Vector2];
            // if (min.x !== Number.MAX_SAFE_INTEGER) {
            //     object2D.point4Line = [min, max];
            // }
            // const object2D: PointsByType = {
            //     normal: normal as Points,
            //     point4Line: point4Line,
            // };
            // result[cuboid.perspective.userData.clientID] = object2D;
            this.setProjectInfo(
                +cuboid.perspective.userData.clientID,
                {
                    length: cuboid.perspective.scale.x,
                    width: cuboid.perspective.scale.y,
                    height: cuboid.perspective.scale.z,
                },
                index,
            );
            if (normal.length === 8) {
                this.setProjectInfo(
                    +cuboid.perspective.userData.clientID,
                    {
                        points: normal,
                        // rects: getSurfaces(object2D.normal),
                        points4: point4Line,
                    },
                    index,
                );
            }
        });
        // return result;
    }

    private addSceneChildren(shapeObject: CuboidModel | SphereModel): void {
        this.views.perspective.scene.children[0].add(shapeObject.perspective);
        this.views.top.scene.children[0].add(shapeObject.top);
        this.views.side.scene.children[0].add(shapeObject.side);
        this.views.front.scene.children[0].add(shapeObject.front);
    }

    private dispatchEvent(event: CustomEvent): void {
        this.views.perspective.renderer.domElement.dispatchEvent(event);
    }

    public notify(model: Canvas3dModel & Master, reason: UpdateReasons): void {
        if (reason === UpdateReasons.IMAGE_CHANGED) {
            if (!model.data.image) return;
            this.dispatchEvent(new CustomEvent('canvas.canceled'));
            if (this.model.mode === Mode.DRAW) {
                this.model.data.drawData.enabled = false;
            }
            this.views.perspective.renderer.dispose();
            this.model.mode = Mode.BUSY;
            this.action.loading = true;
            const loader = new PCDLoader();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const objectURL = URL.createObjectURL(model.data.image.imageData?.data.data);
            // this.clearScene();
            loader.load(objectURL, this.addScene.bind(this));
            URL.revokeObjectURL(objectURL);
        } else if (reason === UpdateReasons.SHAPE_ACTIVATED) {
            const { clientID } = this.model.data.activeElement;
            this.setupObjects();
            // if (clientID !== 'null') {
            if (clientID) {
                this.setDefaultZoom();
            }
        } else if (reason === UpdateReasons.ISSUE_ACTIVATED) {
            const { issueID } = this.model.data.activeElement;
            this.setupObjects();
            if (issueID) {
                this.setDefaultZoom();
            }
        } else if (reason === UpdateReasons.DRAW) {
            const data: DrawData = this.controller.drawData;
            this.cube = new CuboidModel('line', '#ffffff');
            this.cube.setScale(4, 1.5, 1.5);
            // this.transformTop();
            this.model.data.activeElement.clientID = null;

            [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType): void => {
                this.views[viewType as keyof Views].scene.children[0].children = [];
            });

            if (data.redraw) {
                const object = this.views.perspective.scene.getObjectByName(String(data.redraw));
                if (object) {
                    this.cube.perspective = object.clone() as THREE.Mesh;
                    object.visible = false;
                }
            } else if (data.initialState) {
                // this.model.data.activeElement.clientID = 'null';
                this.setupObjects();
                this.cube = this.setupObject(data.initialState, false);
            }
            this.setHelperVisibility(false);
        } else if (reason === UpdateReasons.OBJECTS_UPDATED) {
            this.setupObjects();
        } else if (reason === UpdateReasons.REVIEW_UPDATED) {
            // this.setupIssue();

            this.setupObjects();
        } else if (reason === UpdateReasons.ISSUE_REGIONS_UPDATED) {
            this.setupObjects();
        } else if (reason === UpdateReasons.CONFIG_UPDATED) {
            this.setupObjects();
        } else if (reason === UpdateReasons.DRAG_CANVAS) {
            this.dispatchEvent(
                new CustomEvent(this.model.mode === Mode.DRAG_CANVAS ? 'canvas.dragstart' : 'canvas.dragstop', {
                    bubbles: false,
                    cancelable: true,
                }),
            );
            this.sphere = new SphereModel();
            this.model.data.activeElement.clientID = undefined;
            this.model.data.activeElement.issueID = null;
            // this.mode = Mode.DRAG_CANVAS;
            if (this.model.mode === Mode.DRAG_CANVAS) {
                const { controls } = this.views.perspective;
                // controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
                // controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
                // controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
                // controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
                // controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
                // controls.touches.three = CameraControls.ACTION.TOUCH_TRUCK;
                setControls(controls);
            }
            this.setupObjects();
        } else if (reason === UpdateReasons.REVIEW_CANVAS) {
            // 开始审批
            // 向外暴露事件
            this.dispatchEvent(
                new CustomEvent(this.model.mode === Mode.REVIEW_CANVAS ? 'canvas.reviewstart' : 'canvas.reviewstop', {
                    bubbles: false,
                    cancelable: true,
                }),
            );
            this.model.data.activeElement.clientID = null;
            this.model.data.activeElement.issueID = null;

            // this.mode = Mode.IDLE;
            // this.model.data.activeElement.clientID = null;
            // if (this.model.mode === Mode.DRAG_CANVAS) {
            //     const { controls } = this.views.perspective;
            //     controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
            //     controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
            //     controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
            //     controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
            //     controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
            //     controls.touches.three = CameraControls.ACTION.TOUCH_TRUCK;
            // }
            this.setupObjects();
            // this.setupIssue();
        } else if (reason === UpdateReasons.CANCEL) {
            if (this.mode === Mode.DRAW || (this.controller.drawData && this.controller.drawData.enabled)) {
                this.controller.drawData.enabled = false;
                this.controller.drawData.redraw = undefined;
                Object.keys(this.views).forEach((view: string): void => {
                    this.views[view as keyof Views].scene.children[0].remove(this.cube[view as keyof Views]);
                });
            }
            this.model.data.groupData.grouped = [];
            this.setHelperVisibility(false);
            this.model.mode = Mode.IDLE;
            // const { controls } = this.views.perspective;
            // controls.mouseButtons.left = CameraControls.ACTION.NONE;
            // controls.mouseButtons.right = CameraControls.ACTION.NONE;
            // controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
            // controls.touches.one = CameraControls.ACTION.NONE;
            // controls.touches.two = CameraControls.ACTION.NONE;
            // controls.touches.three = CameraControls.ACTION.NONE;
            this.dispatchEvent(new CustomEvent('canvas.canceled'));
        } else if (reason === UpdateReasons.FITTED_CANVAS) {
            this.dispatchEvent(new CustomEvent('canvas.fit'));
        } else if (reason === UpdateReasons.GROUP) {
            if (!this.model.groupData.enabled) {
                this.onGroupDone(this.model.data.groupData.grouped);
            } else {
                this.model.data.groupData.grouped = [];
                // this.model.data.activeElement.clientID = 'null';
                this.model.data.activeElement.clientID = null;
                this.setupObjects();
            }
        }
    }

    public async notifyAsync(model: Canvas3dModel & Master, reason: UpdateReasons): Promise<void> {
        if (reason === UpdateReasons.summarize_data) {
            await this.summarize();
        }
    }

    private clearScene(): void {
        Object.keys(this.views).forEach((view: string): void => {
            this.views[view as keyof Views].scene.children = [];
        });
    }

    private clearSceneObjects(): void {
        Object.keys(this.views).forEach((view: string): void => {
            this.views[view as keyof Views].scene.children[0].children = [];
        });
    }

    private setHelperVisibility(visibility: boolean): void {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType): void => {
            const globalRotationObject = this.views[viewType].scene.getObjectByName('globalRotationHelper');
            if (globalRotationObject) {
                globalRotationObject.visible = visibility;
            }
            for (let i = 0; i < 8; i++) {
                const resizeObject = this.views[viewType].scene.getObjectByName(`globalResizeHelper${i}`);
                if (resizeObject) {
                    resizeObject.visible = visibility;
                }
            }
        });
    }

    private static setupRotationHelper(): THREE.Mesh {
        const sphereGeometry = new THREE.SphereGeometry(0.3);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: true });
        const rotationHelper = new THREE.Mesh(sphereGeometry, sphereMaterial);
        rotationHelper.name = 'globalRotationHelper';
        return rotationHelper;
    }

    private updateRotationHelperPos(): void {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            const point = new THREE.Vector3(0, 0, 0);
            if (this.model.data.selected[view].getObjectByName('rotationHelper')) {
                this.model.data.selected[view].getObjectByName('rotationHelper').getWorldPosition(point);
                const globalRotationObject = this.views[view].scene.getObjectByName('globalRotationHelper');
                if (globalRotationObject) {
                    globalRotationObject.position.set(point.x, point.y, point.z);
                }
            }
        });
    }

    private setHelperSize(viewType: ViewType): void {
        if ([ViewType.TOP, ViewType.SIDE, ViewType.FRONT].includes(viewType)) {
            const { camera } = this.views[viewType];
            if (!camera || camera instanceof THREE.PerspectiveCamera) return;
            const factor = (camera.top - camera.bottom) / camera.zoom;
            const rotationObject = this.views[viewType].scene.getObjectByName('globalRotationHelper');
            if (rotationObject) {
                rotationObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
            }
            for (let i = 0; i < 8; i++) {
                const resizeObject = this.views[viewType].scene.getObjectByName(`globalResizeHelper${i}`);
                if (resizeObject) {
                    resizeObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
                }
            }
        }
    }

    private setupResizeHelper(viewType: ViewType): void {
        const sphereGeometry = new THREE.SphereGeometry(0.3);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: true });
        const helpers = [];
        for (let i = 0; i < 8; i++) {
            helpers[i] = new THREE.Mesh(sphereGeometry.clone(), sphereMaterial.clone());
            helpers[i].name = `globalResizeHelper${i}`;
            this.globalHelpers[viewType].resize.push(helpers[i]);
            this.views[viewType].scene.add(helpers[i]);
        }
    }

    private updateResizeHelperPos(): void {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            let i = 0;
            this.model.data.selected[view].children.forEach((element: any): void => {
                if (element.name === 'resizeHelper') {
                    const p = new THREE.Vector3(0, 0, 0);
                    element.getWorldPosition(p);
                    const name = `globalResizeHelper${i}`;
                    const object = this.views[view].scene.getObjectByName(name);
                    if (object) {
                        object.position.set(p.x, p.y, p.z);
                    }
                    i++;
                }
            });
        });
    }

    private addScene(points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void {
        // eslint-disable-next-line no-param-reassign
        points.material.size = 0.05;
        // points.geometry = points.geometry
        const positions = points.geometry.getAttribute('position');

        const { array, itemSize } = positions;
        const newPoints = [];
        const colors = [];
        points.name = 'points';

        // const white = new THREE.Color(0xffffff);
        // const { r: wr, g: wg, b: wb } = white;
        const red = new THREE.Color('red');
        const { r: rr, g: rg, b: rb } = red;
        const fuchsia = new THREE.Color('fuchsia');
        const { r: fr, g: fg, b: fb } = fuchsia;
        const yellow = new THREE.Color('yellow');
        const { r: yr, g: yg, b: yb } = yellow;
        const green = new THREE.Color('green');
        const { r: gr, g: gg, b: gb } = green;
        const aqua = new THREE.Color('aqua');
        const { r: ar, g: ag, b: ab } = aqua;
        const blue = new THREE.Color('blue');
        const { r: br, g: bg, b: bb } = blue;

        // console.time('重载点云：');

        for (let index = 0; index < array.length; index += itemSize) {
            const x = array[index];
            const y = array[index + 1];
            const z = array[index + 2];
            // 跳过nan数据
            if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z)) {
                newPoints.push(x, y, z);
                if (z < 0.5) {
                    colors.push(fr, fg, fb);
                } else if (z >= 0.5 && z < 1) {
                    colors.push(br, bg, bb);
                } else if (z >= 1 && z < 1.5) {
                    colors.push(ar, ag, ab);
                } else if (z >= 1.5 && z < 2) {
                    colors.push(gr, gg, gb);
                } else if (z >= 2 && z <= 2.5) {
                    colors.push(yr, yg, yb);
                } else if (z > 2.5) {
                    colors.push(rr, rg, rb);
                }
            }
        }
        points.geometry.setAttribute('position', new THREE.Float32BufferAttribute(newPoints, itemSize));
        // 自定义颜色
        points.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, itemSize));
        // 重新设计点后，重新计算点
        // console.timeEnd('重载点云：');
        // 使用自定义点颜色，而不使用材质颜色
        points.material.vertexColors = true;

        points.material.color.set(new THREE.Color(0xffffff));
        const material = points.material.clone() as THREE.PointsMaterial;
        // 激光雷达的中间，而不是点云的
        // const sphereCenter = points.geometry.boundingSphere.center;
        // const { radius } = points.geometry.boundingSphere;
        if (!this.views.perspective.camera) return;
        // const xRange = -radius / 2 < this.views.perspective.camera.position.x - sphereCenter.x &&
        //     radius / 2 > this.views.perspective.camera.position.x - sphereCenter.x;
        // const yRange = -radius / 2 < this.views.perspective.camera.position.y - sphereCenter.y &&
        //     radius / 2 > this.views.perspective.camera.position.y - sphereCenter.y;
        // const zRange = -radius / 2 < this.views.perspective.camera.position.z - sphereCenter.z &&
        //     radius / 2 > this.views.perspective.camera.position.z - sphereCenter.z;
        const newX = 0;
        const newY = 0;
        const newZ = 0;
        // if (!xRange) {
        //     newX = sphereCenter.x;
        // }
        // if (!yRange) {
        //     newY = sphereCenter.y;
        // }
        // if (!zRange) {
        //     newZ = sphereCenter.z;
        // }
        if (newX || newY || newZ) {
            this.action.frameCoordinates = { x: newX, y: newY, z: newZ };
            this.positionAllViews(newX, newY, newZ, false);
        }

        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            this.globalHelpers[view].resize = [];
            this.globalHelpers[view].rotation = [];
        });

        points.geometry.computeBoundingSphere();
        // this.views.perspective.scene.children[0] = points.clone();
        this.clearScene();
        this.views.perspective.scene.add(points.clone());
        // const perspectiveChildren = [];
        // const topChildren = [];
        // const sideChildren = [];
        // const frontChildren = [];
        // perspectiveChildren.push(points.clone());
        // Setup TopView
        const canvasTopView = this.views.top.renderer.domElement;
        const topScenePlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(
                canvasTopView.offsetHeight,
                canvasTopView.offsetWidth,
                canvasTopView.offsetHeight,
                canvasTopView.offsetWidth,
            ),
            new THREE.MeshBasicMaterial({
                color: 0xffffff,
                alphaTest: 0,
                visible: false,
                transparent: true,
                opacity: 0,
            }),
        );
        topScenePlane.position.set(0, 0, 0);
        topScenePlane.name = Planes.TOP;
        (topScenePlane.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;
        (topScenePlane as any).verticesNeedUpdate = true;
        // eslint-disable-next-line no-param-reassign
        points.material = material;
        material.size = 0.5;
        const topPoint = points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        const topGeometry = topPoint.geometry.clone();
        topPoint.material.vertexColors = true;
        topGeometry.setAttribute('position', new THREE.Float32BufferAttribute([], itemSize));
        topGeometry.setAttribute('color', new THREE.Float32BufferAttribute([], itemSize));
        topPoint.geometry = topGeometry;
        const topRotationHelper = Canvas3dViewImpl.setupRotationHelper();
        this.globalHelpers.top.rotation.push(topRotationHelper);
        this.views.top.scene.add(topPoint);
        this.views.top.scene.add(topScenePlane);
        this.views.top.scene.add(topRotationHelper);

        // this.views.top.scene.add(points.clone());
        // topChildren.push(topPoint, topScenePlane, topRotationHelper);
        // Setup Side View
        const canvasSideView = this.views.side.renderer.domElement;
        const sideScenePlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(
                canvasSideView.offsetHeight,
                canvasSideView.offsetWidth,
                canvasSideView.offsetHeight,
                canvasSideView.offsetWidth,
            ),
            new THREE.MeshBasicMaterial({
                color: 0xffffff,
                alphaTest: 0,
                visible: false,
                transparent: true,
                opacity: 0,
            }),
        );
        sideScenePlane.position.set(0, 0, 0);
        sideScenePlane.rotation.set(-Math.PI / 2, Math.PI / 2000, Math.PI);
        sideScenePlane.name = Planes.SIDE;
        (sideScenePlane.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;
        (sideScenePlane as any).verticesNeedUpdate = true;
        // this.views.side.scene.add(points.clone());
        const sidePoint = points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        const sideGeometry = sidePoint.geometry.clone();
        sidePoint.material.vertexColors = true;
        sideGeometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3));
        sidePoint.geometry = sideGeometry;
        const sideRotationHelper = Canvas3dViewImpl.setupRotationHelper();
        this.globalHelpers.side.rotation.push(sideRotationHelper);

        this.views.side.scene.add(sidePoint);
        this.views.side.scene.add(sideScenePlane);
        this.views.side.scene.add(sideRotationHelper);

        // sideChildren.push(sidePoint, sideScenePlane, sideRotationHelper);

        // Setup front View
        const canvasFrontView = this.views.front.renderer.domElement;
        const frontScenePlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(
                canvasFrontView.offsetHeight,
                canvasFrontView.offsetWidth,
                canvasFrontView.offsetHeight,
                canvasFrontView.offsetWidth,
            ),
            new THREE.MeshBasicMaterial({
                color: 0xffffff,
                alphaTest: 0,
                visible: false,
                transparent: true,
                opacity: 0,
            }),
        );
        frontScenePlane.position.set(0, 0, 0);
        frontScenePlane.rotation.set(0, Math.PI / 2, 0);
        frontScenePlane.name = Planes.FRONT;
        (frontScenePlane.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;
        (frontScenePlane as any).verticesNeedUpdate = true;
        const frontPoint = points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        const frontGeometry = frontPoint.geometry.clone();
        frontPoint.material.vertexColors = true;
        frontGeometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3));
        frontPoint.geometry = frontGeometry;
        const frontRotationHelper = Canvas3dViewImpl.setupRotationHelper();
        this.globalHelpers.front.rotation.push(frontRotationHelper);

        this.views.front.scene.add(frontPoint);
        this.views.front.scene.add(frontScenePlane);
        this.views.front.scene.add(frontRotationHelper);
        // this.views.front.scene.add(points.clone());
        // frontChildren.push(frontPoint, frontScenePlane, frontRotationHelper);

        // const map = new THREE.CanvasTexture(this.getTexture('这是一个测试文案'));
        // const material11 = new THREE.SpriteMaterial({ map });

        // const sprite = new THREE.Sprite(material11);
        // sprite.position.set(
        //     this.views.top.camera.position.x,
        //     this.views.top.camera.position.y,
        //     this.views.top.camera.position.z,
        // );
        // sprite.visible = false;
        // this.views.top.scene.add(sprite);

        // this.initText(this.views.top.scene);

        // points.geometry.computeBoundingSphere();

        // this.views.perspective.scene.children = perspectiveChildren;
        // this.views.top.scene.children = topChildren;
        // this.views.side.scene.children = sideChildren;
        // this.views.front.scene.children = frontChildren;

        this.addNewHelper();
        this.setupResizeHelper(ViewType.TOP);
        this.setupResizeHelper(ViewType.SIDE);
        this.setupResizeHelper(ViewType.FRONT);
        this.setHelperVisibility(false);
        this.setupObjects();
        this.dispatchEvent(new CustomEvent('canvas.setup'));
    }

    // private initText = (scene: Scene): void => {
    //     const moonDiv = document.createElement('div');
    //     moonDiv.className = 'label';
    //     moonDiv.innerHTML = '这是一次测试！！！！！';
    //     // moonDiv.style.marginTop = '-1em';
    //     moonDiv.style.color = '#fff';
    //     moonDiv.style.padding = '4px 10px';
    //     moonDiv.style.width = '800';
    //     moonDiv.style.height = '500';
    //     moonDiv.style.backgroundColor = 'red';
    //     moonDiv.style.zIndex = '300';
    //     moonDiv.style.position = 'absolute';
    //     moonDiv.style.top = '0';
    //     moonDiv.style.bottom = '0';
    //     moonDiv.style.left = '0';
    //     moonDiv.style.right = '0';
    //     moonDiv.style.margin = 'auto';

    //     const moonLabel = new CSS2DObject(moonDiv);
    //     // moonLabel.position.set(0, 0, 0);
    //     this.views.perspective.scene.children[0].add(moonLabel);
    //     // document.body.append(moonDiv);
    // };

    // private getTexture(text: string): HTMLCanvasElement {
    //     const width = 512;
    //     const
    //         height = 256;
    //     const canvas = document.createElement('canvas');
    //     canvas.width = width;
    //     canvas.height = height;
    //     const ctx = canvas.getContext('2d');
    //     ctx.fillStyle = '#C3C3C3';
    //     ctx.fillRect(0, 0, width, height);
    //     ctx.font = `${50}px " bold`;
    //     ctx.fillStyle = '#2891FF';
    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'middle';
    //     ctx.fillText(text, width / 2, height / 2);
    //     return canvas;
    // }

    private addNewHelper(): void {
        // 方向坐标系
        const axesHelper = new THREE.AxesHelper(5);
        const axesHelper1 = new THREE.AxesHelper(5);
        const axesHelper2 = new THREE.AxesHelper(5);
        const axesHelper3 = new THREE.AxesHelper(5);
        const { cameraRadius } = this.model.data.pcdParameter;

        if (cameraRadius) {
            // 范围半径
            const curve = new THREE.EllipseCurve(
                0,
                0, // ax, aY
                cameraRadius,
                cameraRadius, // xRadius, yRadius
                0,
                2 * Math.PI, // aStartAngle, aEndAngle
                false, // aClockwise
                0, // aRotation
            );
            const newpoints = curve.getPoints(50);
            const geometry = new THREE.BufferGeometry().setFromPoints(newpoints);
            const newmaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
            const ellipse = new THREE.Line(geometry, newmaterial);

            this.views.perspective.scene.add(ellipse);
        }

        // 目前的设计中，如果显示两个视角的标注范围，就会显得难以分辨。因此暂时只支持一个视角
        const { viewAngle, viewStartMiddleAngle } = this.model.data.pcdParameter;
        // const {
        //     cameraViewAngle, viewAngleAble, viewRadius, viewStartMiddleAngle,
        // } = item;
        if (viewAngle) {
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 'yellow',
            });
            const angle = viewAngle * (Math.PI / 180);
            const middleAngle = viewStartMiddleAngle * (Math.PI / 180);
            const lineLength = new THREE.Vector2(viewAngle, 0); // 第一条线沿x轴半径长
            const lineLength2 = lineLength.clone(); // 第二条线沿x轴半径长长
            // 第一条线沿x轴半径长，围绕圆心旋转半个cameraViewAngle， 获得第一条边
            lineLength.rotateAround(new THREE.Vector2(0, 0), middleAngle + angle / 2);
            // 第二条线沿x轴半径长，围绕圆心逆旋转半个cameraViewAngle， 获得第二条边
            lineLength2.rotateAround(new THREE.Vector2(0, 0), middleAngle - angle / 2);

            const linePoints = [];
            const center = new THREE.Vector3(0, 0, 0);
            const linePoint2 = new THREE.Vector3(lineLength.x, lineLength.y, 0);
            const linePoint3 = new THREE.Vector3(lineLength2.x, lineLength2.y, 0);
            linePoints.push(center);
            linePoints.push(linePoint2);
            const geometry = new THREE.BufferGeometry().setFromPoints(linePoints);
            const line = new THREE.Line(geometry, lineMaterial);
            this.views.perspective.scene.add(line);

            const geometry2 = new THREE.BufferGeometry().setFromPoints([center, linePoint3]);
            const newline = new THREE.Line(geometry2, lineMaterial);
            this.views.perspective.scene.add(newline);
        }

        this.views.perspective.scene.add(axesHelper);
        this.views.top.scene.add(axesHelper1);
        this.views.side.scene.add(axesHelper2);
        this.views.front.scene.add(axesHelper3);

        // const light = new THREE.AmbientLight(new THREE.Color('yellow')); // soft white light
        // const spotLight = new THREE.SpotLight('yellow');
        // spotLight.position.set(100, 1000, 100);

        // spotLight.castShadow = true;

        // spotLight.shadow.mapSize.width = 1024;
        // spotLight.shadow.mapSize.height = 1024;

        // spotLight.shadow.camera.near = 500;
        // spotLight.shadow.camera.far = 4000;
        // spotLight.shadow.camera.fov = 30;
        const light = new THREE.PointLight(new THREE.Color('yellow'), 1, 100);
        light.position.set(50, 50, 50);

        this.views.perspective.scene.add(light);
        this.views.top.scene.add(light);
    }

    private positionAllViews(x: number, y: number, z: number, animation: boolean): void {
        if (
            this.views.perspective.controls &&
            this.views.top.controls &&
            this.views.side.controls &&
            this.views.front.controls
        ) {
            // this.views.perspective.controls.setLookAt(x - 8, y - 8, z + 3, x, y, z, animation);
            this.views.perspective.controls.setLookAt(x, y, z + 40, x, y, z, animation);
            this.views.top.camera.position.set(x, y, z + 8);
            this.views.top.camera.lookAt(x, y, z);
            this.views.top.camera.zoom = CONST.FOV_DEFAULT;
            this.views.side.camera.position.set(x, y + 8, z);
            this.views.side.camera.lookAt(x, y, z);
            this.views.side.camera.zoom = CONST.FOV_DEFAULT;
            this.views.front.camera.position.set(x + 8, y, z);
            this.views.front.camera.lookAt(x, y, z);
            this.views.front.camera.zoom = CONST.FOV_DEFAULT;
        }
    }

    private static resizeRendererToDisplaySize(viewName: string, view: RenderView): void {
        const { camera, renderer } = view;
        const canvas = renderer.domElement;
        if (!canvas.parentElement) return;
        const width = canvas.parentElement.clientWidth;
        const height = canvas.parentElement.clientHeight;
        const needResize = canvas.clientWidth !== width || canvas.clientHeight !== height;
        if (needResize && camera && view.camera) {
            if (camera instanceof THREE.PerspectiveCamera) {
                camera.aspect = width / height;
            } else {
                const topViewFactor = 0;
                const viewSize = CONST.ZOOM_FACTOR;
                const aspectRatio = width / height;
                if (!(camera instanceof THREE.PerspectiveCamera)) {
                    camera.left = (-aspectRatio * viewSize) / 2 - topViewFactor;
                    camera.right = (aspectRatio * viewSize) / 2 + topViewFactor;
                    camera.top = viewSize / 2 + topViewFactor;
                    camera.bottom = -viewSize / 2 - topViewFactor;
                }
                camera.near = -50;
                camera.far = 50;
            }
            view.renderer.setSize(width, height);
            view.camera.updateProjectionMatrix();
        }
    }

    private renderRayCaster = (viewType: RenderView): void => {
        viewType.rayCaster.renderer.setFromCamera(viewType.rayCaster.mouseVector, viewType.camera);
        if (this.mode === Mode.DRAW) {
            const intersects = this.views.perspective.rayCaster.renderer.intersectObjects(
                this.views.perspective.scene.children,
                false,
            );
            if (intersects.length > 0) {
                this.views.perspective.scene.children[0].add(this.cube.perspective);
                const newPoints = intersects[0].point;
                this.cube.perspective.position.copy(newPoints);
                this.views.perspective.renderer.domElement.style.cursor = 'default';
            }
        } else if (this.mode === Mode.REVIEW_CANVAS) {
            const intersects = this.views.perspective.rayCaster.renderer.intersectObjects(
                this.views.perspective.scene.children,
                false,
            );
            if (intersects.length > 0) {
                this.views.perspective.scene.children[0].add(this.sphere.perspective);
                const newPoints = intersects[0].point;
                this.sphere.perspective.position.copy(newPoints);
                this.views.perspective.renderer.domElement.style.cursor = 'default';
            }
        } else if (this.idle()) {
            const { children } = this.views.perspective.scene.children[0];
            const { renderer } = this.views.perspective.rayCaster;
            const intersects = renderer.intersectObjects(children, false);
            // console.log('有值了', intersects);
            if (intersects.length !== 0) {
                // console.log('有值了2');
                // if (this.editable(intersects[0].object)) {
                //     if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry) {
                //         this.action.perspMoveAble = true;
                //         viewType.renderer.domElement.style.cursor = 'move';
                //         viewType.controls.mouseButtons.left = CameraControls.ACTION.NONE;
                //         viewType.controls.mouseButtons.right = CameraControls.ACTION.NONE;
                //     } else if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                //         this.action.perspMoveAble = true;
                //         viewType.renderer.domElement.style.cursor = 'move';
                //         viewType.controls.mouseButtons.right = CameraControls.ACTION.NONE;
                //         viewType.controls.mouseButtons.left = CameraControls.ACTION.NONE;
                //     }
                // }
                // console.log('激活：');

                // // 碰撞的是box
                if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry) {
                    const clientID = intersects[0].object.name;
                    if (clientID === undefined || clientID === '' || this.model.data.focusData.clientID === clientID) {
                        return;
                    }
                    if (!this.action.selectable) return;
                    this.resetColor();
                    const object = this.views.perspective.scene.getObjectByName(clientID);
                    if (object === undefined) return;
                    this.model.data.focusData.issueID = null;
                    this.model.data.focusData.clientID = clientID;
                    this.dispatchEvent(
                        new CustomEvent('canvas.selected', {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                clientID: Number(intersects[0].object.name),
                                issueID: null,
                            },
                        }),
                    );
                } else if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                    // 碰撞的是圆体
                    viewType.renderer.domElement.style.cursor = 'move';
                    const issueID = intersects[0].object.name;
                    if (issueID === undefined || issueID === '' || this.model.data.focusData.issueID === issueID) {
                        return;
                    }
                    if (!this.action.selectable) return;
                    this.resetColor();
                    const object = this.views.perspective.scene.getObjectByName(issueID);
                    if (object === undefined) return;
                    this.model.data.focusData.issueID = issueID;
                    this.model.data.focusData.clientID = null;

                    this.dispatchEvent(
                        new CustomEvent('canvas.selected', {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                clientID: null,
                                issueID: Number(intersects[0].object.name),
                            },
                        }),
                    );
                } else {
                    // 视为没有碰撞物
                    this.model.data.focusData.issueID = null;
                    this.model.data.focusData.clientID = null;
                    this.dispatchEvent(
                        new CustomEvent('canvas.selected', {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                clientID: null,
                                issueID: null,
                            },
                        }),
                    );
                }
            } else if (this.model.data.focusData.clientID !== null) {
                this.resetColor();
                this.model.data.focusData.clientID = null;
                // this.dispatchEvent(
                //     new CustomEvent('canvas.selected', {
                //         bubbles: false,
                //         cancelable: true,
                //         detail: {
                //             clientID: null,
                //             issueID: null,
                //         },
                //     }),
                // );
            } else if (this.model.data.focusData.issueID !== null) {
                // this.resetIssueColor();
                this.model.data.focusData.issueID = null;
            }
        }
    };

    private resetColor(): void {
        this.model.data.objects.forEach((object: any): void => {
            const { clientID } = object;
            const target = this.views.perspective.scene.getObjectByName(String(clientID));
            if (target) {
                ((target as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set((target as any).originalColor);
            }
        });
    }

    // private resetIssueColor(): void {
    //     this.model.data.issueRegions.forEach((object: any): void => {
    //         const { id } = object;
    //         const target = this.views.perspective.scene.getObjectByName(String(id));
    //         if (target) {
    //             ((target as THREE.Mesh).material as
    // THREE.MeshBasicMaterial).color.set((target as any).originalColor);
    //         }
    //     });
    // }

    public render(): void {
        Object.keys(this.views).forEach((view: string): void => {
            const viewType = this.views[view as keyof Views];
            viewType.renderer.domElement.style.cursor = 'default';
            if (!(viewType.controls && viewType.camera && viewType.rayCaster)) return;
            Canvas3dViewImpl.resizeRendererToDisplaySize(view, viewType);
            if (viewType.controls.enabled) {
                viewType.controls.update(this.clock.getDelta());
            } else {
                viewType.camera.updateProjectionMatrix();
            }
            viewType.renderer.render(viewType.scene, viewType.camera);
            // cameraControl正在活动时，不需要进行移动检测。
            if (view === ViewType.PERSPECTIVE && viewType.scene.children.length !== 0) {
                this.renderRayCaster(viewType);
            }
            const { clientID } = this.model.data.activeElement;
            // if (clientID !== 'null' && view !== ViewType.PERSPECTIVE) {

            if (clientID && view !== ViewType.PERSPECTIVE) {
                // console.log('move:', this.action.activeMove);
                viewType.rayCaster.renderer.setFromCamera(viewType.rayCaster.mouseVector, viewType.camera);
                // First Scan
                if (this.action.scan === view) {
                    if (!(this.action.translation.status || this.action.resize.status || this.action.rotation.status)) {
                        if (this.model.data.selected instanceof CuboidModel) {
                            this.initiateAction(view, viewType);
                        } else if (this.model.data.selected instanceof SphereModel) {
                            this.initiateActionReview(view, viewType);
                        }
                    }
                    // Action Operations
                    if (this.action.detected) {
                        if (this.model.data.selected instanceof CuboidModel) {
                            if (this.action.translation.status) {
                                this.renderTranslateAction(view as ViewType, viewType);
                            } else if (this.action.resize.status) {
                                this.renderResizeAction(view as ViewType, viewType);
                            } else {
                                this.renderRotateAction(view as ViewType, viewType);
                            }
                            this.updateRotationHelperPos();
                            this.updateResizeHelperPos();
                        } else if (this.model.data.selected instanceof SphereModel) {
                            if (this.action.translation.status) {
                                this.renderTranslateAction(view as ViewType, viewType);
                            } else if (this.action.resize.status) {
                                this.renderResizeAction(view as ViewType, viewType);
                            } else {
                                this.renderRotateAction(view as ViewType, viewType);
                            }
                        }
                    } else {
                        this.resetActions();
                    }
                }
            }
        });
        if (this.action.detachCam && this.action.detachCamRef === this.model.data.activeElement.clientID) {
            try {
                this.detachCamera(null);
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.action.detachCam = false;
            }
        }

        if (this.action.detachIssueCam && this.action.detachIssueCamRef === this.model.data.activeElement.issueID) {
            try {
                this.detachCamera(null);
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.action.detachIssueCam = false;
            }
        }

        if (this.model.mode === Mode.BUSY && !this.action.loading) {
            if (this.action.oldState !== '') {
                this.model.mode = this.action.oldState;
                this.action.oldState = '';
            } else {
                this.model.mode = Mode.IDLE;
            }
        } else if (this.model.data.objectUpdating && !this.action.loading) {
            this.model.data.objectUpdating = false;
        }
    }

    private adjustPerspectiveCameras(): void {
        const coordinatesTop = this.model.data.selected.getReferenceCoordinates(ViewType.TOP);
        const sphericalTop = new THREE.Spherical();
        sphericalTop.setFromVector3(coordinatesTop);
        this.views.top.camera.position.setFromSpherical(sphericalTop);
        this.views.top.camera.updateProjectionMatrix();

        const coordinatesSide = this.model.data.selected.getReferenceCoordinates(ViewType.SIDE);
        const sphericalSide = new THREE.Spherical();
        sphericalSide.setFromVector3(coordinatesSide);
        this.views.side.camera.position.setFromSpherical(sphericalSide);
        this.views.side.camera.updateProjectionMatrix();

        const coordinatesFront = this.model.data.selected.getReferenceCoordinates(ViewType.FRONT);
        const sphericalFront = new THREE.Spherical();
        sphericalFront.setFromVector3(coordinatesFront);
        this.views.front.camera.position.setFromSpherical(sphericalFront);
        this.views.front.camera.updateProjectionMatrix();
    }

    private renderTranslateAction(view: ViewType, viewType: any): void {
        if (
            this.action.translation.helper.x === this.views[view].rayCaster.mouseVector.x &&
            this.action.translation.helper.y === this.views[view].rayCaster.mouseVector.y
        ) {
            return;
        }
        const intersects = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );

        if (intersects.length !== 0 && intersects[0].point) {
            const coordinates = intersects[0].point;
            this.action.translation.coordinates = coordinates;
            this.moveObject(coordinates);
        }
    }

    private moveObject(coordinates: THREE.Vector3): void {
        const { perspective, top, side, front } = this.model.data.selected;
        let localCoordinates = coordinates;
        if (this.action.translation.status) {
            localCoordinates = coordinates
                .clone()
                .sub(this.action.translation.offset)
                .applyMatrix4(this.action.translation.inverseMatrix);
        }
        perspective.position.copy(localCoordinates.clone());
        top.position.copy(localCoordinates.clone());
        side.position.copy(localCoordinates.clone());
        front.position.copy(localCoordinates.clone());
    }

    private setSelectedChildScale(x: number, y: number, z: number): void {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            this.model.data.selected[view].children.forEach((element: any): void => {
                if (element.name !== CONST.CUBOID_EDGE_NAME) {
                    element.scale.set(
                        x == null ? element.scale.x : x,
                        y == null ? element.scale.y : y,
                        z == null ? element.scale.z : z,
                    );
                }
            });
        });
    }

    private renderResizeAction(view: ViewType, viewType: any): void {
        const intersects = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );
        // Return if no intersection with the reference plane
        if (intersects.length === 0) return;
        const { x: scaleInitX, y: scaleInitY, z: scaleInitZ } = this.action.resize.initScales;
        const { x: scaleMemX, y: scaleMemY, z: scaleMemZ } = this.action.resize.memScales;
        const { x: initPosX, y: initPosY } = this.action.resize.helper;
        const { x: currentPosX, y: currentPosY } = viewType.rayCaster.mouseVector;
        const { resizeVector } = this.action.resize;

        if (this.action.resize.helper.x === currentPosX && this.action.resize.helper.y === currentPosY) {
            return;
        }

        if (
            this.action.resize.recentMouseVector.x === currentPosX &&
            this.action.resize.recentMouseVector.y === currentPosY
        ) {
            return;
        }
        this.action.resize.recentMouseVector = viewType.rayCaster.mouseVector.clone();
        switch (view) {
            case ViewType.TOP: {
                let y = scaleInitX * (currentPosX / initPosX);
                let x = scaleInitY * (currentPosY / initPosY);
                if (x < 0) x = 0.2;
                if (y < 0) y = 0.2;
                this.model.data.selected.setScale(y, x, this.model.data.selected.top.scale.z);
                this.setSelectedChildScale(1 / y, 1 / x, null);
                const differenceX = y / 2 - scaleMemX / 2;
                const differenceY = x / 2 - scaleMemY / 2;

                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                }

                this.action.resize.memScales.x = y;
                this.action.resize.memScales.y = x;
                break;
            }
            case ViewType.SIDE: {
                let x = scaleInitX * (currentPosX / initPosX);
                let z = scaleInitZ * (currentPosY / initPosY);
                if (x < 0) x = 0.2;
                if (z < 0) z = 0.2;
                this.model.data.selected.setScale(x, this.model.data.selected.top.scale.y, z);
                this.setSelectedChildScale(1 / x, null, 1 / z);
                const differenceX = x / 2 - scaleMemX / 2;
                const differenceY = z / 2 - scaleMemZ / 2;

                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                }

                this.action.resize.memScales = { ...this.action.resize.memScales, x, z };
                break;
            }
            case ViewType.FRONT: {
                let y = scaleInitY * (currentPosX / initPosX);
                let z = scaleInitZ * (currentPosY / initPosY);
                if (y < 0) y = 0.2;
                if (z < 0) z = 0.2;
                this.model.data.selected.setScale(this.model.data.selected.top.scale.x, y, z);
                this.setSelectedChildScale(null, 1 / y, 1 / z);
                let differenceX;
                let differenceY;

                if (!this.action.resize.frontBool) {
                    differenceX = z / 2 - scaleMemZ / 2;
                    differenceY = y / 2 - scaleMemY / 2;
                    this.action.resize.frontBool = true;
                } else {
                    differenceX = z / 2 - scaleMemY / 2;
                    differenceY = y / 2 - scaleMemZ / 2;
                }
                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                }

                this.action.resize.memScales.y = z;
                this.action.resize.memScales.z = y;
                break;
            }
            default:
        }
        const coordinates = resizeVector.clone();
        intersects[0].object.localToWorld(coordinates);
        this.moveObject(coordinates);
        this.adjustPerspectiveCameras();
    }

    private static isLeft(a: any, b: any, c: any): boolean {
        // For reference
        // A
        // |\                // A = Rotation Center
        // | \               // B = Previous Frame Position
        // |  C              // C = Current Frame Position
        // B
        return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) > 0;
    }

    private rotateCube(instance: CuboidModel, direction: number, view: ViewType): void {
        // console.log('direction');

        switch (view) {
            case ViewType.TOP:
                instance.perspective.rotateZ(direction);
                instance.top.rotateZ(direction);
                instance.side.rotateZ(direction);
                instance.front.rotateZ(direction);
                this.rotateCamera(direction, view);
                break;
            case ViewType.FRONT:
                instance.perspective.rotateX(direction);
                instance.top.rotateX(direction);
                instance.side.rotateX(direction);
                instance.front.rotateX(direction);
                this.rotateCamera(direction, view);
                break;
            case ViewType.SIDE:
                instance.perspective.rotateY(direction);
                instance.top.rotateY(direction);
                instance.side.rotateY(direction);
                instance.front.rotateY(direction);
                this.rotateCamera(direction, view);
                break;
            default:
        }
    }

    private rotateCamera(direction: any, view: ViewType): void {
        switch (view) {
            case ViewType.TOP:
                this.views.top.camera.rotateZ(direction);
                break;
            case ViewType.FRONT:
                this.views.front.camera.rotateZ(direction);
                break;
            case ViewType.SIDE:
                this.views.side.camera.rotateZ(direction);
                break;
            default:
        }
    }

    private attachCamera(view: ViewType): void {
        switch (view) {
            case ViewType.TOP:
                this.model.data.selected.side.attach(this.views.side.camera);
                this.model.data.selected.front.attach(this.views.front.camera);
                break;
            case ViewType.SIDE:
                this.model.data.selected.front.attach(this.views.front.camera);
                this.model.data.selected.top.attach(this.views.top.camera);
                break;
            case ViewType.FRONT:
                this.model.data.selected.side.attach(this.views.side.camera);
                this.model.data.selected.top.attach(this.views.top.camera);
                break;
            default:
        }
    }

    private detachCamera(view: ViewType): void {
        const coordTop = this.model.data.selected.getReferenceCoordinates(ViewType.TOP);
        const sphericaltop = new THREE.Spherical();
        sphericaltop.setFromVector3(coordTop);

        const coordSide = this.model.data.selected.getReferenceCoordinates(ViewType.SIDE);
        const sphericalside = new THREE.Spherical();
        sphericalside.setFromVector3(coordSide);

        const coordFront = this.model.data.selected.getReferenceCoordinates(ViewType.FRONT);
        const sphericalfront = new THREE.Spherical();
        sphericalfront.setFromVector3(coordFront);

        const { side: objectSideView, front: objectFrontView, top: objectTopView } = this.model.data.selected;
        const { camera: sideCamera } = this.views.side;
        const { camera: frontCamera } = this.views.front;
        const { camera: topCamera } = this.views.top;

        switch (view) {
            case ViewType.TOP: {
                const camRotationSide = objectSideView
                    .getObjectByName('cameraSide')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectSideView.remove(sideCamera);
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.setRotationFromQuaternion(camRotationSide);
                sideCamera.scale.set(1, 1, 1);

                const camRotationFront = objectFrontView
                    .getObjectByName('cameraFront')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectFrontView.remove(frontCamera);
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camRotationFront);
                frontCamera.scale.set(1, 1, 1);
                break;
            }
            case ViewType.SIDE: {
                const camRotationFront = objectFrontView
                    .getObjectByName('cameraFront')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectFrontView.remove(frontCamera);
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camRotationFront);
                frontCamera.scale.set(1, 1, 1);

                objectTopView.remove(topCamera);
                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);
                break;
            }
            case ViewType.FRONT: {
                const camRotationSide = objectSideView
                    .getObjectByName('cameraSide')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectSideView.remove(sideCamera);
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.setRotationFromQuaternion(camRotationSide);
                sideCamera.scale.set(1, 1, 1);

                objectTopView.remove(topCamera);
                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);
                break;
            }
            default: {
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.rotation.z = this.views.side.scene.getObjectByName(Planes.SIDE).rotation.z;
                sideCamera.scale.set(1, 1, 1);

                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);

                const camFrontRotate = objectFrontView
                    .getObjectByName('camRefRot')
                    .getWorldQuaternion(new THREE.Quaternion());
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camFrontRotate);
                frontCamera.scale.set(1, 1, 1);
            }
        }
    }

    private rotatePlane(direction: number, view: ViewType): void {
        const sceneTopPlane = this.views.top.scene.getObjectByName(Planes.TOP);
        const sceneSidePlane = this.views.side.scene.getObjectByName(Planes.SIDE);
        const sceneFrontPlane = this.views.front.scene.getObjectByName(Planes.FRONT);
        switch (view) {
            case ViewType.TOP:
                // console.log('旋转中TOP中：', direction);
                sceneTopPlane.rotateZ(direction);
                sceneSidePlane.rotateY(direction);
                sceneFrontPlane.rotateX(-direction);
                break;
            case ViewType.SIDE:
                // console.log('旋转中side中：', direction);

                sceneTopPlane.rotateY(direction);
                sceneSidePlane.rotateZ(direction);
                sceneFrontPlane.rotateY(direction);
                break;
            case ViewType.FRONT:
                sceneTopPlane.rotateX(direction);
                sceneSidePlane.rotateX(-direction);
                sceneFrontPlane.rotateZ(direction);
                break;
            default: {
                const { top: objectTopView, side: objectSideView, front: objectFrontView } = this.model.data.selected;
                objectTopView.add(sceneTopPlane);
                objectSideView.add(sceneSidePlane);
                objectFrontView.add(sceneFrontPlane);
                objectTopView.getObjectByName(Planes.TOP).rotation.set(0, 0, 0);
                objectSideView.getObjectByName(Planes.SIDE).rotation.set(-Math.PI / 2, Math.PI / 2000, Math.PI);
                objectFrontView.getObjectByName(Planes.FRONT).rotation.set(0, Math.PI / 2, 0);

                const quaternionSide = new THREE.Quaternion();
                objectSideView.getObjectByName(Planes.SIDE).getWorldQuaternion(quaternionSide);
                const rotationSide = new THREE.Euler();
                rotationSide.setFromQuaternion(quaternionSide);

                const quaternionFront = new THREE.Quaternion();
                objectFrontView.getObjectByName(Planes.FRONT).getWorldQuaternion(quaternionFront);
                const rotationFront = new THREE.Euler();
                rotationFront.setFromQuaternion(quaternionFront);

                const quaternionTop = new THREE.Quaternion();
                objectTopView.getObjectByName(Planes.TOP).getWorldQuaternion(quaternionTop);
                const rotationTop = new THREE.Euler();
                rotationTop.setFromQuaternion(quaternionTop);

                objectTopView.remove(sceneTopPlane);
                objectSideView.remove(sceneSidePlane);
                objectFrontView.remove(sceneFrontPlane);

                const canvasTopView = this.views.top.renderer.domElement;
                const planeTop = new THREE.Mesh(
                    new THREE.PlaneBufferGeometry(
                        canvasTopView.offsetHeight,
                        canvasTopView.offsetWidth,
                        canvasTopView.offsetHeight,
                        canvasTopView.offsetWidth,
                    ),
                    new THREE.MeshBasicMaterial({
                        color: 0xff0000,
                        alphaTest: 0,
                        visible: false,
                        transparent: true,
                        opacity: 0.1,
                    }),
                );
                planeTop.name = Planes.TOP;
                (planeTop.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;

                const canvasSideView = this.views.side.renderer.domElement;
                const planeSide = new THREE.Mesh(
                    new THREE.PlaneBufferGeometry(
                        canvasSideView.offsetHeight,
                        canvasSideView.offsetWidth,
                        canvasSideView.offsetHeight,
                        canvasSideView.offsetWidth,
                    ),
                    new THREE.MeshBasicMaterial({
                        color: 0x00ff00,
                        alphaTest: 0,
                        visible: false,
                        transparent: true,
                        opacity: 0.1,
                    }),
                );
                planeSide.name = Planes.SIDE;
                (planeSide.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;

                const canvasFrontView = this.views.front.renderer.domElement;
                const planeFront = new THREE.Mesh(
                    new THREE.PlaneBufferGeometry(
                        canvasFrontView.offsetHeight,
                        canvasFrontView.offsetWidth,
                        canvasFrontView.offsetHeight,
                        canvasFrontView.offsetWidth,
                    ),
                    new THREE.MeshBasicMaterial({
                        color: 0x0000ff,
                        alphaTest: 0,
                        visible: false,
                        transparent: true,
                        opacity: 0.5,
                    }),
                );
                planeFront.name = Planes.FRONT;
                (planeFront.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;

                const coordinates = {
                    x: objectTopView.position.x,
                    y: objectTopView.position.y,
                    z: objectTopView.position.z,
                };

                planeTop.rotation.set(rotationTop.x, rotationTop.y, rotationTop.z);
                planeSide.rotation.set(rotationSide.x, rotationSide.y, rotationSide.z);
                planeFront.rotation.set(rotationFront.x, rotationFront.y, rotationFront.z);
                this.views.top.scene.add(planeTop);
                this.views.side.scene.add(planeSide);
                this.views.front.scene.add(planeFront);

                this.translateReferencePlane(coordinates);
            }
        }
    }

    private renderRotateAction(view: ViewType, viewType: any): void {
        // console.log('运行时机');

        let rotationSpeed = Math.PI / CONST.ROTATION_SPEED;
        // console.log('rotationSpeed:', rotationSpeed);
        if (this.model.data.isFineTuning) {
            // rotationSpeed /= 10;
            rotationSpeed = Math.PI / CONST.ROTATION_FINETUNING_SPEED;
        }

        const { renderer } = viewType;
        const canvas = renderer.domElement;
        if (!canvas) return;
        const canvasCentre = {
            x: canvas.offsetLeft + canvas.offsetWidth / 2,
            y: canvas.offsetTop + canvas.offsetHeight / 2,
        };
        if (
            this.action.rotation.screenInit.x === this.action.rotation.screenMove.x &&
            this.action.rotation.screenInit.y === this.action.rotation.screenMove.y
        ) {
            return;
        }

        if (
            this.action.rotation.recentMouseVector.x === this.views[view].rayCaster.mouseVector.x &&
            this.action.rotation.recentMouseVector.y === this.views[view].rayCaster.mouseVector.y
        ) {
            return;
        }
        this.action.rotation.recentMouseVector = this.views[view].rayCaster.mouseVector.clone();
        if (Canvas3dViewImpl.isLeft(canvasCentre, this.action.rotation.screenInit, this.action.rotation.screenMove)) {
            this.rotateCube(this.model.data.selected, -rotationSpeed, view);
            this.rotatePlane(-rotationSpeed, view);
        } else {
            this.rotateCube(this.model.data.selected, rotationSpeed, view);
            this.rotatePlane(rotationSpeed, view);
        }
        this.action.rotation.screenInit.x = this.action.rotation.screenMove.x;
        this.action.rotation.screenInit.y = this.action.rotation.screenMove.y;
    }

    private initiateAction(view: string, viewType: any): void {
        const intersectsHelperResize = viewType.rayCaster.renderer.intersectObjects(
            this.globalHelpers[view].resize,
            false,
        );
        const [state] = this.model.data.objects.filter(
            (_state: any): boolean => _state.clientID === Number(this.model.data.selected[view].name),
        );
        if (state.lock && !this.controller.configuration.forceDisableEditing) return;

        if (intersectsHelperResize.length !== 0) {
            this.action.resize.helper = viewType.rayCaster.mouseVector.clone();
            this.action.resize.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
            const { x, y, z } = this.model.data.selected[view].scale;
            this.action.resize.initScales = { x, y, z };
            this.action.resize.memScales = { x, y, z };
            this.action.resize.frontBool = false;
            this.action.resize.resizeVector = new THREE.Vector3(0, 0, 0);
            return;
        }
        const intersectsHelperRotation = viewType.rayCaster.renderer.intersectObjects(
            this.globalHelpers[view].rotation,
            false,
        );
        if (intersectsHelperRotation.length !== 0) {
            this.action.rotation.helper = viewType.rayCaster.mouseVector.clone();
            this.action.rotation.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
            this.attachCamera(view as ViewType);
            return;
        }

        const intersectsBox = viewType.rayCaster.renderer.intersectObjects([this.model.data.selected[view]], false);

        const intersectsPointCloud = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );
        // console.log(`${view}Plane111`, intersectsPointCloud);
        if (intersectsBox.length !== 0 && intersectsPointCloud.length !== 0) {
            if (state.pinned) return;
            this.action.translation.helper = viewType.rayCaster.mouseVector.clone();
            this.action.translation.inverseMatrix = intersectsBox[0].object.parent.matrixWorld.invert();

            // console.log(`${view}Plane`, intersectsPointCloud);
            this.action.translation.offset = intersectsPointCloud[0].point.sub(
                new THREE.Vector3().setFromMatrixPosition(intersectsBox[0].object.matrixWorld),
            );
            this.action.translation.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
        }
    }

    private initiateActionReview(view: string, viewType: any): void {
        const intersectsHelperResize = viewType.rayCaster.renderer.intersectObjects(
            this.globalHelpers[view].resize,
            false,
        );
        // const [state] = this.model.data.issueRegions.filter(
        //     (_state: any): boolean => _state.issID === Number(this.model.data.selected[view].name),
        // );

        if (intersectsHelperResize.length !== 0) {
            this.action.resize.helper = viewType.rayCaster.mouseVector.clone();
            this.action.resize.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
            const { x, y, z } = this.model.data.selected[view].scale;
            this.action.resize.initScales = { x, y, z };
            this.action.resize.memScales = { x, y, z };
            this.action.resize.frontBool = false;
            this.action.resize.resizeVector = new THREE.Vector3(0, 0, 0);
            return;
        }

        const intersectsHelperRotation = viewType.rayCaster.renderer.intersectObjects(
            this.globalHelpers[view].rotation,
            false,
        );
        if (intersectsHelperRotation.length !== 0) {
            this.action.rotation.helper = viewType.rayCaster.mouseVector.clone();
            this.action.rotation.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
            this.attachCamera(view as ViewType);
            return;
        }

        const intersectsBox = viewType.rayCaster.renderer.intersectObjects([this.model.data.selected[view]], false);
        const intersectsPointCloud = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );
        if (intersectsBox.length !== 0) {
            this.action.translation.helper = viewType.rayCaster.mouseVector.clone();
            this.action.translation.inverseMatrix = intersectsBox[0].object.parent.matrixWorld.invert();
            this.action.translation.offset = intersectsPointCloud[0].point.sub(
                new THREE.Vector3().setFromMatrixPosition(intersectsBox[0].object.matrixWorld),
            );
            this.action.translation.status = true;
            this.action.detected = true;
            this.views.top.controls.enabled = false;
            this.views.side.controls.enabled = false;
            this.views.front.controls.enabled = false;
        }
    }

    public keyControls(key: any): void {
        const { controls } = this.views.perspective;
        if (!controls) return;
        if (key.shiftKey) {
            switch (key.code) {
                case CameraAction.ROTATE_RIGHT:
                    controls.rotate(0.1 * THREE.MathUtils.DEG2RAD * this.speed, 0, true);
                    break;
                case CameraAction.ROTATE_LEFT:
                    controls.rotate(-0.1 * THREE.MathUtils.DEG2RAD * this.speed, 0, true);
                    break;
                case CameraAction.TILT_UP:
                    controls.rotate(0, -0.05 * THREE.MathUtils.DEG2RAD * this.speed, true);
                    break;
                case CameraAction.TILT_DOWN:
                    controls.rotate(0, 0.05 * THREE.MathUtils.DEG2RAD * this.speed, true);
                    break;
                default:
                    break;
            }
        } else if (key.altKey === true) {
            switch (key.code) {
                case CameraAction.ZOOM_IN:
                    controls.dolly(CONST.DOLLY_FACTOR, true);
                    break;
                case CameraAction.ZOOM_OUT:
                    controls.dolly(-CONST.DOLLY_FACTOR, true);
                    break;
                case CameraAction.MOVE_LEFT:
                    controls.truck(-0.01 * this.speed, 0, true);
                    break;
                case CameraAction.MOVE_RIGHT:
                    controls.truck(0.01 * this.speed, 0, true);
                    break;
                case CameraAction.MOVE_DOWN:
                    controls.truck(0, -0.01 * this.speed, true);
                    break;
                case CameraAction.MOVE_UP:
                    controls.truck(0, 0.01 * this.speed, true);
                    break;
                // case 'c':
                //     console.log('进入编辑模式');
                //     break;
                // case 'x':
                //     console.log('进入编辑模式');
                //     break;
                default:
                    break;
            }
        } else if (key.code === 'ControlLeft') {
            this.action.selectable = !key.ctrlKey;
        }
    }

    public html(): ViewsDOM {
        return {
            perspective: this.views.perspective.renderer.domElement,
            top: this.views.top.renderer.domElement,
            side: this.views.side.renderer.domElement,
            front: this.views.front.renderer.domElement,
        };
    }

    private async summarize(): Promise<void> {
        const objectURL = URL.createObjectURL(this.model.data.summarize?.framePcd?.imageData?.data.data);
        const points = await new PCDLoader().loadAsync(objectURL);

        // 将对象加入点中
        const { objects } = this.model.data.summarize;
        const material = new THREE.MeshBasicMaterial();
        const geometry = new THREE.BoxGeometry(1, 1, 1);

        for (const object of objects) {
            const mesh = new THREE.Mesh(geometry, material);
            mesh.position.set(object.points[0], object.points[1], object.points[2]);
            mesh.scale.set(object.points[6], object.points[7], object.points[8]);
            mesh.rotation.set(object.points[3], object.points[4], object.points[5]);

            points.add(mesh);

            points.updateMatrixWorld();
            mesh.updateMatrixWorld();

            mesh.geometry.computeBoundingBox();

            const positions = points.geometry.getAttribute('position');
            const { array, itemSize } = positions;

            const bounding = mesh.geometry.boundingBox;

            let pointsNum = 0;

            for (let index = 0; index < array.length; index += itemSize) {
                const x = array[index];
                const y = array[index + 1];
                const z = array[index + 2];

                const point = new THREE.Vector3(x, y, z);

                const isInBox = bounding.containsPoint(mesh.worldToLocal(point.clone()));
                if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z) && isInBox) {
                    pointsNum++;
                }
            }

            const extendValues = getExtendValues(object.label.id, object.attributes);

            this.model.data.summarize.data.push({
                jobId: 0,

                frame: 0,
                frameType: 0,

                trackShapeId: object.serverID,
                objId: object.serverID,

                normalShapeId: object.serverID,

                pointsNum,

                objLength: object.points[6],
                objWidth: object.points[7],
                objHeight: object.points[8],

                objWx: object.points[0],
                objWy: object.points[1],
                objWz: object.points[2],

                objRoll: object.points[3],
                objPitch: object.points[4],
                objYaw: object.points[5],

                ...extendValues,
            });
        }
    }
}
