// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { ReactNode } from 'react';
import { Row, Col } from 'antd/lib/grid';
import Icon, {
    UnlockOutlined,
    LockFilled,
    TeamOutlined,
    UserOutlined,
    PushpinFilled,
    PushpinOutlined,
    EyeInvisibleFilled,
    StarFilled,
    SelectOutlined,
    StarOutlined,
    EyeOutlined,
} from '@ant-design/icons';

import CVATTooltip from 'components/common/cvat-tooltip';
import { ObjectType, ShapeType } from 'reducers/interfaces';
import {
    ObjectOutsideIcon,
    // FirstIcon, LastIcon, PreviousIcon, NextIcon,
} from 'icons';
import { TFunction, useTranslation } from 'react-i18next';

interface Props {
    readonly: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    occluded: boolean;
    outside: boolean | undefined;
    locked: boolean;
    pinned: boolean;
    hidden: boolean;
    keyframe: boolean | undefined;
    outsideDisabled: boolean;
    hiddenDisabled: boolean;
    keyframeDisabled: boolean;
    switchOccludedShortcut: string;
    switchOutsideShortcut: string;
    switchLockShortcut: string;
    switchHiddenShortcut: string;
    switchKeyFrameShortcut: string;
    nextKeyFrameShortcut: string;
    prevKeyFrameShortcut: string;
    collapsed: boolean;

    // navigateFirstKeyframe: null | (() => void);
    // navigatePrevKeyframe: null | (() => void);
    // navigateNextKeyframe: null | (() => void);
    // navigateLastKeyframe: null | (() => void);

    setOccluded(e: React.MouseEvent): void;
    unsetOccluded(e: React.MouseEvent): void;
    setOutside(e: React.MouseEvent): void;
    unsetOutside(e: React.MouseEvent): void;
    setKeyframe(e: React.MouseEvent): void;
    unsetKeyframe(e: React.MouseEvent): void;
    lock(e: React.MouseEvent): void;
    unlock(e: React.MouseEvent): void;
    pin(e: React.MouseEvent): void;
    unpin(e: React.MouseEvent): void;
    hide(e: React.MouseEvent): void;
    show(e: React.MouseEvent): void;
}

interface sProps extends Props {
    title: ReactNode;
}

const classes = {
    firstKeyFrame: { className: 'cvat-object-item-button-first-keyframe' },
    prevKeyFrame: { className: 'cvat-object-item-button-prev-keyframe' },
    nextKeyFrame: { className: 'cvat-object-item-button-next-keyframe' },
    lastKeyFrame: { className: 'cvat-object-item-button-last-keyframe' },
    outside: {
        enabled: { className: 'cvat-object-item-button-outside cvat-object-item-button-outside-enabled' },
        disabled: { className: 'cvat-object-item-button-outside' },
    },
    lock: {
        enabled: { className: 'cvat-object-item-button-lock cvat-object-item-button-lock-enabled' },
        disabled: { className: 'cvat-object-item-button-lock' },
    },
    occluded: {
        enabled: { className: 'cvat-object-item-button-occluded cvat-object-item-button-occluded-enabled' },
        disabled: { className: 'cvat-object-item-button-occluded' },
    },
    pinned: {
        enabled: { className: 'cvat-object-item-button-pinned cvat-object-item-button-pinned-enabled' },
        disabled: { className: 'cvat-object-item-button-pinned' },
    },
    hidden: {
        enabled: { className: 'cvat-object-item-button-hidden cvat-object-item-button-hidden-enabled' },
        disabled: { className: 'cvat-object-item-button-hidden' },
    },
    keyframe: {
        enabled: { className: 'cvat-object-item-button-keyframe cvat-object-item-button-keyframe-enabled' },
        disabled: { className: 'cvat-object-item-button-keyframe' },
    },
};

// function NavigateFirstKeyframe(props: Props): JSX.Element {
//     const { navigateFirstKeyframe } = props;
//     return navigateFirstKeyframe ? (
//         <Icon {...classes.firstKeyFrame} component={FirstIcon} onClick={navigateFirstKeyframe} />
//     ) : (
//         <Icon {...classes.firstKeyFrame} component={FirstIcon} style={{ opacity: 0.5, pointerEvents: 'none' }} />
//     );
// }

// function NavigatePrevKeyframe(props: Props): JSX.Element {
//     const { prevKeyFrameShortcut, navigatePrevKeyframe } = props;
//     return navigatePrevKeyframe ? (
//         <CVATTooltip title={`Go to previous keyframe ${prevKeyFrameShortcut}`}>
//             <Icon {...classes.prevKeyFrame} component={PreviousIcon} onClick={navigatePrevKeyframe} />
//         </CVATTooltip>
//     ) : (
//         <Icon {...classes.prevKeyFrame} component={PreviousIcon} style={{ opacity: 0.5, pointerEvents: 'none' }} />
//     );
// }

// function NavigateNextKeyframe(props: Props): JSX.Element {
//     const { navigateNextKeyframe, nextKeyFrameShortcut } = props;
//     return navigateNextKeyframe ? (
//         <CVATTooltip title={`Go to next keyframe ${nextKeyFrameShortcut}`}>
//             <Icon {...classes.nextKeyFrame} component={NextIcon} onClick={navigateNextKeyframe} />
//         </CVATTooltip>
//     ) : (
//         <Icon {...classes.nextKeyFrame} component={NextIcon} style={{ opacity: 0.5, pointerEvents: 'none' }} />
//     );
// }

// function NavigateLastKeyframe(props: Props): JSX.Element {
//     const { navigateLastKeyframe } = props;
//     return navigateLastKeyframe ? (
//         <Icon {...classes.lastKeyFrame} component={LastIcon} onClick={navigateLastKeyframe} />
//     ) : (
//         <Icon {...classes.lastKeyFrame} component={LastIcon} style={{ opacity: 0.5, pointerEvents: 'none' }} />
//     );
// }

function SwitchLock(props: sProps): JSX.Element {
    const { title, locked, switchLockShortcut, lock, unlock } = props;
    return (
        // <CVATTooltip title={`Switch lock property ${switchLockShortcut}`}>
        <CVATTooltip title={`${title} ${switchLockShortcut}`}>
            {locked ? (
                <LockFilled {...classes.lock.enabled} onClick={unlock} />
            ) : (
                <UnlockOutlined {...classes.lock.disabled} onClick={lock} />
            )}
        </CVATTooltip>
    );
}

function SwitchOccluded(props: sProps): JSX.Element {
    const { title, switchOccludedShortcut, occluded, unsetOccluded, setOccluded } = props;
    return (
        // <CVATTooltip title={`Switch occluded property ${switchOccludedShortcut}`}>
        <CVATTooltip title={`${title} ${switchOccludedShortcut}`}>
            {occluded ? (
                <TeamOutlined {...classes.occluded.enabled} onClick={unsetOccluded} />
            ) : (
                <UserOutlined {...classes.occluded.disabled} onClick={setOccluded} />
            )}
        </CVATTooltip>
    );
}

function SwitchPinned(props: sProps): JSX.Element {
    const { title, pinned, pin, unpin } = props;
    return (
        // <CVATTooltip title='Switch pinned property'>
        <CVATTooltip title={title}>
            {pinned ? (
                <PushpinFilled {...classes.pinned.enabled} onClick={unpin} />
            ) : (
                <PushpinOutlined {...classes.pinned.disabled} onClick={pin} />
            )}
        </CVATTooltip>
    );
}

function SwitchHidden(props: sProps): JSX.Element {
    const { title, switchHiddenShortcut, hidden, hiddenDisabled, show, hide } = props;
    const hiddenStyle = hiddenDisabled ? { opacity: 0.5, pointerEvents: 'none' as const } : {};
    return (
        // <CVATTooltip title={`Switch hidden property ${switchHiddenShortcut}`}>
        <CVATTooltip title={`${title} ${switchHiddenShortcut}`}>
            {hidden ? (
                <EyeInvisibleFilled {...classes.hidden.enabled} onClick={show} style={hiddenStyle} />
            ) : (
                <EyeOutlined {...classes.hidden.disabled} onClick={hide} style={hiddenStyle} />
            )}
        </CVATTooltip>
    );
}

function SwitchOutside(props: sProps): JSX.Element {
    const { title, outside, switchOutsideShortcut, outsideDisabled, unsetOutside, setOutside } = props;
    const outsideStyle = outsideDisabled ? { opacity: 0.5, pointerEvents: 'none' as const } : {};
    return (
        // <CVATTooltip title={`Switch outside property ${switchOutsideShortcut}`}>
        <CVATTooltip title={`${title}  ${switchOutsideShortcut}`}>
            {outside ? (
                <Icon
                    {...classes.outside.enabled}
                    component={ObjectOutsideIcon}
                    onClick={unsetOutside}
                    style={outsideStyle}
                />
            ) : (
                <SelectOutlined {...classes.outside.disabled} onClick={setOutside} style={outsideStyle} />
            )}
        </CVATTooltip>
    );
}

function SwitchKeyframe(props: sProps): JSX.Element {
    const { title, keyframe, switchKeyFrameShortcut, keyframeDisabled, unsetKeyframe, setKeyframe } = props;
    const keyframeStyle = keyframeDisabled ? { opacity: 0.5, pointerEvents: 'none' as const } : {};
    return (
        // <CVATTooltip title={`Switch keyframe property ${switchKeyFrameShortcut}`}>
        <CVATTooltip title={`${title}  ${switchKeyFrameShortcut}`}>
            {keyframe ? (
                <StarFilled {...classes.keyframe.enabled} onClick={unsetKeyframe} style={keyframeStyle} />
            ) : (
                <StarOutlined {...classes.keyframe.disabled} onClick={setKeyframe} style={keyframeStyle} />
            )}
        </CVATTooltip>
    );
}

function ItemButtonsComponent(props: Props): JSX.Element {
    const { readonly, objectType, shapeType } = props;
    const { t } = useTranslation();

    if (objectType === ObjectType.TRACK) {
        return (
            <Row align='middle' justify='space-around'>
                <Col span={20} style={{ textAlign: 'center' }}>
                    {/* <Row justify='space-around'>
                        <Col>
                            <NavigateFirstKeyframe {...props} />
                        </Col>
                        <Col>
                            <NavigatePrevKeyframe {...props} />
                        </Col>
                        <Col>
                            <NavigateNextKeyframe {...props} />
                        </Col>
                        <Col>
                            <NavigateLastKeyframe {...props} />
                        </Col>
                    </Row> */}
                    {!readonly && (
                        <Row justify='space-around' className='cvat-object-item-button-hover'>
                            <Col>
                                <SwitchOutside title={t('workspace.side.object.outside')} {...props} />
                            </Col>
                            <Col>
                                <SwitchLock title={t('workspace.side.object.lock')} {...props} />
                            </Col>
                            <Col>
                                <SwitchOccluded title={t('workspace.side.object.occluded')} {...props} />
                            </Col>
                            <Col>
                                <SwitchHidden title={t('workspace.side.object.hidden')} {...props} />
                            </Col>
                            <Col>
                                <SwitchKeyframe title={t('workspace.side.object.keyframe')} {...props} />
                            </Col>
                            {shapeType !== ShapeType.POINTS && (
                                <Col>
                                    <SwitchPinned title={t('workspace.side.object.pinned')} {...props} />
                                </Col>
                            )}
                        </Row>
                    )}
                </Col>
            </Row>
        );
    }

    if (readonly) {
        return <div />;
    }

    if (objectType === ObjectType.TAG) {
        return (
            <Row align='middle' justify='space-around'>
                <Col span={20} style={{ textAlign: 'center' }}>
                    <Row justify='space-around' className='cvat-object-item-button-hover'>
                        <Col>
                            <SwitchLock title={t('workspace.side.object.lock')} {...props} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <Row align='middle' justify='space-around'>
            <Col span={20} style={{ textAlign: 'center' }}>
                <Row justify='space-around' className='cvat-object-item-button-hover'>
                    <Col>
                        <SwitchLock title={t('workspace.side.object.lock')} {...props} />
                    </Col>
                    <Col>
                        <SwitchOccluded title={t('workspace.side.object.occluded')} {...props} />
                    </Col>
                    <Col>
                        <SwitchHidden title={t('workspace.side.object.hidden')} {...props} />
                    </Col>
                    {shapeType !== ShapeType.POINTS && (
                        <Col>
                            <SwitchPinned title={t('workspace.side.object.lock')} {...props} />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
}

export default React.memo(ItemButtonsComponent);
