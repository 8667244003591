/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-17 16:12:07
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useHistory } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import { Project, CombinedState } from 'reducers/interfaces';
import { useSelector } from 'react-redux';
import ActionsMenu from 'components/projects-page/actions-menu';
import { useTranslation } from 'react-i18next';

interface DetailsComponentProps {
    projectInstance: Project;
}

export default function ProjectTopBar(props: DetailsComponentProps): JSX.Element {
    const { projectInstance } = props;
    const user = useSelector((state: CombinedState) => state.auth.user);

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
            <Col>
                <Button onClick={() => history.push('/projects')} type='link' size='large'>
                    <LeftOutlined />
                    {/* Back to projects */}
                    {t('project.back_project_list')}
                </Button>
            </Col>
            {user && user.groups && user.groups.includes('admin') && (
                <Col className='cvat-project-top-bar-actions'>
                    <Dropdown overlay={<ActionsMenu projectInstance={projectInstance} />}>
                        <Button size='middle' className='cvat-project-page-actions-button'>
                            {/* <Text className='cvat-text-color'>Actions</Text> */}
                            <Text className='cvat-text-color'>更多功能</Text>
                            <MoreOutlined className='cvat-menu-icon' />
                        </Button>
                    </Dropdown>
                </Col>
            )}
        </Row>
    );
}
