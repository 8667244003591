/*
 * @Author: swxy
 * @Date: 2022-08-15 10:15:14
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import base from './base';
import login from './login';
import project from './project';
import workspace from './workspace';
import shapetype from './shapetype';

export default {
    ...base,
    ...login,
    ...project,
    ...workspace,
    ...shapetype,
};
