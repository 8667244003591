// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useRef } from 'react';
import Layout from 'antd/lib/layout';

import {
    Divider,
    // Switch,
    Tooltip,
    Space,
    InputNumber,
    Radio,
    Checkbox,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { CuboidDrawingMethod } from 'cvat-canvas/src/typescript/canvasModel';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { createAndSaveAttachAnnotations } from 'actions/annotation-actions';
import { ActiveControl, Rotation, ShapeType, ObjectType, CombinedState } from 'reducers/interfaces';
// import { ActiveControl, Rotation } from 'reducers/interfaces';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
// import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas, RectDrawingMethod } from 'cvat-canvas-wrapper';
import getCore from 'cvat-core-wrapper';

import { clamp } from 'utils/math';

import ControlVisibilityObserver, { ExtraControlsControl } from './control-visibility-observer';
import RotateControl, { Props as RotateControlProps } from './rotate-control';
import CursorControl, { Props as CursorControlProps } from './cursor-control';
import MoveControl, { Props as MoveControlProps } from './move-control';
import FitControl, { Props as FitControlProps } from './fit-control';
import ResizeControl, { Props as ResizeControlProps } from './resize-control';
import ToolsControl from './tools-control';
import OpenCVControl from './opencv-control';
import DrawRectangleControl, { Props as DrawRectangleControlProps } from './draw-rectangle-control';
import DrawPolygonControl, { Props as DrawPolygonControlProps } from './draw-polygon-control';
import DrawPolylineControl, { Props as DrawPolylineControlProps } from './draw-polyline-control';
import DrawPointsControl, { Props as DrawPointsControlProps } from './draw-points-control';
import DrawEllipseControl, { Props as DrawEllipseControlProps } from './draw-ellipse-control';
import DrawCuboidControl, { Props as DrawCuboidControlProps } from './draw-cuboid-control';
// import SetupTagControl, { Props as SetupTagControlProps } from './setup-tag-control';
import MergeControl, { Props as MergeControlProps } from './merge-control';
import GroupControl, { Props as GroupControlProps } from './group-control';
import SplitControl, { Props as SplitControlProps } from './split-control';
import ContentRightTool, { Props as ContentRightToolProps } from './content-right-tool';
import SplitRectangleControl, { Props as SplitRectangleControlProps } from './split-rectangle-control';
import { useTranslation } from 'react-i18next';

const cvat = getCore();

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    labels: any[];

    frame: number;
    jobInstance: any;
    // isDrawShape: boolean;
    activeLabelID: number;
    activeObjectType: ObjectType;
    activeShapeType: ShapeType;
    activeNumOfPoints: number;
    activeRectDrawingMethod: RectDrawingMethod;
    selectedStatesID: number[];
    states: any[];

    mergeObjects(enabled: boolean): void;
    groupObjects(enabled: boolean): void;
    splitTrack(enabled: boolean): void;
    rotateFrame(rotation: Rotation): void;
    repeatDrawShape(): void;
    pasteShape(): void;
    resetGroup(): void;
    redrawShape(): void;

    // changeDrawType(isDrawShape: boolean): void;
    onDrawStart(
        shapeType: ShapeType,
        labelID: number,
        objectType: ObjectType,
        points?: number,
        rectDrawingMethod?: RectDrawingMethod,
    ): void;
    onAnnotationCreate(sessionInstance: any, frame: number, states: any[]): void;
    onRememberObject(labelID: number): void;
    // onIncludeStart(statesToUpdate: any[]): void;
}

const enum BottomCheckType {
    ContinueLabel = 'continueLabel',
    ConsecutiveFrames = 'ConsecutiveFrames',
}

// We use the observer to see if these controls are in the viewport
// They automatically put to extra if not
const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedMoveControl = ControlVisibilityObserver<MoveControlProps>(MoveControl);
const ObservedRotateControl = ControlVisibilityObserver<RotateControlProps>(RotateControl);
const ObservedFitControl = ControlVisibilityObserver<FitControlProps>(FitControl);
const ObservedResizeControl = ControlVisibilityObserver<ResizeControlProps>(ResizeControl);
const ObservedToolsControl = ControlVisibilityObserver(ToolsControl);
const ObservedOpenCVControl = ControlVisibilityObserver(OpenCVControl);
const ObservedDrawRectangleControl = ControlVisibilityObserver<DrawRectangleControlProps>(DrawRectangleControl);
const ObservedDrawPolygonControl = ControlVisibilityObserver<DrawPolygonControlProps>(DrawPolygonControl);
const ObservedDrawPolylineControl = ControlVisibilityObserver<DrawPolylineControlProps>(DrawPolylineControl);
const ObservedDrawPointsControl = ControlVisibilityObserver<DrawPointsControlProps>(DrawPointsControl);
const ObservedDrawEllipseControl = ControlVisibilityObserver<DrawEllipseControlProps>(DrawEllipseControl);
const ObservedDrawCuboidControl = ControlVisibilityObserver<DrawCuboidControlProps>(DrawCuboidControl);
// const ObservedSetupTagControl = ControlVisibilityObserver<SetupTagControlProps>(SetupTagControl);
const ObservedMergeControl = ControlVisibilityObserver<MergeControlProps>(MergeControl);
const ObservedGroupControl = ControlVisibilityObserver<GroupControlProps>(GroupControl);
const ObservedSplitControl = ControlVisibilityObserver<SplitControlProps>(SplitControl);
const ObservedContentRightTool = ControlVisibilityObserver<ContentRightToolProps>(ContentRightTool);
const ObservedSplitRectangleControl = ControlVisibilityObserver<SplitRectangleControlProps>(SplitRectangleControl);

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const {
        activeControl,
        canvasInstance,
        normalizedKeyMap,
        keyMap,
        labels,
        // isDrawShape,
        activeLabelID,
        activeObjectType,
        // activeShapeType,
        mergeObjects,
        groupObjects,
        splitTrack,
        rotateFrame,
        repeatDrawShape,
        pasteShape,
        resetGroup,
        redrawShape,
        // changeDrawType,
        onDrawStart,
        // onIncludeStart,
    } = props;

    const [drawTrack, setDrawTrack] = useState(activeObjectType === ObjectType.TRACK);
    // const [continueLabel, setContinueLabel] = useState(false);
    const inputNumberRef = useRef<HTMLInputElement>(null);
    // const [params, setParams] = useState<any>({});
    // 使用参数记录下上次点击的传参
    // 更改参数后，再次调用onclick事件
    const paramsRef = useRef<any>({
        objectType: ObjectType.SHAPE,
        continueLabel: false, // 是否继续绘画
        enabled: false,
        rectDrawingMethod: RectDrawingMethod.CLASSIC,
        cuboidDrawingMethod: CuboidDrawingMethod.CORNER_POINTS,
        numberOfPoints: 0,
        shapeType: ShapeType.RECTANGLE,
        crosshair: false,
    });
    const { t } = useTranslation();
    // const getDrawShapeRef = useRef<any>(drawShape);

    // const onDraw = (): void => {
    //     // 及时获取已更新的。
    //     const {
    //         activeShapeType: shapeType,
    //         activeNumOfPoints,
    //         activeRectDrawingMethod,
    //     } = propsRef.current;
    // };

    const onClick = (
        shapeType: ShapeType,
        isDrawing: boolean,
        numberOfPoints?: number,
        rectDrawingMethod?: RectDrawingMethod,
        cuboidDrawingMethod?: CuboidDrawingMethod,
    ): void => {
        if (activeControl !== ActiveControl.CURSOR) {
            // 非空闲状态，先取消上一个的动作
            canvasInstance.cancel();
        }

        if (isDrawing) {
            return;
        }

        onDrawStart(shapeType, activeLabelID, paramsRef.current.objectType, numberOfPoints, rectDrawingMethod);
        paramsRef.current.enabled = true;
        paramsRef.current.rectDrawingMethod = rectDrawingMethod;
        paramsRef.current.cuboidDrawingMethod = cuboidDrawingMethod;
        paramsRef.current.numberOfPoints = numberOfPoints;
        paramsRef.current.shapeType = shapeType;
        paramsRef.current.crosshair = [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(shapeType);
        canvasInstance.draw({
            ...paramsRef.current,
        });
    };

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    // tag 事件
    const onSetup = (): void => {
        const { frame, jobInstance, onAnnotationCreate } = props;

        canvasInstance.cancel();

        const objectState = new cvat.classes.ObjectState({
            objectType: ObjectType.TAG,
            label: labels.filter((label: any) => label.id === activeLabelID)[0],
            frame,
        });

        onAnnotationCreate(jobInstance, frame, [objectState]);
    };

    let subKeyMap: any = {
        CANCEL: keyMap.CANCEL,
        CLOCKWISE_ROTATION: keyMap.CLOCKWISE_ROTATION,
        ANTICLOCKWISE_ROTATION: keyMap.ANTICLOCKWISE_ROTATION,
    };

    let handlers: any = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        },
        CLOCKWISE_ROTATION: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            rotateFrame(Rotation.CLOCKWISE90);
        },
        ANTICLOCKWISE_ROTATION: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            rotateFrame(Rotation.ANTICLOCKWISE90);
        },
    };

    const { states, activatedStateID } = useSelector((state: CombinedState) => state.annotation.annotations);
    const dispatch = useDispatch();

    // type === 1(垂直)，type === 2（水平）
    const click = (type: number): void => {
        const selectObj = states.filter((object: any) => object.clientID === activatedStateID);
        if (selectObj) {
            dispatch(createAndSaveAttachAnnotations(selectObj, type));
        }
    };

    if (labels.length) {
        handlers = {
            ...handlers,
            QUICKLABEL: (event: KeyboardEvent | undefined) => {
                // 快速标注，目前仅为快速进入矩形的标注模式
                preventDefault(event);
                const isDrawing = activeControl === ActiveControl.DRAW_RECTANGLE;

                onClick(ShapeType.RECTANGLE, isDrawing, 2, RectDrawingMethod.CLASSIC);
            },
            PASTE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                canvasInstance.cancel();
                pasteShape();
            },
            SWITCH_DRAW_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [
                    ActiveControl.DRAW_POINTS,
                    ActiveControl.DRAW_POLYGON,
                    ActiveControl.DRAW_POLYLINE,
                    ActiveControl.DRAW_RECTANGLE,
                    ActiveControl.DRAW_CUBOID,
                    ActiveControl.AI_TOOLS,
                    ActiveControl.OPENCV_TOOLS,
                ].includes(activeControl);

                if (!drawing) {
                    canvasInstance.cancel();
                    // repeateDrawShapes gets all the latest parameters
                    // and calls canvasInstance.draw() with them

                    if (event && event.shiftKey) {
                        redrawShape();
                    } else {
                        repeatDrawShape();
                    }
                } else {
                    if ([ActiveControl.AI_TOOLS, ActiveControl.OPENCV_TOOLS].includes(activeControl)) {
                        // separated API method
                        canvasInstance.interact({ enabled: false });
                        return;
                    }

                    canvasInstance.draw({ enabled: false });
                }
            },
            SWITCH_MERGE_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const merging = activeControl === ActiveControl.MERGE;
                if (!merging) {
                    canvasInstance.cancel();
                }
                canvasInstance.merge({ enabled: !merging });
                mergeObjects(!merging);
            },
            SWITCH_SPLIT_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const splitting = activeControl === ActiveControl.SPLIT;
                if (!splitting) {
                    canvasInstance.cancel();
                }
                canvasInstance.split({ enabled: !splitting });
                splitTrack(!splitting);
            },
            SWITCH_GROUP_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    canvasInstance.cancel();
                }
                canvasInstance.group({ enabled: !grouping });
                groupObjects(!grouping);
            },
            RESET_GROUP: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    return;
                }
                resetGroup();
                canvasInstance.group({ enabled: false });
                groupObjects(false);
            },
            CREATE_SUB_SPLIT_LINE_V: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                click(1);
            },
            CREATE_SUB_SPLIT_LINE_H: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                click(2);
            },
        };
        subKeyMap = {
            ...subKeyMap,
            QUICKLABEL: keyMap.QUICKLABEL,
            PASTE_SHAPE: keyMap.PASTE_SHAPE,
            SWITCH_DRAW_MODE: keyMap.SWITCH_DRAW_MODE,
            SWITCH_MERGE_MODE: keyMap.SWITCH_MERGE_MODE,
            SWITCH_SPLIT_MODE: keyMap.SWITCH_SPLIT_MODE,
            SWITCH_GROUP_MODE: keyMap.SWITCH_GROUP_MODE,
            RESET_GROUP: keyMap.RESET_GROUP,
            CREATE_SUB_SPLIT_LINE_V: keyMap.CREATE_SUB_SPLIT_LINE_V,
            CREATE_SUB_SPLIT_LINE_H: keyMap.CREATE_SUB_SPLIT_LINE_H,
        };
    }

    let min = 1;
    if (activeControl === ActiveControl.DRAW_POLYLINE || activeControl === ActiveControl.DRAW_POINTS) {
        min = 2;
    }
    if (activeControl === ActiveControl.DRAW_POLYGON) {
        min = 3;
    }

    const [cuboidDrawType, setCuboidDrawType] = useState(CuboidDrawingMethod.CORNER_POINTS);
    const cuboidTypeRef = useRef(cuboidDrawType);
    const options = [
        { label: t('workspace.side.controls.draw_cuboid_model_from_rectangle'), value: CuboidDrawingMethod.CLASSIC },
        { label: t('workspace.side.controls.draw_cuboid_model_4_points'), value: CuboidDrawingMethod.CORNER_POINTS },
    ];
    // CheckboxChangeEvent
    const switchChange = (e: CheckboxChangeEvent, checkType: BottomCheckType): void => {
        if (checkType === BottomCheckType.ConsecutiveFrames) {
            paramsRef.current.objectType = e.target.checked ? ObjectType.TRACK : ObjectType.SHAPE;
            setDrawTrack(e.target.checked);
        }
        if (checkType === BottomCheckType.ContinueLabel) {
            paramsRef.current.continueLabel = e.target.checked;
        }
        // 非空闲模式
        if (activeControl !== ActiveControl.CURSOR) {
            onClick(
                paramsRef.current.shapeType,
                false,
                paramsRef.current.numberOfPoints,
                paramsRef.current.rectDrawingMethod,
                paramsRef.current.cuboidDrawingMethod,
            );
        }
    };

    return (
        // <Layout.Sider className='cvat-canvas-controls-sidebar' theme='light' width={44}>
        <Layout.Sider
            className={`${drawTrack ? 'aatp_canvas_controls_sidebar_track ' : ''}cvat-canvas-controls-sidebar`}
            theme='light'
            width={90}
        >
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <ObservedCursorControl
                cursorShortkey={normalizedKeyMap.CANCEL}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />
            <ObservedMoveControl
                cursorShortkey={normalizedKeyMap.ENTER_MOVE}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />
            <ObservedRotateControl
                anticlockwiseShortcut={normalizedKeyMap.ANTICLOCKWISE_ROTATION}
                clockwiseShortcut={normalizedKeyMap.CLOCKWISE_ROTATION}
                rotateFrame={rotateFrame}
            />

            <Divider dashed style={{ borderColor: 'lightgray' }} />

            <ObservedFitControl canvasInstance={canvasInstance} />
            <ObservedResizeControl canvasInstance={canvasInstance} activeControl={activeControl} />

            <Divider dashed style={{ borderColor: 'lightgray' }} />

            {/* <ObservedToolsControl />
            <ObservedOpenCVControl /> */}
            <ObservedDrawRectangleControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_RECTANGLE}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.RECTANGLE,
                        activeControl === ActiveControl.DRAW_RECTANGLE,
                        2,
                        RectDrawingMethod.CLASSIC,
                    );
                }}
            />
            <ObservedDrawPolygonControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_POLYGON}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.POLYGON,
                        activeControl === ActiveControl.DRAW_POLYGON,
                        inputNumberRef && inputNumberRef.current?.value
                            ? Math.floor(clamp(+inputNumberRef.current?.value, min, Number.MAX_SAFE_INTEGER))
                            : undefined,
                    );
                }}
            />
            <ObservedDrawPolylineControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_POLYLINE}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.POLYLINE,
                        activeControl === ActiveControl.DRAW_POLYLINE,
                        inputNumberRef && inputNumberRef.current?.value
                            ? Math.floor(clamp(+inputNumberRef.current?.value, min, Number.MAX_SAFE_INTEGER))
                            : undefined,
                    );
                }}
            />
            <ObservedDrawPointsControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_POINTS}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.POINTS,
                        activeControl === ActiveControl.DRAW_POINTS,
                        inputNumberRef && inputNumberRef.current?.value
                            ? Math.floor(clamp(+inputNumberRef.current?.value, min, Number.MAX_SAFE_INTEGER))
                            : undefined,
                    );
                }}
            />
            <ObservedDrawEllipseControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_ELLIPSE}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.ELLIPSE,
                        activeControl === ActiveControl.DRAW_ELLIPSE,
                        inputNumberRef && inputNumberRef.current?.value
                            ? Math.floor(clamp(+inputNumberRef.current?.value, min, Number.MAX_SAFE_INTEGER))
                            : undefined,
                    );
                }}
            />
            <ObservedDrawCuboidControl
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_CUBOID}
                disabled={!labels.length}
                onClick={() => {
                    onClick(
                        ShapeType.CUBOID,
                        activeControl === ActiveControl.DRAW_CUBOID,
                        undefined,
                        undefined,
                        cuboidTypeRef.current,
                    );
                }}
            />
            {/* <ObservedSetupTagControl
                canvasInstance={canvasInstance}
                isDrawing={false}
                disabled={!labels.length}
                onClick={onSetup}
            /> */}

            <Divider dashed style={{ borderColor: 'lightgray' }} />

            <ObservedMergeControl
                switchMergeShortcut={normalizedKeyMap.SWITCH_MERGE_MODE}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                mergeObjects={mergeObjects}
                disabled={!labels.length}
            />
            <ObservedGroupControl
                switchGroupShortcut={normalizedKeyMap.SWITCH_GROUP_MODE}
                resetGroupShortcut={normalizedKeyMap.RESET_GROUP}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                groupObjects={groupObjects}
                disabled={!labels.length}
            />
            <ObservedSplitControl
                canvasInstance={canvasInstance}
                switchSplitShortcut={normalizedKeyMap.SWITCH_SPLIT_MODE}
                activeControl={activeControl}
                splitTrack={splitTrack}
                disabled={!labels.length}
            />

            {/* <ObservedContentRightTool disabled={!labels.length} /> */}
            {/* <div onClick={include} role='button'>
                <ForkOutlined className='anticon cvat-setup-tag-control' />
            </div> */}
            {/* <ObservedSplitRectangleControl
                canvasInstance={canvasInstance}
                disabled={!labels.length}
                onClick={click}
                shortcut1={normalizedKeyMap.CREATE_SUB_SPLIT_LINE_V}
                shortcut2={normalizedKeyMap.CREATE_SUB_SPLIT_LINE_H}
            /> */}

            <ExtraControlsControl />

            <div className='aatp_controls_side_bar_bottom'>
                {(activeControl === ActiveControl.DRAW_POINTS ||
                    activeControl === ActiveControl.DRAW_POLYGON ||
                    activeControl === ActiveControl.DRAW_POLYLINE) && (
                    <InputNumber
                        ref={inputNumberRef}
                        size='small'
                        step={1}
                        min={min}
                        placeholder={t('workspace.side.controls.max_points')}
                        onChange={(value) => {
                            onClick(
                                paramsRef.current.shapeType,
                                false,
                                Math.floor(clamp(+value, min, Number.MAX_SAFE_INTEGER)),
                                paramsRef.current.rectDrawingMethod,
                                paramsRef.current.cuboidDrawingMethod,
                            );
                        }}
                    />
                )}
                {activeControl === ActiveControl.DRAW_CUBOID && (
                    <Tooltip title={t('workspace.side.controls.draw_cuboid_model_tip')} placement='right'>
                        <Radio.Group
                            className='aatp_left_sidebar_bottom_radio'
                            size='small'
                            optionType='button'
                            buttonStyle='solid'
                            value={cuboidDrawType}
                            onChange={(e) => {
                                setCuboidDrawType(e.target.value);
                                // shapeType: ShapeType,
                                // isDrawing: boolean,
                                // numberOfPoints?: number,
                                // rectDrawingMethod?: RectDrawingMethod,
                                // cuboidDrawingMethod?: CuboidDrawingMethod,
                                onClick(
                                    paramsRef.current.shapeType,
                                    false,
                                    paramsRef.current.numberOfPoints,
                                    paramsRef.current.rectDrawingMethod,
                                    e.target.value,
                                );
                            }}
                        >
                            <Space direction='vertical'>
                                {options.map(
                                    (item): JSX.Element => (
                                        <Radio key={item.value} value={item.value}>
                                            {item.label}
                                        </Radio>
                                    ),
                                )}
                            </Space>
                        </Radio.Group>
                    </Tooltip>
                )}
                <Tooltip title={t('workspace.side.controls.draw_continue_tip')} placement='right'>
                    {/* <Switch
                        className='aatp_controls_side_bar_model'
                        checkedChildren='普通帧'
                        unCheckedChildren='连续帧'
                        onChange={switchChange}
                        defaultChecked={paramsRef.current.objectType === ObjectType.SHAPE}
                    /> */}
                    <div>
                        <Checkbox
                            defaultChecked={false}
                            onChange={(e: CheckboxChangeEvent) => {
                                // setContinueLabel(e.target.checked);
                                switchChange(e, BottomCheckType.ContinueLabel);
                            }}
                            // onChange={switchChange}
                        >
                            {t('workspace.side.controls.draw_continue')}
                        </Checkbox>
                    </div>
                </Tooltip>
                <Tooltip title={t('workspace.side.controls.track_model_tip')} placement='right'>
                    {/* <Switch
                        className='aatp_controls_side_bar_model'
                        checkedChildren='普通帧'
                        unCheckedChildren='连续帧'
                        onChange={switchChange}
                        defaultChecked={paramsRef.current.objectType === ObjectType.SHAPE}
                    /> */}
                    <div>
                        <Checkbox
                            defaultChecked={paramsRef.current.objectType === ObjectType.TRACK}
                            onChange={(e) => {
                                switchChange(e, BottomCheckType.ConsecutiveFrames);
                            }}
                        >
                            {t('workspace.side.controls.track_model')}
                        </Checkbox>
                    </div>
                </Tooltip>
            </div>
        </Layout.Sider>
    );
}
