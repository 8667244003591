/*
 * @Author: swxy
 * @Date: 2022-07-30 16:08:55
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { useEffect, useState } from 'react';
import { Button, Row, Space, Rate, Typography, Divider, Spin, Result, Popconfirm, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import { CombinedState, SubmitStatus } from 'reducers/interfaces';
import { acceptorAndMore, annotatorAndMore, reviewFristAndMore, reviewSecondAndMore } from 'utils/constant';
import { useHistory } from 'react-router';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { useTranslation } from 'react-i18next';

// 当前提交/审批的场景类型
enum Mode {
    annotation,
    first_review,
    second_review,
    acception,
    finish,
}

function getMode(jobStatus: SubmitStatus): Mode {
    if ([SubmitStatus.annotating, SubmitStatus.callback_frist].includes(jobStatus)) {
        return Mode.annotation;
    }
    if ([SubmitStatus.submit_frist, SubmitStatus.callback_second].includes(jobStatus)) {
        return Mode.first_review;
    }
    if ([SubmitStatus.callback_acceptance, SubmitStatus.submit_second].includes(jobStatus)) {
        return Mode.second_review;
    }
    if ([SubmitStatus.submit_acceptance, SubmitStatus.callback_frist].includes(jobStatus)) {
        return Mode.acception;
    }
    return Mode.finish;
}

interface Props {
    onCancel?: () => void;
    onSubmit?: () => void;
}

const { Text } = Typography;

export default ({ onCancel }: Props): JSX.Element => {
    const jobInstance = useSelector((state: CombinedState) => state.annotation.job.instance);
    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance) as Canvas3d;
    const user = useSelector((state: CombinedState) => state.auth.user);
    const [score, setScore] = useState<number>(3);
    const [loading, setLoading] = useState<boolean>(false);
    const [backReason, setBackReason] = useState<string>('');
    const [mode] = useState<Mode>(getMode(jobInstance.jobStatus));
    const { t } = useTranslation();

    const history = useHistory();

    useEffect(() => {
        if (jobInstance.score && jobInstance.score !== score) {
            setScore(jobInstance.score);
        }
    }, [jobInstance.score]);

    // useEffect(() => {
    //     if (loading) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 3000);
    //     }
    // }, [loading]);

    // 检查当前用户权限，是否能够审核
    const checkRole = (): boolean => {
        if (mode === Mode.annotation && annotatorAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.first_review && reviewFristAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.second_review && reviewSecondAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.acception && acceptorAndMore(user.roleType)) {
            return true;
        }
        return false;
    };

    if (!checkRole()) {
        return (
            <>
                <Row justify='center'>
                    <Result
                        status='403'
                        title={t('workspace.top.modal.submit_permission')}
                        subTitle={t('workspace.top.modal.submit_permission_sub')}
                    />
                </Row>
                <Divider />
                <Row justify='center'>
                    <Space>
                        <Button type='primary' onClick={onCancel}>
                            {t('workspace.top.modal.submit_permission_close')}
                        </Button>
                    </Space>
                </Row>
            </>
        );
    }

    const getFrameStateDatas = async (frame?: number, data?: any): Promise<any> => {
        const newData = data || [];
        const frameNumber = frame || jobInstance.startFrame;

        const states = await jobInstance.annotations.get(frameNumber, false, []);
        const frameData = await jobInstance.frames.get(frameNumber, false);
        const frameStateData = await canvasInstance.summarize(frameData, states);

        if (frameNumber < jobInstance.stopFrame) {
            return [...frameStateData, ...(await getFrameStateDatas(frameNumber + 1, newData))];
        }
        return frameStateData;
    };

    const save3DInfo = async (): Promise<void> => {
        let data = await getFrameStateDatas();

        // console.log('数据：', data);

        data = data.map((item: any) => ({
            ...item,
            frameType: jobInstance.engineTask?.engineProject?.parentFrameType || 0,
        }));
        // console.time('测试接口时间');

        const result = await jobInstance.reSaveData3D(data);

        // console.timeEnd('测试接口时间');
        if (!result) {
            throw new Error('重新统计框错误！');
        }
        // setLoading(false);

        // console.log('保存：', data);
    };

    const onPass = async (pass = true): Promise<void> => {
        // console.time('重新统计所有框数据总耗时');
        setLoading(true);

        try {
            // 二审且类型是3D
            if (mode === Mode.second_review && pass && jobInstance.dimension === '3d') {
                // 3D才需要
                await save3DInfo();
            }
            // console.timeEnd('重新统计所有框数据总耗时');
            // return;

            const data: any = {
                jobId: jobInstance.id,
                backReason,
                score: score * 20,
                isPass: pass,
            };

            // 标注不需要分数
            if (mode === Mode.annotation) {
                delete data.score;
                delete data.backReason;
            }

            // 通过不需要退回原因
            if (pass) {
                delete data.backReason;
            }

            const result = await jobInstance.submit(data);
            setLoading(false);
            // console.timeEnd('重新统计所有框数据总耗时');
            if (result?.success) {
                message.success(t('workspace.top.modal.submit_review_success'));
                history.replace(`/tasks/${jobInstance.taskId}`);
            }
        } catch (error: any) {
            message.error(error.message);
            setLoading(false);
        }
        // return result as unknown as boolean;
    };

    const onChangeBackReason = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setBackReason(e.target.value || '');
    };

    // 退回时，确认
    const onBack = async (): Promise<void> => {
        if (!backReason) {
            message.warn(t('workspace.top.modal.submit_review_back_reason_w'));
            return Promise.reject();
        }

        onPass(false);

        // if (result) {
        //     return Promise.resolve();
        // }
        return Promise.resolve();
    };

    // 没有权限的已被过滤，因此一下一定会有权限
    const getTip = (): string => {
        if (mode === Mode.annotation) {
            return 'workspace.top.modal.submit_status_submit';
        }
        if (mode === Mode.first_review) {
            return 'workspace.top.modal.submit_status_frist_review';
        }
        if (mode === Mode.second_review) {
            return 'workspace.top.modal.submit_status_second_review';
        }
        if (mode === Mode.acception) {
            return 'workspace.top.modal.submit_status_acceptance';
        }
        return 'workspace.top.modal.submit_status_review';
    };

    const render = (
        <Spin spinning={loading} tip={t('workspace.top.modal.submit_loading_tip')}>
            <Row justify='center'>
                <Space align='center' direction='vertical'>
                    {mode !== Mode.annotation && (
                        <Rate
                            allowHalf
                            allowClear={false}
                            onChange={setScore}
                            defaultValue={3}
                            tooltips={[
                                'workspace.top.modal.submit_sorce_very_bad',
                                'workspace.top.modal.submit_sorce_bad',
                                'workspace.top.modal.submit_sorce_authorized',
                                'workspace.top.modal.submit_sorce_good',
                                'workspace.top.modal.submit_sorce_very_good',
                            ]}
                        />
                    )}
                    <Text>
                        {t(getTip())}
                        {t('workspace.top.modal.submit_content')}
                    </Text>
                </Space>
            </Row>
            <Divider />
            <Row justify='center'>
                <Space>
                    {mode === Mode.annotation ? (
                        <Button type='primary' onClick={() => onPass()} loading={loading}>
                            {t('workspace.top.modal.submit_submit')}
                        </Button>
                    ) : (
                        <>
                            <Button type='primary' onClick={() => onPass()} loading={loading}>
                                {t('workspace.top.modal.submit_pass')}
                            </Button>
                            <Popconfirm
                                title={
                                    <Input.TextArea
                                        autoSize={{ minRows: 3 }}
                                        placeholder='请输入退回原因！'
                                        value={backReason}
                                        maxLength={2000}
                                        onChange={onChangeBackReason}
                                    />
                                }
                                onCancel={() => {
                                    // console.log('清空了');
                                    setBackReason('');
                                }}
                                onConfirm={onBack}
                                destroyTooltipOnHide
                            >
                                <Button danger loading={loading}>
                                    {t('workspace.top.modal.submit_back')}
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                    <Button onClick={onCancel}>{t('workspace.top.modal.submit_close')}</Button>
                </Space>
            </Row>
        </Spin>
    );

    return <>{render}</>;
};
