/*
 * @Author: swxy
 * @Date: 2022-07-08 18:32:03
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC } from 'react';
import Text from 'antd/lib/typography/Text';
import { ImageLabelAttribute, InputType } from 'reducers/interfaces';
import { InputNumber, Form, Input, Checkbox, Select, Radio, FormItemProps } from 'antd';

interface Props {
    name?: boolean;
    attribute: ImageLabelAttribute;
}

const LabelControlTypeComponent: FC<Props> = ({ attribute, name }): JSX.Element => {
    let component = <></>;
    const formItemProps: FormItemProps = { name: `${attribute.id}` };
    if (attribute.inputType === InputType.serialid) {
        component = <Text>{attribute.value}</Text>;
    } else if (attribute.inputType === InputType.select) {
        component = <Select options={attribute?.values?.map((value) => ({ label: value, value }))} />;
    } else if (attribute.inputType === InputType.radio) {
        component = <Radio.Group options={attribute?.values?.map((value) => ({ label: value, value }))} />;
    } else if (attribute.inputType === InputType.checkbox) {
        formItemProps.valuePropName = 'checked';
        component = <Checkbox />;
    } else if (attribute.inputType === InputType.number) {
        const { values } = attribute;
        // formItemProps.trigger = 'onBlur';
        component = (
            <Form.Item {...formItemProps} noStyle>
                <InputNumber
                    min={Number(values?.[0] || 0)}
                    max={Number(values?.[1] || 0)}
                    step={Number(values?.[2] || 0)}
                />
            </Form.Item>
        );
        delete formItemProps.name;
    } else if (attribute.inputType === InputType.text) {
        // formItemProps.trigger = 'onBlur';
        // formItemProps.validateTrigger = 'onBlur';
        // formItemProps.name = undefined;

        component = <Input />;
    } else {
        return component;
    }
    return (
        <Form.Item
            label={name ? attribute.name : undefined}
            // name={`${attribute.inputType}-${attribute.id}`}
            {...formItemProps}
        >
            {component}
        </Form.Item>
    );
};

export default React.memo(
    LabelControlTypeComponent,
    (prevProp, nextProp) => prevProp.name === nextProp.name && prevProp.attribute.value === nextProp.attribute.value,
);
