/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-05-23 15:49:05
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState, useEffect } from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'containers/annotation-page/canvas/canvas-wrapper3D';
import ControlsSideBarContainer from 'containers/annotation-page/review3D-workspace/controls-side-bar/controls-side-bar';
import ObjectSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
import IssueAggregatorComponent from 'components/annotation-page/review3D/issues-aggregator';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { disableEditing3D } from 'utils/constant';

export default function ReviewWorkspaceComponent(props: any): JSX.Element {
    const { sidebarPositionLeft } = props;
    const { roleType, jobStatus } = useSelector((state:CombinedState) => ({
        roleType: state.auth.user?.roleType,
        jobStatus: state.annotation.job.instance?.jobStatus,
    }));
    const [disableEdit, setDisableEdit] = useState(true);

    useEffect(() => {
        setDisableEdit(disableEditing3D(jobStatus, roleType));
    }, [jobStatus, roleType]);
    return (
        <Layout hasSider className='cvat-review-workspace'>
            <ControlsSideBarContainer />
            <CanvasWrapperContainer disableEdit={disableEdit} />
            {/* <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} /> */}
            {sidebarPositionLeft &&
                <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} /> }
            <CanvasContextMenuContainer readonly />
            {!sidebarPositionLeft &&
                <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} />}
            <IssueAggregatorComponent />
        </Layout>
    );
}
