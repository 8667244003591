// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

// const User = require('./user');
const { ArgumentError } = require('./exceptions');

/**
 * Class representing a single IssueOS
 * @memberof module:API.cvat.classes
 * @hideconstructor
 */
// 全局批注，没有修改它的功能。
class IssueOS {
    constructor(initialData) {
    // addTime: "2022-05-20 17:59:04"
    // content: "类型1"
    // id: 4
    // projectId: 3
    // updateTime: "2022-05-20 17:59:04"
        const data = {
            id: undefined,
            projectId: undefined,
            content: undefined,
            addTime: undefined,
            updateTime: undefined,
            // owner: undefined,
        };

        for (const property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property) && property in initialData) {
                data[property] = initialData[property];
            }
        }

        // if (data.owner && !(data.owner instanceof User)) data.owner = new User(data.owner);
        if (typeof data.addTime === 'undefined') {
            data.addTime = new Date().toISOString();
        }

        Object.defineProperties(
            this,
            Object.freeze({
                /**
                 * @name id
                 * @type {integer}
                 * @memberof module:API.cvat.classes.IssueOS
                 * @readonly
                 * @instance
                 */
                id: {
                    get: () => data.id,
                },
                /**
                 * @name projectId
                 * @type {integer}
                 * @memberof module:API.cvat.classes.IssueOS
                 * @readonly
                 * @instance
                 */
                projectId: {
                    get: () => data.projectId,
                },
                /**
                 * @name content
                 * @type {string}
                 * @memberof module:API.cvat.classes.IssueOS
                 * @instance
                 * @throws {module:API.cvat.exceptions.ArgumentError}
                 */
                content: {
                    get: () => data.content,
                    set: (value) => {
                        if (!value.trim().length) {
                            throw new ArgumentError('Value must not be empty');
                        }
                        data.content = value;
                    },
                },
                /**
                 * @name addTime
                 * @type {string}
                 * @memberof module:API.cvat.classes.IssueOS
                 * @readonly
                 * @instance
                 */
                addTime: {
                    get: () => data.addTime,
                },
                /**
                 * @name updateTime
                 * @type {string}
                 * @memberof module:API.cvat.classes.IssueOS
                 * @readonly
                 * @instance
                 */
                updateTime: {
                    get: () => data.updateTime,
                },
                // /**
                //  * Instance of a user who has created the IssueOS
                //  * @name owner
                //  * @type {module:API.cvat.classes.User}
                //  * @memberof module:API.cvat.classes.IssueOS
                //  * @readonly
                //  * @instance
                //  */
                // owner: {
                //     get: () => data.owner,
                // },
                __internal: {
                    get: () => data,
                },
            }),
        );
    }

    serialize() {
        const data = {
            content: this.content,
        };

        // if (typeof this.id === 'number') {
        //     data.id = this.id;
        // }
        // if (this.addTime) {
        //     data.addTime = this.addTime;
        // }
        // if (this.updateTime) {
        //     data.updated_date = this.updateTime;
        // }
        // if (this.owner) {
        //     data.owner_id = this.owner.serialize().id;
        // }

        return data;
    }
}

module.exports = IssueOS;
