/*
 * @Author: swxy
 * @Date: 2022-08-11 10:37:03
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC, memo, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { noImageBase64, noImageFile } from './imageUtils';
import ImageGroup, { context } from './imageGroup';
import { dataBase64ToBlob } from 'utils/utils';
import { Space, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Preview from './preview';
import ImageCanvas from './imageCanvas';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { Canvas3d, ViewsDOM, ProjectionInfo, Projection } from 'cvat-canvas3d-wrapper';
import '../styles.scss';

interface CompoundedComponent<P> extends React.FC<P> {
    ImageGroup: typeof ImageGroup;
}

type ImageStatus = 'normal' | 'error' | 'loading';

interface Props {
    src?: string;
    name?: string;
    mask?: ReactNode;
    preview?: boolean; // 是否可以预览 默认可以
    loading?: boolean;
}

// 是什么值并不重要，唯一才重要
let uuid = 0;

const Image: CompoundedComponent<Props> = ({ src, mask = true, preview = true, loading = false }) => {
    // const [image, setImage] = useState<ImageBitmap>();
    // const canvasRef = useRef<HTMLCanvasElement>(null);
    const url = src || noImageBase64;
    // const [status, setStatus] = useState<ImageStatus>('loading');
    const { isImageGroup, preview: groupPreview, setCurrent, registerImage } = useContext(context);

    const [projectInfo, setProjectInfo] = useState<ProjectionInfo>();

    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance) as Canvas3d;
    // const isError = status === 'error';

    const [currentId] = React.useState(() => {
        uuid += 1;
        return uuid;
    });

    const canPreview = isImageGroup ? groupPreview && preview : preview;

    // 设置图片
    // const imageSet = async (originImage: string | Blob | undefined) => {
    //     // setStatus('loading');

    //     let showImage: Blob | ImageBitmap = noImageFile;
    //     if (originImage instanceof Blob) {
    //         // base64, 换成blob
    //         showImage = originImage;
    //     } else if (typeof originImage === 'string' && originImage.indexOf('base64') === 0) {
    //         // base64, 换成blob
    //         showImage = dataBase64ToBlob(originImage);
    //     } else if (typeof originImage === 'string') {
    //         // url
    //         showImage = await (await fetch(originImage)).blob();
    //     }

    //     if (self.createImageBitmap) {
    //         showImage = await self.createImageBitmap(showImage);
    //     }

    //     setStatus('normal');
    //     setImage(showImage as ImageBitmap);
    //     registerImage(currentId, showImage as ImageBitmap, canPreview);
    // };

    const onProjection = (event: any) => {
        const { projectInfo: proInfo }: { projectInfo: ProjectionInfo; actID: number } = event.detail;

        setProjectInfo({ ...proInfo });
    };

    // 生命周期，src变更时
    useEffect(() => {
        if (src) {
            registerImage(currentId, url, canPreview);
            // try {
            //     imageSet(src);
            // } catch (error) {
            //     setStatus('error');
            // }
        }
    }, [src]);

    useEffect(() => {
        const canvasInstanceDOM = canvasInstance.html() as ViewsDOM;
        canvasInstanceDOM.perspective.addEventListener('canvas.projection', onProjection);
        const unRegister = registerImage(currentId, url);
        return () => {
            unRegister();
            // console.log('取消');

            canvasInstanceDOM.perspective.removeEventListener('canvas.projection', onProjection);
        };
    }, []);

    if (!src) {
        return <img src={noImageBase64} />;
    }

    const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        // 开始展示预览图
        if (isImageGroup) {
            setCurrent(currentId);
        }
    };

    const maskClassName = `aatp_image_mask${canPreview ? ' aatp_image_mask_preview' : ''}`;

    return (
        <div className='aatp_image' onClick={onClick}>
            <Spin spinning={loading} tip={'图片读取...'} style={{ position: 'relative', lineHeight: '0' }}>
                {/* <canvas
                    ref={canvasRef}
                    width={image?.width}
                    height={image?.height}
                    style={{ width: '100%', height: '100%' }}
                /> */}
                <ImageCanvas className='aatp_image_scale' url={url} projectInfo={projectInfo} />{' '}
                {mask && canPreview && status === 'normal' && (
                    <div className={maskClassName}>
                        {mask && mask === true ? (
                            <Space>
                                <EyeOutlined />
                                预览
                            </Space>
                        ) : (
                            mask
                        )}
                    </div>
                )}
            </Spin>
            {!isImageGroup && canPreview && <Preview />}
        </div>
    );
};

Image.ImageGroup = ImageGroup;

export default memo(Image);
