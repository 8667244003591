/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    language: '中文',
    setting: '设置',
    logout: '登出',
    loginfo_timeout: '登录信息已过期，请重新登录！',

    // logout: '登出',
    // logout: '登出',
};
