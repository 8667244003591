/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'project.projcet': 'Projects',
    'project.no_task': 'No task',
    'project.back_project_list': 'Back to projects',
    'project.task': 'Tasks',
    'project.last_update_time': 'Last updated',
    'project.create_by': 'Create by',
    'project.pending': 'Pending',
    'project.in_progress': 'In Progress',
    'project.completed': 'Completed',
    'project.open': 'Open',
    'project.back_project': 'Back to project',
    'project.back_task': 'Back to tasks',

    'project.job': 'Job',
    'project.jobs': 'Job list',
    'project.frames': 'Frames',
    'project.job_status': 'Job Status',
    'project.start_date': 'Started on',
    'project.duration': 'Duration',
    'project.copy': 'Copy',
    'project.copy_success': 'Copied to clipboard!',

    'project.overlap_size': 'Overlap',
    'project.segment_size': 'Segment size',
    'project.image_quality': 'Image quality',
};
