/*
 * @Author: swxy
 * @Date: 2022-07-08 15:41:32
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import './styles.scss';
import React, {
    Dispatch,
    useEffect,
    useState,
    // TransitionEvent
} from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Collapse, Form } from 'antd';
import Layout from 'antd/lib/layout';

import { Canvas } from 'cvat-canvas-wrapper';
import { CombinedState, ImageLabel, Label, ObjectState } from 'reducers/interfaces';
// import { adjustContextImagePosition } from
// 'components/annotation-page/standard-workspace/context-image/context-image';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import LabelControlTypeComponent from './labelControlTypeComponent';

const { Panel } = Collapse;

interface StateToProps {
    sidebarCollapsed: boolean;
    sidebarPositionLeft?: boolean;
    canvasInstance: Canvas;
    jobInstance: any;

    labels: Label[];
    states: ObjectState[];
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance, labels },
            annotations: { states },
        },
    } = state;

    return {
        sidebarCollapsed,
        canvasInstance: canvasInstance as Canvas,
        jobInstance,

        labels,
        states,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ControlsSideBar(props: StateToProps & DispatchToProps): JSX.Element {
    const {
        sidebarCollapsed,
        // canvasInstance, collapseSidebar,
        // jobInstance,
        labels,

        states,
    } = props;

    const [imageLabels] = useState<ImageLabel[]>(labels);

    const getImageLabelValue = (): void => {
        // console.log('更新后的states:', states);
    };

    useEffect(() => {
        getImageLabelValue();
    }, [states]);

    // console.log('states:', states);

    // const collapse = (): void => {
    //     const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
    //     const listener = (event: TransitionEvent): void => {
    //         if (event.target && event.propertyName === 'width' && event.target === collapser) {
    //             canvasInstance.fitCanvas();
    //             canvasInstance.fit();
    //             (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
    //         }
    //     };

    //     if (collapser) {
    //         (collapser as HTMLElement).addEventListener('transitionend', listener as any);
    //     }

    //     adjustContextImagePosition(!sidebarCollapsed);
    //     collapseSidebar();
    // };

    // const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIM_2D : true;

    const attributeChange = (changedValues: any, values: any) => {
        // console.log('更改了：', changedValues);
        // console.log('所有：', values);
    };

    return (
        <Layout.Sider
            className='aatp-controls-side-bar'
            theme='dark'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            <Form onValuesChange={attributeChange} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <Collapse
                    defaultActiveKey={imageLabels?.map((label) => label.id)}
                    // onChange={onChange}
                    // expandIconPosition={expandIconPosition}
                >
                    {imageLabels?.map((label) => (
                        <Panel header={label.name} key={label.id}>
                            {label.attributes?.map((attribute) => (
                                <>
                                    <LabelControlTypeComponent
                                        key={attribute.id}
                                        // labelID={label.id}
                                        attribute={attribute}
                                    />
                                    {/* <Divider /> */}
                                </>
                                // <div key={attribute.id}>
                                //     {attribute.name}
                                //     --
                                //     {attribute.value}
                                // </div>
                            ))}
                        </Panel>
                    ))}
                </Collapse>
            </Form>
            {/* 这是列表 */}
            {/* <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span> */}
            {/* <Tabs type='card' tabPosition='bottom' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>对象</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>标签</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>审批</Text>} key='issues'>
                    <IssuesListComponent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>外观</Text>} key='Appearance'>
                    <AppearanceBlock />
                </Tabs.TabPane>
            </Tabs> */}
            {/* {!sidebarCollapsed && <AppearanceBlock />} */}
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ControlsSideBar));
