// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

(() => {
    const serverProxy = require('./server-proxy');
    const { Task } = require('./session');
    const { ScriptingError, ServerError } = require('./exceptions');

    class AnnotationsSaver {
        constructor(version, collection, session) {
            this.sessionType = session instanceof Task ? 'task' : 'job';
            this.id = session.id;
            this.version = version;
            this.collection = collection;
            this.initialObjects = {};
            // this.addClientId = { shapes: [], tracks: [], tags: [] };
            this.hash = this._getHash();

            // 等待保存promise， 保存池
            this.waitPromises = [];
            this.saving = false;

            // We need use data from export instead of initialData
            // Otherwise we have differ keys order and JSON comparison code incorrect
            const exported = this.collection.export();

            this._resetState();
            for (const shape of exported.shapes) {
                this.initialObjects.shapes[shape.id] = shape;
            }

            for (const track of exported.tracks) {
                this.initialObjects.tracks[track.id] = track;
            }

            for (const tag of exported.tags) {
                this.initialObjects.tags[tag.id] = tag;
            }
        }

        _resetState() {
            this.initialObjects = {
                shapes: {},
                tracks: {},
                tags: {},
            };
        }

        _getHash() {
            const exported = this.collection.export();
            return JSON.stringify(exported);
        }

        async _request(data, action) {
            const result = await serverProxy.annotations.updateAnnotations(this.sessionType, this.id, data, action);

            return result;
        }

        async _put(data) {
            const result = await this._request(data, 'put');
            return result;
        }

        async _create(created) {
            const result = await this._request(created, 'create');
            return result;
        }

        async _updateShape(updated) {
            const result = await this._request(updated, 'shape');
            return result;
        }

        async _updateTrack(updated) {
            const result = await this._request(updated, 'track');
            return result;
        }

        async _delete(deleted) {
            const result = await this._request(deleted, 'delete');
            return result;
        }

        _split(exported) {
            const splitted = {
                created: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
                updated: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
                deleted: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
            };

            const keys = [
                'id',
                'label_id',
                'group',
                'frame',
                'occluded',
                'z_order',
                'points',
                'rotation',
                'type',
                'shapes',
                'attributes',
                'value',
                'specId',
                'source',
                'outside',
            ];

            // Find created and updated objects
            for (const type of Object.keys(exported)) {
                for (const object of exported[type]) {
                    if (object.id in this.initialObjects[type]) {
                        const exportedHash = JSON.stringify(object, keys);
                        const initialHash = JSON.stringify(this.initialObjects[type][object.id], keys);
                        if (exportedHash !== initialHash) {
                            splitted.updated[type].push(object);
                        }
                    } else if (typeof object.id === 'undefined') {
                        splitted.created[type].push(object);
                    } else {
                        throw new ScriptingError(
                            `Id of object is defined "${object.id}" but it absents in initial state`,
                        );
                    }
                }
            }

            // Now find deleted objects
            const indexes = {
                shapes: exported.shapes.map((object) => +object.id),
                tracks: exported.tracks.map((object) => +object.id),
                tags: exported.tags.map((object) => +object.id),
            };

            for (const type of Object.keys(this.initialObjects)) {
                for (const id of Object.keys(this.initialObjects[type])) {
                    if (!indexes[type].includes(+id)) {
                        const object = this.initialObjects[type][id];
                        splitted.deleted[type].push(object);
                    }
                }
            }

            return splitted;
        }

        // _updateCreatedObjects(saved, indexes) {
        //     const savedLength = saved.tracks.length + saved.shapes.length + saved.tags.length;

        //     const indexesLength = indexes.tracks.length + indexes.shapes.length + indexes.tags.length;

        //     if (indexesLength !== savedLength) {
        //         throw new ScriptingError(
        //             `Number of indexes is differed by number of saved objects ${indexesLength} vs ${savedLength}`,
        //         );
        //     }

        //     // Updated IDs of created objects
        //     for (const type of Object.keys(indexes)) {
        //         for (let i = 0; i < indexes[type].length; i++) {
        //             const clientID = indexes[type][i];
        //             this.collection.objects[clientID].serverID = saved[type][i].id;
        //         }
        //     }
        // }

        _updateCreatedObjects(beforSaved, saved) {
            const savedLength = saved.tracks.length + saved.shapes.length + saved.tags.length;

            const beforSavedLength = beforSaved.tracks.merges.length +
                beforSaved.shapes.merges.length +
                beforSaved.tags.merges.length;

            if (beforSavedLength !== savedLength) {
                throw new ScriptingError(
                    `保存前和保存后的数量不一致，保存前${beforSavedLength}, 保存后:${savedLength}`,
                );
            }

            // 更新
            for (const type of Object.keys(saved)) {
                for (let i = 0; i < saved[type].length; i++) {
                    const savedObject = saved[type][i];
                    const beforSavedObject = beforSaved[type].merges[i];
                    this.collection.objects[beforSavedObject.clientID].serverID = savedObject.id;
                    this.collection.objects[beforSavedObject.clientID].attributesID =
                        savedObject.attributes.reduce((attributeAccumulator, attr) => {
                            attributeAccumulator[attr.specId] = attr;
                            return attributeAccumulator;
                        }, {});
                    if (type === 'tracks') {
                        // 连续帧， 更新shapes里的id和shapes里的属性id

                        if (this.collection.objects[beforSavedObject.clientID].shapes) {
                            Object.entries(this.collection.objects[beforSavedObject.clientID].shapes)
                                .forEach(([key], index) => {
                                    // shape.id = savedObject.shapes[index].id;

                                    this.collection.objects[beforSavedObject.clientID]
                                        .shapes[+key].id = savedObject.shapes[index].id;
                                    this.collection.objects[beforSavedObject.clientID]
                                        .shapes[+key].serverID = savedObject.shapes[index].id;

                                    this.collection.objects[beforSavedObject.clientID]
                                        .attributesID.shapes = this.collection.objects[beforSavedObject.clientID]
                                            .attributesID.shapes || {};
                                    savedObject.shapes[index].attributes.forEach((attr) => {
                                        this.collection.objects[beforSavedObject.clientID]
                                            .attributesID.shapes[+key] = attr;
                                    });
                                });
                        }
                    }
                }
            }
        }

        _receiveIndexes(exported) {
            // Receive client indexes before saving
            const indexes = {
                tracks: exported.tracks.map((track) => track.clientID),
                shapes: exported.shapes.map((shape) => shape.clientID),
                tags: exported.tags.map((tag) => tag.clientID),
            };

            // Remove them from the request body
            exported.tracks
                .concat(exported.shapes)
                .concat(exported.tags)
                .map((value) => {
                    delete value.clientID;
                    return value;
                });

            return indexes;
        }

        setAttId(object) {
            if (object.attributes && object.attributes.length) {
                object.attributes.forEach((_att) => {
                    const attribute = this.collection.objects[object.clientID].attributesID[+_att.specId];
                    if (attribute && attribute.id) {
                        _att.id = attribute.id;
                    }
                });
            }
        }

        transAttributes(attributes, clientID, type, frame) {
            if (Array.isArray(attributes)) {
                if (attributes && attributes.length) {
                    for (const attribute of attributes) {
                        this.transAttributes(attribute, clientID, type, frame);
                    }
                }
                return;
            }

            attributes.specId = attributes.specId;

            let attribute;
            if (this.collection.objects[clientID].attributesID) {
                attribute = this.collection.objects[clientID].attributesID[+attributes.specId] || {};
                if (type === 'trackshape') {
                    if (this.collection.objects[clientID].attributesID.shapes) {
                        attribute = this.collection.objects[clientID].attributesID.shapes[frame] || {};
                    }
                }
            }
            if (!attribute.id) {
                // eslint-disable-next-line no-underscore-dangle
                attributes._insert = true;
            } else {
                attributes.id = attribute.id;
            }
            if (attribute && attribute.shapeId) {
                attributes.shapeId = attribute.shapeId;
            }
            delete attributes.specId;
        }

        transShape(shape, action, isTrack, trackId, clientID) {
            if (Array.isArray(shape)) {
                if (shape && shape.length) {
                    for (const object of shape) {
                        this.transShape(object);
                    }
                }
                return;
            }
            if (typeof shape.id === 'undefined') {
                // eslint-disable-next-line no-underscore-dangle
                shape._insert = true;
            }
            // if (!isTrack && action !== 'delete') {
            //     // 新增和更新会记录clientId，以替换serverId
            //     const { shapes } = this.addClientId;
            //     shapes.push(shape.clientID);
            //     this.addClientId.shapes = shapes;
            // }
            if (!isTrack) {
                shape.labelId = shape.label_id;
            }
            shape.zOrder = shape.z_order;
            shape.specId = shape.specId;
            shape.jobId = this.id;
            if (shape.attributes) {
                this.transAttributes(shape.attributes, shape.clientID || clientID, isTrack ? 'trackshape' : undefined, shape.frame);
            }
            if (trackId) {
                shape.trackId = trackId;
            }
            if (shape.attributes) {
                shape.engineLabeledshapeattributevals = shape.attributes;
            }

            if (isTrack && shape.attributes) {
                delete shape.engineLabeledshapeattributevals;
                shape.engineTrackedshapeattributevals = shape.attributes;
            }

            if (shape.points) {
                shape.points = JSON.stringify(shape.points);
            }

            if (!shape.specId) {
                delete shape.specId;
            }

            delete shape.label_id;
            delete shape.z_order;
            delete shape.attributes;
            delete shape.specId;
            // delete shape.clientID;

            if (shape.shapes && shape.shapes.length) {
                for (const object of shape.shapes) {
                    this.transShape(object);
                }
            }
        }

        transTrack(track) {
            if (Array.isArray(track)) {
                if (track && track.length) {
                    for (const object of track) {
                        this.transTrack(object);
                    }
                }
                return;
            }
            if (typeof track.id === 'undefined') {
                // eslint-disable-next-line no-underscore-dangle
                track._insert = true;
            }
            // if (action !== 'delete') {
            //     const { tracks } = this.addClientId;
            //     tracks.push(track.clientID);
            //     this.addClientId.tracks = tracks;
            // }
            track.labelId = track.label_id;
            track.jobId = this.id;
            // track.zOrder = track.z_order;
            if (track.attributes) {
                this.transAttributes(track.attributes, track.clientID);
            }
            if (track.attributes) {
                track.engineLabeledtrackattributevals = track.attributes;
            }
            if (track.shapes) {
                if (track.shapes && track.shapes.length) {
                    for (const object of track.shapes) {
                        this.transShape(object, 'update', true, track.id, track.clientID);
                    }
                }
                track.engineTrackedshapes = track.shapes;
            }
            // track.specId = track.specId;
            if (track.points) {
                track.points = JSON.stringify(track.points);
            }
            delete track.label_id;
            delete track.z_order;
            delete track.attributes;
            delete track.specId;
            delete track.shapes;
            // delete track.clientID;
        }

        toAttributes(attributes) {
            if (Array.isArray(attributes)) {
                if (attributes && attributes.length) {
                    for (const attribute of attributes) {
                        this.toAttributes(attribute);
                    }
                    return;
                }
            }
            attributes.specId = attributes.specId;
            delete attributes.id;
            delete attributes.insert;
            // delete attributes.shapeId;
        }

        toShape(shape) {
            if (Array.isArray(shape)) {
                if (shape && shape.length) {
                    for (const object of shape) {
                        this.toShape(object);
                    }
                }
                return;
            }

            if (shape.attributes) {
                this.toAttributes(shape.attributes);
            }
            // shape.label_id = shape.labelId;
            // shape.z_order = shape.zOrder || 0;
            // shape.specId = shape.specId;

            // if (shape.engineLabeledshapeattributevals) {
            //     shape.attributes = shape.engineLabeledshapeattributevals;
            // }

            // if (trackId && shape.engineTrackedshapeattributevals) {
            //     shape.attributes = shape.engineTrackedshapeattributevals;
            // }

            // if (shape.points) {
            //     shape.points = JSON.parse(shape.points);
            // }

            // if (shape.specId === undefined) {
            //     delete shape.specId;
            // }

            // delete shape.labelId;
            // delete shape.zOrder;
            // delete shape.engineLabeledshapeattributevals;
            // delete shape.engineTrackedshapeattributevals;
            // delete shape.specId;

            if (shape.shapes && shape.shapes.length) {
                this.toShape(shape.shapes);
            }
        }

        toTrack(track) {
            if (Array.isArray(track)) {
                if (track && track.length) {
                    for (const object of track) {
                        this.toTrack(object);
                    }
                }
                return;
            }
            track.label_id = track.labelId;
            track.z_order = track.zOrder;
            track.specId = track.specId;

            if (track.engineLabeledtrackattributevals) {
                track.attributes = track.engineLabeledtrackattributevals;
            }

            if (track.engineTrackedshapes) {
                track.shapes = track.engineTrackedshapes;
            }

            if (track.shapes && track.shapes.length) {
                this.toShape(track.shapes, track.id);
            }

            if (track.points) {
                track.points = JSON.parse(track.points);
            }

            delete track.labelId;
            delete track.zOrder;
            delete track.engineLabeledshapeattributevals;
            delete track.engineLabeledtrackattributevals;
            delete track.engineTrackedshapes;
            delete track.specId;
        }

        to(object, type) {
            if (type === 'tracks') {
                this.toTrack(object);
            } else if (type === 'shapes') {
                this.toShape(object);
            }
        }

        trans(object, type, action) {
            if (type === 'tracks') {
                this.transTrack(object, action);
            } else if (type === 'shapes') {
                this.transShape(object, action, false);
            }
        }

        // convertAnnotation(object, type) {
        //     // if (type === 'track') {

        //     // }

        //     // if (type === 'shape') {

        //     // }
        // }

        getAttr(attributes) {
            if (Array.isArray(attributes)) {
                return attributes.reduce((previous, attr) => {
                    previous.push(this.getAttr(attr));
                    return previous;
                }, []);
            }

            const copyAttr = { ...attributes };
            delete copyAttr.id;
            delete copyAttr.trackId;
            return copyAttr;
        }

        getHashJSON(object) {
            if (Array.isArray(object)) {
                return object.reduce((previous, shape) => {
                    previous.push(this.getHashJSON(shape));
                    return previous;
                }, []);
            }

            let shapes;
            if (object.shapes) {
                shapes = this.getHashJSON(object.shapes);
            }
            const copyObject = {
                ...object,
                attributes: this.getAttr(object.attributes),
                shapes,
            };
            if (!shapes) {
                delete copyObject.shapes;
            }
            return copyObject;
        }

        _tParams(exported) {
            // console.log('exported:', exported);
            const splitted = {
                created: {
                    shapes: [],
                    tracks: [],
                    // tags: [],
                },
                updated: {
                    shapes: [],
                    tracks: [],
                    // tags: [],
                },
                deleted: {
                    shapes: [],
                    tracks: [],
                    // tags: [],
                },
            };

            // const changeKey = {
            //     id: 'id',
            //     label_id: 'labelId',
            //     group: 'group',
            //     frame: 'frame',
            //     occluded: 'occluded',
            //     z_order: 'zOrder',
            //     points: 'points',
            //     rotation: 'rotation',
            //     type: 'type',
            //     shapes: 'shapes',
            //     attributes: 'engineLabeledshapeattributevals',
            //     value: 'value',
            //     specId: 'specId',
            //     source: 'source',
            //     outside: 'outside',
            // };

            const keys = [
                'id',
                'labelId',
                'group',
                'frame',
                'occluded',
                'zOrder',
                'points',
                'rotation',
                'type',
                'shapes',
                'engineTrackedshapes',
                'value',
                'specId',
                'source',
                'outside',
                'engineLabeledtrackattributevals',
                'engineLabeledshapeattributevals',
                'engineTrackedshapeattributevals',
            ];

            // Find created and updated objects
            for (const type of Object.keys(exported)) {
                for (const object of exported[type]) {
                    if (object.id in this.initialObjects[type]) {
                        const exportedHash = JSON.stringify(object, keys);
                        const initialHash = JSON.stringify(this.initialObjects[type][object.id], keys);
                        if (exportedHash !== initialHash) {
                            splitted.updated[type].push(object);
                        }
                    } else if (typeof object.id === 'undefined') {
                        splitted.created[type].push(object);
                    } else {
                        throw new ScriptingError(
                            `对象的 ID “${object.id}”，在初始状态下不存在`,
                        );
                    }
                }
            }

            // Now find deleted objects
            const indexes = {
                shapes: exported.shapes.map((object) => +object.id),
                tracks: exported.tracks.map((object) => +object.id),
                // tags: exported.tags.map((object) => +object.id),
            };

            // const shapeObj = exported.shapes.reduce((previous, current) => {
            //     previous[current.clientID] = current;
            //     return previous;
            // }, {});
            const trackObj = exported.tracks.reduce((previous, current) => {
                previous[current.clientID] = current;
                return previous;
            }, {});

            for (const type of Object.keys(this.initialObjects)) {
                for (const id of Object.keys(this.initialObjects[type])) {
                    const object = this.initialObjects[type][id];
                    if (!indexes[type].includes(+id)) {
                        // 找到初始里有，但当前导出的对象里没有的id，当前对象需要删除
                        splitted.deleted[type].push(object);
                    } else if (type === 'shapes') {
                        // 当id包含时，找到需要删除的属性，及多余的属性删除。
                        // const shape = shapeObj[object.clientID];
                        // 外层对象不需要删除，属性删除，只会有一种情况，切换标签。
                        // 当init的labelId 与现有labelid不同时，删除当前的所有属性。

                        // 一般这么判断是可以的，但是有脏数据，需要进一步清理
                        // if (shape.labelId !== object.labelId && object.engineLabeledshapeattributevals.length) {
                        //     splitted.deleted[type].push({
                        //         engineLabeledshapeattributevals: object.engineLabeledshapeattributevals,
                        //     });
                        // }

                        // 进一步，对比collection里目前objectState里的label，如果label的属性里没有的值，会被清除掉。
                        // 也可以防止更改项目标签的属性
                        const objectState = this.collection.objects[object.clientID];
                        const attributeids = objectState.label.attributes.map((att) => att.id);
                        const attributes = object.engineLabeledshapeattributevals.reduce((previous, current) => {
                            if (!attributeids.includes(current.specId)) {
                                previous.push(current);
                            }
                            return previous;
                        }, []);
                        if (attributes.length) {
                            splitted.deleted[type].push({
                                signId: object.id,
                                clientID: object.clientID,
                                engineLabeledshapeattributevals: attributes,
                            });
                        }
                    } else if (type === 'tracks') {
                        // 当id包含时，找到需要删除和多余的的属性、连续帧的关键帧、关键帧的属性里，是否有多余及需要删除的。

                        const track = trackObj[object.clientID];

                        // 获取当前页面上的objectState
                        const objectState = this.collection.objects[object.clientID];
                        // 取出目前展示的标签，拥有的标签属性id们（一定会有id）
                        const [attributeTrackids, attributeShapeids] = objectState.label.attributes
                            .reduce((attributeAccumulator, current) => {
                                const [trackattIds, shapeattIds] = attributeAccumulator;
                                if (current.mutable) {
                                // 可改变，是shapeattIds
                                    shapeattIds.push(current.id);
                                } else {
                                    trackattIds.push(current.id);
                                }
                                return [trackattIds, shapeattIds];
                            }, [[], []]);

                        // 获取现有的连续帧下所有普通帧的id（需要新增的，还没来得及创建id，id不一定存在）
                        const trackedshapesIds = track.engineTrackedshapes.reduce((previous, current) => {
                            // eslint-disable-next-line no-underscore-dangle
                            if (current.id) {
                                // 排除掉没有id的
                                previous.push(current.id);
                            }
                            return previous;
                        }, []);

                        // const deleteObj = {
                        // // 连续帧的属性，删除只有一种情况，切换标签时，把上一个标签的属性全部删除。
                        // engineLabeledtrackattributevals:
                        //     track.labelId !== object.labelId ?
                        //         track.engineLabeledtrackattributevals :
                        //         [],

                        // engineTrackedshapes: object.engineTrackedshapes.reduce((shapesAccumulator, current) => {
                        //     // const shape = object.engineTrackedshapes[frame];
                        //     if (!trackedshapesIds.includes(current.id)) {
                        //         // 找出初始中有，而现在没有的帧
                        //         // 这些帧需要删除
                        //         shapesAccumulator.push({
                        //             ...current,
                        //         });
                        //     } else if (current.id) {
                        //         shapesAccumulator.push({
                        //             // 普通帧中的属性
                        //             engineTrackedshapeattributevals: track.labelId !== object.labelId ?
                        //                 current.engineTrackedshapeattributevals :
                        //                 [],
                        //         });
                        //     }

                        //     return shapesAccumulator;
                        // }, []),
                        // };

                        // 遍历连续帧自身的属性列表
                        const engineLabeledtrackattributevals = object.engineLabeledtrackattributevals
                            .reduce((attributeAccumulator, current) => {
                                if (!attributeTrackids.includes(current.specId)) {
                                    attributeAccumulator.push(current);
                                }
                                return attributeAccumulator;
                            }, []);

                        // 初始化需要删除的shape列表
                        // 遍历以往的shape列表，一定会有id
                        const engineTrackedshapes = object.engineTrackedshapes.reduce((previous, current) => {
                            // 以往的shapeid里，如果现有的没有包含，则需要删除该对象
                            if (!trackedshapesIds.includes(current.id)) {
                                previous.push(current);
                            } else {
                                // 对于删除对象的，则属性也会删除。因此遍历其下的属性列表
                                const attributes = current.engineTrackedshapeattributevals
                                    .reduce((previousAtts, att) => {
                                        if (!attributeShapeids.includes(att.specId)) {
                                        // 目前的标签不包含这些属性，因此删除这些属性
                                            previousAtts.push(att);
                                        }
                                        return previousAtts;
                                    }, []);
                                if (attributes.length) {
                                    previous.push({
                                        signId: current.id,
                                        engineTrackedshapeattributevals: attributes,
                                    });
                                }
                            }
                            return previous;
                        }, []);

                        if ((engineTrackedshapes && engineTrackedshapes.length) ||
                            (engineLabeledtrackattributevals && engineLabeledtrackattributevals.length)) {
                            splitted.deleted[type].push({
                                signId: object.id,
                                clientID: object.clientID,
                                engineLabeledtrackattributevals,
                                engineTrackedshapes,
                            });
                        }

                        // object.engineTrackedshapes.forEach((current) => {
                        //     if (!trackedshapesIds.includes(current.id)) {
                        //         // 没有的(已被取消关键帧)需要全部删除
                        //         engineTrackedshapes.push({
                        //             ...current,
                        //         });
                        //     } else if (current.id) {
                        //         // 除此之外，有id, 且，标签变更时，删除所有属性
                        //         if (track.labelId !== object.labelId) {
                        //             engineTrackedshapes.push({
                        //                 engineLabeledtrackattributevals: track.engineLabeledtrackattributevals,
                        //             });
                        //         }
                        //     }
                        // });

                        // if ((track.labelId !== object.labelId && track.engineLabeledtrackattributevals.length) ||
                        //  engineTrackedshapes.length) {
                        //     splitted.deleted[type].push({
                        //         engineLabeledtrackattributevals: track.engineLabeledtrackattributevals,
                        //         engineTrackedshapes,
                        //     });
                        // }
                    }
                }
            }

            return {
                shapes: {
                    deletes: [...splitted.deleted.shapes],
                    merges: [...splitted.created.shapes, ...splitted.updated.shapes],
                },
                tracks: {
                    deletes: [...splitted.deleted.tracks],
                    merges: [...splitted.created.tracks, ...splitted.updated.tracks],
                },
                // tags: {
                //     deletes: [],
                //     merges: [],
                // },
            };
        }

        async saveShape(shapes) {
            const { deletes, merges } = await this._updateShape(shapes);
            if (deletes && deletes.length) {
                // 已删除，则在init中删除。
                for (const object of deletes) {
                    if (object.id) {
                        // 有id，代表是删除这个对象
                        delete this.initialObjects.shapes[object.id];
                    } else if (object.signId) {
                        // 有标记id，删除这个标记id下的属性
                        const deleteAttributeids = object.engineLabeledshapeattributevals
                            .map((deleteAttribute) => {
                                // 下一行的删除是因为有脏数据
                                delete this.collection.objects[object.clientID].attributes[deleteAttribute.specId];

                                return deleteAttribute.specId;
                            });
                        if (deleteAttributeids && deleteAttributeids.length) {
                            this.initialObjects.shapes[object.signId].engineLabeledshapeattributevals =
                                    this.initialObjects.shapes[object.signId].engineLabeledshapeattributevals
                                        .filter((att) => !deleteAttributeids.includes(att.specId));
                        }
                    }
                }
            }

            if (merges && merges.length) {
                merges.forEach((object) => {
                    this.initialObjects.shapes[object.id] = object;

                    // 回填id, 新增该对象
                    if (object.insert) {
                        this.collection.objects[object.clientID].serverID = object.id;
                    }

                    if (object.engineLabeledshapeattributevals) {
                        object.engineLabeledshapeattributevals.forEach((att) => {
                            if (att.insert) {
                                this.collection.objects[object.clientID].attributes[att.specId].id = att.id;
                            }
                        });
                    }
                    // if (object.insert) {
                    //     // 是新增,回填。直接将返回的对象放入初始对象即可
                    //     this.initialObjects.shapes[object.id] = object;
                    // } else {
                    //     // 修改，查找新增的属性。有新增的，则用返回的替换现有的
                    //     const addAttributes = object.engineLabeledshapeattributevals
                    //         .filter((deleteAttribute) => deleteAttribute.insert);
                    //     if (addAttributes && addAttributes.length) {
                    //         this.initialObjects.shapes[object.id].engineLabeledshapeattributevals =
                    //             this.initialObjects.shapes[object.id].engineLabeledshapeattributevals.map((att) => {
                    //                 const addAtt = addAttributes.find((data) => data.specId === att.specId);
                    //                 if (addAtt) {
                    //                     return addAtt;
                    //                 }
                    //                 return att;
                    //             });
                    //     }
                    // }
                });
            }
        }

        async saveTrack(track) {
            const { deletes, merges } = await this._updateTrack(track);
            if (deletes && deletes.length) {
                // 已删除，则在init中删除。
                for (const object of deletes) {
                    if (object.id) {
                        // 有id，代表是删除这个对象，其余不考虑
                        delete this.initialObjects.tracks[object.id];
                    } else if (object.signId) {
                        // 有标记id，代表这个对象有删除属性或关键帧，又或者关键帧属性
                        // delete中，只会有删除的
                        const deleteAttTrackIds = object.engineLabeledtrackattributevals
                            .map((current) => {
                                // 下一行的删除是因为有脏数据
                                delete this.collection.objects[object.clientID]
                                    .attributes[current.specId];

                                return current.id;
                            });

                        if (deleteAttTrackIds && deleteAttTrackIds.length) {
                            // 删除 连续帧的属性
                            this.initialObjects.tracks[object.signId].engineLabeledshapeattributevals =
                                    this.initialObjects.tracks[object.signId].engineLabeledshapeattributevals
                                        .filter((att) => !deleteAttTrackIds.includes(att.id));
                        }

                        // 已被删除的关键帧id
                        const [deleteKeyTrackIds, signIds] = object.engineTrackedshapes.reduce((previous, shape) => {
                            const [delKeys, signs] = previous;
                            if (shape.id) {
                                delKeys.push(shape.id);
                            } else if (shape.signId) {
                                signs.push(shape.signId);
                            }

                            return [delKeys, signs];
                        }, [[], []]);

                        if (deleteKeyTrackIds && deleteKeyTrackIds.length) {
                            // 删除关键帧
                            this.initialObjects.tracks[object.signId].engineTrackedshapes =
                                this.initialObjects.tracks[object.signId].engineTrackedshapes
                                    .reduce((previous, shape) => {
                                        // 有属性被删除的关键帧
                                        if (signIds.includes(shape.id)) {
                                            const deleteKeyShape = object.engineTrackedshapes
                                                .find((obj) => obj.signId === shape.id);
                                            // 被删除的关键帧属性id列表
                                            const deleteKeyAttIds = deleteKeyShape.engineTrackedshapeattributevals
                                                .map((att) => {
                                                    // 下一行的删除是因为有脏数据
                                                    delete this.collection.objects[object.clientID].shapes[shape.frame]
                                                        .attributes[att.specId];

                                                    return att.specId;
                                                });
                                            previous.push({
                                                ...shape,
                                                engineTrackedshapeattributevals:
                                                    shape.engineTrackedshapeattributevals
                                                        .reduce((attPrevous, currentAtt) => {
                                                            if (!deleteKeyAttIds.includes(currentAtt.specId)) {
                                                                attPrevous.push(currentAtt);
                                                            }
                                                            return attPrevous;
                                                        }, []),
                                            });
                                        } else if (!deleteKeyTrackIds.includes(shape.id)) {
                                            // 排除掉已被删除的关键帧
                                            // 这里是需要保留的关键帧，
                                            previous.push(shape);
                                        }
                                        return previous;
                                    }, []);
                        }
                    }
                }
            }

            if (merges && merges.length) {
                merges.forEach((object) => {
                    this.initialObjects.tracks[object.id] = object;

                    // 回填id, 新增该对象
                    if (object.insert) {
                        this.collection.objects[object.clientID].serverID = object.id;
                    }

                    if (object.engineLabeledtrackattributevals) {
                        object.engineLabeledtrackattributevals.forEach((att) => {
                            if (att.insert) {
                                this.collection.objects[object.clientID].attributes[att.specId].id = att.id;
                            }
                        });
                    }

                    if (object.engineTrackedshapes) {
                        object.engineTrackedshapes.forEach((shape) => {
                            if (shape.insert) {
                                this.collection.objects[object.clientID].shapes[shape.frame].serverID = shape.id;
                            }
                            if (shape.engineTrackedshapeattributevals) {
                                shape.engineTrackedshapeattributevals.forEach((att) => {
                                    this.collection.objects[object.clientID]
                                        .shapes[shape.frame].attributes[att.specId].id = att.id;
                                });
                            }
                        });
                    }
                });
            }
        }

        // 正式的保存
        async saveAction(onUpdateArg) {
            this.saving = true;
            // // 更新和新增两种id

            const onUpdate = typeof onUpdateArg === 'function' ? onUpdateArg : (message) => {
                console.log(message);
            };
            onUpdate('正在将修改保存到服务器...');
            const exported = this.collection.export();

            let param;
            try {
                param = this._tParams(exported);
            } catch (error) {
                throw new ScriptingError(
                    `保存时转换对象错误:${error}`,
                );
            }

            const { shapes, tracks } = param;
            const savePromises = [];

            if (shapes && (shapes.deletes.length || param.shapes.merges.length)) {
                savePromises.push(this.saveShape(shapes));
            }

            if (tracks && (tracks.deletes.length || tracks.merges.length)) {
                savePromises.push(this.saveTrack(tracks));
            }

            await Promise.all(savePromises).catch((e) => {
                this.waitPromises = [];
                this.saving = false;
                throw new ServerError(e.message, 0);
            });

            this.hash = this._getHash();

            this.saving = false;
            // 执行完保存结束后，查看是否有等待保存的。有的话，按顺序一个个保存。
            if (this.waitPromises && this.waitPromises.length) {
                const data = this.waitPromises.shift();
                await this.save(data.param);
            }
        }

        async save(onUpdateArg) {
            // 是否正在保存
            if (this.saving || (this.waitPromises && this.waitPromises.length)) {
                this.waitPromises.push({ param: onUpdateArg });
            } else {
                await this.saveAction(onUpdateArg);
            }
        }

        hasUnsavedChanges() {
            return this._getHash() !== this.hash;
        }
    }

    module.exports = AnnotationsSaver;
})();
