/*
 * @Author: swxy
 * @Date: 2022-08-15 12:10:23
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
const language = 'Language';

export const removeLanguage = (): void => {
    localStorage.removeItem(language);
};
export const setLanguage = (value: string): void => {
    if (value) {
        localStorage.setItem(language, value);
    }
};
export const getLanguage = (): string | null => localStorage.getItem(language);
