/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    language: 'English',
    setting: 'setting',
    logout: 'logout',
    loginfo_timeout: 'Login information has expired，Please logoin again！',

    // logout: 'logout',
    // logout: 'logout',
};
