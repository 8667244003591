/*
 * @Author: swxy
 * @Date: 2022-08-11 10:36:48
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import Image from './image';
import Group from './imageGroup';

// export * from './image';
export const ImageGroup = Group;

export default Image;
