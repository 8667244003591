/*
 * @Author: swxy
 * @Date: 2022-08-19 11:50:48
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'workspace.side.object.track_simple': 'T',

    'shape.rectangle': 'R',
    'shape.polygon': 'PG',
    'shape.polyline': 'PL',
    'shape.points': 'P',
    'shape.cuboid': 'C',
    'shape.image': 'I',
    'shape.ellipse': 'E',
    'shape.other': 'U',

    'shape.full.rectangle': 'rectangle',
    'shape.full.polygon': 'polygon',
    'shape.full.polyline': 'polyline',
    'shape.full.points': 'points',
    'shape.full.cuboid': 'cuboid',
    'shape.full.image': 'image',
    'shape.full.ellipse': 'ellipse',
    'shape.full.other': 'unkown',
};
