/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-03-16 21:25:58
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useHistory } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';

interface DetailsComponentProps {
    taskInstance: any;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { taskInstance } = props;
    const user = useSelector((state: CombinedState) => state.auth.user);

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
            <Col>
                {taskInstance.projectId ? (
                    <Button
                        onClick={() => history.push(`/projects/${taskInstance.projectId}`)}
                        type='link'
                        size='large'
                    >
                        <LeftOutlined />
                        {/* Back to project */}
                        {/* 返回项目 */}
                        {t('project.back_project')}
                    </Button>
                ) : (
                    <Button onClick={() => history.push('/tasks')} type='link' size='large'>
                        <LeftOutlined />
                        {/* Back to tasks */}
                        {t('project.back_task')}
                    </Button>
                )}
            </Col>
            {user && user.groups && user.groups.includes('admin') && (
                <Col>
                    <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} />}>
                        <Button size='middle' className='cvat-task-page-actions-button'>
                            {/* <Text className='cvat-text-color'>Actions</Text> */}
                            <Text className='cvat-text-color'>更多功能</Text>
                            <MoreOutlined className='cvat-menu-icon' />
                        </Button>
                    </Dropdown>
                </Col>
            )}
        </Row>
    );
}
