/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-08-18 18:24:11
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { ZoomIcon } from 'icons';
import { ActiveControl } from 'reducers/interfaces';
import { Canvas } from 'cvat-canvas-wrapper';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
}

function ResizeControl(props: Props): JSX.Element {
    const { activeControl, canvasInstance } = props;
    const { t } = useTranslation();

    return (
        // <CVATTooltip title='Select a region of interest' placement='right'>
        <CVATTooltip title={t('workspace.side.controls.resize_interest_tip')} placement='right'>
            <Icon
                component={ZoomIcon}
                className={
                    activeControl === ActiveControl.ZOOM_CANVAS
                        ? 'cvat-resize-control cvat-active-canvas-control'
                        : 'cvat-resize-control'
                }
                onClick={(): void => {
                    if (activeControl === ActiveControl.ZOOM_CANVAS) {
                        canvasInstance.zoomCanvas(false);
                    } else {
                        canvasInstance.cancel();
                        canvasInstance.zoomCanvas(true);
                    }
                }}
            />
        </CVATTooltip>
    );
}

export default React.memo(ResizeControl);
