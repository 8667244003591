// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/122-CAI978.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._____test{color:rgba(3,56,19,.384)}.cvat-header.ant-layout-header{display:flex;padding-left:0;padding-right:0;line-height:0;height:48px;background:#d8d8d8}.ant-btn.cvat-header-button{color:#303030;padding:0 8px;margin-right:8px}.cvat-left-header{width:50%;display:flex;justify-content:flex-start;align-items:center}.cvat-left-header .ant-btn.cvat-header-button{opacity:.7}.cvat-left-header .ant-btn.cvat-header-button.cvat-active-header-button{font-weight:bold;opacity:1}.cvat-right-header{width:50%;display:flex;justify-content:flex-end;align-items:center}.cvat-right-header>a.ant-btn span[role=img]{font-size:24px;line-height:24px}.anticon.cvat-logo-icon>svg{transform:scale(0.7)}.cvat-header-menu-user-dropdown{display:flex;align-items:center;border-left:1px solid #c3c3c3}.cvat-header-menu-user-dropdown .anticon.cvat-header-dropdown-icon{font-size:20px;padding:8px}.cvat-header-menu-user-dropdown .anticon.cvat-header-dropdown-icon.anticon-caret-down{font-size:12px}.cvat-header-menu-user-dropdown>div:nth-child(2){max-width:120px;height:40px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;align-items:center}.cvat-header-menu-user-dropdown>div:nth-child(2)>div:nth-child(2){font-size:10px}.cvat-header-menu-active-organization-item{font-weight:bold}.cvat-header-menu-active-organization-item ::after{content:\" ✓\";float:right;margin-left:8px}.cvat-modal-organization-selector{width:100%}.header-top-logo-icon{width:80px;height:20px;margin-left:10px}@font-face{font-family:\"AmygoFamily\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.amygo_header_logo{margin-left:10px;font-family:\"AmygoFamily\";font-size:x-large;color:#5eba76}", ""]);
// Exports
module.exports = exports;
