// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
    FormOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    CheckOutlined,
    FileSyncOutlined,
    MoreOutlined,
    ApartmentOutlined,
    RiseOutlined,
    SelectOutlined,
    // CheckSquareOutlined,
    // CoffeeOutlined,
    // ReloadOutlined,
} from '@ant-design/icons';
import { Tooltip, Dropdown, Button, Menu, Modal, Select } from 'antd';

// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import {
    CombinedState,
    DimensionType,
    Workspace,
    StatesOrdering,
    TaskStatus,
    SubmitStatus,
    WorkspaceLng,
} from 'reducers/interfaces';
import { updateJobAsync } from 'actions/tasks-actions';
import {
    activateObject,
    changeSibebarPosition as changeSibebarPositionAction,
    changePanelModel as changePanelModelAction,
    updateSerialId as updateSerialIdAction,
    changeWorkspace as changeWorkspaceAction,
    changeStatesOrdering as changeStatesOrderingAction,
    // switchRequestReviewDialog as switchRequestReviewDialogAction,
    // switchSubmitReviewDialog as switchSubmitReviewDialogAction,
    setForceExitAnnotationFlag as setForceExitAnnotationFlagAction,
    saveAnnotationsAsync,
} from 'actions/annotation-actions';
import StatesOrderingSelector from 'components/annotation-page/standard-workspace/objects-side-bar/states-ordering-selector';
import { changeWorkspaceAble } from 'utils/constant';
import SubmitPage from 'components/annotation-page/submit';
import IDInforManage from './IDInforManage';
import { Translation, getI18n } from 'react-i18next';

import '../styles.scss';

export enum Actions {
    // DUMP_TASK_ANNO = 'dump_task_anno',
    // LOAD_JOB_ANNO = 'load_job_anno',
    // EXPORT_TASK_DATASET = 'export_task_dataset',
    // REMOVE_ANNO = 'remove_anno',
    // OPEN_TASK = 'open_task',
    CHANGE_LIST_MODEL = 'change_list_model',
    CHANGE_PANEL_SHOW_POSITION = 'change_panel_show_position',
    CHANGE_SERIALID = 'change_serialID',
    CHANGE_PANEL_SORT = 'change_panel_sort',
    CHANGE_WORKSPACE = 'change_workspace',

    REQUEST_REVIEW = 'request_review',
    SUBMIT_REVIEW = 'submit_review',
    SUBMIT_ACCEPTANCE = 'submit_acceptance',
    FINISH_JOB = 'finish_job',
    RENEW_JOB = 'renew_job',
}

interface State {
    visible: boolean;
    workVisible: boolean;
    panelSortVisible: boolean;
    work: Workspace;
    statesOrderingValue: StatesOrdering;
    submitVisible: boolean;
}

interface StateToProps {
    workspace: Workspace;
    sidebarPositionLeft: boolean;
    isPanelListModel: boolean;
    normalizedKeyMap: Record<string, string>;
    serialID: any;
    jobInstance: any;
    statesOrdering: StatesOrdering;
    user: any;
}

interface DispatchToProps {
    changeSibebarPosition(): void;
    changePanelModel(): void;
    updateSerialId(minID: string | undefined, includesLocked: boolean): void;
    changeWorkspace(workspace: Workspace): void;
    changeStatesOrdering(statesOrdering: StatesOrdering): void;
    // switchRequestReviewDialog(visible: boolean): void;
    // switchSubmitReviewDialog(visible: boolean): void;
    setForceExitAnnotationFlag(forceExit: boolean): void;
    updateJob(jobInstance: any): void;
    saveAnnotations(jobInstance: any, afterSave?: () => void): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarPositionLeft,
            annotations: { serialID, isPanelListModel, statesOrdering },
            job: { instance: jobInstance },
            workspace,
        },
        shortcuts: { normalizedKeyMap },
        auth: { user },
    } = state;

    return {
        serialID,
        sidebarPositionLeft,
        isPanelListModel,
        normalizedKeyMap,
        jobInstance,
        workspace,
        statesOrdering,
        user,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        changeSibebarPosition(): void {
            dispatch(changeSibebarPositionAction());
        },
        changePanelModel(): void {
            dispatch(changePanelModelAction());
        },
        updateSerialId(minID: string | undefined, includesLocked: boolean): void {
            dispatch(updateSerialIdAction(minID, includesLocked));
        },
        changeWorkspace(workspace: Workspace): void {
            dispatch(activateObject(null, null));
            dispatch(changeWorkspaceAction(workspace));
        },
        changeStatesOrdering(statesOrdering: StatesOrdering): void {
            dispatch(changeStatesOrderingAction(statesOrdering));
        },
        // switchRequestReviewDialog(visible: boolean): void {
        //     dispatch(switchRequestReviewDialogAction(visible));
        // },
        // switchSubmitReviewDialog(visible: boolean): void {
        //     dispatch(switchSubmitReviewDialogAction(visible));
        // },
        setForceExitAnnotationFlag(forceExit: boolean): void {
            dispatch(setForceExitAnnotationFlagAction(forceExit));
        },
        updateJob(jobInstance: any): void {
            dispatch(updateJobAsync(jobInstance));
        },
        saveAnnotations(jobInstance: any, afterSave?: () => void): void {
            dispatch(saveAnnotationsAsync(jobInstance, afterSave));
        },
    };
}

// function showChangeSerialIdPage(props: StateToProps & DispatchToProps): JSX.Element {
//     const {
//         sidebarPositionLeft,
//         isPanelListModel,
//         changePanelModel,
//         normalizedKeyMap,
//     } = props;

//     return (
//     );
// }

type Props = StateToProps & DispatchToProps & RouteComponentProps;
class MoreDropdown extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            work: props.workspace || Workspace.STANDARD,
            visible: false,
            workVisible: false,
            panelSortVisible: false,
            statesOrderingValue: StatesOrdering.ID_ASCENT,
            submitVisible: false,
        };
    }

    // static getDerivedStateFromProps(props: Props, state: State): State | null {
    //     return {
    //         ...state,
    //         work: props.workspace,
    //         statesOrderingValue: props.statesOrdering,
    //     };
    // }

    // const [work, setWork] = useState(workspace);
    // const [workVisible, setWorkVisible] = useState(false);

    // const [visible, setVisible] = useState(false);
    // const [panelSortVisible, setPanelSortVisible] = useState(false);

    private changePosition = (): void => {
        // 调用redux，执行一个action
        const { changeSibebarPosition } = this.props;
        if (changeSibebarPosition) {
            changeSibebarPosition();
        }
    };

    private changePanelModelEvent = (): void => {
        // message.info('please wait');
        const { changePanelModel } = this.props;
        changePanelModel();
    };

    private enableSelect = (select: boolean, text: React.ReactNode, toolTipText?: React.ReactNode): JSX.Element => {
        const render = (
            <span className={`more-item-div${select ? ' more-item-div-select' : ''}`}>
                {text}
                {select ? <CheckOutlined style={{ marginLeft: '20px' }} /> : null}
            </span>
        );

        return toolTipText ? (
            <Tooltip title={toolTipText} placement='left'>
                {render}
            </Tooltip>
        ) : (
            render
        );
    };

    private changeSerialid = (): void => {
        // setVisible(!visible);
        const { visible } = this.state;
        this.setState({
            visible: !visible,
        });
    };

    private onChange = async (minID: string | undefined, includesLocked: boolean): Promise<void> => {
        const { updateSerialId } = this.props;
        updateSerialId(minID, includesLocked);
        this.changeSerialid();
    };

    private closeWorkSpace = (): void => {
        const { workVisible } = this.state;
        this.setState({
            workVisible: !workVisible,
        });
    };

    // 变更视角
    private changeWorkspaceEvent = (): void => {
        const { changeWorkspace } = this.props;
        const { work } = this.state;
        changeWorkspace(work);
        this.closeWorkSpace();
    };

    private closePanelSort = (): void => {
        const { panelSortVisible } = this.state;
        this.setState({
            panelSortVisible: !panelSortVisible,
        });
    };

    private setWork = (value: Workspace): void => {
        this.setState({
            work: value,
        });
    };

    private changeItemsSort = (): void => {
        const { changeStatesOrdering } = this.props;
        const { statesOrderingValue } = this.state;
        changeStatesOrdering(statesOrderingValue);
        this.closePanelSort();
    };

    private onChangeStatesOrdering = (value: StatesOrdering): void => {
        this.setState({
            statesOrderingValue: value,
        });
    };

    private checkUnsavedChanges = (_copyParams: MenuInfo): void => {
        const { jobInstance, setForceExitAnnotationFlag, saveAnnotations } = this.props;
        if (jobInstance.annotations.hasUnsavedChanges()) {
            Modal.confirm({
                // title: 'The job has unsaved annotations',
                // content: 'Would you like to save changes before continue?',
                // title: '当前工作还有未保存的操作',
                // content: '是否先保存后再继续?',
                title: <Translation>{(t) => t('workspace.top.drop.unsavedata_tip')}</Translation>,
                content: <Translation>{(t) => t('workspace.top.drop.save_tip')}</Translation>,
                className: 'cvat-modal-content-save-job',
                okButtonProps: {
                    // children: '保存',
                    // children: 'Save',
                    children: <Translation>{(t) => t('workspace.top.drop.save')}</Translation>,
                },
                cancelButtonProps: {
                    // children: '不保存',
                    // children: 'No',
                    children: <Translation>{(t) => t('workspace.top.drop.unsave')}</Translation>,
                },
                onOk: () => {
                    saveAnnotations(jobInstance, () => this.saveClickMenu(_copyParams));
                },
                onCancel: () => {
                    // do not ask leave confirmation
                    setForceExitAnnotationFlag(true);
                    setTimeout(() => {
                        this.saveClickMenu(_copyParams);
                    });
                },
            });
        } else {
            this.saveClickMenu(_copyParams);
        }
    };

    private saveClickMenu = (params: MenuInfo): void => {
        const { jobInstance, updateJob, history } = this.props;
        if (params.key === Actions.FINISH_JOB) {
            jobInstance.status = TaskStatus.COMPLETED;
            updateJob(jobInstance);
            history.push(`/tasks/${jobInstance.taskId}`);
        } else if (params.key === Actions.SUBMIT_ACCEPTANCE) {
            this.setState({
                submitVisible: true,
            });
        }
    };

    private onClickMenu = (params: MenuInfo): void => {
        const {
            jobInstance,
            // switchRequestReviewDialog,
            // switchSubmitReviewDialog,
            updateJob,
            history,
        } = this.props;
        if (params.key === Actions.CHANGE_LIST_MODEL) {
            this.changePanelModelEvent();
        } else if (params.key === Actions.CHANGE_PANEL_SHOW_POSITION) {
            this.changePosition();
        } else if (params.key === Actions.CHANGE_SERIALID) {
            this.changeSerialid();
        } else if (params.key === Actions.CHANGE_PANEL_SORT) {
            this.closePanelSort();
        } else if (params.key === Actions.CHANGE_WORKSPACE) {
            this.closeWorkSpace();
        } else if (params.key === Actions.REQUEST_REVIEW) {
            // switchRequestReviewDialog(true);
        } else if (params.key === Actions.SUBMIT_ACCEPTANCE) {
            // 提交验收
            this.checkUnsavedChanges(params);
        } else if (params.key === Actions.FINISH_JOB) {
            Modal.confirm({
                // title: 'The job status is going to be switched',
                // content: 'Status will be changed to "completed". Would you like to continue?',
                // okText: 'Continue',
                // cancelText: 'Cancel',
                // title: '改变当前工作状态',
                // content: '当前工作状态将更改为"已完成"，是否继续?',
                // okText: '继续',
                // cancelText: '取消',
                title: <Translation>{(t) => t('workspace.top.drop.change_workspace_tip')}</Translation>,
                content: <Translation>{(t) => t('workspace.top.drop.change_workspace_content_tip')}</Translation>,
                okText: <Translation>{(t) => t('workspace.top.drop.change_workspace_continue')}</Translation>,
                cancelText: <Translation>{(t) => t('workspace.top.drop.change_workspace_cancle')}</Translation>,
                className: 'cvat-modal-content-finish-job',
                onOk: () => {
                    this.checkUnsavedChanges(params);
                },
            });
        } else if (params.key === Actions.SUBMIT_REVIEW) {
            // switchSubmitReviewDialog(true);
        } else if (params.key === Actions.RENEW_JOB) {
            jobInstance.status = TaskStatus.ANNOTATION;
            updateJob(jobInstance);
            history.push(`/tasks/${jobInstance.taskId}`);
        }
    };

    private overlay = (): JSX.Element => {
        const { normalizedKeyMap, isPanelListModel, sidebarPositionLeft, jobInstance } = this.props;
        const { jobStatus } = jobInstance;

        return (
            <Menu onClick={this.onClickMenu} theme='dark'>
                <Menu.Item key={Actions.CHANGE_LIST_MODEL} icon={<FormOutlined />}>
                    {/*
                    {this.enableSelect(isPanelListModel, 'list model',
                    `panel show item list ${normalizedKeyMap.PANEL_SHOW_MODEL}`)}
                    */}
                    {/* {this.enableSelect(
                        isPanelListModel,
                        '面板展示模式',
                        `切换面板列表展示模式 ${normalizedKeyMap.PANEL_SHOW_MODEL}`,
                    )} */}
                    {this.enableSelect(
                        isPanelListModel,
                        <Translation>{(t) => t('workspace.top.drop.panel_model')}</Translation>,
                        <Translation>
                            {(t) =>
                                `${t('workspace.top.drop.change_panel_model')} ${normalizedKeyMap.PANEL_SHOW_MODEL}`
                            }
                        </Translation>,
                    )}
                </Menu.Item>
                {/* <Menu.Item
                    key={Actions.CHANGE_PANEL_SHOW_POSITION}
                    icon={
                        sidebarPositionLeft ? <MenuUnfoldOutlined title='right' /> : <MenuFoldOutlined title='left' />
                    }
                >
                    {this.enableSelect(sidebarPositionLeft, 'panel left')}
                    {this.enableSelect(
                        sidebarPositionLeft,
                        //  '左侧面板', '面板列表展示在左侧'
                        <Translation>{(t) => t('workspace.top.drop.panel_left')}</Translation>,
                        <Translation>{(t) => `${t('workspace.top.drop.panel_show_on_left')}`}</Translation>,
                    )}
                </Menu.Item> */}
                {/* <Menu.Item key={Actions.CHANGE_SERIALID} onClick={this.changeSerialid} icon={<FileSyncOutlined />}>
                    change SerialID
                    修改标识
                    <Translation>{(t) => t('workspace.top.drop.change_serialid')}</Translation>
                </Menu.Item> */}
                <Menu.Item key={Actions.CHANGE_PANEL_SORT} onClick={this.closePanelSort} icon={<RiseOutlined />}>
                    {/* panel sort */}
                    {/* 面板列表排序 */}
                    <Translation>{(t) => t('workspace.top.drop.panel_sort')}</Translation>
                </Menu.Item>
                <Menu.Item key={Actions.CHANGE_WORKSPACE} onClick={this.closeWorkSpace} icon={<ApartmentOutlined />}>
                    {/* change workspace */}
                    {/* 更改工作模式 */}
                    <Translation>{(t) => t('workspace.top.drop.change_workspace')}</Translation>
                </Menu.Item>
                {[SubmitStatus.annotating, SubmitStatus.callback_frist].includes(jobStatus) && (
                    <Menu.Item key={Actions.SUBMIT_ACCEPTANCE} icon={<SelectOutlined />}>
                        {/* 提交审核 */}
                        {/* 提交 */}
                        <Translation>{(t) => t('workspace.top.drop.submit')}</Translation>
                    </Menu.Item>
                )}
                {[
                    SubmitStatus.submit_frist,
                    SubmitStatus.submit_second,
                    SubmitStatus.callback_second,
                    SubmitStatus.callback_acceptance,
                ].includes(jobStatus) && (
                    <Menu.Item key={Actions.SUBMIT_ACCEPTANCE} icon={<SelectOutlined />}>
                        {/* 审核 */}
                        {/* 提交 */}
                        <Translation>{(t) => t('workspace.top.drop.submit')}</Translation>
                    </Menu.Item>
                )}
                {jobStatus === SubmitStatus.submit_acceptance && (
                    <Menu.Item key={Actions.SUBMIT_ACCEPTANCE} icon={<SelectOutlined />}>
                        {/* 验收 */}
                        {/* 提交 */}
                        <Translation>{(t) => t('workspace.top.drop.submit')}</Translation>
                    </Menu.Item>
                )}
                {/* {jobStatus === 'annotation' && (
                    <Menu.Item key={Actions.FINISH_JOB} icon={<CheckSquareOutlined />}>
                        完成当前工作
                    </Menu.Item>
                )}
                {jobStatus === 'validation' && isReviewer && (
                    <Menu.Item key={Actions.SUBMIT_REVIEW} icon={<CoffeeOutlined />}>
                        审核结束
                    </Menu.Item>
                )}
                {jobStatus === 'completed' && (
                    <Menu.Item key={Actions.RENEW_JOB} icon={<ReloadOutlined />}>
                        重开工作
                    </Menu.Item>
                )} */}
            </Menu>
        );
    };

    /**
     * name
     */
    public render(): JSX.Element {
        const {
            serialID,
            // sidebarPositionLeft,
            // isPanelListModel,
            // normalizedKeyMap,
            // workspace,
            jobInstance,
            user,
            // statesOrdering,

            // changePanelModel,
            // changeWorkspace,
            // changeStatesOrdering,
        } = this.props;
        const { visible, workVisible, panelSortVisible, work, statesOrderingValue, submitVisible } = this.state;

        // const jobStatus = jobInstance.stage;

        // const isReviewer = jobInstance.reviewer?.id === user.id || user.isSuperuser;

        const changeWorkspaceDisable = !changeWorkspaceAble(jobInstance.jobStatus, user?.roleType);

        return (
            <>
                <Dropdown overlay={this.overlay} className='top-bar-right-dropdown' trigger={['click']}>
                    <Button type='link' className='cvat-annotation-header-button'>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
                <IDInforManage
                    minID={serialID.minID}
                    maxID={serialID.maxID}
                    visible={visible}
                    changeSerialid={this.changeSerialid}
                    onChange={this.onChange}
                />
                <Modal
                    visible={workVisible}
                    destroyOnClose
                    onOk={this.changeWorkspaceEvent}
                    onCancel={this.closeWorkSpace}
                >
                    <Select
                        dropdownClassName='cvat-workspace-selector-dropdown'
                        className='cvat-workspace-selector'
                        onChange={this.setWork}
                        value={work}
                        disabled={changeWorkspaceDisable}
                    >
                        {Object.values(Workspace).map((ws) => {
                            if (jobInstance?.dimension === DimensionType.DIM_3D) {
                                if (ws === Workspace.STANDARD) {
                                    return null;
                                }
                                return (
                                    <Select.Option
                                        disabled={ws !== Workspace.STANDARD3D && ws !== Workspace.REVIEW_WORKSPACE}
                                        key={ws}
                                        value={ws}
                                    >
                                        <Translation>{(t) => t(WorkspaceLng[ws])}</Translation>
                                    </Select.Option>
                                );
                            }
                            if (ws !== Workspace.STANDARD3D) {
                                if (ws !== Workspace.REVIEW_WORKSPACE && changeWorkspaceDisable) {
                                    return null;
                                }
                                return (
                                    <Select.Option key={ws} value={ws}>
                                        <Translation>{(t) => t(WorkspaceLng[ws])}</Translation>
                                    </Select.Option>
                                );
                            }
                            return null;
                        })}
                    </Select>
                </Modal>
                <Modal
                    destroyOnClose
                    visible={panelSortVisible}
                    onOk={this.changeItemsSort}
                    onCancel={this.closePanelSort}
                >
                    <StatesOrderingSelector
                        statesOrdering={statesOrderingValue}
                        changeStatesOrdering={this.onChangeStatesOrdering}
                    />
                </Modal>
                <Modal
                    destroyOnClose
                    // title='提交'
                    title={<Translation>{(t) => t('workspace.top.drop.submit_title')}</Translation>}
                    visible={submitVisible}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                >
                    <SubmitPage onCancel={() => this.setState({ submitVisible: false })} />
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MoreDropdown));
