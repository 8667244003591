// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import * as THREE from 'three';
import { MasterImpl } from './master';
import { SummarizeData } from './exportType';

export interface Size {
    width: number;
    height: number;
}

export interface ActiveElement {
    clientID: string | null;
    attributeID: number | null;
    issueID: string | null;
}

export interface GroupData {
    enabled: boolean;
    grouped?: [];
}

export interface Image {
    renderWidth: number;
    renderHeight: number;
    imageData: ImageData | CanvasImageSource;
}

export interface DrawData {
    enabled: boolean;
    initialState?: any;
    redraw?: number;
    shapeType?: string;
}

export interface ReviewData {
    enabled: boolean;
    // initialIssue?: any;
    redraw?: boolean;
}

export enum FrameZoom {
    MIN = 0.1,
    MAX = 10,
}

export enum ObjectType {
    SHAPE = 'shape',
    TRACK = 'track',
    TAG = 'tag',
}

export enum Planes {
    TOP = 'topPlane',
    SIDE = 'sidePlane',
    FRONT = 'frontPlane',
    PERSPECTIVE = 'perspectivePlane',
}

export enum ViewType {
    PERSPECTIVE = 'perspective',
    TOP = 'top',
    SIDE = 'side',
    FRONT = 'front',
}

export enum MouseInteraction {
    CLICK = 'click',
    DOUBLE_CLICK = 'dblclick',
    HOVER = 'hover',
}

export interface FocusData {
    clientID: string | null;
    issueID: string | null;
}

export interface ShapeProperties {
    opacity: number;
    outlined: boolean;
    outlineColor: string;
    selectedOpacity: number;
    colorBy: string;
}

export enum UpdateReasons {
    IMAGE_CHANGED = 'image_changed',
    OBJECTS_UPDATED = 'objects_updated',
    ISSUE_REGIONS_UPDATED = 'issue_regions_update',
    REVIEW_UPDATED = 'revew_updated',
    DRAW = 'draw',
    SELECT = 'select',
    CANCEL = 'cancel',
    DATA_FAILED = 'data_failed',
    DRAG_CANVAS = 'drag_canvas',
    REVIEW_CANVAS = 'review_canvas',
    SHAPE_ACTIVATED = 'shape_activated',
    ISSUE_ACTIVATED = 'issue_activated',
    GROUP = 'group',
    FITTED_CANVAS = 'fitted_canvas',
    CONFIG_UPDATED = 'config_updated',
    summarize_data = 'summarize_data', // 后面慢慢换成小写
}

export enum Mode {
    IDLE = 'idle',
    DRAG = 'drag',
    RESIZE = 'resize',
    DRAW = 'draw',
    EDIT = 'edit',
    INTERACT = 'interact',
    DRAG_CANVAS = 'drag_canvas',
    REVIEW_CANVAS = 'review_canvas',
    GROUP = 'group',
    BUSY = 'busy',
}

export enum CameraType {
    normal = 'normal',
    buttonhole = 'buttonhole',
    fisheye = 'fisheye',
}

export interface CameraDistortionParameter {
    cameraType: CameraType;
    k1?: number;
    k2?: number;
    k3?: number;
    k4?: number;
    k5?: number;
    k6?: number;
    s1?: number;
    s2?: number;
    s3?: number;
    s4?: number;
    p1?: number;
    p2?: number;
    // cameraViewAngle?: number;
    // viewAngleAble?: boolean;// 是否展示视场角参考线
    // viewRadius?: number;
    // viewStartMiddleAngle?: number; // 开始位置的中间角度
}

export interface PcdParameter {
    // 标注范围半径
    cameraRadius?: number; // 有值时（> 0））展示

    // 视场角
    // 起始线标注角度
    viewStartMiddleAngle?: number;
    // 标注角度
    viewAngle?: number;
}

// 激活模式， standard模式下，默认是点击激活。review模式下默认是移入激活
export enum ActivationModel {
    clickActive = 'CLICK_ACTIVE',
    moveInActive = 'MOVE_IN_ACTIVE',
}

export interface Configuration {
    forceDisableEditing: boolean;
    activeModel: ActivationModel;
}

export interface Issue {
    id: number;
    points: number[];
    message: string;
    resolve: boolean;
    hidden?: boolean;
}

export interface Canvas3dDataModel {
    activeElement: ActiveElement;
    canvasSize: Size;
    image: Image | null;
    imageID: number | null;
    imageOffset: number;
    imageSize: Size;
    drawData: DrawData;
    mode: Mode;
    objectUpdating: boolean;
    exception: Error | null;
    objects: any[];
    groupedObjects: any[];
    focusData: FocusData;
    selected: any;
    shapeProperties: ShapeProperties;
    groupData: GroupData;
    cameraCalibs: THREE.Matrix3[];
    cameraToBumpers: THREE.Matrix4[];
    cameraDistortionParameter: CameraDistortionParameter[];
    pcdParameter: PcdParameter;
    isFineTuning: boolean;
    configuration: Configuration;
    reviewData: ReviewData;
    issueRegions: Issue[];

    summarize: {
        framePcd: any;
        data: SummarizeData[];
        objects: any[];
        frame: number;
    };
}

export interface Canvas3dModel {
    mode: Mode;
    data: Canvas3dDataModel;
    readonly groupData: GroupData;
    readonly issueRegions: Issue[];
    readonly configuration: Configuration;
    setup(frameData: any, objectStates: any[], issues?: any[]): void;
    setupIssueRegions(issueRegions: Issue[]): void;
    isAbleToChangeFrame(): boolean;
    draw(drawData: DrawData): void;
    cancel(): void;
    dragCanvas(enable: boolean): void;
    reviewCanvas(enable: boolean): void;
    activate(clientID: string | null, attributeID: number | null): void;
    activateIssue(issueID: string): void;
    configureShapes(shapeProperties: any): void;
    getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter?: CameraDistortionParameter[],
        pcdParameter?: PcdParameter,
        needInvert?: boolean[],
    ): void;
    actionModel(isFineTuning: boolean): void;
    fit(): void;
    group(groupData: GroupData): void;
    configure(configuration: Configuration): void;
    summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]>;
    destroy(): void;
}

export class Canvas3dModelImpl extends MasterImpl implements Canvas3dModel {
    public data: Canvas3dDataModel;

    public constructor() {
        super();
        this.data = {
            activeElement: {
                clientID: null,
                attributeID: null,
                issueID: null,
            },
            canvasSize: {
                height: 0,
                width: 0,
            },
            objectUpdating: false,
            objects: [],
            groupedObjects: [],
            image: null,
            imageID: null,
            imageOffset: 0,
            imageSize: {
                height: 0,
                width: 0,
            },
            drawData: {
                enabled: false,
                initialState: null,
            },
            mode: Mode.IDLE,
            exception: null,
            focusData: {
                clientID: null,
                issueID: null,
            },
            groupData: {
                enabled: false,
                grouped: [],
            },
            selected: null,
            shapeProperties: {
                opacity: 40,
                outlined: false,
                outlineColor: '#000000',
                selectedOpacity: 60,
                colorBy: 'Label',
            },
            cameraCalibs: [],
            cameraToBumpers: [],
            cameraDistortionParameter: [],
            pcdParameter: {
                cameraRadius: 0, // 有值时（> 0））展示
                // 视场角
                // 起始线标注角度
                viewStartMiddleAngle: 0,
                // 标注角度
                viewAngle: 0,
            },
            isFineTuning: false,
            configuration: {
                forceDisableEditing: false,
                activeModel: ActivationModel.clickActive,
            },

            reviewData: {
                enabled: false,
                redraw: true,
            },
            // issueUpdate: false,
            issueRegions: [],
            summarize: {
                framePcd: undefined,
                data: [],
                objects: [],
                frame: 0,
            },
        };
    }

    public setup(frameData: any, objectStates: any[]): void {
        if (this.data.imageID !== frameData.number) {
            if ([Mode.EDIT, Mode.DRAG, Mode.RESIZE].includes(this.data.mode)) {
                throw Error(`Canvas is busy. Action: ${this.data.mode}`);
            }
        }
        if ([Mode.EDIT, Mode.BUSY].includes(this.data.mode)) {
            return;
        }

        if (frameData.number === this.data.imageID) {
            if (this.data.objectUpdating) {
                return;
            }
            this.data.objects = objectStates;
            this.data.objectUpdating = true;
            this.notify(UpdateReasons.OBJECTS_UPDATED);
            // this.data.issues = issues;
            this.data.objectUpdating = true;
            this.notify(UpdateReasons.REVIEW_UPDATED);
            this.data.objectUpdating = false; // 由于更新改成了异步，这里的控制会提前结束
            return;
        }

        this.data.imageID = frameData.number;
        frameData
            .data((): void => {
                this.data.image = null;

                this.notify(UpdateReasons.IMAGE_CHANGED);
            })
            .then((data: Image): void => {
                if (frameData.number !== this.data.imageID) {
                    // already another image
                    return;
                }

                this.data.imageSize = {
                    height: frameData.height as number,
                    width: frameData.width as number,
                };

                this.data.image = data;
                this.data.objects = objectStates;
                this.notify(UpdateReasons.IMAGE_CHANGED);
                this.notify(UpdateReasons.OBJECTS_UPDATED);
                this.notify(UpdateReasons.REVIEW_UPDATED);
            })
            .catch((exception: any): void => {
                this.data.exception = exception;
                this.notify(UpdateReasons.DATA_FAILED);
                throw exception;
            });
    }

    public setupIssueRegions(issueRegions: Issue[]): void {
        this.data.issueRegions = issueRegions;
        this.notify(UpdateReasons.ISSUE_REGIONS_UPDATED);
    }

    public set mode(value: Mode) {
        this.data.mode = value;
    }

    public get mode(): Mode {
        return this.data.mode;
    }

    public isAbleToChangeFrame(): boolean {
        const isUnable =
            [Mode.DRAG, Mode.EDIT, Mode.RESIZE, Mode.INTERACT, Mode.BUSY].includes(this.data.mode) ||
            (this.data.mode === Mode.DRAW && typeof this.data.drawData.redraw === 'number');
        return !isUnable;
    }

    public draw(drawData: DrawData): void {
        if (drawData.enabled && this.data.drawData.enabled && !drawData.initialState) {
            throw new Error('Drawing has been already started');
        }
        if ([Mode.DRAW, Mode.EDIT].includes(this.data.mode) && !drawData.initialState) {
            return;
        }
        this.data.drawData.enabled = drawData.enabled;
        this.data.mode = Mode.DRAW;

        if (typeof drawData.redraw === 'number') {
            const clientID = drawData.redraw;
            const [state] = this.data.objects.filter((_state: any): boolean => _state.clientID === clientID);

            if (state) {
                this.data.drawData = { ...drawData };
                this.data.drawData.initialState = { ...this.data.drawData.initialState, label: state.label };
                this.data.drawData.shapeType = state.shapeType;
            } else {
                return;
            }
        } else {
            this.data.drawData = { ...drawData };
            if (this.data.drawData.initialState) {
                this.data.drawData.shapeType = this.data.drawData.initialState.shapeType;
            }
        }
        this.notify(UpdateReasons.DRAW);
    }

    public cancel(): void {
        this.notify(UpdateReasons.CANCEL);
    }

    public dragCanvas(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.DRAG_CANVAS) {
            throw Error(`Canvas is not in the drag mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.DRAG_CANVAS : Mode.IDLE;
        this.notify(UpdateReasons.DRAG_CANVAS);
    }

    public reviewCanvas(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.REVIEW_CANVAS) {
            throw Error(`Canvas is not in the drag mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.REVIEW_CANVAS : Mode.IDLE;
        this.notify(UpdateReasons.REVIEW_CANVAS);
    }

    public activate(clientID: string, attributeID: number | null): void {
        if (this.data.activeElement.clientID === clientID && this.data.activeElement.attributeID === attributeID) {
            return;
        }
        if (this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }
        if (typeof clientID === 'number') {
            const [state] = this.data.objects.filter((_state: any): boolean => _state.clientID === clientID);
            if (!state || state.objectType === 'tag') {
                return;
            }
        }
        this.data.activeElement = {
            clientID,
            attributeID,
            issueID: null,
        };
        this.notify(UpdateReasons.SHAPE_ACTIVATED);
    }

    public activateIssue(issueID: string): void {
        if (this.data.activeElement.issueID === issueID) {
            return;
        }
        if (this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }
        if (typeof issueID === 'number') {
            const [state] = this.data.issueRegions.filter((_state: any): boolean => _state.id === issueID);
            if (!state) {
                return;
            }
        }
        this.data.activeElement = {
            clientID: null,
            attributeID: null,
            issueID,
        };
        this.notify(UpdateReasons.ISSUE_ACTIVATED);
    }

    public group(groupData: GroupData): void {
        if (![Mode.IDLE, Mode.GROUP].includes(this.data.mode)) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }

        if (this.data.groupData.enabled && groupData.enabled) {
            return;
        }

        if (!this.data.groupData.enabled && !groupData.enabled) {
            return;
        }
        this.data.mode = groupData.enabled ? Mode.GROUP : Mode.IDLE;
        this.data.groupData = { ...this.data.groupData, ...groupData };
        this.notify(UpdateReasons.GROUP);
    }

    public configureShapes(shapeProperties: ShapeProperties): void {
        this.data.drawData.enabled = false;
        this.data.mode = Mode.IDLE;
        this.cancel();
        this.data.shapeProperties = {
            ...shapeProperties,
        };
        this.notify(UpdateReasons.OBJECTS_UPDATED);
    }

    public configure(configuration: Configuration): void {
        if (typeof configuration.forceDisableEditing === 'boolean') {
            this.data.configuration.forceDisableEditing = configuration.forceDisableEditing;
        }
        if (typeof configuration.activeModel === 'string') {
            this.data.configuration.activeModel = configuration.activeModel;
        }

        this.notify(UpdateReasons.CONFIG_UPDATED);
    }

    // 总结数据
    public async summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]> {
        this.data.summarize.objects = objectStates;
        this.data.summarize.frame = frameData.number;
        this.data.summarize.data = [];
        this.data.summarize.framePcd = await frameData.data().catch((exception: any): void => {
            this.data.exception = exception;
            // this.notify(UpdateReasons.DATA_FAILED);
            throw exception;
        });

        await this.notifyAsync(UpdateReasons.summarize_data);

        // return this.data.summarize.data[this.data.summarize.frame];
        return this.data.summarize.data.map((item) => ({
            ...item,
            jobId: frameData.jid,
            frame: frameData.number,
        }));
    }

    public actionModel(isFineTuning: boolean): void {
        this.data.isFineTuning = isFineTuning;
    }

    public getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter?: CameraDistortionParameter[],
        pcdParameter?: PcdParameter,
        needInvert?: boolean[],
    ): void {
        if (cameraCalibs && cameraCalibs.length) {
            type Calib = [number, number, number, number, number, number, number, number, number];
            this.data.cameraCalibs = cameraCalibs.map(
                (cameraCalib: number[]): THREE.Matrix3 => new THREE.Matrix3().set(...(cameraCalib as Calib)),
            );
        }
        if (cameraToBumpers && cameraToBumpers.length) {
            type Bumper = [
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
            ];
            this.data.cameraToBumpers = cameraToBumpers.map((cameraToBumper: number[], index): THREE.Matrix4 => {
                if (needInvert?.[index]) {
                    return new THREE.Matrix4().set(...(cameraToBumper as Bumper)).invert();
                }
                return new THREE.Matrix4().set(...(cameraToBumper as Bumper));
            });
        }
        if (cameraDistortionParameter && cameraDistortionParameter.length) {
            this.data.cameraDistortionParameter = cameraDistortionParameter.map(
                (item): CameraDistortionParameter => ({
                    cameraType: item.cameraType || CameraType.normal,
                    k1: item.k1 || 0,
                    k2: item.k2 || 0,
                    k3: item.k3 || 0,
                    k4: item.k4 || 0,
                    k5: item.k5 || 0,
                    k6: item.k6 || 0,
                    s1: item.s1 || 0,
                    s2: item.s2 || 0,
                    s3: item.s3 || 0,
                    s4: item.s4 || 0,
                    p1: item.p1 || 0,
                    p2: item.p2 || 0,

                    // ...item,
                }),
            );
        } else {
            this.data.cameraDistortionParameter = cameraToBumpers.map(
                (): CameraDistortionParameter => ({
                    cameraType: CameraType.normal,
                    k1: 0,
                    k2: 0,
                    k3: 0,
                    k4: 0,
                    k5: 0,
                    k6: 0,
                    s1: 0,
                    s2: 0,
                    s3: 0,
                    s4: 0,
                    p1: 0,
                    p2: 0,
                }),
            );
        }

        if (pcdParameter) {
            this.data.pcdParameter = {
                ...pcdParameter,
            };
        }
    }

    public fit(): void {
        this.notify(UpdateReasons.FITTED_CANVAS);
    }

    public get issueRegions(): Issue[] {
        return { ...this.data.issueRegions };
    }

    public get groupData(): GroupData {
        return { ...this.data.groupData };
    }

    public get configuration(): Configuration {
        return { ...this.data.configuration };
    }

    public destroy(): void {}
}
