/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-03-16 19:55:24
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'containers/annotation-page/canvas/canvas-wrapper';
import TagAnnotationSidebar from './tag-annotation-sidebar/tag-annotation-sidebar';

export default function TagAnnotationWorkspace(props: any): JSX.Element {
    const { sidebarPositionLeft } = props;
    return (
        <Layout hasSider className='cvat-tag-annotation-workspace'>
            { sidebarPositionLeft && <TagAnnotationSidebar /> }

            <CanvasWrapperContainer />
            { !sidebarPositionLeft && <TagAnnotationSidebar /> }
            {/* <TagAnnotationSidebar /> */}
        </Layout>
    );
}
