// Copyright 2021 swxy
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React, { useState } from 'react';
import { Store } from 'redux';
import { Input, InputProps, Modal } from 'antd';
import { getCVATStore } from 'cvat-store';
import { CombinedState } from 'reducers/interfaces';

let store: null | Store<CombinedState> = null;

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}

export default (props: InputProps): JSX.Element => {
    const state: CombinedState = getStore().getState();

    const {
        value,
        onChange,
        onBlur,
        ...otherProps
    } = props;

    const [changeValue, setChangeValue] = useState(value);

    const checkSignIdRepeat = (inputValue: string): boolean => {
        if (value === inputValue || otherProps.defaultValue === inputValue) {
            // 和原来的相同，不需要校验
            return false;
        }

        const { annotation: { annotations: { states }, job: { serialidItemBylabelId } } } = state;

        let isRepeat = false;
        // 获取id
        try {
            const item = states.find((ele: any) => ele.attributes[serialidItemBylabelId[ele.label.id]] === inputValue);
            if (item) {
                isRepeat = true;
            }
        } catch (err) {
            throw new Error('Error finding id in object');
        }

        return isRepeat;
    };

    const onChangeBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (checkSignIdRepeat(event.target.value) && event.target && event.target.focus) {
            const target = event.currentTarget;
            Modal.warning({
                title: 'Warn',
                content: 'serialid already exists, please change one!',
                onOk: () => {
                    setChangeValue(value);
                    target.focus();
                },
                onCancel: () => {
                    setChangeValue(value);
                    target.focus();
                },
            });
            return;
        }

        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <Input
            {...otherProps}
            value={changeValue}
            onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                setChangeValue(event.target.value);
            }}
            onBlur={onChangeBlur}
        />
    );
};
