/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'project.projcet': '项目',
    'project.no_task': '无任务',
    'project.back_project_list': '返回项目列表',
    'project.task': '任务',
    'project.last_update_time': '最后更新时间',
    'project.create_by': '创建于',
    'project.pending': '待办',
    'project.in_progress': '进行中',
    'project.completed': '完成',
    'project.open': '打开',
    'project.back_project': '返回项目',
    'project.back_task': '返回任务',

    'project.job': '题包',
    'project.jobs': '题包列表',
    'project.frames': '帧数',
    'project.job_status': '题包状态',
    'project.start_date': '开始时间',
    'project.duration': '期间',
    'project.copy': '复制',
    'project.copy_success': '已复制到剪贴板！',

    'project.overlap_size': '重叠尺寸',
    'project.segment_size': '总数量',
    'project.image_quality': '画面质量',
};
