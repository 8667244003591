/*
 * @Author: swxy
 * @Date: 2022-08-15 10:13:12
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from 'utils/storage';
import translation_en_US from './en_US';
import translation_zh_CN from './zh_CH';
// import moment from 'moment';

export const resources = {
    en_US: {
        translation: translation_en_US,
    },
    zh_CN: {
        translation: translation_zh_CN,
    },
};

const defaultLanguage = getLanguage();
// if (!defaultLanguage) {
//     const spotList = ['zh_CN', 'en_US', 'ja_JP'];
//     const { language } = navigator;
// }

i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage || 'en_US',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
