// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import message from 'antd/lib/message';
import { LockFilled, UnlockOutlined, EyeInvisibleFilled, EyeOutlined, AimOutlined } from '@ant-design/icons';

import CVATTooltip from 'components/common/cvat-tooltip';
import LabelKeySelectorPopover from './label-key-selector-popover';
import { useTranslation } from 'react-i18next';

interface Props {
    labelName: string;
    labelColor: string;
    labelID: number;
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    keyToLabelMapping: Record<string, number>;
    ownObjectStates: any[];
    activeLabelID: number;
    hideStates(): void;
    showStates(): void;
    lockStates(): void;
    unlockStates(): void;
    updateLabelShortcutKey(updatedKey: string, labelID: number): void;
    activateObject(activatedStateID: number | null, activatedAttributeID: number | null): void;
    selectObjects(selectedStatesID: number[]): void;
    rememberObject(obj: any): void;
}

function LabelItemComponent(props: Props): JSX.Element {
    const {
        labelName,
        labelColor,
        labelID,
        keyToLabelMapping,
        visible,
        statesHidden,
        statesLocked,
        ownObjectStates,
        activeLabelID,
        hideStates,
        showStates,
        lockStates,
        unlockStates,
        updateLabelShortcutKey,
        activateObject,
        selectObjects,
        rememberObject,
    } = props;

    const { t } = useTranslation();

    // create reversed mapping just to receive key easily
    const labelToKeyMapping: Record<string, string> = Object.fromEntries(
        Object.entries(keyToLabelMapping).map(([key, _labelID]) => [_labelID, key]),
    );
    const labelShortcutKey = labelToKeyMapping[labelID] || '?';
    const classes = {
        lock: {
            enabled: { className: 'cvat-label-item-button-lock cvat-label-item-button-lock-enabled' },
            disabled: { className: 'cvat-label-item-button-lock' },
        },
        hidden: {
            enabled: { className: 'cvat-label-item-button-hidden cvat-label-item-button-hidden-enabled' },
            disabled: { className: 'cvat-label-item-button-hidden' },
        },
    };

    const click = (e: React.MouseEvent): void => {
        if (e) {
            e.stopPropagation();
        }
    };

    const select = (): void => {
        activateObject(null, null);
        selectObjects(
            ownObjectStates.reduce((previous: number[], current: any): number[] => {
                if (current.label.id === labelID) {
                    previous.push(current.clientID);
                }
                return previous;
            }, []),
        );
    };

    const changeActiveLabelID = (): void => {
        rememberObject({ activeLabelID: labelID });
        message.destroy();
        // message.success(`Default label was changed to "${labelName}"`);
        message.success(`${t('workspace.side.issue.defualt_label_msg')} "${labelName}"`);
    };

    return (
        <Row
            align='stretch'
            justify='space-around'
            className={[
                'cvat-objects-sidebar-label-item',
                visible ? '' : 'cvat-objects-sidebar-label-item-disabled',
                labelID === activeLabelID ? 'aatp_objects_sidebar_label_item_select' : '',
            ].join(' ')}
            onClick={changeActiveLabelID}
        >
            <Col span={2}>
                <div style={{ background: labelColor }} className='cvat-label-item-color'>
                    {' '}
                </div>
            </Col>
            <Col span={11}>
                <CVATTooltip title={labelName}>
                    <Text strong className='cvat-text'>
                        {labelName}
                    </Text>
                </CVATTooltip>
            </Col>
            <Col span={3}>
                <LabelKeySelectorPopover
                    keyToLabelMapping={keyToLabelMapping}
                    labelID={labelID}
                    updateLabelShortcutKey={updateLabelShortcutKey}
                >
                    <Button
                        className='cvat-label-item-setup-shortcut-button'
                        size='small'
                        ghost
                        type='dashed'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {labelShortcutKey}
                    </Button>
                </LabelKeySelectorPopover>
            </Col>
            <Col span={2} offset={1}>
                {statesLocked ? (
                    // <LockFilled {...classes.lock.enabled} onClick={unlockStates} />
                    <LockFilled
                        {...classes.lock.enabled}
                        onClick={(e: React.MouseEvent) => {
                            click(e);
                            unlockStates();
                        }}
                    />
                ) : (
                    // <UnlockOutlined {...classes.lock.disabled} onClick={lockStates} />
                    <UnlockOutlined
                        {...classes.lock.disabled}
                        // onClick={lockStates}
                        onClick={(e: React.MouseEvent) => {
                            click(e);
                            lockStates();
                        }}
                    />
                )}
            </Col>
            <Col span={2}>
                {statesHidden ? (
                    // <EyeInvisibleFilled {...classes.hidden.enabled} onClick={showStates} />

                    <EyeInvisibleFilled
                        {...classes.hidden.enabled}
                        // onClick={showStates}
                        onClick={(e: React.MouseEvent) => {
                            click(e);
                            showStates();
                        }}
                    />
                ) : (
                    // <EyeOutlined {...classes.hidden.disabled} onClick={hideStates} />
                    <EyeOutlined {...classes.hidden.disabled} onClick={hideStates} />
                )}
            </Col>
            <Col span={2} onClick={select}>
                <AimOutlined />
                {/* {statesHidden ? (
                    <EyeInvisibleFilled {...classes.hidden.enabled} onClick={showStates} />
                ) : (
                    <AimOutlined {...classes.hidden.disabled} onClick={hideStates} />
                )} */}
            </Col>
        </Row>
    );
}

export default React.memo(LabelItemComponent);
