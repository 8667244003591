// Copyright (C) 2019-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

(() => {
    const FormData = require('form-data');
    const { ServerError } = require('./exceptions');
    const store = require('store');
    const config = require('./config');
    const DownloadWorker = require('./download.worker');
    const Axios = require('axios');
    // const tus = require('tus-js-client');

    function enableOrganization() {
        return { org: config.organizationID || '' };
    }

    function removeToken() {
        Axios.defaults.headers.common.Authorization = '';
        store.remove('token');
        store.remove('loginInfo');
        store.remove('userInfo');
    }

    function waitFor(frequencyHz, predicate) {
        return new Promise((resolve, reject) => {
            if (typeof predicate !== 'function') {
                reject(new Error(`Predicate must be a function, got ${typeof predicate}`));
            }

            const internalWait = () => {
                let result = false;
                try {
                    result = predicate();
                } catch (error) {
                    reject(error);
                }

                if (result) {
                    resolve();
                } else {
                    setTimeout(internalWait, 1000 / frequencyHz);
                }
            };

            setTimeout(internalWait);
        });
    }

    function generateError(errorData) {
        if (errorData.response) {
            const message = `${errorData.message}. ${JSON.stringify(errorData.response.data) || ''}.`;
            return new ServerError(message, errorData.response.status);
        }

        // Server is unavailable (no any response)
        if (typeof errorData === 'string') {
            return new ServerError(errorData, 0);
        }
        if (typeof errorData === 'undefined') {
            return new ServerError('接口请求异常！请记下最近操作，并告知给管理员！', 0);
        }
        const message = `${errorData.message}.`; // usually is "Error Network"
        return new ServerError(message, 0);
    }

    function prepareData(details) {
        const data = new FormData();
        for (const [key, value] of Object.entries(details)) {
            if (Array.isArray(value)) {
                value.forEach((element, idx) => {
                    data.append(`${key}[${idx}]`, element);
                });
            } else {
                data.set(key, value);
            }
        }
        return data;
    }

    class WorkerWrappedAxios {
        constructor(requestInterseptor) {
            const worker = new DownloadWorker(requestInterseptor);
            const requests = {};
            let requestId = 0;

            worker.onmessage = (e) => {
                if (e.data.id in requests) {
                    if (e.data.isSuccess) {
                        requests[e.data.id].resolve(e.data.responseData);
                    } else {
                        requests[e.data.id].reject({
                            response: {
                                status: e.data.status,
                                data: e.data.responseData,
                            },
                        });
                    }

                    delete requests[e.data.id];
                }
            };

            worker.onerror = (e) => {
                if (e.data.id in requests) {
                    requests[e.data.id].reject(e);
                    delete requests[e.data.id];
                }
            };

            function getRequestId() {
                return requestId++;
            }

            async function get(url, requestConfig) {
                return new Promise((resolve, reject) => {
                    const newRequestId = getRequestId();
                    requests[newRequestId] = {
                        resolve,
                        reject,
                    };
                    worker.postMessage({
                        url,
                        config: requestConfig,
                        id: newRequestId,
                    });
                });
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    get: {
                        value: get,
                        writable: false,
                    },
                }),
            );
        }
    }

    class ServerProxy {
        constructor() {
            Axios.defaults.withCredentials = true;
            Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
            Axios.defaults.xsrfCookieName = 'csrftoken';
            const workerAxios = new WorkerWrappedAxios();
            Axios.interceptors.request.use((reqConfig) => {
                if ('params' in reqConfig && 'org' in reqConfig.params) {
                    return reqConfig;
                }

                reqConfig.params = { ...enableOrganization(), ...(reqConfig.params || {}) };
                return reqConfig;
            });

            // 添加响应拦截器
            Axios.interceptors.response.use((response) => {
                // 对响应数据做点什么
                if (response && response.data && response.data.success) {
                    return response;
                }
                if (response && response.data && response.data.code === 1007 && Axios.tokenExpiredCallback) {
                    removeToken();
                    const { tokenExpiredCallback } = Axios;
                    delete Axios.tokenExpiredCallback;
                    tokenExpiredCallback();
                    return response;
                }
                return response;
            }, (error) => Promise.reject(error));
            let token = store.get('token');
            if (token) {
                // Axios.defaults.headers.common.Authorization = `Token ${token}`;
                Axios.defaults.headers.common.Authorization = `${token}`;
            }

            async function about() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/about`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function share(directoryArg) {
                const { backendAPI } = config;
                const directory = encodeURI(directoryArg);

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/share`, {
                        proxy: config.proxy,
                        params: { directory },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function exception(exceptionObject) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/server/exception`, JSON.stringify(exceptionObject), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function formats() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/annotation/formats`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function userAgreements() {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/restrictions/user-agreements`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function register(username, firstName, lastName, email, password1, password2, confirmations) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username,
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        password1,
                        password2,
                        confirmations,
                    });
                    response = await Axios.post(`${config.backendAPI}/auth/register`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function login(username, password) {
                // const authenticationData = [
                //     `${encodeURIComponent('username')}=${encodeURIComponent(username)}`,
                //     `${encodeURIComponent('password')}=${encodeURIComponent(password)}`,
                // ]
                //     .join('&')
                //     .replace(/%20/g, '+');

                removeToken();
                let authenticationResponse = null;
                // console.log('config.backendAPI', config.backendAPI);
                try {
                    authenticationResponse = await Axios.post(`${config.backendAPI}/auth/login`, {
                        username,
                        password,
                    }, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (authenticationResponse.headers['set-cookie']) {
                    // Browser itself setup cookie and header is none
                    // In NodeJS we need do it manually
                    const cookies = authenticationResponse.headers['set-cookie'].join(';');
                    Axios.defaults.headers.common.Cookie = cookies;
                }

                // token = authenticationResponse.data.key;
                token = authenticationResponse.data.data.token;
                store.set('token', token);
                store.set('loginInfo', authenticationResponse.data.data);
                // Axios.defaults.headers.common.Authorization = `Token ${token}`;
                Axios.defaults.headers.common.Authorization = `${token}`;
            }

            async function logout() {
                try {
                    await Axios.post(`${config.backendAPI}/auth/logout`, {
                        proxy: config.proxy,
                    });
                    removeToken();
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function changePassword(oldPassword, newPassword1, newPassword2) {
                try {
                    const data = JSON.stringify({
                        old_password: oldPassword,
                        new_password1: newPassword1,
                        new_password2: newPassword2,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/change`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/jupdateAnnotationsson',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function requestPasswordReset(email) {
                try {
                    const data = JSON.stringify({
                        email,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/reset`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function resetPassword(newPassword1, newPassword2, uid, _token) {
                try {
                    const data = JSON.stringify({
                        new_password1: newPassword1,
                        new_password2: newPassword2,
                        uid,
                        token: _token,
                    });
                    await Axios.post(`${config.backendAPI}/auth/password/reset/confirm`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function authorized(callback) {
                try {
                    // await module.exports.users.self();
                    if (callback) {
                        Axios.tokenExpiredCallback = callback;
                    }
                    if (store.get('loginInfo')) {
                        return true;
                    }
                } catch (serverError) {
                    if (serverError.code === 401) {
                        removeToken();
                        return false;
                    }

                    throw serverError;
                }

                return false;
            }

            async function serverRequest(url, data) {
                try {
                    return (
                        await Axios({
                            url,
                            ...data,
                        })
                    ).data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getUser(filter) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (filter && filter.id) {
                        response = await Axios.post(`${backendAPI}/api/authUser/selectByPrimary`, {
                            id: filter.id,
                        }, {
                            proxy: config.proxy,
                        });
                        response = response.data;
                    } else {
                        throw new ServerError('没有传入查询id', 401);
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }
                if (response.success) {
                    return response.data;
                }
                throw new ServerError('没有用户信息', 401);
            }

            // async function getLabelAttributes(filter = {}) {
            //     const { backendAPI, proxy } = config;

            //     let response = null;
            //     try {
            //         response = await Axios.post(`${backendAPI}/api/engineAttributespec/select`, {
            //             ...filter,
            //         }, {
            //             proxy,
            //         });
            //         response = response.data;
            //         response = response.data;
            //         if (response) {
            //             response.forEach((item) => {
            //                 item.input_type = item.inputType;
            //                 item.default_value = item.defaultValue;
            //                 item.values = JSON.parse(item.values);
            //                 delete item.defaultValue;
            //                 delete item.inputType;
            //             });
            //         }
            //     } catch (errorData) {
            //         throw generateError(errorData);
            //     }

            //     // response.count = response.count;
            //     return response;
            // }

            // // 保存属性， 没有删除
            // async function saveLabelAttributes(filter = {}) {
            //     const { backendAPI, proxy } = config;

            //     let response = null;
            //     try {
            //         if (filter.id) {
            //             response = await Axios.post(`${backendAPI}/api/engineAttributespec/update`, {
            //                 ...filter,
            //             }, {
            //                 proxy,
            //             });
            //             response = response.data;
            //             if (!response.success) {
            //                 throw new Error(response.message);
            //             }
            //             return;
            //         }
            //         response = await Axios.post(`${backendAPI}/api/engineAttributespec/insert`, {
            //             ...filter,
            //         }, {
            //             proxy,
            //         });
            //         response = response.data;
            //         if (!response.success) {
            //             throw new Error(response.message);
            //         }
            //         return;
            //     } catch (errorData) {
            //         throw generateError(errorData);
            //     }

            //     // response.count = response.count;
            //     // return response;
            // }

            // async function deleteLabelAttributes(filter = {}) {
            //     const { backendAPI, proxy } = config;
            //     try {
            //         // 先删除属性

            //         await Axios.post(`${backendAPI}/api/engineAttributespec/delete`, {
            //             id: filter.id,
            //         }, {
            //             proxy,
            //         });
            //     } catch (errorData) {
            //         throw generateError(errorData);
            //     }
            // }

            async function getLabels(filter = {}) {
                const { backendAPI, proxy } = config;

                let response = null;
                try {
                    // response = await Axios.post(`${backendAPI}/api/engineLabel/selectNest`, {
                    response = await Axios.post(`${backendAPI}/api/engineProjectLabel/selectNest`, {
                        ...filter,
                        // pageSize: 12,
                    }, {
                        proxy,
                    });
                    response = response.data;

                    if (response.success) {
                        const controlTypes = [
                            'normal',
                            'serialid',
                            'select',
                            'radio',
                            'checkbox',
                            'number',
                            'text',
                        ];

                        const labels = response.data;
                        labels.forEach((label) => {
                            // label.attributes = label.engineAttributespecs;
                            // const { engineAttributespecs = [] } = label;
                            // label.attributes = engineAttributespecs.map((item) => ({
                            //     id: item.id,
                            //     labelId: 57,
                            //     labelSpecType: item.labelSpecType,
                            //     mutable: item.mutable,
                            //     name: item.name,
                            //     input_type: item.inputType,
                            //     default_value: item.defaultValue,
                            //     values: JSON.parse(item.values),
                            // }));
                            // delete label.engineAttributespecs;

                            // 将labelID设为默认id
                            [label.id, label.ownId] = [label.labelId, label.id];
                            const { engineProjectSpecs = [] } = label;
                            label.attributes = engineProjectSpecs.map((item) => ({
                                id: item.id,
                                labelId: item.labelId,
                                labelSpecType: item.labelSpecType,
                                mutable: item.mutable,
                                name: item.name,
                                input_type: controlTypes[item.controlType],
                                default_value: item.defaultValue,
                                values: JSON.parse(item.values),
                            }));
                            delete label.engineProjectSpecs;
                        });
                        return labels;
                    }
                    // response = await Axios.post(`${backendAPI}/api/engineLabel/select`, {
                    //     ...filter,
                    //     // pageSize: 12,
                    // }, {
                    //     proxy,
                    // });
                    // response = response.data;
                    // response = response.data;
                    // const promises = [];
                    // for (const label of response) {
                    //     promises.push(getLabelAttributes({ labelId: label.id }));
                    // }
                    // const results = await Promise.all(promises);
                    // response.forEach((label, index) => {
                    //     label.attributes = results[index];
                    // });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // response.count = response.count;
                return response;
            }

            // function getRandomColor() {
            //     return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            // }

            // async function deleteLabel(filter = {}) {
            //     const { backendAPI, proxy } = config;
            //     try {
            //         // 先删除属性
            //         if (filter.attributes && filter.attributes.length) {
            //             const promises = [];
            //             filter.attributes.forEach((item) => {
            //                 promises.push(deleteLabelAttributes(item));
            //             });
            //             await Promise.all(promises);
            //         }

            //         await Axios.post(`${backendAPI}/api/engineLabel/delete`, {
            //             id: filter.id,
            //         }, {
            //             proxy,
            //         });
            //     } catch (errorData) {
            //         throw generateError(errorData);
            //     }
            // }

            async function saveLabel(filter = {}) {
                const { backendAPI, proxy } = config;

                let response = null;
                try {
                    // const { attributes } = filter;
                    // delete filter.attributes;
                    // if (!filter.color) {
                    //     // 没有颜色时，随机生成一个颜色
                    //     filter.color = getRandomColor();
                    // }
                    // if (filter.deleted) {
                    //     await deleteLabel({ ...filter, attributes });
                    //     return;
                    // }
                    // if (filter.id) {
                    //     // 修改
                    //     response = await Axios.post(`${backendAPI}/api/engineLabel/update`, {
                    //         ...filter,
                    //     }, {
                    //         proxy,
                    //     });
                    //     response = response.data;
                    //     if (!response.success) {
                    //         throw new Error(response.message);
                    //     }

                    //     if (attributes && attributes.length) {
                    //         const promises = attributes
                    //             .map((item) => saveLabelAttributes({ labelId: filter.id, ...item }));
                    //         await Promise.all(promises);
                    //     }
                    //     return;
                    // }
                    response = await Axios.post(`${backendAPI}/api/aatp/label/batchLabel`, {
                        ...filter,
                    }, {
                        proxy,
                    });
                    response = response.data;
                    if (!response.success) {
                        throw new Error(response.message);
                    }
                    // if (attributes && attributes.length) {
                    //     const label = response.data;
                    //     const promises =
                    // attributes.map((item) => saveLabelAttributes({ labelId: label.id, ...item }));
                    //     await Promise.all(promises);
                    // }
                    return;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function searchProjectNames(search, limit) {
                const { backendAPI, proxy } = config;

                // console.log('是这里：21', filter);
                let response = null;
                try {
                    // response = await Axios.get(`${backendAPI}/projects`, {
                    response = await Axios.get(`${backendAPI}/api/engineProject/selectRelative`, {
                        // names_only: true,
                        // page: 1,
                        // pageSize: limit,
                        pageSize: limit,
                        name: search,
                    }, {
                        proxy,
                    });
                    response = response.data;
                    const { data } = response;
                    response = response.data.rows;
                    response.count = data.total;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // response.data.results.count = response.data.total;
                return response;
            }

            async function getProjects(filter = {}) {
                const { backendAPI, proxy } = config;

                let response = null;
                // console.log('是这里：1', filter);
                try {
                    if ('id' in filter) {
                        // response = await Axios.get(`${backendAPI}/projects/${filter.id}`, {
                        response = await Axios.post(`${backendAPI}/api/engineProject/selectByPrimary`, {
                            id: filter.id,
                        }, {
                            proxy,
                        });
                        response = response.data;
                        if (response.success) {
                            const project = response.data;
                            // 获取标签信息
                            const labels = await getLabels({ projectId: project.id });
                            project.labels = labels;

                            // 获取所有人信息
                            if (project.ownerId) {
                                const owner = await getUser({ id: project.ownerId });
                                project.owner = owner;
                            }

                            // 获取指定人信息
                            if (project.assigneeId) {
                                const assignee = await getUser({ id: project.assigneeId });
                                project.assignee = assignee;
                            }

                            const results = [project];
                            results.count = 1;
                            return results;
                        }
                        throw generateError(response.message);
                        // 根据项目id，查询相关的标签列表
                        // const results = [response.data];
                        // results.count = 1;
                        // return results;
                    }

                    response = await Axios.post(`${backendAPI}/api/engineProject/selectRelative`, {

                        ...filter,
                        pageSize: -1,
                    }, {
                        proxy,
                    });
                    response = response.data;
                    const { data } = response;
                    response = response.data.rows;
                    response.count = data.total;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // response.count = response.count;
                return response;
            }

            async function saveProject(id, projectData) {
                const { backendAPI } = config;
                // console.log('saveData', projectData);

                try {
                    const { labels } = projectData;
                    // delete projectData.labels;
                    // delete projectData.oldLabels;
                    if (labels) {
                        // 有删除，就只需要执行删除的。 由于操作时，只能同时操作一个标签。因此筛选。
                        const deleteLabels = labels.find((item) => {
                            const isAddAtt = (item.attributes || []).find((_att) => !_att.id);
                            // 当有id没有值的属性时，就代表操作的是当前标签。
                            return item.deleted || !item.id || isAddAtt;
                        });
                        const saveLabels = deleteLabels ? [deleteLabels] : labels;
                        const promises = [];
                        for (const value of saveLabels) {
                            promises.push(saveLabel({ ...value }));
                        }
                        await Promise.all(promises);
                        return;
                    }
                    await Axios.post(`${backendAPI}/api/engineProject/update`, {
                        id,
                        ...projectData,
                    }, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteProject(id) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/api/engineProject/delete`, {
                        id,
                    }, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function createProject(projectSpec) {
                const { backendAPI } = config;

                try {
                    let response = await Axios.post(`${backendAPI}/api/engineProject/insert`,
                        JSON.stringify(projectSpec), {
                            proxy: config.proxy,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                    response = response.data;
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getTasks(filter = {}) {
                const { backendAPI } = config;
                // ordering: "subset"
                // page: 1
                // pageSize: 10
                // projectId: 24
                let response = null;

                const taskTransformation = {
                    id: 'id',
                    name: 'name',
                    projectId: 'project_id',
                    status: 'status',
                    // size: 'id',
                    mode: 'mode',
                    // owner: 'id',
                    // assignee: 'id',
                    createdDate: 'created_date',
                    updatedDate: 'updated_date',
                    bugTracker: 'bug_tracker',
                    subset: 'subset',
                    overlap: 'overlap',
                    segmentSize: 'segment_size',
                    // image_quality: 'id',
                    startFrame: 0,
                    // stopFrame: 'stop_frame',
                    stopFrame: 'totalFrame',
                    // frame_filter: 'id',
                    // data_chunk_size: 'id',
                    // data_compressed_chunk_type: 'id',
                    // data_original_chunk_type: 'id',
                    // use_zip_chunks: 'id',
                    // use_cache: 'id',
                    // copy_data: 'id',
                    dimension: 'dimension',
                    // cloud_storage_id: 'id',
                    // sorting_method: 'id',
                    ownerId: 'ownerId',
                    assigneeId: 'assigneeId',
                    taskStatus: 'taskStatus',
                    totalFrame: 'totalFrame',
                };

                try {
                    if ('id' in filter) {
                        response = await Axios.post(`${backendAPI}/api/engineTask/selectByPrimary`, {
                            id: filter.id,
                        }, {
                            proxy: config.proxy,
                        });
                        response = response.data;
                        if (response.success) {
                            const task = response.data;
                            for (const key in task) {
                                if (Object.hasOwnProperty.call(task, key)) {
                                    task[taskTransformation[key]] = task[key];
                                }
                            }
                            // 获取标签信息
                            const labels = await getLabels({ projectId: task.id });
                            task.labels = labels;

                            // 获取所有人信息
                            if (task.ownerId) {
                                const owner = await getUser({ id: task.ownerId });
                                task.owner = owner;
                            }

                            // 获取指定人信息
                            if (task.assigneeId) {
                                const assignee = await getUser({ id: task.assigneeId });
                                task.assignee = assignee;
                            }

                            const results = [task];
                            results.count = 1;
                            return results;
                        }
                        throw generateError(response.message);
                    }

                    response = await Axios.post(`${backendAPI}/api/engineTask/select`, {
                        ...filter,
                        pageSize: 10,
                    }, {
                        proxy: config.proxy,
                    });
                    response = response.data;
                    if (response.success) {
                        const tasks = response.data;
                        for (const task of tasks) {
                            for (const key in task) {
                                if (Object.hasOwnProperty.call(task, key)) {
                                    task[taskTransformation[key]] = task[key];
                                }
                            }
                        }
                        response = tasks;
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.count = response.length;
                return response;
            }

            async function saveTask(id, taskData) {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/engineTask/update`, {
                        id,
                        ...taskData,
                    }, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    response = response.data;
                    if (response.success) {
                        response = await getTasks({ id });
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response;
            }

            async function deleteTask(id) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/api/engineTask/delete`, {
                        id,
                    }, {
                        // ...(organizationID ? { org: organizationID } : {}),
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            function exportDataset(instanceType) {
                return async function (id, format, name, saveImages) {
                    const { backendAPI } = config;
                    const baseURL = `${backendAPI}/${instanceType}/${id}/${saveImages ? 'dataset' : 'annotations'}`;
                    const params = {
                        ...enableOrganization(),
                        format,
                    };

                    if (name) {
                        params.filename = name.replace(/\//g, '_');
                    }

                    return new Promise((resolve, reject) => {
                        async function request() {
                            Axios.get(baseURL, {
                                proxy: config.proxy,
                                params,
                            })
                                .then((response) => {
                                    if (response.status === 202) {
                                        setTimeout(request, 3000);
                                    } else {
                                        params.action = 'download';
                                        resolve(`${baseURL}?${new URLSearchParams(params).toString()}`);
                                    }
                                })
                                .catch((errorData) => {
                                    reject(generateError(errorData));
                                });
                        }

                        setTimeout(request);
                    });
                };
            }

            async function importDataset(id, format, file, onUpdate) {
                const { backendAPI } = config;
                const url = `${backendAPI}/projects/${id}/dataset`;

                const formData = new FormData();
                formData.append('dataset_file', file);

                return new Promise((resolve, reject) => {
                    async function requestStatus() {
                        try {
                            const response = await Axios.get(`${url}?action=import_status`, {
                                proxy: config.proxy,
                            });
                            if (response.status === 202) {
                                if (onUpdate && response.data.message !== '') {
                                    onUpdate(response.data.message, response.data.progress || 0);
                                }
                                setTimeout(requestStatus, 3000);
                            } else if (response.status === 201) {
                                resolve();
                            } else {
                                reject(generateError(response));
                            }
                        } catch (error) {
                            reject(generateError(error));
                        }
                    }

                    Axios.post(`${url}?format=${format}`, formData, {
                        proxy: config.proxy,
                    }).then(() => {
                        setTimeout(requestStatus, 2000);
                    }).catch((error) => {
                        reject(generateError(error));
                    });
                });
            }

            async function exportTask(id) {
                const { backendAPI } = config;
                const params = {
                    ...enableOrganization(),
                };
                const url = `${backendAPI}/tasks/${id}/backup`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.get(url, {
                                proxy: config.proxy,
                                params,
                            });
                            if (response.status === 202) {
                                setTimeout(request, 3000);
                            } else {
                                params.action = 'download';
                                resolve(`${url}?${new URLSearchParams(params).toString()}`);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            async function importTask(file) {
                const { backendAPI } = config;
                // keep current default params to 'freeze" them during this request
                const params = enableOrganization();

                let taskData = new FormData();
                taskData.append('task_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.post(`${backendAPI}/tasks/backup`, taskData, {
                                proxy: config.proxy,
                                params,
                            });
                            if (response.status === 202) {
                                taskData = new FormData();
                                taskData.append('rq_id', response.data.rq_id);
                                setTimeout(request, 3000);
                            } else {
                                // to be able to get the task after it was created, pass frozen params
                                const importedTask = await getTasks({ id: response.data.id, ...params });
                                resolve(importedTask[0]);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            async function backupProject(id) {
                const { backendAPI } = config;
                // keep current default params to 'freeze" them during this request
                const params = enableOrganization();
                const url = `${backendAPI}/projects/${id}/backup`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.get(url, {
                                proxy: config.proxy,
                                params,
                            });
                            if (response.status === 202) {
                                setTimeout(request, 3000);
                            } else {
                                params.action = 'download';
                                resolve(`${url}?${new URLSearchParams(params).toString()}`);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            async function restoreProject(file) {
                const { backendAPI } = config;
                // keep current default params to 'freeze" them during this request
                const params = enableOrganization();

                let data = new FormData();
                data.append('project_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.post(`${backendAPI}/projects/backup`, data, {
                                proxy: config.proxy,
                                params,
                            });
                            if (response.status === 202) {
                                data = new FormData();
                                data.append('rq_id', response.data.rq_id);
                                setTimeout(request, 3000);
                            } else {
                                // to be able to get the task after it was created, pass frozen params
                                const restoredProject = await getProjects({ id: response.data.id, ...params });
                                resolve(restoredProject[0]);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            async function createTask(values) {
                const formData = new FormData();
                for (const [key, value] of Object.entries(values)) {
                    formData.append(key, value);
                }

                const { backendAPI } = config;
                let response = null;

                try {
                    response = await Axios.post(`${backendAPI}/api/aatp/project/createTask`, formData, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    response = response.data;
                    if (response.success) {
                        response = response.data;
                    } else {
                        throw new ServerError(500, response.message);
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }
                return response;
            }
            // async function createTask(taskSpec, taskDataSpec, onUpdate) {
            //     const { backendAPI, origin } = config;
            //     // keep current default params to 'freeze" them during this request
            //     const params = enableOrganization();

            //     async function wait(id) {
            //         return new Promise((resolve, reject) => {
            //             async function checkStatus() {
            //                 try {
            //                     const response = await Axios.get(`${backendAPI}/tasks/${id}/status`, { params });
            //                     if (['Queued', 'Started'].includes(response.data.state)) {
            //                         if (response.data.message !== '') {
            //                             onUpdate(response.data.message, response.data.progress || 0);
            //                         }
            //                         setTimeout(checkStatus, 1000);
            //                     } else if (response.data.state === 'Finished') {
            //                         resolve();
            //                     } else if (response.data.state === 'Failed') {
            //                         // If request has been successful, but task hasn't been created
            //                         // Then passed data is wrong and we can pass code 400
            //                         const message = `
            //                             Could not create the task on the server. ${response.data.message}.
            //                         `;
            //                         reject(new ServerError(message, 400));
            //                     } else {
            //                         // If server has another status, it is unexpected
            //                         // Therefore it is server error and we can pass code 500
            //                         reject(
            //                             new ServerError(
            //                                 `Unknown task state has been received: ${response.data.state}`,
            //                                 500,
            //                             ),
            //                         );
            //                     }
            //                 } catch (errorData) {
            //                     reject(generateError(errorData));
            //                 }
            //             }

            //             setTimeout(checkStatus, 1000);
            //         });
            //     }

            //     const chunkSize = config.uploadChunkSize * 1024 * 1024;
            //     const clientFiles = taskDataSpec.client_files;
            //     const chunkFiles = [];
            //     const bulkFiles = [];
            //     let totalSize = 0;
            //     let totalSentSize = 0;
            //     for (const file of clientFiles) {
            //         if (file.size > chunkSize) {
            //             chunkFiles.push(file);
            //         } else {
            //             bulkFiles.push(file);
            //         }
            //         totalSize += file.size;
            //     }
            //     delete taskDataSpec.client_files;

            //     const taskData = new FormData();
            //     for (const [key, value] of Object.entries(taskDataSpec)) {
            //         if (Array.isArray(value)) {
            //             value.forEach((element, idx) => {
            //                 taskData.append(`${key}[${idx}]`, element);
            //             });
            //         } else {
            //             taskData.set(key, value);
            //         }
            //     }

            //     let response = null;

            //     onUpdate('The task is being created on the server..', null);
            //     try {
            //         response = await Axios.post(`${backendAPI}/tasks`, JSON.stringify(taskSpec), {
            //             proxy: config.proxy,
            //             params,
            //             headers: {
            //                 'Content-Type': 'application/json',
            //             },
            //         });
            //     } catch (errorData) {
            //         throw generateError(errorData);
            //     }

            //     onUpdate('The data are being uploaded to the server..', null);

            //     async function chunkUpload(taskId, file) {
            //         return new Promise((resolve, reject) => {
            //             const upload = new tus.Upload(file, {
            //                 endpoint: `${origin}${backendAPI}/tasks/${taskId}/data/`,
            //                 metadata: {
            //                     filename: file.name,
            //                     filetype: file.type,
            //                 },
            //                 headers: {
            //                     // Authorization: `Token ${store.get('token')}`,
            //                     Authorization: `${store.get('token')}`,
            //                 },
            //                 chunkSize,
            //                 retryDelays: null,
            //                 onError(error) {
            //                     reject(error);
            //                 },
            //                 onBeforeRequest(req) {
            //                     const xhr = req.getUnderlyingObject();
            //                     const { org } = params;
            //                     req.setHeader('X-Organization', org);
            //                     xhr.withCredentials = true;
            //                 },
            //                 onProgress(bytesUploaded) {
            //                     const currentUploadedSize = totalSentSize + bytesUploaded;
            //                     const percentage = currentUploadedSize / totalSize;
            //                     onUpdate('The data are being uploaded to the server', percentage);
            //                 },
            //                 onSuccess() {
            //                     totalSentSize += file.size;
            //                     resolve();
            //                 },
            //             });
            //             upload.start();
            //         });
            //     }

            //     async function bulkUpload(taskId, files) {
            //         const fileBulks = files.reduce((fileGroups, file) => {
            //             const lastBulk = fileGroups[fileGroups.length - 1];
            //             if (chunkSize - lastBulk.size >= file.size) {
            //                 lastBulk.files.push(file);
            //                 lastBulk.size += file.size;
            //             } else {
            //                 fileGroups.push({ files: [file], size: file.size });
            //             }
            //             return fileGroups;
            //         }, [{ files: [], size: 0 }]);
            //         const totalBulks = fileBulks.length;
            //         let currentChunkNumber = 0;
            //         while (currentChunkNumber < totalBulks) {
            //             for (const [idx, element] of fileBulks[currentChunkNumber].files.entries()) {
            //                 taskData.append(`client_files[${idx}]`, element);
            //             }
            //             const percentage = totalSentSize / totalSize;
            //             onUpdate('The data are being uploaded to the server', percentage);
            //             await Axios.post(`${backendAPI}/tasks/${taskId}/data`, taskData, {
            //                 ...params,
            //                 proxy: config.proxy,
            //                 headers: { 'Upload-Multiple': true },
            //             });
            //             for (let i = 0; i < fileBulks[currentChunkNumber].files.length; i++) {
            //                 taskData.delete(`client_files[${i}]`);
            //             }
            //             totalSentSize += fileBulks[currentChunkNumber].size;
            //             currentChunkNumber++;
            //         }
            //     }

            //     try {
            //         await Axios.post(`${backendAPI}/tasks/${response.data.id}/data`,
            //             taskData, {
            //                 ...params,
            //                 proxy: config.proxy,
            //                 headers: { 'Upload-Start': true },
            //             });
            //         for (const file of chunkFiles) {
            //             await chunkUpload(response.data.id, file);
            //         }
            //         if (bulkFiles.length > 0) {
            //             await bulkUpload(response.data.id, bulkFiles);
            //         }
            //         await Axios.post(`${backendAPI}/tasks/${response.data.id}/data`,
            //             taskData, {
            //                 ...params,
            //                 proxy: config.proxy,
            //                 headers: { 'Upload-Finish': true },
            //             });
            //     } catch (errorData) {
            //         try {
            //             await deleteTask(response.data.id, params.org || null);
            //         } catch (_) {
            //             // ignore
            //         }
            //         throw generateError(errorData);
            //     }

            //     try {
            //         await wait(response.data.id);
            //     } catch (createException) {
            //         await deleteTask(response.data.id, params.org || null);
            //         throw createException;
            //     }

            //     // to be able to get the task after it was created, pass frozen params
            //     const createdTask = await getTasks({ id: response.data.id, ...params });
            //     return createdTask[0];
            // }

            async function getJobs(filter = {}) {
                const { backendAPI } = config;
                const id = filter.id || null;

                let response = null;
                try {
                    if (id !== null) {
                        // response = await Axios.post(`${backendAPI}/api/engineJob/selectNest`, {
                        response = await Axios.post(`${backendAPI}/api/aatp/job/selectNest`, {
                            id,
                        }, {
                            proxy: config.proxy,
                        });
                        response = response.data;
                        if (response.success) {
                            const jobs = response.data;

                            let job = jobs.infos[0];
                            job = { ...job, ...jobs.shapes[0] };
                            job = { ...job, ...jobs.tracks[0] };

                            // backOneTimes: 0
                            // backReceiveTimes: 0
                            // backTwoTimes: 0
                            // createdDate: "2022-03-29 05:11:43"
                            // dimension: "three"
                            // enableReview: false
                            // engineBlocks: (4) [{…}, {…}, {…}, {…}]
                            // engineLabeledimages: []
                            // engineLabeledshapes: []
                            // engineLabeledtracks: []
                            // engineReviews: []
                            // id: 94
                            // imgHeight: 1080
                            // imgWidth: 1920
                            // jobStatus: "正常"
                            // name: "yj_20220304-0"
                            // pcdHeight: 0
                            // pcdWidth: 0
                            // projectId: 30
                            // startFrame: 0
                            // status: "annotation"
                            // stopFrame: 19
                            // taskId: 91
                            // totalBlock: 4
                            // totalFrame: 20
                            // updatedDate: "2022-03-29 05:11:43"

                            // job.task_id = job.taskId;
                            // job.project_id = job.projectId;
                            // job.start_frame = job.startFrame;
                            // job.stop_frame = job.stopFrame;
                            // job.mode = job.status;
                            // job.bug_tracker = '';
                            // job.data_chunk_size = 72;
                            // job.data_compressed_chunk_type = 'imageset';
                            job.dimension = job.engineTask.engineProject.projectType === 2 ? '3d' : '2d';
                            // job.stage = job.status;
                            // job.state = job.jobStatus;

                            job.labels = await getLabels({ projectId: job.projectId });
                            // job.status = job.status;
                            // 获取标签信息
                            // const labels = await getLabels({ projectId: 30 });
                            // job.labels = labels;

                            return job;
                        }
                        throw new Error(response.message);
                    }
                    response = await Axios.post(`${backendAPI}/api/engineJob/selectRelative`, {
                    // response = await Axios.post(`${backendAPI}/api/engineJob/select`, {
                        ...filter,
                        // pageSize: 12,
                    }, {
                        proxy: config.proxy,
                        // params: {
                        //     ...filter,
                        //     page_size: 12,
                        // },
                    });
                    response = response.data;
                    // response = response.data;
                    if (response.success) {
                        const { data } = response;
                        response = data.rows;
                        response.count = data.total;
                        return response;
                    }
                    throw new Error(response.message);
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // return response;
            }

            async function data3D({ shape = [], job = {} }) {
                const { backendAPI } = config;

                let response = null;
                try {
                    // 先查询有没有
                    if (!job.jobId) {
                        throw new Error('参数jobId错误');
                    }

                    // 先查询有没有
                    let jobInfo = await Axios.post(`${backendAPI}/api/dumpJobInfo/select`, {
                        jobId: job.jobId,
                    }, {
                        proxy: config.proxy,
                    });
                    jobInfo = jobInfo.data;
                    if (jobInfo.data && jobInfo.data.length) {
                        [jobInfo] = jobInfo.data;
                    } else {
                        jobInfo = {};
                    }

                    const result = await Axios.post(`${backendAPI}/api/dumpFrameShape/delete`, {
                        jobId: job.jobId,
                    }, {
                        proxy: config.proxy,
                    });
                    if (!result.data.success) {
                        throw new Error('清除数据失败！');
                    }

                    const promises = [];
                    promises.push(Axios.post(`${backendAPI}${jobInfo.id ? '/api/dumpJobInfo/update' : '/api/dumpJobInfo/insert'}`, {
                        ...jobInfo,
                        ...job,
                    }, {
                        proxy: config.proxy,
                    }),
                    Axios.post(`${backendAPI}/api/dumpFrameShape/insertSync`, shape, {
                    // Axios.post(`${backendAPI}/api/dumpFrameShape/insertAsyn`, shape, {
                        proxy: config.proxy,
                    }));

                    response = await Promise.all(promises);
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.every((result) => result.data.success);
            }

            async function submit(data = {}) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/engineReview/insert`, data, {
                        proxy: config.proxy,
                    });
                    response = response.data;
                    if (!response || !response.success) {
                        throw generateError(response && response.message ? response.message : '变更题包状态失败！');
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response;
            }

            async function getJobIssues(jobId) {
                // if (jobID) {
                //     return [];
                // }
                // return [];
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/engineLabeledimagenote/select`, {
                        jobId,
                    }, {
                        proxy: config.proxy,
                    });
                    response = response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data || [];
            }

            // 设置预先评论类型列表
            async function getJobBeforehandIssues(jobId) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/engineProjectNote/select`, {
                        jobId,
                    }, {
                        proxy: config.proxy,
                        'Content-Type': 'application/json',
                    });
                    response = response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data || [];
                // if (jobID) {
                //     return [{
                //         id: 1,
                //         message: '这是错误评论',
                //         labeler: '评论人',
                //     }, {
                //         id: 2,
                //         message: '漏标',
                //         labeler: '评论人',
                //     }, {
                //         id: 3,
                //         message: '错误标注',
                //         labeler: '评论人',
                //     }, {
                //         id: 4,
                //         message: '这是错误评论',
                //         labeler: '评论人',
                //     }];
                // }
                // return [];
            }

            async function createComment(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/comments`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    response = response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function createIssue(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios
                        .post(
                            `${backendAPI}/api/engineLabeledimagenote/insert`,
                            {
                                ...data,
                            },
                            {
                                proxy: config.proxy,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            },
                        );
                    response = response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function updateIssue(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(
                        `${backendAPI}/api/engineLabeledimagenote/update`,
                        {
                            ...data,
                        },
                        {
                            proxy: config.proxy,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        },
                    );
                    response = response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response;
            }

            async function deleteIssue(issueID) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/api/engineLabeledimagenote/delete`,
                        {
                            id: issueID,
                        },
                        {
                            proxy: config.proxy,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function saveJob(id, jobData) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.patch(`${backendAPI}/jobs/${id}`, JSON.stringify(jobData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    response = response.data;
                    if (response.success === false) {
                        throw new Error(response.message);
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response;
            }

            async function saveRelation(relation, action) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (action === 'create') {
                        response = await Axios.post(`${backendAPI}/api/tShapeRelation/insert`,
                            relation,
                            {
                                proxy: config.proxy,
                            });
                    }
                    if (action === 'update') {
                        response = await Axios.post(`${backendAPI}/api/tShapeRelation/update`,
                            relation,
                            {
                                proxy: config.proxy,
                            });
                    }
                    if (action === 'delete') {
                        response = await Axios.post(`${backendAPI}/api/tShapeRelation/delete`,
                            relation,
                            {
                                proxy: config.proxy,
                            });
                    }
                    response = response.data;
                    if (response.success) {
                        return response.data;
                    }
                    throw generateError(response.message);
                } catch (errorData) {
                    throw generateError(errorData);
                }
                // return response;
            }

            async function getRelation(relation) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/tShapeRelation/select`,
                        {
                            jobId: relation,
                        },
                        {
                            proxy: config.proxy,
                            // headers: {
                            //     'Content-Type': 'application/json',
                            // },
                        });
                    response = response.data;
                    if (response.success) {
                        return response.data;
                    }
                    throw generateError(response.message);
                } catch (errorData) {
                    throw generateError(errorData);
                }
                // return response;
            }

            async function getUsers(filter = { page_size: 'all' }) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/authUser/select`, {
                        ...filter,
                    }, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data.data;
            }

            async function getSelf() {
                const { backendAPI } = config;

                let response = null;
                // console.log('loginInfo:', loginInfo);
                try {
                    const loginInfo = store.get('loginInfo');
                    const userInfo = store.get('userInfo');
                    // const loginToken = store.get('token');
                    // if (loginToken) {
                    //     // Axios.defaults.headers.common.Authorization = `Token ${token}`;
                    //     Axios.defaults.headers.common.Authorization = `${token}`;
                    // }
                    if (userInfo) {
                        return userInfo;
                    }
                    if (loginInfo && loginInfo.id) {
                        response = await Axios.post(`${backendAPI}/api/authUser/selectByPrimary`, {
                            id: loginInfo.id,
                        }, {
                            proxy: config.proxy,
                        });
                        response = response.data;
                    } else {
                        throw new ServerError('message', 401);
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                store.set('userInfo', response.data);
                return response.data;
            }

            async function getPreview(tid, jid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/${jid !== null ? 'jobs' : 'tasks'}/${jid || tid}/data`;
                    response = await Axios.get(url, {
                        params: {
                            type: 'preview',
                        },
                        proxy: config.proxy,
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    const code = errorData.response ? errorData.response.status : errorData.code;
                    throw new ServerError(`Could not get preview frame for the task ${tid} from the server`, code);
                }

                return response.data;
            }

            async function getImageContext(jid, frame) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/jobs/${jid}/data`, {
                        params: {
                            quality: 'original',
                            type: 'context_image',
                            number: frame,
                        },
                        proxy: config.proxy,
                        // responseType: 'blob',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getData(tid, jid, chunk) {
                const { backendAPI } = config;

                // const url = jid === null ? `tasks/${tid}/data` : `jobs/${jid}/data`;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/aatp/project/downBlock`, {
                        jobId: jid,
                        blockIndex: chunk,
                    }, {
                        // params: {
                        //     // ...enableOrganization(),
                        //     quality: 'compressed',
                        //     type: 'chunk',
                        //     number: chunk,
                        // },
                        proxy: config.proxy,
                        responseType: 'arraybuffer',
                    });
                    response = response.data;
                    if (response && response instanceof ArrayBuffer) {
                        // 是下载文件
                    } else {
                        throw generateError(response);
                    }
                } catch (errorData) {
                    throw generateError({
                        message: '',
                        response: {
                            ...errorData.response,
                            data: String.fromCharCode.apply(null, new Uint8Array(errorData.response.data)),
                        },
                    });
                }

                return response;
            }

            async function getMeta(tid, jid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/api/engineJob/selectNest`, {
                        id: jid,
                    }, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            // function toAttributes(attributes) {
            //     if (Array.isArray(attributes)) {
            //         if (attributes && attributes.length) {
            //             for (const attribute of attributes) {
            //                 toAttributes(attribute);
            //             }
            //             return;
            //         }
            //     }
            //     attributes.specId = `${attributes.specId}`;
            //     delete attributes.specId;
            //     delete attributes.insert;
            // }

            function toShape(shape, trackId) {
                if (Array.isArray(shape)) {
                    if (shape && shape.length) {
                        for (const object of shape) {
                            toShape(object, trackId);
                        }
                    }
                    return;
                }
                shape.label_id = shape.labelId;
                shape.z_order = shape.zOrder || 0;
                // shape.specId = shape.specId;

                if (shape.engineLabeledshapeattributevals) {
                    shape.attributes = shape.engineLabeledshapeattributevals;
                }

                if (trackId && shape.engineTrackedshapeattributevals) {
                    shape.attributes = shape.engineTrackedshapeattributevals;
                }

                // if (shape.attributes) {
                //     toAttributes(shape.attributes);
                // }

                if (shape.points) {
                    shape.points = JSON.parse(shape.points);
                }

                // if (shape.specId === undefined) {
                //     delete shape.specId;
                // }

                // delete shape.labelId;
                // delete shape.zOrder;
                delete shape.engineLabeledshapeattributevals;
                delete shape.engineTrackedshapeattributevals;
                // delete shape.specId;
                // delete shape.insert;

                if (shape.shapes && shape.shapes.length) {
                    toShape(shape.shapes);
                }
            }

            function toTrack(track) {
                if (Array.isArray(track)) {
                    if (track && track.length) {
                        for (const object of track) {
                            toTrack(object);
                        }
                    }
                    return;
                }
                // track.label_id = track.labelId;
                // track.z_order = track.zOrder;
                // track.specId = track.specId;

                if (track.engineLabeledtrackattributevals) {
                    track.attributes = track.engineLabeledtrackattributevals;
                }

                // if (track.attributes) {
                //     toAttributes(track.attributes);
                // }

                if (track.engineTrackedshapes) {
                    track.shapes = track.engineTrackedshapes;
                }

                if (track.shapes && track.shapes.length) {
                    toShape(track.shapes, track.id);
                }

                if (track.points) {
                    track.points = JSON.parse(track.points);
                }
                // delete track.labelId;
                // delete track.zOrder;
                delete track.engineLabeledshapeattributevals;
                delete track.engineLabeledtrackattributevals;
                delete track.engineTrackedshapes;
                // delete track.specId;
                // delete track.insert;
            }

            // Session is 'task' or 'job'
            async function getAnnotations(session, id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    // response = await Axios.get(`${backendAPI}/${session}s/${id}/annotations`, {
                    response = await Axios.post(`${backendAPI}/api/engineJob/selectNest`, {
                        id,
                    }, {
                        proxy: config.proxy,
                    });
                    response = response.data;
                    if (response.success) {
                        const [job] = response.data;
                        const { engineLabeledshapes, engineLabeledtracks } = job;

                        toShape(engineLabeledshapes);
                        toTrack(engineLabeledtracks);

                        return {
                            shapes: engineLabeledshapes,
                            tracks: engineLabeledtracks,
                            tags: [],
                        };
                    }
                    throw generateError(response.message);
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            // Session is 'task' or 'job'
            async function updateAnnotations(
                session,
                id,
                data,
                action,
            ) {
                // console.log('保存：', data);
                const { backendAPI } = config;

                let response = null;
                try {
                    if (action === 'shape') {
                        response = await Axios.post(`${backendAPI}/api/aatp/shape/batchLabeledshape`, {
                            ...data,
                        }, {
                            proxy: config.proxy,
                        });
                    }

                    if (action === 'track') {
                        response = await Axios.post(`${backendAPI}/api/aatp/shape/batchLabeledtrack`, {
                            ...data,
                        }, {
                            proxy: config.proxy,
                        });
                    }

                    response = response.data;

                    if (response.success) {
                        const update = response.data;
                        const { deletes, merges } = update;
                        return {
                            deletes: deletes || [],
                            merges: merges || [],
                        };
                        // if (action === 'shape') {
                        //     toShape(deletes);
                        //     toShape(merges);
                        // }
                        // if (action === 'track') {
                        //     toTrack(deletes);
                        //     toTrack(merges);
                        // }
                        // return {
                        //     deletes: {
                        //         shapes: action === 'shape' ? deletes : [],
                        //         tags: [],
                        //         tracks: action === 'track' ? deletes : [],
                        //     },
                        //     merges: {
                        //         shapes: action === 'shape' ? merges : [],
                        //         tags: [],
                        //         tracks: action === 'track' ? merges : [],
                        //     },
                        // };
                    }
                    throw generateError(response.message);
                    // response = await requestFunc(url, JSON.stringify(data), {
                    //     proxy: config.proxy,
                    //     params,
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    // });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                // return response.data;
            }

            // Session is 'task' or 'job'
            async function uploadAnnotations(session, id, file, format) {
                const { backendAPI } = config;
                const params = {
                    ...enableOrganization(),
                    format,
                };
                let annotationData = new FormData();
                annotationData.append('annotation_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.put(
                                `${backendAPI}/${session}s/${id}/annotations`,
                                annotationData,
                                {
                                    params,
                                    proxy: config.proxy,
                                },
                            );
                            if (response.status === 202) {
                                annotationData = new FormData();
                                setTimeout(request, 3000);
                            } else {
                                resolve();
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }
                    }

                    setTimeout(request);
                });
            }

            // Session is 'task' or 'job'
            async function dumpAnnotations(id, name, format) {
                const { backendAPI } = config;
                const baseURL = `${backendAPI}/tasks/${id}/annotations`;
                const params = enableOrganization();
                params.format = encodeURIComponent(format);
                if (name) {
                    const filename = name.replace(/\//g, '_');
                    params.filename = encodeURIComponent(filename);
                }

                return new Promise((resolve, reject) => {
                    async function request() {
                        Axios.get(baseURL, {
                            proxy: config.proxy,
                            params,
                        })
                            .then((response) => {
                                if (response.status === 202) {
                                    setTimeout(request, 3000);
                                } else {
                                    params.action = 'download';
                                    resolve(`${baseURL}?${new URLSearchParams(params).toString()}`);
                                }
                            })
                            .catch((errorData) => {
                                reject(generateError(errorData));
                            });
                    }

                    setTimeout(request);
                });
            }

            async function saveLogs(
                // logs
            ) {
                // const { backendAPI } = config;

                try {
                    // await Axios.post(`${backendAPI}/server/logs`, JSON.stringify(logs), {
                    //     proxy: config.proxy,
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    // });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getLambdaFunctions() {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/functions`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function runLambdaRequest(body) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/lambda/requests`, JSON.stringify(body), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function callLambdaFunction(funId, body) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.post(`${backendAPI}/lambda/functions/${funId}`, JSON.stringify(body), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getLambdaRequests() {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/requests`, {
                        proxy: config.proxy,
                    });

                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getRequestStatus(requestID) {
                const { backendAPI } = config;

                try {
                    const response = await Axios.get(`${backendAPI}/lambda/requests/${requestID}`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function cancelLambdaRequest(requestId) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/lambda/requests/${requestId}`, {
                        method: 'DELETE',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            function predictorStatus(projectId) {
                const { backendAPI } = config;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios.get(`${backendAPI}/predict/status`, {
                                params: {
                                    project: projectId,
                                },
                            });
                            return response.data;
                        } catch (errorData) {
                            throw generateError(errorData);
                        }
                    }

                    const timeoutCallback = async () => {
                        let data = null;
                        try {
                            data = await request();
                            if (data.status === 'queued') {
                                setTimeout(timeoutCallback, 1000);
                            } else if (data.status === 'done') {
                                resolve(data);
                            } else {
                                throw new Error(`Unknown status was received "${data.status}"`);
                            }
                        } catch (error) {
                            reject(error);
                        }
                    };

                    setTimeout(timeoutCallback);
                });
            }

            function predictAnnotations(taskId, frame) {
                return new Promise((resolve, reject) => {
                    const { backendAPI } = config;

                    async function request() {
                        try {
                            const response = await Axios.get(`${backendAPI}/predict/frame`, {
                                params: {
                                    task: taskId,
                                    frame,
                                },
                            });
                            return response.data;
                        } catch (errorData) {
                            throw generateError(errorData);
                        }
                    }

                    const timeoutCallback = async () => {
                        let data = null;
                        try {
                            data = await request();
                            if (data.status === 'queued') {
                                setTimeout(timeoutCallback, 1000);
                            } else if (data.status === 'done') {
                                predictAnnotations.latestRequest.fetching = false;
                                resolve(data.annotation);
                            } else {
                                throw new Error(`Unknown status was received "${data.status}"`);
                            }
                        } catch (error) {
                            predictAnnotations.latestRequest.fetching = false;
                            reject(error);
                        }
                    };

                    const closureId = Date.now();
                    predictAnnotations.latestRequest.id = closureId;
                    const predicate = () => !predictAnnotations.latestRequest.fetching ||
                        predictAnnotations.latestRequest.id !== closureId;
                    if (predictAnnotations.latestRequest.fetching) {
                        waitFor(5, predicate).then(() => {
                            if (predictAnnotations.latestRequest.id !== closureId) {
                                resolve(null);
                            } else {
                                predictAnnotations.latestRequest.fetching = true;
                                setTimeout(timeoutCallback);
                            }
                        });
                    } else {
                        predictAnnotations.latestRequest.fetching = true;
                        setTimeout(timeoutCallback);
                    }
                });
            }

            predictAnnotations.latestRequest = {
                fetching: false,
                id: null,
            };

            async function installedApps() {
                const { backendAPI } = config;
                try {
                    const response = await Axios.get(`${backendAPI}/server/plugins`, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function createCloudStorage(storageDetail) {
                const { backendAPI } = config;

                const storageDetailData = prepareData(storageDetail);
                try {
                    const response = await Axios.post(`${backendAPI}/cloudstorages`, storageDetailData, {
                        proxy: config.proxy,
                    });
                    return response.data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function updateCloudStorage(id, storageDetail) {
                const { backendAPI } = config;

                const storageDetailData = prepareData(storageDetail);
                try {
                    await Axios.patch(`${backendAPI}/cloudstorages/${id}`, storageDetailData, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getCloudStorages(filter = '') {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/cloudstorages?page_size=12&${filter}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function getCloudStorageContent(id, manifestPath) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/content${
                        manifestPath ? `?manifest_path=${manifestPath}` : ''
                    }`;
                    response = await Axios.get(url, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function getCloudStoragePreview(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/preview`;
                    response = await workerAxios.get(url, {
                        params: enableOrganization(),
                        proxy: config.proxy,
                        responseType: 'arraybuffer',
                    });
                } catch (errorData) {
                    throw generateError({
                        message: '',
                        response: {
                            ...errorData.response,
                            data: String.fromCharCode.apply(null, new Uint8Array(errorData.response.data)),
                        },
                    });
                }

                return new Blob([new Uint8Array(response)]);
            }

            async function getCloudStorageStatus(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    const url = `${backendAPI}/cloudstorages/${id}/status`;
                    response = await Axios.get(url, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function deleteCloudStorage(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/cloudstorages/${id}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getOrganizations() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/organizations`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function createOrganization(data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/organizations`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function updateOrganization(id, data) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.patch(`${backendAPI}/organizations/${id}`, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function deleteOrganization(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/organizations/${id}`, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getOrganizationMembers(orgSlug, page, pageSize, filters = {}) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/memberships`, {
                        proxy: config.proxy,
                        params: {
                            ...filters,
                            org: orgSlug,
                            page,
                            page_size: pageSize,
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function inviteOrganizationMembers(orgId, data) {
                const { backendAPI } = config;
                try {
                    await Axios.post(
                        `${backendAPI}/invitations`,
                        {
                            ...data,
                            organization: orgId,
                        },
                        {
                            proxy: config.proxy,
                        },
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function updateOrganizationMembership(membershipId, data) {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.patch(
                        `${backendAPI}/memberships/${membershipId}`,
                        {
                            ...data,
                        },
                        {
                            proxy: config.proxy,
                        },
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function deleteOrganizationMembership(membershipId) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/memberships/${membershipId}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getMembershipInvitation(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/invitations/${id}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    server: {
                        value: Object.freeze({
                            about,
                            share,
                            formats,
                            exception,
                            login,
                            logout,
                            changePassword,
                            requestPasswordReset,
                            resetPassword,
                            authorized,
                            register,
                            request: serverRequest,
                            userAgreements,
                            installedApps,
                        }),
                        writable: false,
                    },

                    projects: {
                        value: Object.freeze({
                            get: getProjects,
                            searchNames: searchProjectNames,
                            save: saveProject,
                            create: createProject,
                            delete: deleteProject,
                            exportDataset: exportDataset('projects'),
                            backupProject,
                            restoreProject,
                            importDataset,
                        }),
                        writable: false,
                    },

                    tasks: {
                        value: Object.freeze({
                            get: getTasks,
                            save: saveTask,
                            create: createTask,
                            delete: deleteTask,
                            exportDataset: exportDataset('tasks'),
                            export: exportTask,
                            import: importTask,
                        }),
                        writable: false,
                    },

                    jobs: {
                        value: Object.freeze({
                            get: getJobs,
                            save: saveJob,
                            data3D,
                            submit,
                            saveRelation,
                            getRelation,
                        }),
                        writable: false,
                    },

                    users: {
                        value: Object.freeze({
                            get: getUsers,
                            self: getSelf,
                        }),
                        writable: false,
                    },

                    frames: {
                        value: Object.freeze({
                            getData,
                            getMeta,
                            getPreview,
                            getImageContext,
                        }),
                        writable: false,
                    },

                    annotations: {
                        value: Object.freeze({
                            updateAnnotations,
                            getAnnotations,
                            dumpAnnotations,
                            uploadAnnotations,
                        }),
                        writable: false,
                    },

                    logs: {
                        value: Object.freeze({
                            save: saveLogs,
                        }),
                        writable: false,
                    },

                    lambda: {
                        value: Object.freeze({
                            list: getLambdaFunctions,
                            status: getRequestStatus,
                            requests: getLambdaRequests,
                            run: runLambdaRequest,
                            call: callLambdaFunction,
                            cancel: cancelLambdaRequest,
                        }),
                        writable: false,
                    },

                    issues: {
                        value: Object.freeze({
                            create: createIssue,
                            update: updateIssue,
                            get: getJobIssues,
                            getJobBeforehands: getJobBeforehandIssues,
                            delete: deleteIssue,
                        }),
                        writable: false,
                    },

                    comments: {
                        value: Object.freeze({
                            create: createComment,
                        }),
                        writable: false,
                    },

                    predictor: {
                        value: Object.freeze({
                            status: predictorStatus,
                            predict: predictAnnotations,
                        }),
                        writable: false,
                    },

                    cloudStorages: {
                        value: Object.freeze({
                            get: getCloudStorages,
                            getContent: getCloudStorageContent,
                            getPreview: getCloudStoragePreview,
                            getStatus: getCloudStorageStatus,
                            create: createCloudStorage,
                            delete: deleteCloudStorage,
                            update: updateCloudStorage,
                        }),
                        writable: false,
                    },

                    organizations: {
                        value: Object.freeze({
                            get: getOrganizations,
                            create: createOrganization,
                            update: updateOrganization,
                            members: getOrganizationMembers,
                            invitation: getMembershipInvitation,
                            delete: deleteOrganization,
                            invite: inviteOrganizationMembers,
                            updateMembership: updateOrganizationMembership,
                            deleteMembership: deleteOrganizationMembership,
                        }),
                        writable: false,
                    },
                }),
            );
        }
    }

    const serverProxy = new ServerProxy();
    module.exports = serverProxy;
})();
