/*
 * @Author: swxy
 * @Date: 2022-08-11 10:37:11
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import React, { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import Dialog from 'rc-dialog';
import { noImageBase64 } from './imageUtils';
import { createPortal } from 'react-dom';
import { getFixScaleEleTransPosition } from './imageUtils';
import ImageCanvas from './imageCanvas';
import { context } from './imageGroup';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { Canvas3d, ViewsDOM, ProjectionInfo } from 'cvat-canvas3d-wrapper';
import { Row, Space, Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface CanvasProps
    extends React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    src: string;
    projectInfo?: ProjectionInfo;
    onlyShowSelect?: boolean;
}

const initPosition = {
    x: 0,
    y: 0,
};

const previewImageClassName = 'aatp_image_mask_preview_image';
const previewImageMoveClassName = 'aatp_image_mask_preview_image_move';

// 画布
const Canvas: FC<CanvasProps> = memo(({ src, projectInfo, onlyShowSelect = false, ...canvasProps }) => {
    const canvasRef = useRef<HTMLDivElement>(null);

    const [className, setClassName] = useState(previewImageClassName);
    const [move, setMove] = useState(false);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState<{ x: number; y: number }>(initPosition);

    // const { current } = useContext(context);

    const originPositionRef = useRef<{
        originX: number;
        originY: number;
        deltaX: number;
        deltaY: number;
    }>({
        originX: 0,
        originY: 0,
        deltaX: 0,
        deltaY: 0,
    });

    // const clearRect = () => {
    //     const canvasCtx = canvasImageRef.current?.getContext('2d');
    //     if (canvasCtx) {
    //         canvasCtx.clearRect(0, 0, canvasCtx.canvas.width, canvasCtx.canvas.height);
    //     }
    // };

    // useEffect(() => {
    //     clearRect();
    //     const canvasCtx = canvasImageRef.current?.getContext('2d');
    //     if (canvasCtx) {
    //         canvasCtx?.drawImage(src, 0, 0, canvasCtx.canvas.width, canvasCtx.canvas.height);
    //     }
    // }, []);

    const clickImage: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
    };

    const onStartMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.button === 0) {
            e?.stopPropagation();
            e?.preventDefault();
            setClassName(`${previewImageClassName} ${previewImageMoveClassName}`);
            originPositionRef.current.deltaX = e.pageX - position.x;
            originPositionRef.current.deltaY = e.pageY - position.y;
            originPositionRef.current.originX = position.x;
            originPositionRef.current.originY = position.y;
            setMove(true);
        }
    };

    const onMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (move) {
            setPosition({
                x: e.pageX - originPositionRef.current.deltaX,
                y: e.pageY - originPositionRef.current.deltaY,
            });
        }
    };

    const onEndMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
        // maskPreviewImageClassName = `${previewImageClassName}`;
        if (move) {
            // const width = canvasRef.current!.offsetWidth * scale;
            // const height = canvasRef.current!.offsetHeight * scale;
            // const { left, top } = canvasRef.current!.getBoundingClientRect();
            // const fixState = getFixScaleEleTransPosition(width, height, left, top);

            setPosition({
                x: e.pageX - originPositionRef.current.deltaX,
                y: e.pageY - originPositionRef.current.deltaY,
            });
            setMove(false);
            setClassName(`${previewImageClassName}`);
        }
    };

    // 缩放
    const onWheelScroll: React.WheelEventHandler<HTMLDivElement> = (e) => {
        // 最小0.2， 最大无限。 小于1时，缩放减速至五分之一倍。大于1时，成倍成倍的缩放
        const scaleSpeed = 1; // 高速缩放
        const scaleMinSpeed = 0.2; // 低速缩放

        // 不足以高速缩放时，就进行低速缩放。直至不足以低速缩放。
        if (e.deltaY > 0 && scale >= scaleSpeed * 2) {
            setScale((oldScale) => oldScale - scaleSpeed);
        } else if (e.deltaY > 0 && scale >= scaleMinSpeed * 2) {
            setScale((oldScale) => oldScale - scaleMinSpeed);
        } else if (e.deltaY < 0 && scale < scaleSpeed) {
            setScale((oldScale) => oldScale + scaleMinSpeed);
        } else if (e.deltaY < 0) {
            setScale((oldScale) => oldScale + scaleSpeed);
        }
    };

    const reset: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setScale(1);
        setPosition(initPosition);
    };

    return (
        <div className={className} onWheel={onWheelScroll}>
            <div
                className='aatp_image_mask_preview_image_background'
                onMouseDown={onStartMove}
                onMouseMove={onMove}
                onMouseUp={onEndMove}
                onDoubleClick={reset}
                onClick={clickImage}
                style={{ transform: `translate3d(${position.x}px, ${position.y}px, 0) scale3d(${scale}, ${scale}, 1)` }}
            >
                <ImageCanvas
                    type='preview'
                    projectInfo={projectInfo}
                    imageRef={canvasRef}
                    onlyShowSelect={onlyShowSelect}
                    url={src}
                    // canvasProps={{ width: src.width, height: src.height }}
                    // {...canvasProps}
                />
            </div>
        </div>
    );
});

// 展示背景板
const Preview: FC = ({}) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);
    const [src, setImage] = useState<string>();
    const [projectInfo, setProjectInfo] = useState<ProjectionInfo>();

    const { current, setCurrent, previewContexts, isImageGroup, preview: groupPreview } = useContext(context);

    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance) as Canvas3d;

    const [onlyShowSelect, setOnlyShowSelect] = useState<boolean>(false);

    useEffect(() => {
        // current变更，获取current的画布
        if (current !== 0 && previewContexts.has(current)) {
            const preview = previewContexts.get(current);
            // console.log('设置了', previewCtx?.context);
            setVisible(true);
            // console.log('current：', current);
            // console.log('image：', previewContexts);

            setImage(preview?.context);
        } else {
            setVisible(false);
            setImage(undefined);
        }
    }, [current]);

    const clickMask: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        // 取消
        setCurrent(0);
    };

    const onProjection = (event: any) => {
        const { projectInfo: proInfo }: { projectInfo: ProjectionInfo; actID: number } = event.detail;

        setProjectInfo({ ...proInfo });
    };

    useEffect(() => {
        const canvasInstanceDOM = canvasInstance.html() as ViewsDOM;
        canvasInstanceDOM.perspective.addEventListener('canvas.projection', onProjection);
        return () => {
            canvasInstanceDOM.perspective.removeEventListener('canvas.projection', onProjection);
        };
    }, []);

    const body = document.getElementsByTagName('body')[0];
    return createPortal(
        <div style={{ display: visible ? undefined : 'none' }} className='aatp_image_dialog' onClick={clickMask}>
            <Row
                className='aatp_image_buttons'
                onClick={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                }}
            >
                <Space>
                    <Tooltip title={t('workspace.content.image.only_show_projection_tip')}>
                        <Checkbox
                            value={onlyShowSelect}
                            // checked={onlyShowSelect}
                            onChange={(e) => {
                                setOnlyShowSelect(e.target.checked);
                            }}
                        >
                            {t('workspace.content.image.only_show_select')}
                        </Checkbox>
                    </Tooltip>
                </Space>
            </Row>
            {!src && <img src={noImageBase64} />}
            {src && <Canvas projectInfo={projectInfo} src={src} onlyShowSelect={onlyShowSelect} />}
        </div>,
        body,
    );
};

export default Preview;
