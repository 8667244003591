// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col, Typography, Tooltip, Select, Button } from 'antd';
import { useSelector } from 'react-redux';
import Collapse from 'antd/lib/collapse';
import LabelSelector from 'components/label-selector/label-selector';
import { CombinedState } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';
// import ItemAttribute from './object-item-attribute';

const { Text } = Typography;
interface Props {
    readonly: boolean;
    collapsed: boolean;
    // attributes: any[];
    // values: Record<number, string>;
    labelID: number;
    labels: any[];
    parentIDs: number[];
    parentID: number | undefined;
    serverID: number | undefined;
    changeRelegation(parentID?: number | undefined): void;

    // changeAttribute(attrID: number, value: string): void;
    collapse(): void;
    changeLabel(label: any): void;
}

export function attrValuesAreEqual(next: Record<number, string>, prev: Record<number, string>): boolean {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);

    return (
        nextKeys.length === prevKeys.length &&
        nextKeys.map((key: string): boolean => prev[+key] === next[+key]).every((value: boolean) => value)
    );
}

// function attrAreTheSame(prevProps: Props, nextProps: Props): boolean {
//     return (
//         nextProps.readonly === prevProps.readonly &&
//         nextProps.collapsed === prevProps.collapsed &&
//         nextProps.attributes === prevProps.attributes &&
//         attrValuesAreEqual(nextProps.values, prevProps.values)
//     );
// }

function ItemContextMenuComponent(props: Props): JSX.Element {
    const {
        collapsed,
        // attributes,
        // values,
        readonly,
        labels,
        labelID,
        // states,
        // changeAttribute,
        parentIDs,
        parentID,
        serverID,
        changeRelegation,
        collapse,
        changeLabel,
    } = props;

    const { t } = useTranslation();

    // const sorted = [...attributes].sort((a: any, b: any): number => a.inputType.localeCompare(b.inputType));
    // 调整顺序，由类型的字符顺序，更改为id顺序
    // const sorted = [...attributes].sort((a: any, b: any): number => a.id - b.id);

    const attrNameStyle: React.CSSProperties = { wordBreak: 'break-word', lineHeight: '1em' };

    const { normalizedKeyMap } = useSelector((state: CombinedState) => state.shortcuts);
    return (
        <Row>
            <Collapse
                className='cvat-objects-sidebar-state-item-collapse'
                activeKey={collapsed ? [] : ['details']}
                onChange={collapse}
                ghost={false}
            >
                <Collapse.Panel showArrow={false} header={null} key='details'>
                    <Row
                        align='middle'
                        justify='start'
                        className='cvat-object-item-attribute-wrapper object-item-attribute-change-label'
                    >
                        <Col span={8} style={attrNameStyle}>
                            <Text className='cvat-text'>{t('workspace.side.object.label_type')}</Text>
                        </Col>
                        <Col span={8}>
                            {/* <Tooltip title='Change current label'> */}
                            {/* <Tooltip title='更改标签类型'> */}
                            <LabelSelector
                                disabled={readonly}
                                size='small'
                                labels={labels}
                                value={labelID}
                                onChange={changeLabel}
                                className='aatp-objects-sidebar-state-item-label-selector'
                            />
                            {/* </Tooltip> */}
                        </Col>
                    </Row>
                    <Row
                        align='middle'
                        justify='start'
                        className='cvat-object-item-attribute-wrapper object-item-attribute-change-label'
                    >
                        <Col span={8} style={attrNameStyle}>
                            {/* <Text className='cvat-text'>父对象ID</Text> */}
                            <Text className='cvat-text'>{t('workspace.side.object.parent_id')}</Text>
                        </Col>
                        <Col span={8}>
                            {/* <Tooltip title='Change current label'> */}
                            {/* <Tooltip title='将当前对象归属于选择的ID所代表的对象'> */}
                            <Tooltip title={t('workspace.side.object.parent_id')}>
                                {/* <LabelSelector
                                    disabled={readonly}
                                    size='small'
                                    labels={labels}
                                    value={labelID}
                                    onChange={changeLabel}
                                    className='aatp-objects-sidebar-state-item-label-selector'
                                /> */}
                                <Select
                                    className='aatp-objects-sidebar-state-item-label-selector'
                                    virtual={false}
                                    disabled={readonly || !serverID}
                                    // allowClear
                                    size='small'
                                    value={parentID}
                                    showSearch
                                    // defaultValue={parentIDs && parentIDs.length ? parentIDs[0] : undefined}
                                    onChange={(value: number | undefined) => {
                                        changeRelegation(value);
                                    }}
                                >
                                    {parentIDs.map((parent: number) => (
                                        <Select.Option title={`${parent}`} key={parent} value={parent}>
                                            {parent}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip
                                title={`${t('workspace.side.object.cancel_relation_tip')} ${
                                    normalizedKeyMap.CANCLE_RELATION_ON_ACTIVITY
                                }`}
                            >
                                <Button
                                    disabled={readonly || !serverID || !parentID}
                                    type='link'
                                    danger
                                    onClick={() => {
                                        changeRelegation();
                                    }}
                                >
                                    {t('workspace.side.object.cancel_relation')}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {/* {sorted.map(
                        (attribute: any): JSX.Element => (
                            <Row
                                key={attribute.id}
                                align='middle'
                                justify='start'
                                className='cvat-object-item-attribute-wrapper'
                            >
                                <ItemAttribute
                                    readonly={readonly}
                                    attrValue={values[attribute.id]}
                                    attrInputType={attribute.inputType}
                                    attrName={attribute.name}
                                    attrID={attribute.id}
                                    attrValues={attribute.values}
                                    changeAttribute={changeAttribute}
                                />
                            </Row>
                        ),
                    )} */}
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
}

export default React.memo(ItemContextMenuComponent);
