// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { Canvas3d } from 'cvat-canvas3d/src/typescript/canvas3d';
import { Canvas, RectDrawingMethod, CuboidDrawingMethod } from 'cvat-canvas-wrapper';
import { IntelligentScissors } from 'utils/opencv-wrapper/intelligent-scissors';
import { KeyMap } from 'utils/mousetrap-react';
import { OpenCVTracker } from 'utils/opencv-wrapper/opencv-interfaces';

export type Language = 'zh_CN' | 'en_US' | 'ja_JP';

export type StringObject = {
    [index: string]: string;
};

/**
 * Annotation type
 * @enum {string}
 * @name Source
 * @memberof module:API.cvat.enums
 * @property {string} MANUAL 'manual'
 * @property {string} AUTO 'auto'
 * @readonly
 */
enum Source {
    MANUAL = 'manual',
    AUTO = 'auto',
}

export interface ObjectState {
    label: Label;
    attributes: any;

    points: number[];
    outside: boolean;
    occluded: boolean;
    keyframe: boolean;

    zOrder: number | null;
    lock: boolean;
    color: string;
    hidden: boolean;
    pinned: boolean | null;
    activeHidden: boolean;
    source: Source;
    keyframes: any | null;
    group: any;
    updated: number;

    clientID: any;
    serverID: number;

    frame: number;
    objectType: ObjectType;
    shapeType: ShapeType;
    updateFlags: {
        label: boolean;
        attributes: boolean;

        points: boolean;
        outside: boolean;
        occluded: boolean;
        keyframe: boolean;

        zOrder: boolean;
        pinned: boolean;
        lock: boolean;
        color: boolean;
        hidden: boolean;
        activeHidden: boolean;
        relation: boolean;
    };
    relation: {
        parent: ObjectState;
        children: ObjectState[];
    };
}

export interface AuthState {
    initialized: boolean;
    fetching: boolean;
    user: any;
    authActionsFetching: boolean;
    authActionsInitialized: boolean;
    showChangePasswordDialog: boolean;
    allowChangePassword: boolean;
    allowResetPassword: boolean;
}

export interface ProjectsQuery {
    page: number;
    id: number | null;
    search: string | null;
    owner: string | null;
    name: string | null;
    status: string | null;
    assignee: string | null;
    [key: string]: string | boolean | number | null | undefined;
}

export interface Project {
    instance: any;
    preview: string;
}

export interface ProjectsState {
    initialized: boolean;
    fetching: boolean;
    count: number;
    current: Project[];
    gettingQuery: ProjectsQuery;
    tasksGettingQuery: TasksQuery;
    activities: {
        creates: {
            id: null | number;
            error: string;
        };
        deletes: {
            [projectId: number]: boolean; // deleted (deleting if in dictionary)
        };
        backups: {
            [projectId: number]: boolean;
        };
    };
    restoring: boolean;
}

export interface TasksQuery {
    page?: number;
    id?: number | null;
    search?: string | null;
    owner?: string | null;
    assignee?: string | null;
    name?: string | null;
    status?: string | null;
    mode?: string | null;
    filter?: string | null;
    projectId?: number | null;
    [key: string]: string | number | null | undefined;
}

export interface Task {
    instance: any; // cvat-core instance
    preview: string;
}

export interface JobsQuery {
    page?: number;
    sort?: string | null;
    search?: string | null;
    filter?: string | null;
    taskId: number;
    pageSize?: number;
    pageIndex?: number;
}

export interface JobsState {
    query: JobsQuery;
    fetching: boolean;
    count: number;
    current: any[];
    previews: string[];
}

export interface TasksState {
    importing: boolean;
    initialized: boolean;
    fetching: boolean;
    updating: boolean;
    hideEmpty: boolean;
    moveTask: {
        modalVisible: boolean;
        taskId: number | null;
    };
    gettingQuery: TasksQuery;
    count: number;
    current: Task[];
    activities: {
        loads: {
            // only one loading simultaneously
            [tid: number]: string; // loader name
        };
        deletes: {
            [tid: number]: boolean; // deleted (deleting if in dictionary)
        };
        creates: {
            taskId: number | null;
            status: string;
            error: string;
        };
        backups: {
            [tid: number]: boolean;
        };
    };
}

export interface ExportState {
    tasks: {
        [tid: number]: string[];
    };
    projects: {
        [pid: number]: string[];
    };
    instance: any;
    modalVisible: boolean;
}

export interface ImportState {
    importingId: number | null;
    progress: number;
    status: string;
    instance: any;
    modalVisible: boolean;
}

export interface FormatsState {
    annotationFormats: any;
    fetching: boolean;
    initialized: boolean;
}

export interface CloudStoragesQuery {
    page: number;
    id: number | null;
    search: string | null;
    owner: string | null;
    displayName: string | null;
    description: string | null;
    resource: string | null;
    providerType: string | null;
    credentialsType: string | null;
    [key: string]: string | number | null | undefined;
}

interface CloudStorageAdditional {
    fetching: boolean;
    initialized: boolean;
    status: string | null;
    preview: string;
}
type CloudStorageStatus = Pick<CloudStorageAdditional, 'fetching' | 'initialized' | 'status'>;
type CloudStoragePreview = Pick<CloudStorageAdditional, 'fetching' | 'initialized' | 'preview'>;

export type CloudStorage = any;

export interface CloudStoragesState {
    initialized: boolean;
    fetching: boolean;
    count: number;
    current: CloudStorage[];
    statuses: {
        [index: number]: CloudStorageStatus;
    };
    previews: {
        [index: number]: CloudStoragePreview;
    };
    gettingQuery: CloudStoragesQuery;
    activities: {
        creates: {
            attaching: boolean;
            id: null | number;
            error: string;
        };
        updates: {
            updating: boolean;
            cloudStorageID: null | number;
            error: string;
        };
        deletes: {
            [cloudStorageID: number]: boolean;
        };
        contentLoads: {
            cloudStorageID: number | null;
            content: any | null;
            fetching: boolean;
            error: string;
        };
    };
}

export enum SupportedPlugins {
    GIT_INTEGRATION = 'GIT_INTEGRATION',
    ANALYTICS = 'ANALYTICS',
    MODELS = 'MODELS',
    PREDICT = 'PREDICT',
}

export type PluginsList = {
    [name in SupportedPlugins]: boolean;
};

export interface PluginsState {
    fetching: boolean;
    initialized: boolean;
    list: PluginsList;
}

export interface AboutState {
    server: any;
    packageVersion: {
        core: string;
        canvas: string;
        ui: string;
    };
    fetching: boolean;
    initialized: boolean;
}

export interface UserAgreement {
    name: string;
    displayText: string;
    url: string;
    required: boolean;
}

export interface UserAgreementsState {
    list: UserAgreement[];
    fetching: boolean;
    initialized: boolean;
}

export interface ShareFileInfo {
    // get this data from cvat-core
    name: string;
    type: 'DIR' | 'REG';
}

export interface ShareItem {
    name: string;
    type: 'DIR' | 'REG';
    children: ShareItem[];
}

export interface ShareState {
    root: ShareItem;
}

export interface Model {
    id: string;
    name: string;
    labels: string[];
    framework: string;
    description: string;
    type: string;
    onChangeToolsBlockerState: (event: string) => void;
    tip: {
        message: string;
        gif: string;
    };
    params: {
        canvas: Record<string, number | boolean>;
    };
}

export type OpenCVTool = IntelligentScissors | OpenCVTracker;

export interface ToolsBlockerState {
    algorithmsLocked?: boolean;
    buttonVisible?: boolean;
}

export enum TaskStatus {
    ANNOTATION = 'annotation',
    REVIEW = 'validation',
    COMPLETED = 'completed',
}

export enum JobStage {
    ANNOTATION = 'annotation',
    REVIEW = 'validation',
    ACCEPTANCE = 'acceptance',
    FINISH = 'finish',
}

export enum RQStatus {
    unknown = 'unknown',
    queued = 'queued',
    started = 'started',
    finished = 'finished',
    failed = 'failed',
}

export interface ActiveInference {
    status: RQStatus;
    progress: number;
    error: string;
    id: string;
}

export interface ModelsState {
    initialized: boolean;
    fetching: boolean;
    creatingStatus: string;
    interactors: Model[];
    detectors: Model[];
    trackers: Model[];
    reid: Model[];
    inferences: {
        [index: number]: ActiveInference;
    };
    modelRunnerIsVisible: boolean;
    modelRunnerTask: any;
}

export interface ErrorState {
    message: string;
    reason: string;
    className?: string;
}

export interface NotificationsState {
    errors: {
        auth: {
            authorized: null | ErrorState;
            login: null | ErrorState;
            logout: null | ErrorState;
            register: null | ErrorState;
            changePassword: null | ErrorState;
            requestPasswordReset: null | ErrorState;
            resetPassword: null | ErrorState;
            loadAuthActions: null | ErrorState;
        };
        projects: {
            fetching: null | ErrorState;
            updating: null | ErrorState;
            deleting: null | ErrorState;
            creating: null | ErrorState;
            restoring: null | ErrorState;
            backuping: null | ErrorState;
        };
        tasks: {
            fetching: null | ErrorState;
            updating: null | ErrorState;
            dumping: null | ErrorState;
            loading: null | ErrorState;
            exportingAsDataset: null | ErrorState;
            deleting: null | ErrorState;
            creating: null | ErrorState;
            exporting: null | ErrorState;
            importing: null | ErrorState;
            moving: null | ErrorState;
        };
        jobs: {
            updating: null | ErrorState;
            fetching: null | ErrorState;
        };
        formats: {
            fetching: null | ErrorState;
        };
        users: {
            fetching: null | ErrorState;
        };
        about: {
            fetching: null | ErrorState;
        };
        share: {
            fetching: null | ErrorState;
        };
        models: {
            starting: null | ErrorState;
            fetching: null | ErrorState;
            canceling: null | ErrorState;
            metaFetching: null | ErrorState;
            inferenceStatusFetching: null | ErrorState;
        };
        annotation: {
            saving: null | ErrorState;
            jobFetching: null | ErrorState;
            frameFetching: null | ErrorState;
            contextImageFetching: null | ErrorState;
            changingLabelColor: null | ErrorState;
            updating: null | ErrorState;
            creating: null | ErrorState;
            merging: null | ErrorState;
            grouping: null | ErrorState;
            splitting: null | ErrorState;
            removing: null | ErrorState;
            propagating: null | ErrorState;
            collectingStatistics: null | ErrorState;
            savingJob: null | ErrorState;
            uploadAnnotations: null | ErrorState;
            removeAnnotations: null | ErrorState;
            fetchingAnnotations: null | ErrorState;
            undo: null | ErrorState;
            redo: null | ErrorState;
            search: null | ErrorState;
            searchEmptyFrame: null | ErrorState;
            savingLogs: null | ErrorState;
        };
        boundaries: {
            resetError: null | ErrorState;
        };
        userAgreements: {
            fetching: null | ErrorState;
        };
        review: {
            finishingIssue: null | ErrorState;
            resolvingIssue: null | ErrorState;
            reopeningIssue: null | ErrorState;
            commentingIssue: null | ErrorState;
            submittingReview: null | ErrorState;
            deletingIssue: null | ErrorState;
        };
        predictor: {
            prediction: null | ErrorState;
        };
        exporting: {
            dataset: null | ErrorState;
            annotation: null | ErrorState;
        };
        importing: {
            dataset: null | ErrorState;
            annotation: null | ErrorState;
        };
        cloudStorages: {
            creating: null | ErrorState;
            fetching: null | ErrorState;
            updating: null | ErrorState;
            deleting: null | ErrorState;
        };
        organizations: {
            fetching: null | ErrorState;
            creating: null | ErrorState;
            updating: null | ErrorState;
            activation: null | ErrorState;
            deleting: null | ErrorState;
            leaving: null | ErrorState;
            inviting: null | ErrorState;
            updatingMembership: null | ErrorState;
            removingMembership: null | ErrorState;
        };
    };
    messages: {
        tasks: {
            loadingDone: string;
            importingDone: string;
            movingDone: string;
        };
        models: {
            inferenceDone: string;
        };
        auth: {
            changePasswordDone: string;
            registerDone: string;
            requestPasswordResetDone: string;
            resetPasswordDone: string;
        };
        projects: {
            restoringDone: string;
        };
    };
}

export enum ActiveControl {
    CURSOR = 'cursor',
    DRAG_CANVAS = 'drag_canvas',
    ZOOM_CANVAS = 'zoom_canvas',
    DRAW_RECTANGLE = 'draw_rectangle',
    DRAW_POLYGON = 'draw_polygon',
    DRAW_POLYLINE = 'draw_polyline',
    DRAW_POINTS = 'draw_points',
    DRAW_ELLIPSE = 'draw_ellipse',
    DRAW_CUBOID = 'draw_cuboid',
    MERGE = 'merge',
    GROUP = 'group',
    SPLIT = 'split',
    EDIT = 'edit',
    OPEN_ISSUE = 'open_issue',
    AI_TOOLS = 'ai_tools',
    PHOTO_CONTEXT = 'PHOTO_CONTEXT',
    OPENCV_TOOLS = 'opencv_tools',
    MULT_DRAW = 'mult_dram',
}

export enum ShapeType {
    RECTANGLE = 'rectangle',
    POLYGON = 'polygon',
    POLYLINE = 'polyline',
    POINTS = 'points',
    ELLIPSE = 'ellipse',
    CUBOID = 'cuboid',
    IMAGE = 'image',
}

export enum ObjectType {
    SHAPE = 'shape',
    TRACK = 'track',
    TAG = 'tag',
}

// export interface LabelType {
//     rectangle: string;
//     polygon: string;
//     polyline: string;
//     points: string;
//     cuboid: string;
//     [key: string]: string;
// }

// export const SimpleShapeTypeName: { en: Record<string, string>; cn: Record<string, string> } = {
//     en: {
//         rectangle: 'r',
//         polygon: 'pg',
//         polyline: 'pl',
//         points: 'p',
//         cuboid: 'c',
//         image: 'i',
//         ellipse: 'e',
//     },
//     cn: {
//         rectangle: '矩',
//         polygon: '多',
//         polyline: '线',
//         points: '点',
//         cuboid: '体',
//         image: '图',
//         ellipse: '圆',
//     },
// };

export enum StatesOrdering {
    ID_DESCENT = 'ID - descent',
    ID_ASCENT = 'ID - ascent',
    UPDATED = 'Updated time',
}

export enum StatesOrderingLng {
    ID_DESCENT = 'workspace.top.modal.sort_ID_descent',
    ID_ASCENT = 'workspace.top.modal.sort_ID_ascent',
    UPDATED = 'workspace.top.modal.sort_update_time',
    // ID_DESCENT_CN = '对象标识 - 降序',
    // ID_ASCENT_CN = '对象标识 - 升序',
    // UPDATED_CN = '更新时间',
}

export enum ContextMenuType {
    CANVAS_SHAPE = 'canvas_shape',
    CANVAS_SHAPE_POINT = 'canvas_shape_point',
}

export enum Rotation {
    ANTICLOCKWISE90,
    CLOCKWISE90,
}

export interface PredictorState {
    timeRemaining: number;
    progress: number;
    projectScore: number;
    message: string;
    error: Error | null;
    enabled: boolean;
    fetching: boolean;
    annotationAmount: number;
    mediaAmount: number;
    annotatedFrames: number[];
}

// 激活模式， standard模式下，默认是点击激活。review模式下默认是移入激活
export enum ActivationModel {
    clickActive = 'CLICK_ACTIVE',
    moveInActive = 'MOVE_IN_ACTIVE',
}

export enum CameraType {
    normalCamera = '0',
    pinholeCamera = '1',
    fisheyeCamera = '2',
}

export enum CameraTypeCn {
    normalCamera = '无',
    pinholeCamera = '针孔相机',
    fisheyeCamera = '鱼眼相机',
}

export interface AnnotationsSetting {
    visible: boolean;
    cameraType: CameraType;
    cameraIntrinsics:
        | [[number, number, number, number?], [number, number, number, number?], [number, number, number, number?]]
        | null;
    cameraExtrinsics:
        | [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
          ]
        | null; // 外参
    projectionType: string;
    distortion: {
        k1: string;
        k2: string;
        k3: string;
        k4: string;
        k5: string;
        k6: string;
        s1: string | undefined;
        s2: string | undefined;
    }; // 畸变参数
}

export interface AnnotationState {
    activities: {
        loads: {
            // only one loading simultaneously
            [jid: number]: string; // loader name
        };
    };
    canvas: {
        contextMenu: {
            visible: boolean;
            top: number;
            left: number;
            type: ContextMenuType;
            pointID: number | null;
            clientID: number | null;
        };
        instance: Canvas | Canvas3d | null;
        ready: boolean;
        activeControl: ActiveControl;
    };
    job: {
        openTime: null | number;
        labels: any[];
        requestedId: number | null;
        instance: any | null | undefined;
        attributes: Record<number, any[]>;
        fetching: boolean;
        saving: boolean;
        serialidItemBylabelId: Record<number, string>;
    };
    player: {
        frame: {
            number: number;
            filename: string;
            hasRelatedContext: boolean;
            data: any | null;
            fetching: boolean;
            delay: number;
            changeTime: number | null;
        };
        navigationBlocked: boolean;
        playing: boolean;
        frameAngles: number[];
        contextImage: {
            fetching: boolean;
            data: string | null;
            hidden: boolean;
            imagesData: {
                name: string;
                type: string;
                data: string;
            }[];
        };
    };
    drawing: {
        activeInteractor?: Model | OpenCVTool;
        activeShapeType: ShapeType;
        activeRectDrawingMethod?: RectDrawingMethod;
        activeCuboidDrawingMethod?: CuboidDrawingMethod;
        activeNumOfPoints?: number;
        activeLabelID: number;
        activeObjectType: ObjectType;
        activeInitialState?: any;
    };
    annotations: {
        activatedStateID: number | null;
        selectedStatesID: number[];
        activatedAttributeID: number | null;
        collapsed: Record<number, boolean>;
        collapsedAll: boolean;
        states: any[];
        filters: any[];
        resetGroupFlag: boolean;
        history: {
            undo: [string, number][];
            redo: [string, number][];
        };
        saving: {
            forceExit: boolean;
            uploading: boolean;
            statuses: string[];
        };
        zLayer: {
            min: number;
            max: number;
            cur: number;
        };
        annotationsSetting: AnnotationsSetting;
        serialID: {
            minID: number;
            maxID: number;
        };
        activeModel: ActivationModel;
        isOnlyShowSelectStates: boolean;
        isPanelListModel: boolean;
        statesOrdering: StatesOrdering;
    };
    propagate: {
        objectState: any | null;
        frames: number;
    };
    statistics: {
        collecting: boolean;
        visible: boolean;
        data: any;
    };
    colors: any[];
    filtersPanelVisible: boolean;
    sidebarCollapsed: boolean;
    appearanceCollapsed: boolean;
    workspace: Workspace;
    predictor: PredictorState;
    relationOperate: boolean; // 有关联时，父对象操作（移动、删除），子对象一起操作
    sidebarPositionLeft: boolean;
}

export enum Workspace {
    STANDARD3D = 'Standard 3D',
    STANDARD = 'Standard',
    ATTRIBUTE_ANNOTATION = 'Attribute annotation',
    TAG_ANNOTATION = 'Tag annotation',
    REVIEW_WORKSPACE = 'Review',
}

export enum WorkspaceCN {
    'Standard 3D' = '标准 3D',
    'Standard' = '标准',
    'Attribute annotation' = '属性',
    'Tag annotation' = '签条<Tag>',
    'Review' = '审阅',
}

export enum WorkspaceLng {
    'Standard 3D' = 'workspace.top.drop.workspace_standard3d',
    'Standard' = 'workspace.top.drop.workspace_standard',
    'Attribute annotation' = 'workspace.top.drop.workspace_attribute_annotation',
    'Tag annotation' = 'workspace.top.drop.workspace_tag_annotation',
    'Review' = 'workspace.top.drop.workspace_review',
}

export enum GridColor {
    White = 'White',
    Black = 'Black',
    Red = 'Red',
    Green = 'Green',
    Blue = 'Blue',
}

export enum FrameSpeed {
    Fastest = 100,
    Fast = 50,
    Usual = 25,
    Slow = 15,
    Slower = 12,
    Slowest = 1,
}

export enum ColorBy {
    INSTANCE = 'Instance',
    GROUP = 'Group',
    LABEL = 'Label',
}
export enum ColorByLng {
    INSTANCE = 'workspace.side.appearance.instance',
    GROUP = 'workspace.side.appearance.group',
    LABEL = 'workspace.side.appearance.label',
}

export interface PlayerSettingsState {
    canvasBackgroundColor: string;
    frameStep: number;
    frameSpeed: FrameSpeed;
    resetZoom: boolean;
    rotateAll: boolean;
    smoothImage: boolean;
    grid: boolean;
    gridSize: number;
    gridColor: GridColor;
    gridOpacity: number; // in %
    brightnessLevel: number;
    contrastLevel: number;
    saturationLevel: number;
}

export interface WorkspaceSettingsState {
    autoSave: boolean;
    autoSaveInterval: number; // in ms
    aamZoomMargin: number;
    automaticBordering: boolean;
    showObjectsTextAlways: boolean;
    showAllInterpolationTracks: boolean;
    intelligentPolygonCrop: boolean;
    defaultApproxPolyAccuracy: number;
    toolsBlockerState: ToolsBlockerState;
    textFontSize: number;
    textPosition: 'auto' | 'center';
    textContent: string;
    autoSaveByFrame: boolean;
    autoSaveByObject: boolean;
}

export interface ShapesSettingsState {
    colorBy: ColorBy;
    opacity: number;
    selectedOpacity: number;
    outlined: boolean;
    outlineColor: string;
    showBitmap: boolean;
    showProjections: boolean;
}

export interface SettingsState {
    shapes: ShapesSettingsState;
    workspace: WorkspaceSettingsState;
    player: PlayerSettingsState;
    showDialog: boolean;
    language: Language;
}

export interface ShortcutsState {
    visibleShortcutsHelp: boolean;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
}

export enum ReviewStatus {
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    REVIEW_FURTHER = 'review_further',
}

export interface ReviewState {
    beforehandIssues: any[];
    issues: any[];
    frameIssues: any[];
    latestComments: string[];
    newIssuePosition: number[] | null;
    issuesHidden: boolean;
    issuesResolvedHidden: boolean;
    fetching: {
        jobId: number | null;
        issueId: number | null;
    };
    selectIssueID: number | null;
}

export interface OrganizationState {
    list: any[];
    current: any | null;
    initialized: boolean;
    fetching: boolean;
    creating: boolean;
    updating: boolean;
    inviting: boolean;
    leaving: boolean;
    removingMember: boolean;
    updatingMember: boolean;
}

export interface CombinedState {
    auth: AuthState;
    projects: ProjectsState;
    jobs: JobsState;
    tasks: TasksState;
    about: AboutState;
    share: ShareState;
    formats: FormatsState;
    userAgreements: UserAgreementsState;
    plugins: PluginsState;
    models: ModelsState;
    notifications: NotificationsState;
    annotation: AnnotationState;
    settings: SettingsState;
    shortcuts: ShortcutsState;
    review: ReviewState;
    export: ExportState;
    import: ImportState;
    cloudStorages: CloudStoragesState;
    organizations: OrganizationState;
}

export enum DimensionType {
    DIM_3D = '3d',
    DIM_2D = '2d',
}

export interface PCDImageType {
    data: {
        data: Blob;
        name: string;
    };
}

export interface PCDDataType {
    data: {
        data: Blob;
        name: string;
    };
    images: PCDImageType[];
    frame: number;
}

export interface ImageDataType {
    imageData: PCDDataType;
    renderHeight: number;
    renderWidth: number;
}

export enum IssueStaus {
    await,
    resolved,
    finish,
}

export interface Comment {
    id: number;
    message: string;
    createdDate: string;
    updated_date: string;
}

// 批注
export interface Issue {
    jobId: number;
    id: number;
    content: string;
    frame: number;
    // noteStatus: 0 | 1 | 2 | 3; // 0-正常, 1-已通过， 2-已打回， 9-已结束
    noteStatus: IssueStaus; // 0-待解决, 1-已提交 2-已结束
    noteType: 0 | 1 | 2 | 3; // 0-缺省, 1-一般 2-严重 3-最高
    noteUserId?: string;
    points?: number[];
    title?: string;
    updateTime?: string;
    addTime?: string;
    comments?: Comment[];
}

// 当前项目的全局批注
export interface IssueOS {
    id: number;
    projectId: number;
    content: string;
    updateTime?: string;
    addTime?: string;
}

export enum ControlType {
    default,
    serialid,
    select,
    radio,
    checkbox,
    number,
    text,
}

export enum InputType {
    default = 'normal',
    serialid = 'serialid',
    select = 'select',
    radio = 'radio',
    checkbox = 'checkbox',
    number = 'number',
    text = 'text',
}

export interface LabelAttribute {
    id: number;
    name: string;
    inputType: InputType;
    values: string[];
    defaultValue: string;
    mutable: boolean;
    labelSpecType: ControlType;
    toObject?: () => LabelAttribute;
}

export interface ImageLabelAttribute extends LabelAttribute {
    value?: string;
}

export interface Label {
    id: number;
    labelId: number;
    ownId: number;
    name: string;
    projectId: number;
    taskId: number;
    color: string;
    deleted: boolean;
    attributes: LabelAttribute[];
}

export interface ImageLabel extends Label {
    clientID?: number;
    serverID?: number;

    attributes: ImageLabelAttribute[];
}

export enum SubmitStatus {
    creating, // 创建中
    normal, // 正常

    annotating, // 标注中
    submit_frist, // 提交一审
    callback_frist, // 一审打回

    submit_second, // 提交二审
    callback_second, // 二审打回

    submit_acceptance, // 提交验收
    callback_acceptance, // 验收打回

    pass_acceptance, // 验收通过

    finish, // 已结束
}

export enum RoleType {
    default, // 缺省
    normal, // 普通

    annotator = 10, // 标注员
    first_reviewer, // 初级审核员
    second_reviewer, // 高级审核员

    acceptor, // 验收员
    team_leader, // 团队管理员

    project_admin, // 项目管理员
    compony_admin, // 企业管理员

    operation_admin = 20, // 运营管理员

    admin = 99, // 系统管理员
}
